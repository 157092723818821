/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import BrandSettingsValidations from 'embercom/validations/brand-settings';

const SHORT_COLOR_HEX_REGEX = /^#[A-Fa-f0-9]{3}$/;
const MIN_PADDING = 20;
const MAX_PADDING = 32767;

export default class BrandSettings extends Model.extend(BrandSettingsValidations) {
  @service store;
  @service intercomEventService;

  @hasMany('brand-domain') brandDomains;
  @attr('string') brandName;
  @attr('string') messengerLogoUrl;
  @attr('number') messengerLogoId;
  @attr('string') messengerActionColor;
  @attr('string') messengerBackgroundColor;
  @attr('string') messengerWallpaper;
  @attr('string') launcherAlignment;
  @attr('number') launcherHorizontalPadding;
  @attr('number') launcherVerticalPadding;
  @attr('string') launcherLogoUrl;
  @attr('number') launcherLogoId;
  @attr('boolean', { readOnly: true }) isDefault;
  @attr('boolean', { readOnly: true }) defaultMobileBrand;
  @attr('number') helpCenterId;

  // M5 Settings
  @attr('string', { defaultValue: 'solid' }) backgroundType;
  @attr('string') gradientFirstColor;
  @attr('string') gradientSecondColor;
  @attr('string') gradientThirdColor;
  @attr('string', { defaultValue: 'dark' }) textColor;
  @attr('boolean', { defaultValue: true }) fadeToWhite;
  @attr('boolean', { defaultValue: true }) showAvatars;
  @attr('string') backgroundImageUrl;
  @attr('number') backgroundImageId;
  @attr('string') platform;
  @attr('string') mobileBundleId;
  @attr('string') mobilePlatform;

  //Conversational settings
  @attr('boolean', { defaultValue: true }) showConversationBackground;
  @attr('string', { defaultValue: 'colors' }) conversationalBackgroundType;
  @attr('string') backgroundSolidColor;
  @attr('number') brandIdentityLogoId;
  @attr('string') brandIdentityLogoUrl;
  @attr('number') brandId;

  get isMobile() {
    return this.platform === 'mobile';
  }

  get formattedMobilePlatform() {
    if (this.mobilePlatform === 'ios') {
      return 'iOS';
    } else {
      return 'Android';
    }
  }

  get isWeb() {
    return !this.isMobile;
  }

  get showBackgroundImage() {
    return this.backgroundType === 'image' && !!this.backgroundImageUrl;
  }

  get hasGradient() {
    return this.backgroundType === 'colors' && !!this.gradientSecondColor;
  }

  get changedLauncherLogo() {
    return !!this.changedAttributes().launcherLogoUrl;
  }

  addDomain(domain) {
    this.brandDomains.createRecord({ domain });
  }

  removeDomain(domain) {
    domain.deleteRecord();
  }

  get isValid() {
    return this.validations.isValid;
  }

  get brandNameError() {
    let error = this.validations.errors.findBy('attribute', 'brandName');
    if (error) {
      return error.message;
    }
    return null;
  }

  get mobileBundleIdError() {
    let error = this.validations.errors.findBy('attribute', 'mobileBundleId');
    if (error) {
      return error.message;
    }
    return null;
  }

  get allDomainsEmpty() {
    return this.brandDomains.isEvery('isEmpty');
  }

  get messengerActionColorHex() {
    return this.messengerActionColor.slice(1);
  }
  get messengerBackgroundColorHex() {
    return this.messengerBackgroundColor.slice(1);
  }
  get gradientFirstColorHex() {
    return this.gradientFirstColor?.slice(1);
  }
  get gradientSecondColorHex() {
    return this.gradientSecondColor?.slice(1);
  }
  get gradientThirdColorHex() {
    return this.gradientThirdColor?.slice(1);
  }

  get backgroundSolidColorHex() {
    return this.backgroundSolidColor?.slice(1);
  }

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes && this.validPaddings;
  }
  get validPaddings() {
    return (
      this.paddingWithinBoundaries(this.launcherHorizontalPadding) &&
      this.paddingWithinBoundaries(this.launcherVerticalPadding)
    );
  }
  paddingWithinBoundaries(padding) {
    return Number(padding) >= MIN_PADDING && Number(padding) <= MAX_PADDING;
  }

  get lookAndFeelSettings() {
    return this.store.peekAll('messenger-settings/look-and-feel').firstObject;
  }

  get otherBrands() {
    return this.lookAndFeelSettings.brands.reject((brand) => brand === this);
  }

  expandShortColorCode(colorProperty) {
    let color = this[colorProperty];
    if (SHORT_COLOR_HEX_REGEX.exec(color) === null) {
      return;
    }
    let expandedColor = color.replace(/([A-Fa-f0-9])/g, '$1$1');
    this[colorProperty] = expandedColor;
  }

  expandColors() {
    this.expandShortColorCode('messengerActionColor');
    this.expandShortColorCode('messengerBackgroundColor');
    this.expandShortColorCode('gradientFirstColor');
    this.expandShortColorCode('gradientSecondColor');
    this.expandShortColorCode('gradientThirdColor');
    this.expandShortColorCode('backgroundSolidColor');
  }

  // This is a temporary hack while we add an update endpoint
  // with logic to identify default brand settings and skip field copy
  copyM5SettingsToLookAndFeel() {
    if (this.isDefault) {
      this.lookAndFeelSettings.backgroundType = this.backgroundType;
      this.lookAndFeelSettings.textColor = this.textColor;
      this.lookAndFeelSettings.fadeToWhite = this.fadeToWhite;
      this.lookAndFeelSettings.showAvatars = this.showAvatars;
      this.lookAndFeelSettings.gradientFirstColor = this.gradientFirstColor;
      this.lookAndFeelSettings.gradientSecondColor = this.gradientSecondColor;
      this.lookAndFeelSettings.gradientThirdColor = this.gradientThirdColor;
      this.lookAndFeelSettings.backgroundImageId = this.backgroundImageId;
      this.lookAndFeelSettings.backgroundImageUrl = this.backgroundImageUrl;
      this.lookAndFeelSettings.helpCenterId = this.helpCenterId;
      this.lookAndFeelSettings.showConversationBackground = this.showConversationBackground;
      this.lookAndFeelSettings.conversationalBackgroundType = this.conversationalBackgroundType;
      this.lookAndFeelSettings.backgroundSolidColor = this.backgroundSolidColor;
    }
  }

  // This is a temporary hack while we add an update endpoint
  // with logic to identify default brand settings and call the existing commands
  save() {
    if (this.isDefault) {
      return this.lookAndFeelSettings.save();
    }
    this.deleteEmptyDomains();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'save_and_set_live',
      object: 'messenger_brand',
      place: 'messenger_settings',
      section: 'messenger',
      metadata: {
        from_modal: false,
        logo: !!this.messengerLogoUrl,
        background_color: this.messengerBackgroundColor,
        action_color: this.messengerActionColor,
        wallpaper: this.messengerWallpaper,
        launcher_position: this.launcherAlignment,
        side_spacing: this.launcherHorizontalPadding,
        bottom_spacing: this.launcherVerticalPadding,
      },
    });

    return super.save().then(() => {
      // Ember creates duplicate domains from the server response.
      this.deDupeDomains();
    });
  }

  deDupeDomains() {
    this.brandDomains = this.brandDomains.uniqBy('domain');
  }

  deleteEmptyDomains() {
    let emptyDomains = [];
    this.brandDomains.forEach((brandDomain) => {
      if (!brandDomain.domain || brandDomain.domain === '') {
        emptyDomains.push(brandDomain);
      }
    });

    emptyDomains.forEach((emptyDomain) => {
      this.brandDomains.removeObject(emptyDomain);
      emptyDomain.unloadRecord();
    });
  }

  rollbackAttributes() {
    let domainsToRollback = [];
    this.brandDomains.forEach((brandDomain) => {
      if (brandDomain.hasDirtyAttributes) {
        domainsToRollback.push(brandDomain);
      }
    });
    domainsToRollback.forEach((dirtyBrandDomain) => {
      dirtyBrandDomain.rollbackAttributes();
    });
    this.deleteEmptyDomains();

    return super.rollbackAttributes();
  }
}
