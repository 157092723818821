/* RESPONSIBLE TEAM: team-standalone */
import type HandoffToUrl from 'embercom/models/operator/visual-builder/step/handoff-to-url';
import StepConfig, { type StepConfigParams } from '../step';

export default class HandoffToUrlConfiguration extends StepConfig {
  declare step: HandoffToUrl;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/handoff-to-url',
      ...params,
    });
  }

  get alignment() {
    return 'left' as const;
  }
}
