/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

export default class ChangeConversationPriority extends Step {
  get supportedChannels() {
    return SUPPORTED_CHANNELS.changeConversationPriority;
  }

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'inbox_priority_rules';
  }

  static createNewStep(store: Store): ChangeConversationPriority {
    return store.createRecord('operator/visual-builder/step/change-conversation-priority', {
      type: 'operator/visual-builder/step/change-conversation-priority',
    });
  }
}
