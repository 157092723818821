/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { not, equal } from '@ember/object/computed';

export default EmberObject.extend({
  id: null,
  name: null,
  company_url: null,
  creation_source: 'api',
  manuallyCreated: equal('creation_source', 'manual_edit'),
  notManuallyCreated: not('manuallyCreated'),
});
