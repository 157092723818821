/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { chatMessageStepBlockValidators } from 'embercom/models/operator/visual-builder/step/chat-message';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import type ConnectionPoint from '../connection-point';
import { type SyncHasMany } from '@ember-data/model';
import { type Block } from 'embercom/models/common/blocks/block';
import { type CreateStepParams } from 'embercom/models/operator/visual-builder/step';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  ...chatMessageStepBlockValidators,
  outwardConnectionPoints: [
    validator('has-many'),
    validator('visual-builder/connection-points-inbound-bot-mobile-constraints'),
    // This validator in practice only invalidates on the inbound trigger when composer is disabled + 0 reply buttons
    // Having a specific error message here won't show up in places we don't want it to
    validator('length', {
      min: 1,
      messageKey:
        'operator.workflows.visual-builder.validations.one-button-or-composer-enabled-required',
    }),
  ],
  attributeIdentifier: [
    validator('visual-builder/attribute-identifier-is-text-attribute', {
      messageKey:
        'operator.workflows.visual-builder.validations.reply-value-saved-to-text-attribute',
    }),
  ],
  introduction: [
    validator('visual-builder/phone-reply-buttons-warning', {
      isWarning: true,
      messageKey: 'operator.workflows.visual-builder.validations.phone-reply-buttons-warning',
    }),
  ],
});

export default class ReplyButtons extends Step.extend(Validations) {
  @attr('string') declare attributeIdentifier?: string;
  @attr('boolean') declare isComposerDisabled: boolean;

  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  @tracked declare shouldCollectAttribute: boolean;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.replyButtons;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  ready() {
    // Trying to access this.attributeIdentifier in the `constructor` for a model breaks
    // Generally, all `attr` accessors won't work in the class `constructor` function
    this.shouldCollectAttribute = !!this.attributeIdentifier;
  }

  get replyButtons() {
    return this.outwardConnectionPoints.filter(
      (connectionPoint: ConnectionPoint) => connectionPoint.type !== 'capture_composer_input',
    );
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store, params: CreateStepParams = {}): ReplyButtons {
    let outwardConnectionPoints = [store.createRecord('operator/visual-builder/connection-point')];
    let creationParams: any = {
      outwardConnectionPoints,
      type: 'operator/visual-builder/step/reply-buttons',
    };

    if (params.createEmptyBlock) {
      creationParams.blocks = [
        store.createFragment('common/blocks/paragraph', {
          type: 'paragraph',
          text: '', // let the component decide what the default or placeholder text should be
        }),
      ];
    }

    return store.createRecord('operator/visual-builder/step/reply-buttons', creationParams);
  }
}
