/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import TeamSummary, { type TeamSummaryWireFormat } from 'embercom/objects/inbox/team-summary';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export type SenderSummary = AdminSummary | TeamSummary;
type SenderSummaryWireFormat = AdminSummaryWireFormat | TeamSummaryWireFormat;
interface WhatsappWireFormat {
  renderable_type: RenderableType;
  sender_summary: SenderSummaryWireFormat;
  sender_type: OutboundInitialPartSenderType;
  blocks: Array<object>;
  tags: Array<TagWireFormat>;
  ruleset_id?: number;
  selection_set_id?: number;
  deleted_at?: Date;
}

export default class Whatsapp implements RenderableData {
  readonly renderableType = RenderableType.Whatsapp;
  readonly senderSummary: SenderSummary;
  readonly senderType: OutboundInitialPartSenderType;
  readonly blocks: Array<object>;
  @tracked tags: Array<Tag>;
  readonly rulesetId?: number;
  readonly selectionSetId?: number;
  readonly deletedAt?: Date;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  get styles() {
    let styles = {
      textColor: 'text-default',
      backgroundColor: 'bg-beta-container',
    };
    if (this.senderType === OutboundInitialPartSenderType.Bot) {
      styles.backgroundColor = 'bg-beta-container-emphasis';
    }

    return {
      ...styles,
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  constructor(
    blocks: Array<object>,
    senderSummary: SenderSummary,
    senderType: OutboundInitialPartSenderType,
    tags?: Array<Tag>,
    rulesetId?: number,
    selectionSetId?: number,
    deletedAt?: Date,
  ) {
    this.blocks = blocks;
    this.senderSummary = senderSummary;
    this.senderType = senderType;
    this.tags = tags || [];
    this.rulesetId = rulesetId;
    this.selectionSetId = selectionSetId;
    this.deletedAt = deletedAt;
  }

  static deserialize(json: WhatsappWireFormat): Whatsapp {
    let senderSummary;
    if (json.sender_type === OutboundInitialPartSenderType.Team) {
      senderSummary = TeamSummary.deserialize(json.sender_summary as TeamSummaryWireFormat);
    } else {
      senderSummary = AdminSummary.deserialize(json.sender_summary as AdminSummaryWireFormat);
    }
    return new Whatsapp(
      json.blocks,
      senderSummary,
      json.sender_type,
      json.tags && json.tags.map(Tag.deserialize),
      json.ruleset_id,
      json.selection_set_id,
      json.deleted_at,
    );
  }
}
