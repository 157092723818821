/* RESPONSIBLE TEAM: team-product-exploration */
/* eslint-disable @intercom/intercom/no-bare-strings */

// This file powers search within Settings area of the product.
// The data here is used to generate the list of settings routes that are searchable within global search,
// along with search synonyms, and their children.
//
// If you have added a new route to the Settings you should add it to the correct part of this data structure to ensure it
// appears in search. Routes are grouped by how they appear in the nav in the UI.
// Example:
//   {
//     route: 'apps.app.settings.my-cool-new-feature-settings',
//     name: 'Cool New Feature',
//     synonyms: ['Excellent new Feature', 'Amazing new feature'],
//     requiredFeatureFlags: ['my-cool-new-feature'], // Optional - if the route requires a feature flag to be accessed
//     children: [
//       ...
//     ],
//   },
//
// NOTE: If you don't want your route to appear in search, you can add it to the exclusion list at the bottom of this file.

import type SearchableRoute from './searchable-route';
const NewSettingsRoutes: Array<SearchableRoute> = [
  {
    route: 'apps.app.settings.index',
    name: 'Settings',
    synonyms: ['Configuration', 'Preferences', 'Options', 'Home'],
    children: [
      {
        route: 'apps.app.settings.workspace.general',
        name: 'Workspace',
        synonyms: ['App'],
        children: [
          {
            route: 'apps.app.settings.workspace.general',
            name: 'General',
            children: [
              {
                route: 'apps.app.settings.workspace.general',
                name: 'Workspace name & Timezone',
                synonyms: ['Time zone', 'Timezone', 'Workspace name', 'app id', 'workspace id'],
                routeParams: { highlight: 'workspace-name-timezone' },
              },
              {
                route: 'apps.app.settings.workspace.general',
                routeParams: { highlight: 'workspace-companies-toggle' },
                name: 'Companies',
              },
              {
                route: 'apps.app.settings.workspace.general',
                name: 'Test Workspace',
                synonyms: ['Demo Workspace', 'Sandbox'],
                routeParams: { highlight: 'test-workspace' },
              },
              {
                route: 'apps.app.settings.workspace.general',
                name: 'Languages',
                synonyms: ['Localization'],
                routeParams: { highlight: 'workspace-language' },
              },
              {
                route: 'apps.app.settings.workspace.general',
                name: 'Delete account',
                synonyms: ['Deactivate account', 'Close account', 'Delete workspace'],
                routeParams: { highlight: 'workspace-deletion' },
              },
            ],
          },
          {
            name: 'Teammates',
            synonyms: ['Users', 'Team Members', 'Coworkers', 'Colleagues'],
            route: 'apps.app.settings.workspace.teammates',
            children: [
              {
                name: 'Invite teammates',
                synonyms: ['Create teammate', 'Add teammate'],
                route: 'apps.app.settings.workspace.teammates.invite.new',
              },
              {
                name: 'Invited',
                synonyms: ['Users', 'Teammates', 'Invited User', 'Invited Teammate'],
                route: 'apps.app.settings.workspace.teammates',
                routeParams: { tab: 'invited' },
              },
              {
                name: 'Roles',
                synonyms: ['Permissions', 'Access Levels', 'User Roles'],
                route: 'apps.app.settings.workspace.teammates',
                routeParams: { tab: 'roles' },
                children: [
                  {
                    name: 'New role',
                    synonyms: ['Create role', 'Add role'],
                    route: 'apps.app.settings.workspace.teammates.roles.new',
                  },
                ],
              },
              {
                name: 'Activity logs',
                synonyms: ['Teammate activity'],
                route: 'apps.app.settings.workspace.teammates',
                routeParams: { tab: 'activity-logs' },
              },
            ],
          },
          {
            name: 'Billing',
            synonyms: ['Billing', 'Payment'],
            route: 'apps.app.billing',
            children: [
              {
                name: 'Subscription',
                synonyms: ['Billing plan', 'Payment plan'],
                route: 'apps.app.billing.summary',
              },
              {
                name: 'Invoices',
                synonyms: ['Billing invoices', 'Payment invoices'],
                route: 'apps.app.billing.invoices',
              },
              {
                name: 'Billing settings',
                synonyms: ['Payment settings', 'Billing preferences'],
                route: 'apps.app.billing.settings',
              },
              {
                name: 'Manage Fin usage',
                synonyms: ['Billing methods', 'Payment options'],
                route: 'apps.app.billing.manage-usage',
              },
            ],
          },
          {
            name: 'Office Hours',
            synonyms: ['Business Hours', 'Work Schedule', 'Availability'],
            route: 'apps.app.settings.workspace.office-hours',
            routeParams: {
              tab: 'general',
            },
            children: [
              {
                name: 'Default office hours',
                synonyms: ['Default business hours', 'Default work schedule'],
                route: 'apps.app.settings.workspace.office-hours',
                routeParams: {
                  tab: 'general',
                  highlight: 'default-office-hours',
                },
                requiredFeatureFlags: ['team-product-guidance-helpdesk-setup'],
              },
              {
                name: 'Custom office hours',
                synonyms: ['Custom business hours', 'Custom work schedule'],
                route: 'apps.app.settings.workspace.office-hours',
                routeParams: {
                  tab: 'custom-office-hours',
                },
                requiredFeatureFlags: ['team-product-guidance-helpdesk-setup'],
              },
              {
                name: 'Reply time',
                synonyms: ['Reply time', 'Response time'],
                route: 'apps.app.settings.workspace.office-hours',
                routeParams: {
                  tab: 'general',
                  highlight: 'reply-time',
                },
                requiredFeatureFlags: ['team-product-guidance-helpdesk-setup'],
              },
            ],
          },
          {
            name: 'Brands',
            synonyms: ['Multibrand', 'Multi brand', 'Email Brands', 'Brand Identity'],
            route: 'apps.app.settings.workspace.brands',
            requiredFeatureFlags: ['multibrand-email-open-beta'],
          },
          {
            name: 'Security',
            synonyms: ['Safety', 'Cybersecurity', 'Protection', 'Safeguards', 'Secure Access'],
            route: 'apps.app.settings.workspace.security',
            children: [
              {
                name: 'General',
                route: 'apps.app.settings.workspace.security',
                routeParams: { tab: 'general' },
                children: [
                  {
                    name: 'Workspace Security Settings',
                    synonyms: ['Workspace Security', 'Security Settings'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Messenger Security Settings',
                    synonyms: ['Messenger Security', 'Chat Security'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Data Security Settings',
                    synonyms: ['Data Security', 'Information Security'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Compliance documents',
                    synonyms: ['Compliance', 'Legal'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Attachments in conversations',
                    synonyms: ['Attachments', 'Files'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Control while file types can be received',
                    synonyms: ['File types', 'File extensions'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                  {
                    name: 'Links',
                    synonyms: ['Links in conversations'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'general' },
                  },
                ],
              },
              {
                name: 'Enforce Identity',
                synonyms: ['Identity Verification', 'Identity'],
                route: 'apps.app.settings.workspace.security',
                routeParams: { tab: 'enforce-identity' },
                children: [
                  {
                    name: 'Web',
                    synonyms: ['Website', 'Browser'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'enforce-identity' },
                  },
                  {
                    name: 'Android',
                    synonyms: ['Mobile'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'enforce-identity' },
                  },
                  {
                    name: 'iOS',
                    synonyms: ['Mobile', 'apple'],
                    route: 'apps.app.settings.workspace.security',
                    routeParams: { tab: 'enforce-identity' },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        route: 'apps.app.settings.channels.messenger.web',
        name: 'Channels',
        synonyms: ['Channels', 'Communication'],
        children: [
          {
            name: 'Messenger',
            synonyms: ['Messenger', 'Chat', 'Conversation'],
            route: 'apps.app.settings.channels.messenger.web',
            children: [
              {
                name: 'Web',
                synonyms: [
                  'Messenger Settings',
                  'Website Messenger',
                  'Welcome Message',
                  'Show Messenger',
                  'Hide Messenger',
                  'Styling',
                  'Customization',
                ],
                route: 'apps.app.settings.channels.messenger.web',
                routeParams: { tab: 'content', section: 'layout-and-spaces' },
                children: [
                  {
                    name: 'Layout and Spaces',
                    synonyms: ['Compact view', 'Messenger', 'Visitor Space', 'User Space'],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'content', section: 'layout-and-spaces' },
                  },
                  {
                    name: 'Set your welcome message',
                    synonyms: [
                      'Welcome Message',
                      'Greeting Message',
                      'Say Hi',
                      'Introduction',
                      'Messenger languages',
                    ],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'content', section: 'greeting' },
                  },
                  {
                    name: 'Customize Home with apps',
                    synonyms: ['Styling', 'Customize', 'Customize Messenger Home'],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'content', section: 'add-apps' },
                  },
                  {
                    name: 'Launcher',
                    synonyms: ['Hide Messenger', 'Messenger Launcher'],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'content', section: 'show-launcher' },
                  },
                  {
                    name: 'Messenger colors',
                    synonyms: ['Styling', 'Customization', 'Brand Colors'],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'styling', section: 'general' },
                  },
                  {
                    name: 'Messenger Home',
                    synonyms: ['Styling', 'Customization', 'Homepage', 'First page'],
                    route: 'apps.app.settings.channels.messenger.web',
                    routeParams: { tab: 'styling', section: 'messenger' },
                  },
                ],
              },
              {
                name: 'Mobile SDKs',
                synonyms: ['Styling', 'Mobile Messenger', 'Mobile Chat', 'Customization'],
                route: 'apps.app.settings.channels.messenger.mobile-sdk',
                routeParams: { tab: 'content', section: 'layout-and-spaces' },
                children: [
                  {
                    name: 'Spaces',
                    synonyms: ['User Space'],
                    route: 'apps.app.settings.channels.messenger.mobile-sdk',
                    routeParams: { tab: 'content', section: 'layout-and-spaces' },
                  },
                  {
                    name: 'Set your welcome message',
                    synonyms: ['Welcome Message', 'Greeting Message', 'Say Hi', 'Introduction'],
                    route: 'apps.app.settings.channels.messenger.mobile-sdk',
                    routeParams: { tab: 'content', section: 'greeting' },
                  },
                  {
                    name: 'Customize Home with apps',
                    synonyms: ['Styling', 'Customize', 'Customize Messenger Home'],
                    route: 'apps.app.settings.channels.messenger.mobile-sdk',
                    routeParams: { tab: 'content', section: 'add-apps' },
                  },
                  {
                    name: 'Messenger colors',
                    synonyms: ['Styling', 'Customization', 'Brand Colors'],
                    route: 'apps.app.settings.channels.mobile-sdk',
                    routeParams: { tab: 'styling', section: 'general' },
                  },
                  {
                    name: 'Messenger Home',
                    synonyms: ['Styling', 'Customization', 'Homepage', 'First page'],
                    route: 'apps.app.settings.channels.mobile-sdk',
                    routeParams: { tab: 'styling', section: 'messenger' },
                  },
                  {
                    name: 'Launcher',
                    synonyms: ['Change logo', 'Customize logo', 'Customize icon'],
                    route: 'apps.app.settings.channels.mobile-sdk',
                    routeParams: { tab: 'styling', section: 'launcher' },
                  },
                ],
              },
              {
                name: 'Conversations',
                synonyms: ['Start conversations', 'Reply Expectations', 'Intro', 'Special notice'],
                route: 'apps.app.settings.channels.messenger.conversations',
                routeParams: { section: 'start-conversation-button', tab: 'teammates' },
                children: [
                  {
                    name: 'Start conversation button text',
                    synonyms: [
                      'Start conversation',
                      'Start chat',
                      'Start message',
                      'Ask question',
                      'Chat with us',
                      'Contact us',
                    ],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'start-conversation-button', tab: 'teammates' },
                  },
                  {
                    name: 'Reply Expectations',
                    synonyms: ['Reply time', 'Response time', 'Reply SLA'],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'reply-expectations', tab: 'teammates' },
                  },
                  {
                    name: 'Team introduction',
                    synonyms: ['Introduce your team'],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'introduction', tab: 'teammates' },
                  },
                  {
                    name: 'Special notice',
                    synonyms: ['Special message', 'Important message'],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'special-notice', tab: 'teammates' },
                  },
                  {
                    name: 'Choose how Fin sets expectations',
                    synonyms: ['Fin expectations', 'Fin reply time', 'Fin response time'],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'set-expectation', tab: 'fin' },
                  },
                  {
                    name: 'Start conversation button text',
                    synonyms: [
                      'Start conversation',
                      'Start chat',
                      'Start message',
                      'Ask question',
                      'Chat with us',
                      'Contact us',
                    ],
                    route: 'apps.app.settings.channels.messenger.conversations',
                    routeParams: { section: 'start-conversation-button', tab: 'fin' },
                  },
                ],
              },
              {
                name: 'General',
                synonyms: [
                  'inbound volume',
                  'Reply time',
                  'Supported languages',
                  'Secure Messenger',
                ],
                route: 'apps.app.settings.channels.messenger.general',
                routeParams: { section: 'inbound-volume' },
                children: [
                  {
                    name: 'Control inbound volume',
                    synonyms: ['Conversation volume', 'Chat volume', 'Message volume'],
                    route: 'apps.app.settings.channels.messenger.general',
                    routeParams: { section: 'inbound-volume' },
                  },
                  {
                    name: 'Office hours and reply times',
                    synonyms: ['Response time', 'Reply SLA', 'Reply time SLA'],
                    route: 'apps.app.settings.channels.messenger.general',
                    routeParams: { section: 'office-hours' },
                  },
                  {
                    name: 'Choose supported languages',
                    synonyms: ['Language support', 'Language options', 'Language settings'],
                    route: 'apps.app.settings.channels.messenger.general',
                    routeParams: { section: 'languages' },
                  },
                  {
                    name: 'Keep your Messenger secure',
                    synonyms: ['Messenger security', 'Chat security', 'Conversation security'],
                    route: 'apps.app.settings.channels.messenger.general',
                    routeParams: { section: 'security' },
                  },
                  {
                    name: 'Other preferences',
                    synonyms: [
                      'Play sound for incoming messages',
                      'Sound notifications',
                      'Link to Intercom Website',
                    ],
                    route: 'apps.app.settings.channels.messenger.general',
                    routeParams: { section: 'other' },
                  },
                ],
              },
              {
                route: 'apps.app.settings.channels.messenger.install',
                routeParams: { section: 'messenger-setup', tab: 'web' },
                name: 'Install',
                synonyms: ['Setup', 'Messenger installation', 'Messenger setup'],
                children: [
                  {
                    route: 'apps.app.settings.channels.messenger.install',
                    routeParams: { section: 'messenger-setup', tab: 'web' },
                    name: 'Web',
                    synonyms: ['Website', 'Online Presence', 'Internet'],
                  },
                  {
                    route: 'apps.app.settings.channels.messenger.install',
                    routeParams: { tab: 'ios' },
                    name: 'iOS',
                    synonyms: ['Apple Devices', 'iPhone', 'iPad'],
                  },
                  {
                    route: 'apps.app.settings.channels.messenger.install',
                    routeParams: { tab: 'android' },
                    name: 'Android',
                    synonyms: ['Google Devices', 'Android OS', 'Mobile'],
                  },
                ],
              },
            ],
          },
          {
            name: 'Email',
            synonyms: ['Email', 'Mail'],
            route: 'apps.app.settings.channels.email',
            children: [
              {
                route: 'apps.app.settings.channels.email',
                routeParams: { tab: 'email-forwarding' },
                name: 'Email Forwarding',
                synonyms: ['Message Redirection', 'Mail Relay', 'Email Routing'],
              },
              {
                route: 'apps.app.settings.channels.email',
                routeParams: { tab: 'sender-email-addresses' },
                name: 'Sender email addresses',
                synonyms: ['Message Senders', 'Identity', 'From'],
              },
              {
                route: 'apps.app.settings.channels.email',
                routeParams: { tab: 'domains' },
                name: 'Domains',
                synonyms: ['Domain Management', 'Web Domains', 'URLs'],
              },
              {
                route: 'apps.app.settings.channels.email',
                routeParams: { tab: 'customisation' },
                name: 'Customisation',
                synonyms: ['Customization'],
                children: [
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: {
                      tab: 'customisation',
                      highlight: 'customization-convert-to-tickets',
                    },
                    name: 'Convert all inbound emails to tickets',
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'customisation', highlight: 'customization-auto-reply' },
                    name: 'Auto-replies',
                    synonyms: ['email auto replies', 'auto replies'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: {
                      tab: 'customisation',
                      highlight: 'customization-conversation-history',
                    },
                    name: 'Conversation history',
                    synonyms: ['email history'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'customisation', highlight: 'customization-signature' },
                    name: 'Email signatures',
                    synonyms: ['email footer', 'signature'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'customisation', highlight: 'customization-notifications' },
                    name: 'Email notifications',
                    synonyms: ['email alerts', 'override destination url'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'customisation', highlight: 'customization-attachment' },
                    name: 'Choose how to send files in emails',
                    synonyms: ['email attachments'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'customisation', highlight: 'customization-templates' },
                    name: 'Email templates for outbound email',
                  },
                ],
              },
              {
                route: 'apps.app.settings.channels.email',
                routeParams: { tab: 'advanced' },
                name: 'Advanced',
                children: [
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-auto-responders' },
                    name: 'Auto-responders',
                    synonyms: ['auto responders'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-manual-forwarding' },
                    name: 'Manually forwarded emails',
                    synonyms: ['manual forwarding'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-reply-to-closed' },
                    name: 'Replying to closed email conversations',
                    synonyms: ['closed email conversations'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-strip-inbound-email-links' },
                    name: 'Strip links in emails',
                    synonyms: ['strip links', 'links', 'email links'],
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-link-branding' },
                    name: 'Link Branding',
                  },
                  {
                    route: 'apps.app.settings.channels.email',
                    routeParams: { tab: 'advanced', highlight: 'email-using-ips' },
                    name: 'Using IPs',
                    synonyms: ['IPs', 'Dedicated IPs'],
                    requiredFeatureFlags: ['canUseDedicatedIPs'],
                  },
                ],
              },
            ],
          },
          {
            name: 'Phone',
            synonyms: ['Phone', 'Call', 'Calling'],
            route: 'apps.app.settings.channels.phone',
            children: [
              {
                name: 'Messenger calls',
                synonyms: ['Voice', 'Video', 'Screen share'],
                route: 'apps.app.settings.channels.phone',
              },
              {
                name: 'Phone calls',
                synonyms: ['Inbound calls', 'Outbound calls'],
                route: 'apps.app.settings.channels.phone',
              },
              {
                name: 'Recording and transcript',
                synonyms: ['Recordings', 'Transcripts'],
                route: 'apps.app.settings.channels.phone',
              },
            ],
          },
          {
            name: 'WhatsApp',
            synonyms: ['WhatsApp', 'WhatsApp Business'],
            route: 'apps.app.settings.channels.whatsapp',
            requiredFeatureFlags: ['team-product-guidance-new-social-channels'],
            children: [
              {
                name: 'Connected WhatsApp business numbers',
                synonyms: ['WhatsApp business numbers'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'connected-whatsapp-numbers' },
              },
              {
                name: 'Set your business profile',
                synonyms: ['WhatsApp business profile'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'business-profile' },
              },
              {
                name: 'Message templates',
                synonyms: ['WhatsApp message templates'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'message-templates' },
              },
              {
                name: 'Switch from Messenger to WhatsApp',
                synonyms: ['Switch from Messenger to WhatsApp'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'switch-from-messenger' },
              },
              {
                name: 'When to treat messages as new conversations',
                synonyms: ['New conversations'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'new-conversations' },
              },
              {
                name: 'Identify existing users',
                synonyms: ['Identify existing users'],
                route: 'apps.app.settings.channels.whatsapp',
                routeParams: { highlight: 'identify-existing-users' },
              },
            ],
          },
          {
            name: 'Switch',
            synonyms: ['Switch', 'Switch Monitor'],
            route: 'apps.app.settings.channels.switch',
            children: [
              {
                name: 'Setup',
                synonyms: ['Switch', 'Switch setup'],
                route: 'apps.app.settings.channels.switch',
                routeParams: { tab: 'switch' },
              },
              {
                name: 'Monitor',
                synonyms: ['Switch', 'Switch monitor'],
                route: 'apps.app.settings.channels.switch',
                routeParams: { tab: 'monitor' },
              },
            ],
          },
          {
            name: 'SMS',
            synonyms: ['SMS', 'Text', 'Mobile'],
            route: 'apps.app.settings.channels.sms',
            children: [
              {
                name: 'SMS Response Handling',
                synonyms: ['SMS', 'Text', 'Mobile'],
                route: 'apps.app.settings.channels.sms',
                children: [
                  {
                    name: '2-way SMS',
                    synonyms: ['SMS Response', 'SMS Reply', 'Reply'],
                    route: 'apps.app.settings.channels.sms',
                  },
                  {
                    name: 'Automated keyword responses',
                    synonyms: ['Automated response', 'Manage responses', 'Auto reply'],
                    route: 'apps.app.settings.channels.sms',
                  },
                ],
              },
              {
                name: 'Advanced SMS Settings',
                synonyms: ['SMS', 'Text', 'Mobile'],
                route: 'apps.app.settings.channels.sms',
                children: [
                  {
                    name: 'Countries and phone numbers',
                    synonyms: ['Active phone numbers', 'SMS Response numbers', 'Activate SMS'],
                    route: 'apps.app.settings.channels.sms',
                  },
                  {
                    name: 'Quite hours by timezone',
                    synonyms: ['Quite hours delay', 'SMS Response delay', 'SMS timezone delay'],
                    route: 'apps.app.settings.channels.sms',
                  },
                  {
                    name: 'Prefix company identification',
                    synonyms: ['SMS identification', 'SMS Company Name'],
                    route: 'apps.app.settings.channels.sms',
                  },
                ],
              },
            ],
          },
          {
            name: 'Social Channels',
            synonyms: [
              'Other Channels',
              'Social',
              'Social Channels',
              'Social Media',
              'Facebook',
              'Instagram',
              'Whatsapp',
            ],
            route: 'apps.app.settings.channels.social-channels',
            children: [
              {
                name: 'WhatsApp',
                synonyms: ['WhatsApp Business'],
                route: 'apps.app.settings.channels.social-channels',
                hideOnFeatureFlags: ['team-product-guidance-new-social-channels'],
              },
              {
                name: 'Facebook',
                synonyms: ['Social Media', 'Meta'],
                route: 'apps.app.settings.channels.social-channels',
              },
              {
                name: 'Instagram',
                synonyms: ['Social Media', 'Meta', 'Threads'],
                route: 'apps.app.settings.channels.social-channels',
              },
            ],
          },
          {
            name: 'All Channels',
            synonyms: [],
            route: 'apps.app.settings.channels.all',
            requiredFeatureFlags: ['team-product-guidance-channel-awareness'],
          },
        ],
      },
      {
        route: 'apps.app.settings.helpdesk.teams',
        name: 'Helpdesk',
        synonyms: ['Helpdesk', 'Inbox'],
        children: [
          {
            name: 'Teams',
            synonyms: ['Teams', 'Groups'],
            route: 'apps.app.settings.helpdesk.teams',
            hideOnFeatureFlags: ['team-product-guidance-helpdesk-setup'],
            children: [
              {
                name: 'Create a team',
                route: 'apps.app.settings.helpdesk.teams',
              },
              {
                name: 'Delete a team',
                route: 'apps.app.settings.helpdesk.teams',
              },
            ],
          },
          {
            name: 'Team inboxes',
            synonyms: ['Team inboxes', 'Teams'],
            route: 'apps.app.settings.helpdesk.teams',
            requiredFeatureFlags: ['team-product-guidance-helpdesk-setup'],
            children: [
              {
                name: 'Create a team inbox',
                route: 'apps.app.settings.helpdesk.teams',
              },
              {
                name: 'Delete a team inbox',
                route: 'apps.app.settings.helpdesk.teams',
              },
            ],
          },
          {
            name: 'Assignments',
            synonyms: [
              'Assignments',
              'Routing',
              'Workload Management',
              'Assingment Limit',
              'Away mode',
              'Self assign',
              'Presence',
            ],
            route: 'apps.app.settings.helpdesk.assignments',
            routeParams: { tab: 'general' },
            children: [
              {
                route: 'apps.app.settings.helpdesk.assignments',
                routeParams: { tab: 'workspace' },
                name: 'Workload Management',
                synonyms: ['Inbound management'],
                children: [
                  {
                    route: 'apps.app.settings.helpdesk.assignments',
                    name: 'Conversations sorting',
                    synonyms: [
                      'Balancing',
                      'Load Balancing',
                      'Distribution',
                      'Balanced assignment',
                    ],
                    routeParams: { tab: 'workspace', highlight: 'conversations-sorting' },
                  },
                  {
                    route: 'apps.app.settings.helpdesk.assignments',
                    name: 'Assignment limit',
                    routeParams: { tab: 'workspace', highlight: 'assignment-limit' },
                  },
                  {
                    route: 'apps.app.settings.helpdesk.assignments',
                    name: 'Tickets assignment',
                    routeParams: { tab: 'workspace', highlight: 'tickets-assignment' },
                  },
                  {
                    route: 'apps.app.settings.helpdesk.assignments',
                    name: 'Pull conversation',
                    routeParams: { tab: 'workspace', highlight: 'pull-conversation' },
                  },
                ],
              },
              {
                name: 'Teammate assignment limit',
                synonyms: ['Assignment limit'],
                route: 'apps.app.settings.helpdesk.assignments',
                routeParams: { tab: 'assignment-limits' },
              },
              {
                name: 'General',
                route: 'apps.app.settings.helpdesk.assignments',
                routeParams: { tab: 'general' },
                children: [
                  {
                    name: 'Default assignee',
                    route: 'apps.app.settings.helpdesk.assignments',
                    routeParams: { tab: 'general', highlight: 'default-assignee' },
                  },
                  {
                    name: 'Automatic away mode',
                    synonyms: ['Away mode', 'Presence'],
                    route: 'apps.app.settings.helpdesk.assignments',
                    routeParams: { tab: 'general', highlight: 'assignments-away-mode' },
                  },
                  {
                    name: 'Self-assign by replying',
                    synonyms: ['Self-assign', 'Assign by replying'],
                    route: 'apps.app.settings.helpdesk.assignments',
                    routeParams: { tab: 'general', highlight: 'assignments-self-assign' },
                  },
                  {
                    name: 'Teammate presence',
                    synonyms: ['Presence', 'Availability'],
                    route: 'apps.app.settings.helpdesk.assignments',
                    routeParams: { tab: 'general', highlight: 'assignments-teammate-presence' },
                  },
                  {
                    name: 'Assign conversations using workflows',
                    route: 'apps.app.settings.helpdesk.assignments',
                    routeParams: {
                      tab: 'general',
                      highlight: 'assign-conversations-using-workflows',
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'Macros',
            synonyms: ['Macros', 'Saved Replies', 'Canned Responses'],
            route: 'apps.app.settings.helpdesk.macros',
            children: [
              {
                name: 'Create macro',
                synonyms: ['Add macro', 'New macro'],
                route: 'apps.app.settings.helpdesk.macros',
              },
              {
                name: 'Delete macro',
                synonyms: ['Remove macro', 'Trash macro'],
                route: 'apps.app.settings.helpdesk.macros',
              },
            ],
          },
          {
            name: 'Tickets',
            synonyms: [
              'Ticket type',
              'Issues',
              'Back-office',
              'Tracker',
              'Customer ticket',
              'Create ticket types',
              'Tickets',
              'Ticket states',
            ],
            route: 'apps.app.settings.helpdesk.tickets',
            children: [
              {
                route: 'apps.app.settings.helpdesk.tickets',
                routeParams: { tab: 'tickets-portal' },
                name: 'Tickets Portal',
                synonyms: ['portal'],
              },
            ],
          },
          {
            name: 'SLA',
            synonyms: ['SLA', 'Service Level Agreement'],
            route: 'apps.app.settings.helpdesk.sla',
          },
          {
            name: 'Rules',
            synonyms: ['Rules', 'Automation'],
            route: 'apps.app.settings.helpdesk.rules',
            children: [
              {
                name: 'Create rule',
                synonyms: ['Add rule', 'New rule'],
                route: 'apps.app.settings.helpdesk.rules',
              },
              {
                name: 'Delete rule',
                synonyms: ['Remove rule', 'Trash rule'],
                route: 'apps.app.settings.helpdesk.rules',
              },
            ],
          },
        ],
      },
      {
        name: 'AI & Automation',
        synonyms: ['AI & Automation', 'Artificial Intelligence'],
        route: 'apps.app.settings.ai-automation.fin-ai-agent',
        children: [
          {
            name: 'Fin AI Agent',
            synonyms: [
              'Fin AI Agent',
              'AI Agent',
              'Import Zendesk conversation history',
              'real-time translation',
            ],
            route: 'apps.app.settings.ai-automation.fin-ai-agent',
            children: [
              {
                name: "Fin's reply buttons",
                synonyms: [
                  'Fin reply buttons',
                  'Customize buttons',
                  'Reply buttons',
                  'Customize Fin reply buttons',
                ],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
              },
              {
                name: "Fin's multilingual support",
                synonyms: [
                  'Fin multilingual',
                  'realtime translation',
                  'real-time translation',
                  'supported languages',
                ],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
                requiredFeatureFlags: ['ai-agent-multilingual-fallback-search-locale'],
              },
              {
                name: "Customize Fin's answer length",
                synonyms: ['Fin answer length', 'Customize Fin', 'answer length'],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
                hideOnFeatureFlags: ['ai-agent-customisation-settings'],
              },
              {
                name: "Fin's pronoun formality",
                synonyms: [
                  'Fin pronoun formality',
                  'Customize Fin',
                  'Pronoun formality',
                  "Customize Fin's pronoun formality",
                ],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
              },
              {
                name: 'Suggest relevant Custom Answers',
                synonyms: ['Custom Answers', 'Relevant Answers', 'Suggest Answers'],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
              },
              {
                name: 'Build Custom Answers from Zendesk conversation history',
                synonyms: [
                  'Zendesk',
                  'Zendesk import',
                  'Zendesk history',
                  'Import Zendesk conversation history',
                ],
                route: 'apps.app.settings.ai-automation.fin-ai-agent',
              },
            ],
          },
          {
            name: 'Helpdesk AI',
            synonyms: ['Helpdesk AI', 'Inbox AI'],
            route: 'apps.app.settings.ai-automation.helpdesk-ai',
            children: [
              {
                name: 'AI Compose & Summarize',
                synonyms: ['AI Compose', 'AI Summarize', 'Compose', 'Summarize', 'AI'],
                route: 'apps.app.settings.ai-automation.helpdesk-ai',
              },
              {
                name: 'AI Autofill',
                synonyms: ['Autofill', 'Fill', 'AI'],
                route: 'apps.app.settings.ai-automation.helpdesk-ai',
              },
              {
                name: 'AI Articles',
                synonyms: ['Articles', 'AI', 'Knowledge base'],
                route: 'apps.app.settings.ai-automation.helpdesk-ai',
              },
            ],
          },
          {
            name: 'Automation',
            synonyms: ['Automation', 'Automations'],
            route: 'apps.app.settings.ai-automation.automation',
            children: [
              {
                name: 'Choose an identity for bots',
                synonyms: ['Bot identity', 'Bot name', 'Bot avatar'],
                route: 'apps.app.settings.ai-automation.automation',
              },
              {
                name: 'Turn on the bot inbox',
                synonyms: ['Bot inbox', 'Bot chat', 'Bot messages'],
                route: 'apps.app.settings.ai-automation.automation',
              },
              {
                name: 'Auto-close abandoned workflow conversations',
                synonyms: [
                  'Auto-close',
                  'Abandoned conversations',
                  'Close conversations',
                  'Workflow',
                ],
                route: 'apps.app.settings.ai-automation.automation',
              },
            ],
          },
        ],
      },
      {
        name: 'Apps Store',
        synonyms: ['Apps', 'Integrations'],
        route: 'apps.app.appstore',
        children: [
          {
            name: 'Browse Apps Store',
            synonyms: ['Browse All Apps', 'Installed Apps', 'Installed Integrations'],
            route: 'apps.app.appstore',
          },
          {
            name: 'Custom Actions',
            synonyms: ['Custom Actions', 'Custom Integrations'],
            route: 'apps.app.settings.app-settings.custom-actions',
            children: [
              {
                name: 'Create Action',
                synonyms: ['Add Action', 'New Action'],
                route: 'apps.app.settings.app-settings.custom-actions',
              },
              {
                name: 'Delete Action',
                synonyms: ['Remove Action', 'Trash Action'],
                route: 'apps.app.settings.app-settings.custom-actions',
              },
            ],
          },
          // commenting this out for the beta - we can add this back when we release for GA
          // {
          //   name: 'Legacy Webhooks',
          //   synonyms: ['Legacy Webhooks', 'Webhooks'],
          //   route: 'apps.app.settings.app-settings.legacy-webhooks',
          // },
          {
            name: 'Authentication',
            synonyms: ['Authentication', 'Login'],
            route: 'apps.app.settings.app-settings.authentication',
            children: [
              {
                name: 'Create new token',
                synonyms: ['Add token', 'New token'],
                route: 'apps.app.settings.app-settings.authentication.new',
              },
            ],
          },
          {
            name: 'Developer Hub',
            synonyms: ['Developer Hub', 'API'],
            route: 'apps.app.developer-hub',
          },
        ],
      },
      {
        name: 'Data',
        synonyms: ['Data', 'Information'],
        route: 'apps.app.settings.data.tags',
        children: [
          {
            name: 'Tags',
            synonyms: ['Tags', 'Labels'],
            route: 'apps.app.settings.data.tags',
            children: [
              {
                name: 'Create tag',
                synonyms: ['Add tag', 'New tag'],
                route: 'apps.app.settings.data.tags',
              },
              {
                name: 'Delete tag',
                synonyms: ['Remove tag', 'Trash tag'],
                route: 'apps.app.settings.data.tags',
              },
            ],
          },
          {
            name: 'People',
            synonyms: [
              'People',
              'Contacts',
              'Lead Qualification',
              'Segments',
              'Attributes',
              'Events',
              'Blocked',
            ],
            route: 'apps.app.settings.data.people',
            children: [
              {
                name: 'Attributes',
                synonyms: ['User Data', 'Customer Details'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'attributes' },
              },
              {
                name: 'Segments',
                synonyms: ['Groups', 'Lead Segments'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'segments' },
              },
              {
                name: 'Events',
                synonyms: ['Analytics', 'Tracking'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'events' },
              },
              {
                name: 'Lead qualifications',
                synonyms: ['Lead Qualification', 'Customer Profiling', 'Scoring Settings'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'lead-qualification' },
              },
              {
                name: 'Delete data',
                synonyms: ['Delete User', 'Delete lead', 'Erase Member', 'User Deletion'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'delete-data' },
              },
              {
                name: 'Blocked Users',
                synonyms: ['Blacklist', 'Blocked Contacts', 'Blocked Members', 'Banned'],
                route: 'apps.app.settings.data.people',
                routeParams: { tab: 'blocked' },
              },
            ],
          },
          {
            route: 'apps.app.settings.data.audiences',
            name: 'Audiences',
            synonyms: ['Segments', 'Content'],
          },
          {
            name: 'Companies',
            synonyms: ['Companies', 'Organizations', 'Segments'],
            route: 'apps.app.settings.data.companies',
            children: [
              {
                route: 'apps.app.settings.data.companies',
                routeParams: { tab: 'attributes' },
                name: 'Attributes',
                synonyms: ['Company Data', 'Organization Data', 'Business Information'],
              },
              {
                route: 'apps.app.settings.data.companies',
                routeParams: { tab: 'segments' },
                name: 'Segments',
                synonyms: ['Company Segments', 'Business Groups'],
              },
            ],
          },
          {
            name: 'Conversations',
            synonyms: ['Conversations', 'Issues', 'Conversation attributes', 'Custom attributes'],
            route: 'apps.app.settings.data.conversation-tickets',
          },
          {
            name: 'Custom Objects',
            synonyms: [
              'Custom Objects',
              'Custom Data',
              'Custom Entities',
              'Custom Records',
              'CDAs',
            ],
            route: 'apps.app.settings.data.custom-objects',
          },
          {
            name: 'Imports & Exports',
            synonyms: [
              'Imports & Exports',
              'Data Migration',
              'Mixpanel',
              'Mailchimp',
              'CSV',
              'Export',
            ],
            route: 'apps.app.settings.data.imports-exports',
            children: [
              {
                name: 'Import from Zendesk',
                synonyms: [
                  'Data Migration',
                  'Data Transfer',
                  'Data Upload',
                  'CSV Import',
                  'CSV',
                  'Upload CSV',
                ],
                requiredFeatureFlags: ['cdp-migrate-from-zendesk'],
                route: 'apps.app.settings.data.imports-exports',
                routeParams: { tab: 'import-from-zendesk' },
              },
              {
                name: 'Import CSV',
                synonyms: [
                  'Data Migration',
                  'Data Transfer',
                  'Data Upload',
                  'CSV Import',
                  'CSV',
                  'Upload CSV',
                ],
                route: 'apps.app.settings.data.imports-exports',
                routeParams: { tab: 'import-csv' },
              },
              {
                name: 'Import from Mixpanel',
                synonyms: [
                  'Data Migration',
                  'Data Transfer',
                  'Data Upload',
                  'Mixpanel Import',
                  'Mixpanel',
                ],
                route: 'apps.app.settings.data.imports-exports',
                routeParams: { tab: 'import-mixpanel' },
              },
              {
                name: 'Import from Mailchimp',
                synonyms: [
                  'Data Migration',
                  'Data Transfer',
                  'Data Upload',
                  'Mailchimp Import',
                  'Mailchimp',
                ],
                route: 'apps.app.settings.data.imports-exports',
                routeParams: { tab: 'import-mailchimp' },
              },
              {
                name: 'Export data',
                synonyms: ['Data Export', 'Data Download', 'Data Backup'],
                route: 'apps.app.settings.data.imports-exports',
                routeParams: { tab: 'export-data' },
              },
            ],
          },
        ],
      },
      {
        name: 'Proactive Support',
        synonyms: ['Proactive Support', 'Outbound'],
        route: 'apps.app.settings.proactive-support.customization',
        children: [
          {
            name: 'Message Setting',
            synonyms: ['Hi', 'First Message', 'Welcome Message', 'Greeting'],
            route: 'apps.app.settings.proactive-support.customization',
          },
          {
            name: 'Email templates',
            synonyms: ['Email', 'Email Examples', 'Email editor', 'Email customisation'],
            route: 'apps.app.settings.channels.email',
            routeParams: { highlight: 'customization-templates', tab: 'customisation' },
          },
          {
            name: 'Subscriptions',
            synonyms: ['Audience', 'Email Opt-in', 'Unsubscribe', 'SMS Opt-in', 'Opt-out'],
            route: 'apps.app.settings.proactive-support.subscriptions',
            hideOnFeatureFlags: ['team-product-exploration-ia-settings-ga'],
          },
          {
            name: 'Subscriptions',
            synonyms: ['Audience', 'Email Opt-in', 'Unsubscribe', 'SMS Opt-in', 'Opt-out'],
            route: 'apps.app.settings.proactive-support.subscriptions.index',
            requiredFeatureFlags: ['team-product-exploration-ia-settings-ga'],
          },
          {
            name: 'Universal linking',
            synonyms: ['Deep linking', 'Disable click tracking', 'Linking'],
            route: 'apps.app.outbound.settings.universal-linking',
          },
          {
            name: 'News feeds',
            synonyms: ['News', 'Newsletters', 'Newsfeeds', 'Announcements'],
            route: 'apps.app.outbound.settings.news.newsfeeds',
            requiredFeatureFlags: ['news'],
            hideOnFeatureFlags: ['team-product-exploration-ia-settings-ga'],
          },
          {
            name: 'News feeds',
            synonyms: ['News', 'Newsletters', 'Newsfeeds', 'Announcements'],
            route: 'apps.app.settings.proactive-support.newsfeeds',
            requiredFeatureFlags: ['news', 'team-product-exploration-ia-settings-ga'],
          },
          {
            name: 'News labels',
            synonyms: ['News', 'Newsletters', 'Categorize news', 'Labels'],
            route: 'apps.app.settings.proactive-support.news-labels',
            requiredFeatureFlags: ['news'],
          },
        ],
      },
      {
        name: 'Personal',
        synonyms: ['Profile'],
        route: 'apps.app.settings.personal.details',
        showOnlyOnCondition: (app: { hasOptedInForProductIa: boolean }) =>
          app.hasOptedInForProductIa,
        children: [
          {
            name: 'Details',
            synonyms: ['Profile', 'Personal Information', 'Account Information'],
            route: 'apps.app.settings.personal.details',
          },
          {
            name: 'Preferences',
            synonyms: ['Settings', 'Customization', 'Personal Settings'],
            route: 'apps.app.settings.personal.preferences',
            children: [
              {
                name: 'Email Address',
                synonyms: ['Email', 'Update email address', 'Email ID'],
                route: 'apps.app.settings.personal.preferences',
                routeParams: { highlight: 'personal-email-address' },
              },
              {
                name: 'Change password',
                synonyms: ['Update password', 'Reset password'],
                route: 'apps.app.settings.personal.preferences',
                routeParams: { highlight: 'personal-change-password' },
              },
              {
                name: 'Two Factor Authentication',
                synonyms: ['2FA', 'Two Step Verification', 'Two Factor Verification'],
                route: 'apps.app.settings.personal.preferences',
                routeParams: { highlight: 'personal-2fa-authentication' },
              },
            ],
          },
          {
            name: 'Notifications',
            synonyms: ['Alerts', 'Email Notifications', 'Push Notifications'],
            route: 'apps.app.settings.personal.notifications',
          },
          {
            name: 'Visible to you',
            synonyms: ['Visibility', 'Privacy', 'Who can see'],
            route: 'apps.app.settings.personal.visible-to-you',
            children: [
              {
                name: 'Company segments',
                synonyms: [
                  'Contact Segments',
                  'Hidden company segments',
                  'Hide company segment',
                  'Show people segment',
                ],
                route: 'apps.app.settings.personal.visible-to-you.company-segments',
              },
              {
                name: 'People segments',
                synonyms: [
                  'Contact People',
                  'Hidden people segments',
                  'Hide people segment',
                  'Show people segment',
                  'User segments',
                ],
                route: 'apps.app.settings.personal.visible-to-you.user-segments',
              },
              {
                name: 'Tags',
                synonyms: ['Contact Tags', 'Hide tags', 'Show tags', 'Group users'],
                route: 'apps.app.settings.personal.visible-to-you.tags',
              },
            ],
          },
          {
            name: 'Authentication',
            synonyms: ['OAuth'],
            route: 'apps.app.settings.personal.authentication',
          },
          {
            name: 'Account Access',
            synonyms: ['Revoke Access'],
            route: 'apps.app.settings.personal.account-access',
          },
        ],
      },
      {
        name: 'Help Center',
        synonyms: ['Articles', 'FAQ'],
        route: 'apps.app.settings.helpcenter.all',
      },
    ],
  },
];

export default NewSettingsRoutes;

// NOTE: Child routes will also be ignored
const IGNORED_NEW_SETTINGS_ROUTES: string[] = [];

export { IGNORED_NEW_SETTINGS_ROUTES };
