/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { NewZendeskSunshineConversationPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { StandaloneChatMessageConfiguration } from 'embercom/objects/visual-builder/configuration/step/chat-message';
import { StandaloneMessagingFinStepConfig } from 'embercom/objects/visual-builder/configuration/step/fin';
import { StandaloneAttributeCollectorConfiguration } from 'embercom/objects/visual-builder/configuration/step/attribute-collector';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import type Step from 'embercom/models/operator/visual-builder/step';

export default class NewZendeskSunshineConversationBotConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new NewZendeskSunshineConversationPathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.attributeCollector:
        return new StandaloneAttributeCollectorConfiguration(stepConfigArgs);
      case stepTypes.chatMessage:
        return new StandaloneChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.fin:
        return new StandaloneMessagingFinStepConfig(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }

  get collectableAttributesGroupList() {
    return this.attributeService.zendeskSunshineConversationCollectableAttributeGroupList;
  }
}
