/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';

let UserAvatarDataMixin = Mixin.create({
  avatarData: computed('isActive', 'avatar', 'isUnknown', function () {
    let data = this.getProperties(
      'avatar.color',
      'avatar.image_urls.normal',
      'isActive',
      'avatar.initials',
      'isUnknown',
    );
    return {
      color: data['avatar.color'],
      url: data['avatar.image_urls.normal'],
      showAsActive: data['isActive'],
      initials: avatarUtils.formatInitials(data['avatar.initials']),
      isCustomer: true,
      isTeammate: false,
      isCompany: false,
      isTeam: false,
      deleted: data['isUnknown'],
      shouldAnimateUpdate: true,
    };
  }),
});

export default UserAvatarDataMixin;
