/* RESPONSIBLE TEAM: team-actions */
const CUSTOM_ACTION_CONTEXT_MAPPING_PEOPLE = 'people_from_context';
const CUSTOM_ACTION_CONTEXT_MAPPING_CONVERSATION = 'conversation_from_context';

const CUSTOM_ACTION_CONTEXT_MAPPING_IDENTIFIERS = [
  CUSTOM_ACTION_CONTEXT_MAPPING_PEOPLE,
  CUSTOM_ACTION_CONTEXT_MAPPING_CONVERSATION,
];

export {
  CUSTOM_ACTION_CONTEXT_MAPPING_PEOPLE,
  CUSTOM_ACTION_CONTEXT_MAPPING_CONVERSATION,
  CUSTOM_ACTION_CONTEXT_MAPPING_IDENTIFIERS,
};
