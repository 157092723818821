/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { type SyncHasMany } from '@ember-data/model';
import { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { ONLY_PHONE_CALL_CHANNEL } from 'embercom/lib/operator/custom-bots/constants';
import { buildValidations, validator } from 'ember-cp-validations';
import type { Block } from '@intercom/interblocks.ts';

const Validations = buildValidations({
  followUpRules: [
    validator('follow-up-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
    validator('follow-up-rule-is-complete', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  simpleActionRule: [
    validator('simple-action-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  teamId: [validator('presence', true)],
});

export default class HoldAndAssign extends Step.extend(Validations) {
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;
  @fragment('rules/rule') declare simpleActionRule?: any;
  @attr('string') declare teamId: string;
  @attr() declare blocks: Array<Block>;
  @attr('string') declare csatEnabled: boolean;
  @attr('string') declare ttsLanguage: string;
  @attr('string') declare ttsMode: string;

  get supportedChannels() {
    return ONLY_PHONE_CALL_CHANNEL;
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get hasSimpleActionRule() {
    return !!this.simpleActionRule;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): HoldAndAssign {
    return store.createRecord('operator/visual-builder/step/hold-and-assign', {
      type: 'operator/visual-builder/step/hold-and-assign',
    });
  }
}
