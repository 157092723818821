/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import ajax from 'embercom/lib/ajax';

export default EmberObject.extend({
  tagId: null,
  appId: null,
  dependentObjectsUrl: computed('tagId', function () {
    return `/ember/tags/${this.tagId}/dependent_objects`;
  }),
  type: 'tag',
  getDependentObjects() {
    return ajax({
      url: this.dependentObjectsUrl,
      type: 'GET',
      data: {
        app_id: this.appId,
      },
    }).then(function (response) {
      return response.table;
    });
  },
});
