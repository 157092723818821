/* RESPONSIBLE TEAM: team-ml */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { hasMany, type SyncHasMany } from '@ember-data/model';
import type { Block } from 'embercom/models/common/blocks/block';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

export const finCustomStepBlockValidators = {
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.fin-custom.blank-prompt-error',
      messageTranslated: true,
    }),
  ],
  finOutputParams: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.fin-custom.no-output-params',
    }),
    validator('inline', {
      validate(value: AttributeDescriptor[], _options: any, model: FinCustom) {
        let invalidParams = value.filter(
          (param) => !param.name?.trim() || !param.description?.trim(),
        );
        if (invalidParams.length > 0) {
          return model.intl.t(
            'operator.workflows.visual-builder.validations.fin-custom.output-cannot-be-empty',
          );
        }

        return true;
      },
    }),
  ],
};

const Validations = buildValidations({
  ...finCustomStepBlockValidators,
});

export default class FinCustom extends Step.extend(Validations) {
  @service declare intl: IntlService;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  @hasMany('operator/visual-builder/attribute-descriptor', { async: false })
  declare finOutputParams: SyncHasMany<AttributeDescriptor>;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.finCustom;
  }

  get jsonBlocks() {
    return this.blocks.serialize();
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): FinCustom {
    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    return store.createRecord('operator/visual-builder/step/fin-custom', {
      type: 'operator/visual-builder/step/fin-custom',
      blocks,
    });
  }
}
