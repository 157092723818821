/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  triggerableWorkflowId: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  compatibleChannels: [validator('trigger-workflow-channel-compatibility')],
  compatibleWithBackgroundOnlyBots: [
    validator('trigger-workflow-background-only-bot-compatibility'),
  ],
});

export default class TriggerWorkflow extends Step.extend(Validations) {
  @attr('number') declare triggerableWorkflowId?: number;
  @attr('array') declare compatibleChannels?: string[];
  @attr('boolean') declare userVisible: boolean;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.triggerWorkflow;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static get isStepGroupEnding() {
    return !getApp().canUseFeature('mid-path-reusable-workflows');
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Maybe;
  }

  get isCustomerFacing() {
    return this.userVisible;
  }

  static createNewStep(store: Store): TriggerWorkflow {
    return store.createRecord('operator/visual-builder/step/trigger-workflow', {
      type: 'operator/visual-builder/step/trigger-workflow',
      justAdded: true,
    });
  }
}
