/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import { type Channel } from 'embercom/models/data/inbox/channels';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';
import {
  EmailMetadata,
  type EmailMetadataWireFormat,
} from 'embercom/objects/inbox/renderable/email-metadata';

export interface InboundEmailWireFormat {
  id: number;
  dmarc_failed?: boolean;
}

export class InboundEmail {
  readonly id: number;
  readonly dmarcFailed?: boolean;

  constructor(id: number, dmarcFailed?: boolean) {
    this.id = id;
    this.dmarcFailed = dmarcFailed;
  }

  static deserialize(json: InboundEmailWireFormat): InboundEmail {
    return new InboundEmail(json.id, json.dmarc_failed);
  }
}

interface UserEmailCommentWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  subject: string;
  user_summary: UserSummaryWireFormat;
  tags: Array<TagWireFormat>;
  uploads: Array<UploadWireFormat>;
  channel: Channel;
  inbound_email?: InboundEmailWireFormat;
  reply_to_address?: string;
  email_from_address?: string;
  email_forwarded_from?: string;
  email_metadata?: EmailMetadataWireFormat;
}

export default class UserEmailComment implements RenderableData {
  readonly renderableType = RenderableType.UserEmailComment;
  readonly blocks: Array<object>;
  readonly subject: string;
  readonly userSummary: UserSummary;
  @tracked tags: Array<Tag>;
  readonly uploads: Array<Upload>;
  readonly channel: Channel;
  readonly inboundEmail?: InboundEmail;
  readonly replyToAddress?: string;
  readonly emailFromAddress?: string;
  readonly emailForwardedFrom?: string;
  readonly emailMetadata?: EmailMetadata;

  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  get styles() {
    return {
      backgroundColor: 'bg-neutral-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container-emphasis',
    };
  }

  get fromExternalSender(): boolean {
    return this.emailMetadata?.externalSender || false;
  }

  get isReplyToAddressDifferentFromSenderAddress(): boolean {
    if (this.emailFromAddress) {
      if (this.replyToAddress && this.replyToAddress !== this.emailFromAddress) {
        return true;
      } else if (this.emailForwardedFrom && this.emailForwardedFrom !== this.emailFromAddress) {
        return true;
      }
    }
    return false;
  }

  constructor(
    blocks: Array<object>,
    subject: string,
    userSummary: UserSummary,
    tags: Array<Tag>,
    uploads: Array<Upload>,
    channel: Channel,
    inboundEmail?: InboundEmail,
    replyToAddress?: string,
    emailFromAddress?: string,
    emailForwardedFrom?: string,
    emailMetadata?: EmailMetadata,
  ) {
    this.blocks = blocks;
    this.subject = subject;
    this.userSummary = userSummary;
    this.tags = tags || [];
    this.uploads = uploads || [];
    this.channel = channel;
    this.inboundEmail = inboundEmail;
    this.replyToAddress = replyToAddress;
    this.emailFromAddress = emailFromAddress;
    this.emailForwardedFrom = emailForwardedFrom;
    this.emailMetadata = emailMetadata;
  }

  static deserialize(json: UserEmailCommentWireFormat): UserEmailComment {
    let userSummary = UserSummary.deserialize(json.user_summary);
    let inboundEmail = json.inbound_email
      ? InboundEmail.deserialize(json.inbound_email)
      : undefined;
    let emailMetadata = json.email_metadata
      ? EmailMetadata.deserialize(json.email_metadata)
      : undefined;
    return new UserEmailComment(
      json.blocks,
      json.subject,
      userSummary,
      json.tags?.map(Tag.deserialize),
      json.uploads?.map(Upload.deserialize),
      json.channel,
      inboundEmail,
      json.reply_to_address,
      json.email_from_address,
      json.email_forwarded_from,
      emailMetadata,
    );
  }
}
