/* RESPONSIBLE TEAM: team-proactive-support */
import { EntityType } from 'embercom/models/data/entity-types';
import { type BlockList } from '@intercom/interblocks.ts';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import {
  humanReadableObjectNames,
  objectIcons,
} from 'embercom/models/data/matching-system/matching-constants';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface KnowledgeBaseContentWireFormat {
  readonly id: string;
  readonly title: string;
  readonly entity_id: number;
  readonly article_id?: string;
  readonly entity_type: EntityType;
  readonly author_summary?: AdminSummaryWireFormat;
  readonly owner_summary?: AdminSummaryWireFormat;
  readonly blocks: BlockList;
  readonly locale: string;
  readonly siblings?: KnowledgeBaseContentWireFormat[];
  readonly visible_to_user?: boolean;
  readonly public_url?: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly state?: string;
  readonly live_version_id?: string;
  readonly markdown?: string;
}

export interface KnowledgeBaseContentConstructorFormat {
  readonly id: string;
  readonly title: string;
  readonly entityId: number;
  readonly articleId?: string;
  readonly entityType: EntityType;
  readonly author?: AdminSummary;
  readonly owner?: AdminSummary;
  readonly blocks: BlockList;
  readonly locale: string;
  readonly siblings: KnowledgeBaseContent[];
  readonly visibleToUser?: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly state?: string;
  readonly liveVersionId?: string;
  readonly markdown?: string;
  readonly publicUrl?: string;
}

export interface ContentStamp {
  icon: InterfaceIconName | undefined;
  class: string;
}

export const contentStampStyleObj = (entityType: EntityType): ContentStamp | undefined => {
  switch (entityType) {
    case EntityType.ArticleContent:
      return {
        icon: 'article',
        class: 'bg-neutral-container text-default',
      };
    case EntityType.InternalArticle:
      return {
        icon: 'locked',
        class: 'bg-notice-container text-default',
      };
    default:
      return undefined;
  }
};

export class KnowledgeBaseContent {
  readonly id: string;
  readonly title: string;
  readonly entityId: number;
  readonly articleId?: string;
  readonly entityType: EntityType;
  readonly author?: AdminSummary;
  readonly owner?: AdminSummary;
  blocks: BlockList;
  readonly locale: string;
  readonly siblings: KnowledgeBaseContent[];
  readonly visibleToUser?: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly state?: string;
  readonly liveVersionId?: string;
  readonly markdown?: string;
  readonly publicUrl?: string;

  constructor(inputs: KnowledgeBaseContentConstructorFormat) {
    this.id = inputs.id;
    this.title = inputs.title;
    this.entityId = inputs.entityId;
    this.articleId = inputs.articleId;
    this.entityType = inputs.entityType;
    this.author = inputs.author;
    this.owner = inputs.owner;
    this.blocks = inputs.blocks;
    this.locale = inputs.locale;
    this.siblings = inputs.siblings;
    this.visibleToUser = inputs.visibleToUser;
    this.createdAt = inputs.createdAt;
    this.updatedAt = inputs.updatedAt;
    this.state = inputs.state;
    this.liveVersionId = inputs.liveVersionId;
    this.markdown = inputs.markdown;
    this.publicUrl = inputs.publicUrl;
  }

  static deserialize(json: KnowledgeBaseContentWireFormat): KnowledgeBaseContent {
    return new KnowledgeBaseContent({
      id: json.id,
      title: json.title,
      entityId: json.entity_id,
      articleId: json.article_id,
      entityType: json.entity_type,
      author: json.author_summary ? AdminSummary.deserialize(json.author_summary) : undefined,
      owner: json.owner_summary ? AdminSummary.deserialize(json.owner_summary) : undefined,
      blocks: json.blocks,
      locale: json.locale,
      siblings: json.siblings ? json.siblings.map(KnowledgeBaseContent.deserialize) : [],
      visibleToUser: json.visible_to_user,
      createdAt: new Date(json.created_at),
      updatedAt: new Date(json.updated_at),
      state: json.state,
      liveVersionId: json.live_version_id,
      markdown: json.markdown,
      publicUrl: json.public_url,
    });
  }

  get entityName(): string {
    return humanReadableObjectNames[this.entityType];
  }

  get isArticle(): boolean {
    return this.entityType === EntityType.ArticleContent;
  }

  get icon(): InterfaceIconName {
    return objectIcons[this.entityType];
  }

  get stamp(): ContentStamp | undefined {
    return contentStampStyleObj(this.entityType);
  }
}
