/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import { isArray, isObject, isString } from 'underscore';
import WorkflowActionParam from 'embercom/models/workflow-connector/workflow-action-param';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class JiraWorkflowActionParam extends WorkflowActionParam {
  @attr() declare allowedValues: Array<any>;
  @attr('boolean') declare required: boolean;
  @attr('string') declare humanName: string;
  @attr('string') declare inputType: string;
  @attr('boolean') declare refreshOnChange: boolean;
  @attr('string') declare selectedChildValue: string;
  @attr('boolean') declare dynamicallyConfigured: boolean;
  @attr() declare child: any;
  @attr() declare errors: any;

  @tracked declare rawValue: string;
  @tracked declare childRawValue: string;
  @service declare appService: any;
  @service intl: any;

  init() {
    super.init();
    try {
      if (this.inputType === 'float' && this.value && !isString(this.value)) {
        this.value = this.value.toString();
      }

      if (this.dynamicallyConfigured) {
        this.rawValue = this.value as string;
        this.value = null;
      }

      if (this.inputType === 'cascadingselect' && this.childDynamicallyConfigured) {
        this.childRawValue = this.child?.value as string;
        this.child.value = null;
      }

      if (this.errors?.length > 0) {
        this.value = null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  get selectedChild() {
    return this.child?.value;
  }

  set selectedChild(value: string) {
    this.child.value = value;
  }

  get childBlocks() {
    return WorkflowActionParam.blocksFromText(this.childRawValue || this.child.value);
  }

  get blocks() {
    if (this.dynamicallyConfigured) {
      let blocksValue = isArray(this.rawValue) ? this.rawValue.join(', ') : this.rawValue;
      return WorkflowActionParam.blocksFromText(blocksValue);
    }
    return super.blocks;
  }

  @action
  onChildrenBlockChange(newBlocks: any) {
    this.childRawValue = WorkflowActionParam.textFromBlocks(newBlocks.blocks);
  }

  @action
  onBlocksChange(newBlocks: any): void {
    if (this.dynamicallyConfigured) {
      this.rawValue = WorkflowActionParam.textFromBlocks(newBlocks.blocks);
      return;
    }
    super.onBlocksChange(newBlocks);
  }

  @action
  onSelectParamChange(newValue: any) {
    this.value = newValue;

    if (this.inputType === 'cascadingselect') {
      let selectedAllowedValue = this.allowedValuesSelectList?.find(
        (v: any) => v.value === newValue,
      );
      this.selectedChild = selectedAllowedValue.children?.[0].value;
    }
  }

  @action
  handleDynamicallyConfiguredChange(newValue: boolean) {
    this.dynamicallyConfigured = newValue;

    if (!this.dynamicallyConfigured) {
      this.value =
        this.value && this.isDynamicValue(this.value) ? this.allowedValues[0].value : this.value;
    }
  }

  get labelText() {
    let labelText = this.intl.t(
      'operator.workflows.visual-builder.jira-create-issue.settings.field-label',
    );
    if (this.value !== null && this.allowedValuesSelectList !== null) {
      if (this.value.length > 0) {
        labelText = this.allowedValuesSelectList
          .filter((v) => this.value?.includes(v.value))
          .map((v) => v.text)
          .join(', ');
      }
    }
    return labelText;
  }

  isDynamicValue(value: string | string[] | null) {
    if (!value) {
      return false;
    }

    if (isArray(value)) {
      return value.some((v) => v.match(/\{\{.*?\}\}/));
    }

    return !!value.match(/\{\{.*?\}\}/);
  }

  /**
   * if the compared value is an array [1,2] and the element can be [1,2,3,4] or {key: 1, value: 'one'} or {value: 1, text: 'one'}
   * if element is an object as {key: 1, value: 'one'} or {value: 1, text: 'one'} we need to check the value of the key or the value
   * last the element might be a string as the compared value
   **/
  isValueSelected(value: string | string[] | null, element: any) {
    let key = 'value';
    return (
      (isArray(value) && (value.includes(element[key]) || value.includes(element))) ||
      (isObject(element) && value === element[key]) ||
      value === element
    );
  }

  toSelectList(array: any): any[] {
    if (!array) {
      return [];
    }
    let selectList: any[] = [];
    array.forEach((element: any) => {
      let isSelected = this.isValueSelected(this.value, element);
      let children = element.children?.map((child: any) => {
        let isSelectedChild = this.isValueSelected(this.selectedChild, child);
        return { ...child, isSelected: isSelectedChild };
      });
      selectList.push({ ...element, isSelected, children });
    });
    return selectList;
  }

  get constructTextAreaField() {
    return {
      content: [
        {
          content: [
            {
              text: this.value as string,
              type: 'text',
            },
          ],
          type: 'paragraph',
        },
      ],
      type: 'doc',
      version: 1,
    };
  }

  get constractedValue() {
    if (this.key === 'project') {
      return {
        key: this.value,
      };
    } else if (this.key === 'issuetype' || this.key === 'issueType') {
      return {
        name: this.value,
      };
    } else if (this.inputType === 'textarea' || this.key === 'description') {
      return this.constructTextAreaField;
    } else if (this.inputType === 'labels') {
      let labelValue = this.dynamicallyConfigured ? this.rawValue : this.value;
      return isString(labelValue)
        ? labelValue
            ?.split(',')
            .map((s: string) => s.trim())
            .filter(Boolean)
        : labelValue;
    } else if (this.key === 'priority') {
      return this.dynamicallyConfigured ? { name: this.rawValue } : { id: this.value };
    } else if (this.inputType === 'select') {
      return this.dynamicallyConfigured ? { value: this.rawValue } : { id: this.value };
    } else if (this.inputType === 'people') {
      if (this.dynamicallyConfigured) {
        // id, id2, id3
        return this.rawValue.split(',').map((id: string) => ({
          id: id.trim(),
        }));
      }
      return isArray(this.value)
        ? this.value?.map((v: string) => ({
            id: v,
          }))
        : [
            {
              id: this.value,
            },
          ];
    } else if (this.inputType === 'cascadingselect') {
      if (this.dynamicallyConfigured) {
        return {
          value: this.rawValue,
          child: { value: this.childRawValue },
        };
      }
      return {
        id: this.value,
        child: this.childDynamicallyConfigured
          ? { value: this.childRawValue }
          : { id: this.selectedChild },
      };
    } else if (this.key === 'assignee' || this.key === 'reporter' || this.inputType === 'user') {
      return {
        id: this.dynamicallyConfigured ? this.rawValue : this.value,
      };
    } else if (this.inputType === 'multicheckboxes') {
      if (this.dynamicallyConfigured) {
        return this.rawValue.split(',').map((v: string) => ({
          value: v.trim(),
        }));
      }
      if (this.value instanceof Array) {
        return this.value.map((v: string) => ({
          id: v,
        }));
      }
    } else if (this.inputType === 'float') {
      return `%float%${this.value}%float%`;
    }
    return this.value;
  }

  extractJiraCreateCommentField(bodyJson: any) {
    return bodyJson['body']['content'][0]['content'][0]['text'];
  }

  isEmptyArrayValue(value: string | string[] | null) {
    return isArray(value) && value.filter((x) => x).length === 0;
  }

  get isEmptyFieldValue() {
    let value = this.dynamicallyConfigured ? this.rawValue : this.value;
    return !value || this.isEmptyArrayValue(value);
  }

  get disableChildDynamicConfig() {
    return this.dynamicallyConfigured || this.value === null;
  }

  get childDynamicallyConfigured() {
    return this.disableChildDynamicConfig ? true : !!this.child?.dynamically_configured;
  }

  set childDynamicallyConfigured(value: boolean) {
    this.child.dynamically_configured = value;
  }

  get refreshRequest() {
    return this.inputType === 'project' || this.inputType === 'issuetype';
  }

  get selectedValue() {
    return isArray(this.value) ? this.value.filter((n) => n) : this.value;
  }

  get allowedValuesSelectList() {
    return this.toSelectList(this.allowedValues);
  }

  get selectedChildrenSelectList() {
    let key = 'value';
    return this.toSelectList(this.allowedValues.find((v) => v[key] === this.value)?.children);
  }

  get fieldErrorMessage() {
    let errorMessageValue = (this.errors[0] as string).split(':');
    return this.intl.t(
      'operator.workflows.visual-builder.jira-create-issue.settings.field-error-message',
      {
        errorMessageValue: errorMessageValue[1],
      },
    );
  }

  get childSwitchDisabledWithNoParentSelectedOption() {
    return this.disableChildDynamicConfig && !this.value && !this.dynamicallyConfigured;
  }
  get childSwitchDisabledWithNoParentDynamicallyConfig() {
    return !this.rawValue && this.dynamicallyConfigured;
  }

  get childInputFieldIsDisabled() {
    return (
      this.childSwitchDisabledWithNoParentSelectedOption ||
      this.childSwitchDisabledWithNoParentDynamicallyConfig
    );
  }

  get displayedPlaceholder() {
    switch (this.inputType) {
      case 'user':
      case 'people':
      case 'reporter':
      case 'assignee':
        return this.intl.t(
          `operator.workflows.visual-builder.jira-create-issue.settings.user-placeholder`,
        );
      case 'select':
      case 'cascadingselect':
        return this.intl.t(
          `operator.workflows.visual-builder.jira-create-issue.settings.select-placeholder`,
        );
      case 'labels':
      case 'multicheckboxes':
        return this.intl.t(
          `operator.workflows.visual-builder.jira-create-issue.settings.labels-placeholder`,
        );
      case 'priority':
      case 'datepicker':
      case 'float':
      case 'datetime':
      case 'url':
      case 'json':
        return this.intl.t(
          `operator.workflows.visual-builder.jira-create-issue.settings.${this.inputType}-placeholder`,
        );
      default:
        return this.intl.t(
          `operator.workflows.visual-builder.jira-create-issue.settings.default-placeholder`,
        );
    }
  }
}
