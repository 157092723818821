/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import {
  alias,
  and,
  equal,
  gt,
  mapBy,
  not,
  notEmpty,
  or,
  readOnly,
  reads,
} from '@ember/object/computed';
import { findBy, findByProperty } from '@intercom/pulse/lib/computed-properties';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { runTask } from 'ember-lifeline';
import ajax from 'embercom/lib/ajax';
import {
  CURRENT_TEAMMATE_PLACEHOLDER_ID,
  CURRENT_TEAMMATE_TRANSLATION_KEY,
} from 'embercom/lib/inbox/constants';
import { DEFAULT_EMOJI } from 'embercom/lib/available-emoji';
import { INBOX_ID } from 'embercom/lib/billing';
import containerLookup, { getApp, getEmberDataStore } from 'embercom/lib/container-lookup';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import AdminAvatarData from 'embercom/models/mixins/admin-avatar-data';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import storage from 'embercom/vendor/intercom/storage';
import {
  SUPPORT_SEAT_TYPE,
  COLLABORATOR_SEAT_TYPE,
  PRICING_5_X_LITE_SEAT_TYPE,
  PRICING_5_X_FULL_SEAT_TYPE,
  COPILOT_SEAT_TYPE,
} from 'embercom/lib/settings/seats/constants';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { put } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { getIntlService } from 'embercom/lib/container-lookup';
import {
  LIMITED_USAGE,
  NO_ACCESS,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';

const RESEND_VERIFICATION_TIMEOUT = 10000;
const HAS_CHANGED_OWN_STATUS_KEY = 'has_changed_own_away_status';
const UPDATE_TAGGING_URL = '/ember/admins/update_tags';

let Admin = Model.extend(AdminAvatarData, TaggingMixin, {
  intl: service(),
  created_at: attr(),
  name: attr(),
  first_name: attr(),
  firstName: alias('first_name'),
  last_name: attr(),
  alias_name: attr(),
  localized_sender_name: attr(),
  is_me: attr(),
  is_github_bot: attr(),
  is_operator: attr(),
  is_facebook_bot: attr(),
  is_bot: attr(),
  is_unknown: attr(),
  isUnknown: alias('is_unknown'),
  advanced_shortcuts_enabled: attr(),
  email: attr(),
  email_verified: attr(),
  invite_token: attr(),
  pending_email_change: attr(),
  current_email_pending_verification: attr(),
  on_bounce_list: attr(),
  color: attr(),
  avatar: attr(),
  avatar_shape: attr(),
  avatar_emoji: attr('string', { defaultValue: DEFAULT_EMOJI }),
  has_default_avatar: attr(),
  has_photo_avatar: attr(),
  has_unseen_changes: attr(),
  removed_from_current_app: attr(),
  assign_conversations_on_reply: attr(),
  password: attr(),
  terms_of_service: attr(),
  audio_notifications_enabled: attr(),
  important_admin_notifications: attr(),
  importantAdminNotifications: alias('important_admin_notifications'),
  department: attr(),
  impersonation_permission_status: attr(),
  impersonation_permission_default_reason: attr(),
  apps: attr(),
  calendar_url: attr(),
  show_calendar_in_messenger: attr(),

  app_permissions: hasMany('permission', { defaultValue: () => [] }),
  permissions: alias('app_permissions'),

  current_app_id: attr(),
  currentAppPermissions: findByProperty('permissions', 'app_id', 'current_app_id'),
  hasInboxAccess: readOnly('currentAppPermissions.has_inbox_access'),
  canAccessBillingSettings: readOnly('currentAppPermissions.can_access_billing_settings'),
  canAccessWorkSpaceSettings: readOnly('currentAppPermissions.can_access_workspace_settings'),
  canAccessProductSettings: readOnly('currentAppPermissions.can_access_product_settings'),
  canManageAppsAndIntegrations: readOnly('currentAppPermissions.can_manage_apps_and_integrations'),
  canManageTags: readOnly('currentAppPermissions.can_manage_tags'),
  canCanManageTeammates: readOnly('currentAppPermissions.can_manage_teammates'),
  canSendMessages: readOnly('currentAppPermissions.can_send_messages'),
  hasProductAccessForCurrentApp: readOnly('currentAppPermissions.can_access_product_settings'),
  canInboxAccessCopilot: readOnly('currentAppPermissions.can_inbox__access_copilot'),

  email_address_for_app: attr(),
  has_enabled_2fa: attr(),
  type: 'admin',
  nameOrEmail: or('name', 'email'),
  firstNameOrNameOrEmail: or('first_name', 'name', 'email'),
  assigneeIdentifier: alias('email'),
  isAdmin: true,
  isGithubBot: reads('is_github_bot'),
  isOperator: reads('is_operator'),
  isFacebookBot: reads('is_facebook_bot'),
  isBot: or('is_bot', 'isGithubBot', 'isOperator', 'isFacebookBot'),
  visible_tag_ids: attr(),
  visible_segment_ids: attr(),
  visible_attribute_ids: attr(),
  visible_company_attribute_ids: attr(),
  visible_user_event_ids: attr(),
  visible_inbox_sidebar_item_ids: attr(),
  visible_conversation_attribute_ids: attr(),
  browser_alert_setting: attr(),
  linkedin: findBy('socialAccounts', 'provider', 'linkedin'),
  facebook: findBy('socialAccounts', 'provider', 'facebook'),
  twitter: findBy('socialAccounts', 'provider', 'twitter'),
  companySize: attr(),
  job_title: attr(),
  profile_department: attr(),
  phone_number: attr(),
  biography: attr(),
  show_location: attr(),
  alias_enabled: attr(),
  has_password: attr('boolean'),
  can_change_email: attr('boolean'),
  canChangeEmail: alias('can_change_email'),
  away_mode_enabled: attr('boolean'),
  auto_away_after_seconds: attr('number'),
  reassign_conversations: attr('boolean'),
  terms_of_service_accepted: attr('boolean'),
  canResendVerificationEmail: true,
  isTeam: readOnly('is_team'),
  locale: attr(),
  is_team: attr(),
  is_app_team: attr(),
  isAppTeam: alias('is_app_team'),
  isNotAppTeam: not('is_app_team'),
  isTeamButNotAppTeam: and('is_team', 'isNotAppTeam'),
  member_ids: attr(),
  member_ids_with_priority: attr(),
  teamMemberCount: readOnly('member_ids.length'),
  firstTeamMemberId: readOnly('member_ids.firstObject'),
  teamInboxes: attr(),
  seats: attr('array'),
  isDynamicUser: equal('id', `${CURRENT_TEAMMATE_PLACEHOLDER_ID}`),
  isUnassignedAdmin: equal('id', '0'),
  isNotHuman: or('isUnassignedAdmin', 'isDynamicUser', 'is_team', 'isBot'),
  isHuman: not('isNotHuman'),
  outboundType: senderTypes.admin,
  teammate_role_data_completed: attr('boolean'),
  notification_sound: attr(),
  away_status_reason: attr(),
  inbox_2_opt_in: attr('boolean', { allowNull: true }),
  product_ia_opt_in: attr('boolean', { allowNull: true }),
  workload_management_settings: attr(),
  tags: hasMany('tag', { async: true, inverse: null }),
  taggings: hasMany('tagging', { inverse: null }),
  enforced_email_verification_required: attr(),

  isAssignableUnderThePerSeatPricing: or(
    'isUnassignedAdmin',
    'isTeamButNotAppTeam',
    'hasInboxAccess',
  ),

  isSeatless: equal('seats.length', 0),
  hasSeats: not('isSeatless'),
  multipleSeatTypesAssignableAdmins: computed(
    'hasVbp2CollaboratorSeat',
    'hasPricing5LiteSeat',
    'hasSeats',
    'isTeamButNotAppTeam',
    'isUnassignedAdmin',
    function () {
      return (
        !this.hasVbp2CollaboratorSeat &&
        !this.hasPricing5LiteSeat &&
        (this.hasSeats || this.isTeamButNotAppTeam || this.isUnassignedAdmin)
      );
    },
  ),

  // A dummy admin is one that is created to represent an agent in an external system.
  // This allows us to create conversation parts in the intercom representation of the conversation with the correct admin details
  isStandaloneDummyAdmin: computed('email', function () {
    return this.email?.endsWith('hirefin.com');
  }),

  activeImportantAdminNotifications: computed(
    'importantAdminNotifications.[]',
    'importantAdminNotifications.@each.{acknowledged_at,cancelled_at,expired_at}',
    function () {
      if (
        !this.importantAdminNotifications ||
        typeof this.importantAdminNotifications.filter !== 'function'
      ) {
        return [];
      }

      return this.importantAdminNotifications.filter((notification) => {
        return (
          !notification.acknowledged_at &&
          !notification.cancelled_at &&
          (!notification.expired_at || moment(notification.expired_at).isAfter(new Date()))
        );
      });
    },
  ),

  activeProductIdsAcrossApps: mapBy('apps', 'active_product_ids'),

  inboxIsActiveOnAnyApp: computed('activeProductIdsAcrossApps.@each.[]', function () {
    return this.activeProductIdsAcrossApps.some((productIds) =>
      productIds.includes(parseInt(INBOX_ID, 10)),
    );
  }),

  inboxIsActiveOnEveryApp: computed('activeProductIdsAcrossApps.@each.[]', function () {
    return this.activeProductIdsAcrossApps.every((productIds) =>
      productIds.includes(parseInt(INBOX_ID, 10)),
    );
  }),

  inboxIsNotActiveOnEveryApp: not('inboxIsActiveOnEveryApp'),
  hasMoreThanOneApp: gt('apps.length', 1),

  canUseInboxInAnyApp: computed('apps.[]', 'permissions.[]', function () {
    let permissions = this.permissions;
    let canUseInboxPerApp = this.apps.map(function (app) {
      let respondActiveForApp = app.has_inbox_access;
      if (app.per_product_pricing_flow) {
        let permissionsForApp = permissions.findBy('app_id', app.id);
        return (
          respondActiveForApp &&
          permissionsForApp !== undefined &&
          permissionsForApp.get('has_inbox_access')
        );
      } else {
        return respondActiveForApp;
      }
    });
    return canUseInboxPerApp.includes(true);
  }),

  hasAvatarEmoji: notEmpty('avatar_emoji'),
  status: attr(),
  last_active: attr(),
  location_data: attr(),
  timezone: readOnly('location_data.timezone'),
  hasTimezone: notEmpty('location_data.timezone'),

  localTime: computed('hasTimezone', 'timezone', function () {
    if (this.hasTimezone) {
      return this.intl.formatTime(new Date(), {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: this.timezone,
      });
    }
  }),

  displayAs: readOnly('display_as'),

  display_as: readOnly('name'),

  display_as_assignee: computed('is_me', 'name', function () {
    if (this.is_me) {
      return 'You';
    } else {
      return this.name;
    }
  }),

  avatarUrl: computed('avatar', function () {
    return this.get('avatar.image_urls.square_128') || DefaultAvatars.admins[128];
  }),

  canUseCustomDomain() {
    let app = getApp();
    if (!app.emailSenderAddressCustomized) {
      return false;
    }
    let dkimSetting = this.store
      .peekAll('dkim-settings')
      .find((setting) => setting.adminEmails.includes(this.email));
    return !!dkimSetting?.validRecordExists;
  },

  createAvatar(image) {
    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('avatar', image);
    return ajax({
      type: 'POST',
      url: '/ember/admins/create_avatar.json',
      data: formData,
      contentType: false,
      processData: false,
    }).then(
      function (response) {
        this.setProperties({
          avatar: response.avatar,
          has_photo_avatar: response.has_photo_avatar,
        });
      }.bind(this),
    );
  },

  destroyAvatar() {
    return ajax({
      type: 'POST',
      url: '/ember/admins/destroy_avatar.json',
      data: JSON.stringify({
        id: this.id,
      }),
    }).then(
      function (response) {
        this.set('avatar', response.avatar);
        this.set('has_photo_avatar', response.has_photo_avatar);
      }.bind(this),
    );
  },

  analyticsData: computed('id', 'email', 'name', 'isTeam', function () {
    if (this.isTeam) {
      return {
        object: 'team',
        team_id: this.id,
      };
    } else {
      return {
        object: 'admin',
        teammate_id: this.id,
        email: this.email,
        name: this.name,
      };
    }
  }),

  hasSupportSeat: computed('seats', function () {
    return this.seats.includes(SUPPORT_SEAT_TYPE);
  }),

  hasVbp2CollaboratorSeat: computed('seats', function () {
    return this.seats.includes(COLLABORATOR_SEAT_TYPE);
  }),

  hasPricing5LiteSeat: computed('seats', function () {
    return this.seats.includes(PRICING_5_X_LITE_SEAT_TYPE);
  }),

  hasPricing5CoreSeat: computed('seats', function () {
    return this.seats.includes(PRICING_5_X_FULL_SEAT_TYPE);
  }),

  hasLimitedCopilotAccess: computed('canInboxAccessCopilot', 'seats.[]', function () {
    return this.canInboxAccessCopilot && !this.seats.includes(COPILOT_SEAT_TYPE);
  }),

  hasUnlimitedCopilotAccess: computed('canInboxAccessCopilot', 'seats.[]', function () {
    return this.canInboxAccessCopilot && this.seats.includes(COPILOT_SEAT_TYPE);
  }),

  hasCopilotSeat: computed('seats.[]', function () {
    return this.seats.includes(COPILOT_SEAT_TYPE);
  }),

  copilotAccessType: computed('hasLimitedCopilotAccess', 'hasUnlimitedCopilotAccess', function () {
    if (this.hasLimitedCopilotAccess) {
      return LIMITED_USAGE;
    } else if (this.hasUnlimitedCopilotAccess) {
      return UNLIMITED_USAGE;
    } else {
      return NO_ACCESS;
    }
  }),

  addTagIdsToAdminsVisibleTagIds(tagIds) {
    if (this.visible_tag_ids) {
      this.visible_tag_ids.pushObjects(tagIds);
    } else {
      this.set('visible_tag_ids', tagIds);
    }
    this.visibleTagIdsChanged();
  },

  removeTagIdsFromAdminsVisibleTagIds(tagIds) {
    if (this.visible_tag_ids) {
      this.visible_tag_ids.removeObjects(tagIds);
    }
    this.visibleTagIdsChanged();
  },

  addSegmentIdsToAdminsVisibleSegmentIds(segmentIds) {
    if (this.visible_segment_ids) {
      this.visible_segment_ids.pushObjects(segmentIds);
    } else {
      this.set('visible_segment_ids', segmentIds);
    }
    this.visibleSegmentIdsChanged();
  },

  removeSegmentIdsFromAdminsVisibleSegmentIds(segmentIds) {
    if (this.visible_segment_ids) {
      this.visible_segment_ids.removeObjects(segmentIds);
    }
    this.visibleSegmentIdsChanged();
  },

  addAttributeIdToAdminsVisibleAttributeIds(attributeId) {
    if (this.visible_attribute_ids) {
      this.visible_attribute_ids.pushObject(attributeId);
    } else {
      this.set('visible_attribute_ids', [attributeId]);
    }
    this.visibleAttributeIdsChanged();
  },

  removeAttributeIdFromAdminsVisibleAttributeIds(attributeId) {
    if (this.visible_attribute_ids) {
      this.visible_attribute_ids.removeObject(attributeId);
      this.visibleAttributeIdsChanged();
    }
  },

  addAttributeIdToAdminsVisibleCompanyAttributeIds(attributeId) {
    if (this.visible_company_attribute_ids) {
      this.visible_company_attribute_ids.pushObject(attributeId);
    } else {
      this.set('visible_company_attribute_ids', [attributeId]);
    }
    this.visibleCompanyAttributeIdsChanged();
  },

  removeAttributeIdFromAdminsVisibleCompanyAttributeIds(attributeId) {
    if (this.visible_company_attribute_ids) {
      this.visible_company_attribute_ids.removeObject(attributeId);
      this.visibleCompanyAttributeIdsChanged();
    }
  },

  addEventIdToAdminsVisibleUserEventIds(eventId) {
    if (this.visible_user_event_ids) {
      this.visible_user_event_ids.pushObject(eventId);
    } else {
      this.set('visible_user_event_ids', [eventId]);
    }
    this.visibleUserEventIdsChanged();
  },

  removeEventIdFromAdminsVisibleUserEventIds(eventId) {
    if (this.visible_user_event_ids) {
      this.visible_user_event_ids.removeObject(eventId);
    }
    this.visibleUserEventIdsChanged();
  },

  addItemToAdminsVisibleSidebarItemIds(itemId) {
    if (this.visible_inbox_sidebar_item_ids) {
      this.visible_inbox_sidebar_item_ids.pushObject(itemId);
    } else {
      this.set('visible_inbox_sidebar_item_ids', [itemId]);
    }
    this.visibleSidebarItemIdsChanged();
  },

  addAttributeToVisibleConversationAttributeIds(attributeId) {
    if (this.visible_conversation_attribute_ids) {
      this.visible_conversation_attribute_ids.pushObject(attributeId);
    } else {
      this.set('visible_conversation_attribute_ids', [attributeId]);
    }
    this.visibleConversationAttributeIdsChanged();
  },

  removeItemFromAdminsVisibleSidebarItemIds(itemId) {
    if (this.visible_inbox_sidebar_item_ids) {
      this.visible_inbox_sidebar_item_ids.removeObject(itemId);
      this.visibleSidebarItemIdsChanged();
    }
  },

  removeAttributeFromVisibleConversationAttributeIds(attributeId) {
    if (this.visible_conversation_attribute_ids) {
      this.visible_conversation_attribute_ids.removeObject(attributeId);
      this.visibleConversationAttributeIdsChanged();
    }
  },

  updateVisibleSidebarItems(ids) {
    if (!this.visible_inbox_sidebar_item_ids) {
      this.visible_inbox_sidebar_item_ids = [];
    }

    this.visible_inbox_sidebar_item_ids.clear();
    this.visible_inbox_sidebar_item_ids.pushObjects(ids);
    this.visibleSidebarItemIdsChanged();
  },

  visibleTagIdsChanged() {
    if (this._dirtyAttributes && !this._dirtyAttributes.includes('visible_tag_ids')) {
      this._dirtyAttributes.pushObject('visible_tag_ids');
    }
  },

  visibleSegmentIdsChanged() {
    if (this._dirtyAttributes && !this._dirtyAttributes.includes('visible_segment_ids')) {
      this._dirtyAttributes.pushObject('visible_segment_ids');
    }
  },

  visibleAttributeIdsChanged() {
    if (this._dirtyAttributes && !this._dirtyAttributes.includes('visible_attribute_ids')) {
      this._dirtyAttributes.pushObject('visible_attribute_ids');
    }
  },

  visibleCompanyAttributeIdsChanged() {
    if (this._dirtyAttributes && !this._dirtyAttributes.includes('visible_company_attribute_ids')) {
      this._dirtyAttributes.pushObject('visible_company_attribute_ids');
    }
  },

  visibleUserEventIdsChanged() {
    if (this._dirtyAttributes && !this._dirtyAttributes.includes('visible_user_event_ids')) {
      this._dirtyAttributes.pushObject('visible_user_event_ids');
    }
  },

  visibleSidebarItemIdsChanged() {
    if (
      this._dirtyAttributes &&
      !this._dirtyAttributes.includes('visible_inbox_sidebar_item_ids')
    ) {
      this._dirtyAttributes.pushObject('visible_inbox_sidebar_item_ids');
    }
  },

  visibleConversationAttributeIdsChanged() {
    if (
      this._dirtyAttributes &&
      !this._dirtyAttributes.includes('visible_conversation_attribute_ids')
    ) {
      this._dirtyAttributes.pushObject('visible_conversation_attribute_ids');
    }
  },

  setSocialAccounts(collection) {
    this.set('socialAccounts', collection);
  },

  updatePersonalSettings(options) {
    return ajax({
      type: 'POST',
      url: '/ember/admins/update_personal_settings',
      noRedirectOn401: true,
      data: JSON.stringify(options),
    }).then(() => {
      return this.reload();
    });
  },

  twoFactorQRCodeSVG: null,
  hasTwoFactorQRCode: notEmpty('twoFactorQRCodeSVG'),

  getTwoFactorQRCodeSVG() {
    ajax({
      type: 'GET',
      url: '/ember/admins/two_factor_qr_code.svg',
      dataType: 'html',
      data: {
        id: this.id,
        app_id: this.current_app_id,
      },
    }).then((qrCode) => {
      if (!this.isDestroying) {
        this.set('twoFactorQRCodeSVG', sanitizeHtml(qrCode));
      }
    });
  },

  getEmailAddressForApp() {
    if (this.email_address_for_app) {
      return this.email_address_for_app;
    }
    let app = getApp();

    if (app.emailSenderAddressCustomized) {
      this.set('email_address_for_app', this.email);
      return this.email;
    } else {
      return this.getEmail();
    }
  },

  getEmail() {
    ajax({
      type: 'GET',
      url: '/ember/admins/email_address_for_app',
      data: {
        id: this.id,
        app_id: this.current_app_id,
      },
    }).then((emailAddress) => {
      this.set('email_address_for_app', emailAddress.email_address);
      return emailAddress.email_address;
    });
  },

  sendVerificationEmail() {
    if (this.email_verified || this.current_email_pending_verification) {
      return;
    }

    return ajax({
      type: 'PUT',
      url: '/ember/admins/send_verification_email',
      data: JSON.stringify({
        id: this.id,
        app_id: this.current_app_id,
      }),
    }).then(() => this.reload());
  },

  resendValidationEmail() {
    return ajax({
      type: 'PUT',
      url: '/ember/admins/resend_verification_email',
      data: JSON.stringify({
        id: this.id,
        app_id: this.current_app_id,
      }),
    });
  },

  resendSignupValidationEmail(queryParams) {
    return ajax({
      type: 'PUT',
      url: '/ember/admins/resend_signup_verification_email',
      data: JSON.stringify({
        id: this.id,
        app_id: this.current_app_id,
        solution_id: queryParams.solutionId,
      }),
    });
  },

  sendValidationEmailWithRetry() {
    if (this.current_email_pending_verification) {
      this.resendValidationEmail();
    } else {
      this.sendVerificationEmail();
    }
    this.set('canResendVerificationEmail', false);
    runTask(this, () => this.set('canResendVerificationEmail', true), RESEND_VERIFICATION_TIMEOUT);
  },

  isTwitterBot: computed('isBot', 'name', function () {
    return this.isBot && this.name === 'Twitter bot';
  }),

  sortName: computed('is_me', 'isUnassignedAdmin', 'name', function () {
    if (this.is_me) {
      return '';
    }
    if (this.isUnassignedAdmin) {
      return ' ';
    }
    return this.name;
  }),

  hasEnabledCSComponent: computed({
    get() {
      let csComponentState = storage.get('cs-component-enabled');
      if (csComponentState === undefined) {
        return false;
      } else {
        return csComponentState;
      }
    },
    set(key, value) {
      storage.set('cs-component-enabled', value);
      return value;
    },
  }),

  taggable: computed('taggings', 'updateTaggingsTask', function () {
    return {
      tags: [],
      taggings: this.taggings,
      type: 'admin',
      updateTaggings: (admin, addedTags, removedTags, initialTags) => {
        return this.updateTaggings(admin, addedTags, removedTags);
      },
      removeTagging: (tag) => {
        return this.removeTagging(tag);
      },
    };
  }),

  async updateTaggings(admin, addedTags, removedTags) {
    try {
      await put(UPDATE_TAGGING_URL, {
        app_id: Admin._getAppId(),
        added_tag_ids: addedTags.map((tag) => tag.id),
        removed_tag_ids: removedTags.map((tag) => tag.id),
        admin_id: this.id,
      });
    } catch (error) {
      this.refreshTaggings(admin, removedTags, addedTags);
      throw error;
    }
  },

  removeTagging: action(async function (tag) {
    let admin = getApp().currentAdmin;
    let addedTags = [];
    let removedTags = [tag];
    await this.updateTaggings(admin, addedTags, removedTags).catch((e) => {});
    return this.refreshTaggings(admin, addedTags, removedTags);
  }),

  save() {
    try {
      let modelDataCacheService = containerLookup('service:modelDataCacheService');
      modelDataCacheService.cancelRefresh('admin', this.id);
    } catch (e) {
      console.error(`Attempt to cancel admin refresh task failed`);
    }
    return this._super(...arguments);
  },

  saveOutOfOfficeSettings() {
    let logService = containerLookup('service:logService');
    let data = JSON.stringify({
      app_id: this.current_app_id,
      id: this.id,
      reassign_conversations: this.reassign_conversations,
      away_mode_enabled: this.away_mode_enabled,
      away_status_reason: this.away_status_reason,
    });
    if (logService) {
      logService.log(
        `Before calling change away mode with ${data} currentAdmin=${getApp().currentAdmin.id}`,
      );
    }
    // We set this in order to show an info banner to teammates for the FIRST time after they change their status.
    let trackedLocalStorage = containerLookup('service:trackedLocalStorage');
    trackedLocalStorage.setItem(HAS_CHANGED_OWN_STATUS_KEY, '1');

    if (this.id === getApp().currentAdmin.id) {
      return ajax({
        type: 'PUT',
        url: '/ember/admins/change_own_away_mode',
        data,
      });
    } else {
      return ajax({
        type: 'PUT',
        url: '/ember/admins/change_away_mode',
        data,
      });
    }
  },

  sendRecoveryCodeEmail() {
    let data = JSON.stringify({
      app_id: this.current_app_id,
      id: this.id,
    });

    return ajax({
      type: 'PUT',
      url: '/ember/admins/email_recovery_code',
      data,
    });
  },

  updateLocalCache(modelDataCacheService) {
    if (modelDataCacheService.hasCachedItem('admin', this.id)) {
      modelDataCacheService.updateCachedItem('admin', this.id, this.serialize());
    }
  },
}).reopenClass({
  meWithoutRedirect() {
    return Admin.me(true);
  },

  me(noRedirect) {
    noRedirect = noRedirect !== undefined;
    return ajax({
      type: 'GET',
      cache: false,
      url: '/ember/admins/me.json',
      noRedirectOn401: noRedirect,
    }).then(function (response) {
      let store = getEmberDataStore();
      store.pushPayload({ admin: response });
      return store.peekRecord('admin', response.id);
    });
  },

  fetchJSON() {
    return ajax({
      type: 'GET',
      cache: false,
      url: '/ember/admins/me.json',
      data: { app_id: Admin._getAppId() },
    });
  },

  _getAppId() {
    let app = getApp();
    if (app) {
      return app.id;
    }
  },

  loadJSON(data) {
    let store = getEmberDataStore();
    store.pushPayload({ admin: data });
    return store.peekRecord('admin', data.id);
  },

  peekAndMaybeLoad(store, id) {
    if (id === CURRENT_TEAMMATE_PLACEHOLDER_ID) {
      store.pushPayload({
        admins: [{ id, name: getIntlService().t(CURRENT_TEAMMATE_TRANSLATION_KEY) }],
      });
    }

    if (id === null || id === undefined) {
      id = -1;
    }

    let admin = store.peekRecord('admin', id);
    if (admin) {
      return admin;
    }
    // Not finding an admin here either means they were deleted or they are new
    // push a placeholder into the store
    store.pushPayload({ admins: [{ id, name: 'Unknown Teammate', is_unknown: true }] });

    if (id !== -1) {
      // Load the admin to try to fill out more information
      store.findRecord('admin', id);
    }

    // synchronously return the admin we just pushed
    return store.peekRecord('admin', id);
  },
});

export default Admin;
