/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import ajax from 'embercom/lib/ajax';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty, isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { notEmpty, and, equal, not, match, or, alias, readOnly } from '@ember/object/computed';
import { equalsAny } from '@intercom/pulse/lib/computed-properties';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import AttributeIconMap from 'embercom/models/data/attribute-icon-map';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { typeMapping, syncDirection } from 'embercom/models/crm/attribute-mapping-constants';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  USER_OBJECT_TYPE_NAME,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_NAME,
} from 'embercom/models/custom-objects/constants/object-types';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';

const MAX_COMBINED_LENGTH_FOR_BUTTON_GROUP = 18;
const MAX_NUMBER_OF_OPTIONS_FOR_BUTTON_GROUP = 4;
const CRM_STRING_TYPES = [
  'role',
  'browser_locale',
  'android_app_version',
  'android_device',
  'android_version',
  'ios_app_version',
  'ios_device',
  'ios_os_version',
  'plan',
  'priority',
];
const CRM_INTEGER_TYPES = ['owner_id', 'admin_assignee_id', 'team_assignee_id'];

let AttributeModel = Model.extend({
  customObjectsService: service(),
  currencyTypes: ['company.monthly_spend'],
  identifier: attr('string', { defaultValue: '' }),
  name: attr(),
  humanFriendlyName: alias('human_friendly_name'),
  human_friendly_name: attr('string', { defaultValue: '' }),
  type: attr('string', { defaultValue: 'string' }),
  description: attr(),
  cda_id: attr(),
  event_id: attr(),
  displayable: attr(),
  filterable: attr(),
  crm_readable: attr(),
  crm_writable: attr(),
  crm_create_only: attr(),
  templatable: attr(),
  configurable: attr(),
  integration: attr(),
  templatableIdentifier: alias('templatable_identifier'),
  templatable_identifier: attr(),
  sortable: attr(),
  usableInMessageGoal: alias('usable_in_message_goal'),
  usable_in_message_goal: attr(),
  archived: attr(),
  tracked: attr('boolean', { defaultValue: true }),
  visitorTriggered: alias('visitor_triggered'),
  visitor_triggered: attr('boolean', { defaultValue: false }),
  created_at: attr(),
  noFallback: attr('boolean', { defaultValue: false }),
  required: attr('boolean', { defaultValue: false }),
  preventUpdateFromMessenger: alias('prevent_update_from_messenger'),
  prevent_update_from_messenger: attr('boolean', { defaultValue: true }),
  supports_validation: attr('boolean', { defaultValue: false }),
  supportsValidation: alias('supports_validation'),
  validation_enabled: attr('boolean', { defaultValue: false }),
  validationEnabled: alias('validation_enabled'),
  ruleset: attr(),
  options: hasMany('people/attribute-option', { defaultValue: () => [] }),
  metadata: fragmentArray('outbound/event-metadata', { defaultValue: () => [] }),
  relationship: belongsTo('custom-objects/relationship', {
    async: false,
    defaultValue: () => undefined,
  }),
  reference: belongsTo('objects/reference', {
    async: false,
    defaultValue: () => undefined,
  }),
  hasOptions: notEmpty('options'),
  fitsButtonGroupLayout: computed('options.[]', 'hasOptions', 'options.@each.value', function () {
    if (!this.hasOptions) {
      return false;
    }
    let numberOfOptions = this.options.length;
    let maxLengthPerOption = Math.floor(MAX_COMBINED_LENGTH_FOR_BUTTON_GROUP / numberOfOptions);
    return (
      numberOfOptions <= MAX_NUMBER_OF_OPTIONS_FOR_BUTTON_GROUP &&
      this.options.every((option) => option.get('value.length') <= maxLengthPerOption)
    );
  }),
  invalidOptions: and('hasOptions', 'hasEmptyOptions'),
  hasEmptyOptions: computed('options.[]', 'options.@each.value', function () {
    return this.options.any((option) => isEmpty(option.value));
  }),
  isInvalid: readOnly('invalidOptions'),
  cdaId: computed('cda_id', function () {
    return this.cda_id?.toString();
  }),

  inferredType: computed('hasOptions', 'type', function () {
    return this.hasOptions ? 'options' : this.type;
  }),
  icon: computed('identifiers', 'isStripeType', 'isClearbit', 'isSalesforce', function () {
    let defaultIcon = 'transfer';
    let eventIcon = 'event';
    let stripeIcon = 'stripe';
    let clearbitIcon = 'clearbit';
    let salesforceIcon = 'salesforce';
    if (this.isStripeType) {
      return stripeIcon;
    } else if (this.isClearbit) {
      return clearbitIcon;
    } else if (this.identifier.indexOf('user_event') === 0) {
      return eventIcon;
    } else if (this.isSalesforce) {
      return salesforceIcon;
    } else {
      return AttributeModel.icons[this.identifier] || defaultIcon;
    }
  }),
  category: attr('string', { defaultValue: 'conversation' }),
  nameMatchesRegex(regex) {
    return regex.test(this.name);
  },
  isEmail: equal('identifier', 'email'),
  isLanguageOverride: equal('identifier', 'language_override'),
  isStripeType: computed('identifier', function () {
    return this.identifier.toLowerCase().indexOf('custom_data.stripe_') === 0;
  }),
  isCurrencyType: computed('identifier', function () {
    return this.currencyTypes.includes(this.identifier);
  }),
  isUser: and('isNotCompany', 'isNotBehavior', 'isNotContentEvent', 'isNotConversation'),
  canBeUsedForQualification: and('isUser', 'isNotIntegration'),
  isUserEvent: equal('type', 'user_event'),
  isRelationshipDataType: equal('type', 'relationship'),
  isUserSortable: and('sortable', 'isUser'),
  isHiddenForContact: equalsAny('identifier', ['remote_created_at', 'session_count']),
  isCompany: match('identifier', /^company\./),
  isNotCompany: not('isCompany'),
  isCompanySortable: and('sortable', 'isCompany'),
  isBehavior: match('identifier', /^behavior\./),
  isNotBehavior: not('isBehavior'),
  isClearbit: match('identifier', /^custom_data\.__integration__clearbit__/),
  isSalesforce: match('identifier', /^custom_data\.salesforce_/),
  isMessage: match('identifier', /^message\./),
  isConversation: match('identifier', /^conversation\./),
  isNotConversation: not('isConversation'),
  isChannel: match('identifier', /^message\.channel/),
  isNotChannel: not('isChannel'),
  isSocialAccount: match('identifier', /^social_accounts./),
  isContentEvent: equal('type', 'content_event'),
  isNotContentEvent: not('isContentEvent'),
  isBrowserAttribute: computed('identifier', function () {
    return this.identifier.indexOf('user_agent_data') === 0;
  }),
  isUserCustomData: computed('identifier', function () {
    return this.identifier.indexOf('custom_data') === 0;
  }),
  isCompanyCustomData: computed('identifier', function () {
    return this.identifier.indexOf('company.custom_data') === 0;
  }),
  isCustomData: computed('identifier', function () {
    return this.identifier.includes('custom_data');
  }),
  isStandard: not('isCustomData'),
  isRelatedObject: match('identifier', /related_objects\.(.*)\.custom_objects\./),
  isConfigurable: alias('configurable'),
  isNotIntegration: not('integration'),
  settingsPath: computed('isCompany', 'isUserEvent', function () {
    if (this.isUserEvent) {
      return 'apps.app.settings.events';
    } else if (this.isCompany) {
      return 'apps.app.settings.data.companies';
    } else {
      return 'apps.app.settings.data.people';
    }
  }),
  isAccountFilter: equal('identifier', 'anonymous'),
  isDateFilter: computed('type', function () {
    return ['date', 'user_event_date'].includes(this.type);
  }),
  isDescriptionModifiable: or('isConfigurable', 'isUserEvent'),
  unspacedSanitizedIdentifier: computed('identifier', function () {
    return this.identifier.replace('"', '-').split(' ').join('-');
  }),
  prefixedUnspacedIdentifier: computed('unspacedSanitizedIdentifier', function () {
    return `data-attribute-${this.unspacedSanitizedIdentifier}`;
  }),
  identifierWithoutCompanyPrefix: computed('identifier', function () {
    return this.identifier.replace(/^company\./, '');
  }),
  androidAttributes: [
    'last_request_at_android',
    'session_count_android',
    'android_app_version',
    'android_device',
    'android_os_version',
  ],
  isAndroidAttribute: computed('identifier', function () {
    return this.androidAttributes.includes(this.identifier);
  }),
  iosAttributes: [
    'last_request_at_ios',
    'session_count_ios',
    'ios_app_version',
    'ios_device',
    'ios_os_version',
  ],
  isMobileDeviceFilter: computed('type', function () {
    return ['ios_device', 'android_device'].includes(this.type);
  }),
  isIosAttribute: computed('identifier', function () {
    return this.iosAttributes.includes(this.identifier);
  }),
  realTimeAttributes: ['last_request_at', 'last_request_at_android', 'last_request_at_ios'],
  isRealTimeAttribute: computed('identifier', function () {
    return false;
  }),
  isClient: computed('identifier', function () {
    return this.identifier.includes('client_attributes');
  }),
  isTimeOnPage: equal('identifier', 'client_attributes.time_on_page'),
  isClientPageTargeting: equal('identifier', 'client_attributes.last_visited_url'),
  isPredicatePageTargeting: equal('identifier', 'last_visited_url'),
  isPredicateUrlVisitTargeting: equal('identifier', 'url_visits'),
  isPageTarget: or(
    'isPredicatePageTargeting',
    'isClientPageTargeting',
    'isPredicateUrlVisitTargeting',
  ),
  isUntracked: not('tracked'),
  isUntrackedCda: and('cda_id', 'isUntracked'),
  isUrlVisits: equal('identifier', 'url_visits'),
  isAiAgent: match('identifier', /^ai_agent\./),
  filterPredicates(predicates) {
    let identifier = this.identifier;
    if (this.isUserEvent) {
      return this.findUserEventPredicates(predicates, identifier);
    } else {
      return predicates.filterBy('attribute', identifier);
    }
  },
  findUserEventPredicates(predicates, identifier) {
    return ['.count', '.first', '.last', ''].reduce(function (filteredPredicates, suffix) {
      let eventPredicateIdentifier = identifier + suffix;
      return filteredPredicates.concat(predicates.filterBy('attribute', eventPredicateIdentifier));
    }, []);
  },
  requiredFilter: computed(function () {
    return ['name', 'company.name'].includes(this.identifier);
  }),
  userPath: computed(function () {
    let identifier = this.identifier;
    return this.isUser ? identifier : identifier.replace(/company\./, 'companies.firstObject.');
  }),
  companyPath: computed(function () {
    return this.identifier.replace(/^company\./, '');
  }),
  forcedPastFromNow: computed('identifier', function () {
    let forcedPastIdentifiers = [
      'last_heard_from_at',
      'last_request_at',
      'remote_created_at',
      'custom_data.installed_at',
      'custom_data.converted_at',
      'custom_data.custom_data_installed_at',
      'last_contacted_at',
      'last_outbound_content_received_at_data.carousel',
      'last_outbound_content_received_at_data.chat',
      'last_outbound_content_received_at_data.custom_bot',
      'last_outbound_content_received_at_data.email',
      'last_outbound_content_received_at_data.mobile_push',
      'last_outbound_content_received_at_data.post',
      'last_outbound_content_received_at_data.sms_message',
      'last_outbound_content_received_at_data.survey',
    ];
    return forcedPastIdentifiers.includes(this.identifierWithoutCompanyPrefix);
  }),

  objectTypeIdentifier: computed('isUser', 'isConversation', function () {
    if (this.isUser) {
      return USER_OBJECT_TYPE_IDENTIFIER;
    } else if (this.isConversation) {
      return CONVERSATION_OBJECT_TYPE_IDENTIFIER;
    }
  }),

  objectTypeName: computed('isUser', 'isConversation', function () {
    if (this.isUser) {
      return USER_OBJECT_TYPE_NAME;
    } else if (this.isConversation) {
      return CONVERSATION_OBJECT_TYPE_NAME;
    }
  }),

  relatedObjectAttributeName: '',
  relatedObjectAttributeDescription: '',
  relationshipCardinality: alias('relationship.cardinality'),
  cardinality: computed('relationship.cardinality', function () {
    if (this.isDestinationRelationshipAttribute) {
      return REVERSE_CARDINALITY_NAME_MAP[this.relationship.cardinality];
    }
    return this.relationship.cardinality;
  }),

  relatedObjectTypeIdentifier: computed(
    'relationship.sourceObjectTypeIdentifier',
    'relationship.destinationObjectTypeIdentifier',
    function () {
      if (this.isSourceRelationshipAttribute) {
        return this.relationship.get('destinationObjectTypeIdentifier');
      } else if (this.isDestinationRelationshipAttribute) {
        return this.relationship.get('sourceObjectTypeIdentifier');
      }
    },
  ),
  relatedAttribute: computed(
    'relationship.sourceAttributeDescriptorId',
    'relationship.destinationAttribute',
    function () {
      if (this.isSourceRelationshipAttribute) {
        return this.relationship.get('destinationAttributeDescriptor');
      } else if (this.isDestinationRelationshipAttribute) {
        return this.relationship.get('sourceAttributeDescriptor');
      }
    },
  ),
  isSourceRelationshipAttribute: computed(
    'relationship.sourceAttributeDescriptorId',
    'relationship.sourceObjectTypeIdentifier',
    function () {
      return (
        isPresent(this.relationship) &&
        this.objectTypeIdentifier === this.relationship.get('sourceObjectTypeIdentifier') &&
        this.cdaId === this.relationship.get('sourceAttributeDescriptorId')
      );
    },
  ),
  isDestinationRelationshipAttribute: computed(
    'relationship.destinationAttributeDescriptorId',
    'relationship.destinationObjectTypeIdentifier',
    function () {
      return (
        isPresent(this.relationship) &&
        this.objectTypeIdentifier === this.relationship.get('destinationObjectTypeIdentifier') &&
        this.cdaId === this.relationship.get('destinationAttributeDescriptorId')
      );
    },
  ),
  referencedObjectType: computed(
    'isRelationshipDataType',
    'reference',
    'reference.referencedObjectTypeIdentifier',
    'customObjectsService',
    function () {
      if (this.isRelationshipDataType && isPresent(this.reference)) {
        return this.customObjectsService.findObjectTypeByIdentifier(
          this.reference.referencedObjectTypeIdentifier,
        );
      }
    },
  ),
  referencedObjectTypeIdentifier: alias('reference.referencedObjectTypeIdentifier'),
  referenceType: alias('reference.referenceType'),
  asPredicateFilterObject(value) {
    if (this.type === 'date') {
      let date = moment(value);
      value = { day: date.date(), month: date.month() + 1, year: date.year() };
    }
    let comparisonOperator = this._selectEqualityComparator(value);
    return {
      predicates: [
        {
          comparison: comparisonOperator,
          value,
          attribute: this.identifier,
          type: this.type,
        },
      ],
    };
  },

  analyticsData: computed('identifier', 'isCustomData', 'type', function () {
    return {
      object: 'attribute',
      attribute_identifier: this.identifier,
      attribute_is_custom_data: this.isCustomData,
      attribute_type: this.type,
    };
  }),

  _selectEqualityComparator(value) {
    if (this.type === 'boolean' || this.type === 'anonymous') {
      return value.toString();
    } else if (this.isMobileDeviceFilter) {
      return 'in';
    }
    return 'eq';
  },

  crmType: computed('type', function () {
    if (CRM_INTEGER_TYPES.includes(this.type)) {
      return 'integer';
    } else if (CRM_STRING_TYPES.includes(this.type)) {
      return 'string';
    }

    return this.type;
  }),

  asCrmAttributeMappingOption(
    identityMappingIdentifiers,
    attributeMappings,
    intercomIdentityMappingObjectType,
    salesforceIdentityMappingObjectType,
  ) {
    let tooltipText = null;
    if (this._isIntercomIdentityMappingAttribute(identityMappingIdentifiers)) {
      tooltipText = `This attribute is already used for mapping ${intercomIdentityMappingObjectType}`;
    } else if (this._isIntercomAttributeMapped(attributeMappings)) {
      tooltipText = 'This attribute is already used in another attribute mapping';
    } else if (!this.crm_writable) {
      tooltipText = 'This attribute can’t be overwritten in Intercom';
    } else if (this.crm_create_only) {
      tooltipText = `This is identified automatically and is only sent when a ${salesforceIdentityMappingObjectType} is created`;
    }

    let isDisabled =
      this._isIntercomAttributeMapped(attributeMappings) ||
      this._isIntercomIdentityMappingAttribute(identityMappingIdentifiers);
    return {
      text: this.name,
      value: this.identifier,
      count: `(${typeMapping[this.crmType]?.label})`,
      tooltipText,
      isDisabled,
    };
  },

  _isIntercomIdentityMappingAttribute(identityMappingIdentifiers) {
    return identityMappingIdentifiers.includes(this.identifier);
  },

  _isIntercomAttributeMapped(attributeMappings) {
    let sourceMapping = attributeMappings.findBy('sourceAttributePath', this.identifier);
    let destinationMapping = attributeMappings.findBy('destinationAttributePath', this.identifier);
    return (
      (sourceMapping && sourceMapping.direction === syncDirection.IntercomToCRM) ||
      (destinationMapping && destinationMapping.direction === syncDirection.CrmtoIntercom)
    );
  },
}).reopenClass(AttributeIconMap, {
  async findByCdaId(store, modelDataCacheService, app, cdaId) {
    let response = await ajax({
      url: `/ember/custom_data_attributes`,
      type: 'GET',
      data: {
        app_id: app.id,
        cda_id: cdaId,
      },
    }).catch(function () {
      return undefined;
    });

    if (isEmpty(response)) {
      return undefined;
    }

    store.pushPayload({ attribute: response });
    let attribute = store.peekRecord('attribute', response.identifier);
    app.attributes.pushObject(attribute);

    let qualificationAttribute = response.qualification_attribute;
    if (qualificationAttribute) {
      store.push(
        store.normalize('people/qualification-attribute', {
          id: qualificationAttribute.id,
          identifier: attribute.get('identifier'),
        }),
      );
    }
    app.updateLocalCache(modelDataCacheService);

    return attribute;
  },

  peekOrFindByCdaId(store, modelDataCacheService, app, cdaId) {
    let allAttributes = store.peekAll('attribute');
    let attribute = allAttributes.findBy('cdaId', cdaId);

    return isPresent(attribute)
      ? attribute
      : this.findByCdaId(store, modelDataCacheService, app, cdaId);
  },
});

export default AttributeModel;
