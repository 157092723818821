/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';
import { htmlUnescape } from 'embercom/lib/html-unescape';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isArray } from 'underscore';
import { action } from '@ember/object';

export default class WorkflowActionParam extends Model {
  @attr() declare value: string | string[] | null;
  @attr('string') declare key: string;
  @attr('boolean') declare includeAppAttributes: boolean;
  @attr('boolean') declare isSingleLineComposer: boolean;
  @attr('boolean') declare isArray?: boolean;
  @attr('string') declare displayedTitle?: string;
  @attr('string') declare tooltip?: string;
  @attr('string') declare displayedSubtitle?: string;

  get blocks() {
    if (isArray(this.value)) {
      return WorkflowActionParam.blocksFromText(this.value.join(','));
    }
    return WorkflowActionParam.blocksFromText(this.value as string);
  }

  @action
  onBlocksChange(newBlocks: any) {
    this.value = WorkflowActionParam.textFromBlocks(newBlocks.blocks);
  }

  static textFromBlocks(blocks: Array<any>) {
    let blockTexts = blocks.map((block: any) => block.text);
    let processedBlocks = blockTexts.join('\n');
    processedBlocks = sanitizeHtml(htmlUnescape(processedBlocks));
    return processedBlocks.toString();
  }

  static blocksFromText(text: string) {
    return text?.split('\n').map((line: string) => {
      return {
        type: 'paragraph',
        text: line,
        class: 'no-margin',
        modelKey: 'common/blocks/paragraph',
      };
    });
  }
}
