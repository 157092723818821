/* RESPONSIBLE TEAM: team-workflows */
import AssignConversation from './assign-conversation';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';

export default class AssignConversationToOwner extends AssignConversation {
  get supportedChannels() {
    return ALL_CHANNELS;
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): AssignConversationToOwner {
    return store.createRecord('operator/visual-builder/step/assign-conversation-to-owner', {
      action: store.createFragment('rules/rule-action', {
        type: 'assign-conversation-to-owner',
        justAdded: true,
      }),
      type: 'operator/visual-builder/step/assign-conversation-to-owner',
    });
  }
}
