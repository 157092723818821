/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

// This file remains unchanged. The unit tests will be created in a new file.
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { later, cancel } from '@ember/runloop';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type EmberRunTimer } from '@ember/runloop/types';

export interface NavItemInputs {
  route: string;
  activeRoutes?: string[];
  icon?: InterfaceIconName;
  label: string;
  requiredFeatures?: string[];
  excludedFeatures?: string[];
  children?: NavItemInputs[];
  customOnClick?: () => void;
}

export class NavTree {
  @tracked items: NavItem[] = [];

  @tracked forcedActiveItem?: NavItem;

  @service declare router: RouterService;

  constructor(owner: unknown) {
    setOwner(this, owner);
  }

  activeItemTimeout?: EmberRunTimer;

  routeDidChangeCallback?: () => void;

  setForcedActiveItem(item: NavItem) {
    this.forcedActiveItem = item;

    if (this.activeItemTimeout) {
      cancel(this.activeItemTimeout);
    }

    // This is a failsafe to ensure that the forced active item does not remain active forver
    // E.g. in cases where we've redirected to a different route than what the nav item specified.
    this.activeItemTimeout = later(() => {
      this.forcedActiveItem = undefined;
    }, ENV.APP._10000MS);

    if (this.routeDidChangeCallback) {
      this.router.off('routeDidChange', this.routeDidChangeCallback);
    }

    // We listen to the router to know when we've actually reached the route that the nav item specifies.
    // If we've reached that route, we unset the forced active item.
    // We listen for the route changes as the Ember router will go through several interstitial states
    // before reaching the final destination and we want the nav item to remain selected in the UI through those states.
    this.routeDidChangeCallback = () => {
      if (
        this.forcedActiveItem?.route &&
        this.router.currentRouteName.startsWith(this.forcedActiveItem.route)
      ) {
        this.forcedActiveItem = undefined;
      }
    };

    this.router.on('routeDidChange', this.routeDidChangeCallback);
  }

  get allItems() {
    return this.items.flatMap((item) => item.allItems);
  }

  addItem(item: NavItem) {
    this.items.pushObject(item);
  }
}

export class NavItem {
  @service declare appService: { app: { canUseFeature: (feature: string) => boolean } };
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare router: RouterService;

  root: NavTree;
  parent?: NavItem;
  route: string;
  activeRoutes: string[];
  icon?: InterfaceIconName;
  label: string;
  requiredFeatures: string[];
  excludedFeatures: string[];
  children: NavItem[];
  customOnClick?: () => void;

  constructor(root: NavTree, owner: unknown, inputs: NavItemInputs, parent?: NavItem) {
    setOwner(this, owner);
    this.root = root;
    this.route = inputs.route;
    this.activeRoutes = [inputs.route, ...(inputs.activeRoutes ?? [])];
    this.icon = inputs.icon;
    this.label = inputs.label;
    this.parent = parent;
    this.requiredFeatures = inputs.requiredFeatures ?? [];
    this.excludedFeatures = inputs.excludedFeatures ?? [];
    this.root.addItem(this);
    this.children = inputs.children?.map((child) => new NavItem(root, owner, child, this)) ?? [];
    this.customOnClick = inputs.customOnClick?.bind(this);
  }

  get allItems(): Array<NavItem> {
    return [this, ...this.children.flatMap((child) => child.allItems)];
  }

  get count() {
    if (
      this.route !== 'apps.app.knowledge-hub.all-content' ||
      !this.knowledgeHubService.usageSummary
    ) {
      return undefined;
    }
    return Object.values(this.knowledgeHubService.usageSummary).reduce(
      (acc, summary) => (acc += summary.agent),
      0,
    );
  }

  @action onClick() {
    if (this.customOnClick) {
      this.customOnClick();
    } else if (this.eligibleChildren.length === 0) {
      this.root.setForcedActiveItem(this);

      this.router.transitionTo(this.route);
    } else {
      this.eligibleChildren[0].onClick();
    }
  }

  get eligibleChildren() {
    return this.children.filter((child) => child.hasRequiredFeatures);
  }

  get isFirstChild() {
    return this.parent?.eligibleChildren[0] === this;
  }

  get isLastChild() {
    return this.parent?.eligibleChildren[this.parent.eligibleChildren.length - 1] === this;
  }

  get app() {
    return this.appService.app;
  }

  get hasRequiredFeatures() {
    if (this.requiredFeatures.length === 0 && this.excludedFeatures.length === 0) {
      return true;
    }

    if (this.requiredFeatures.length > 0) {
      return this.requiredFeatures.every((feature) => this.app.canUseFeature(feature));
    }

    if (this.excludedFeatures.length > 0) {
      return this.excludedFeatures.every((feature) => !this.app.canUseFeature(feature));
    }

    return false;
  }

  get isActive() {
    if (this.root.forcedActiveItem) {
      return this.root.forcedActiveItem === this;
    }
    return this.activeRoutes.some((route) => this.router.currentRouteName.includes(route));
  }

  get hasActiveChild() {
    return this.eligibleChildren.some((child) => child.isActive);
  }
}
