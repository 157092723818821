/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';

export default function generateViewModeConfig() {
  return ViewModeConfig.create({
    headerComponentConfigs: [],
    tabsConfiguration: TabsConfiguration.create({
      tabComponentConfigs: [],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({}),
    footerComponentConfigs: [],
  });
}
