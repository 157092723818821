/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Admin from 'embercom/models/admin';

export default Model.extend({
  admin_id: attr(),
  app_id: attr(),
  job_title: attr(),
  department: attr(),
  alias_name: attr(),
  phone_number: attr(),
  calendar_url: attr(),
  show_calendar_in_messenger: attr(),
  biography: attr(),
  show_location: attr(),
  email_address_for_app: attr(),
  alias_enabled: attr(),
  admin: computed('admin_id', function () {
    return Admin.peekAndMaybeLoad(this.store, this.admin_id);
  }),
});
