/* RESPONSIBLE TEAM: team-product-exploration */

import { inserterMenuItems } from './inserter-menu-items-map';
import { type StepNames } from '../configuration-list';

export const suggestedNextSteps: Record<string, StepNames[]> = {
  default: [
    inserterMenuItems.fin,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.chatMessage,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.assignConversation,
    inserterMenuItems.changeConversationPriority,
  ],
  outbound: [
    inserterMenuItems.fin,
    inserterMenuItems.chatMessage,
    inserterMenuItems.assignConversation,
    inserterMenuItems.attributeCollector,
    inserterMenuItems.replyButtons,
    inserterMenuItems.closeConversation,
  ],
  everyone: [
    inserterMenuItems.fin,
    inserterMenuItems.chatMessage,
    inserterMenuItems.setExpectations,
    inserterMenuItems.assignConversation,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.attributeCollector,
  ],
  'new-conversation': [
    inserterMenuItems.fin,
    inserterMenuItems.chatMessage,
    inserterMenuItems.assignConversation,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.attributeCollector,
    inserterMenuItems.freeInput,
  ],
  'conversation-started': [
    inserterMenuItems.fin,
    inserterMenuItems.chatMessage,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.assignConversation,
    inserterMenuItems.changeConversationPriority,
    inserterMenuItems.replyButtons,
  ],
  'new-comment': [
    inserterMenuItems.fin,
    inserterMenuItems.setExpectations,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.assignConversation,
    inserterMenuItems.closeConversation,
    inserterMenuItems.conditionalBranches,
  ],
  'new-admin-comment': [
    inserterMenuItems.snooze,
    inserterMenuItems.triggerWorkflow,
    inserterMenuItems.convertToTicket,
    inserterMenuItems.assignConversation,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.notifySlackChannel,
  ],
  'conversation-status-changed': [
    inserterMenuItems.chatMessage,
    inserterMenuItems.conversationRatings,
    inserterMenuItems.assignConversation,
    inserterMenuItems.customActionTemplate,
    inserterMenuItems.wait,
    inserterMenuItems.closeConversation,
    inserterMenuItems.conditionalBranches,
  ],
  'conversation-assignment-changed': [
    inserterMenuItems.conversationRatings,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.applyConversationSla,
    inserterMenuItems.changeConversationPriority,
    inserterMenuItems.conditionalBranches,
  ],
  'user-inactivity': [
    inserterMenuItems.chatMessage,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.note,
    inserterMenuItems.conversationRatings,
    inserterMenuItems.snooze,
    inserterMenuItems.closeConversation,
  ],
  'admin-inactivity': [
    inserterMenuItems.assignConversation,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.chatMessage,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.changeConversationPriority,
  ],
  'admin-note-created': [
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.assignConversation,
    inserterMenuItems.notifySlackChannel,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.customActionTemplate,
    inserterMenuItems.note,
  ],
  'ticket-created': [
    inserterMenuItems.assignConversation,
    inserterMenuItems.changeConversationPriority,
    inserterMenuItems.applyConversationSla,
    inserterMenuItems.note,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.notifySlackChannel,
  ],
  'ticket-state-updated': [
    inserterMenuItems.chatMessage,
    inserterMenuItems.assignConversation,
    inserterMenuItems.note,
    inserterMenuItems.conditionalBranches,
    inserterMenuItems.customActionTemplate,
  ],
  'no-trigger': [
    inserterMenuItems.fin,
    inserterMenuItems.chatMessage,
    inserterMenuItems.conversationRatings,
    inserterMenuItems.addTagToConversation,
    inserterMenuItems.assignConversation,
    inserterMenuItems.replyButtons,
    inserterMenuItems.triggerWorkflow,
  ],
  'new-phone-call': [
    inserterMenuItems.replyButtons,
    inserterMenuItems.callback,
    inserterMenuItems.hangUp,
    inserterMenuItems.forwardCall,
    inserterMenuItems.voicemail,
  ],
};
