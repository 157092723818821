/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import { computed } from '@ember/object';
import { equal, union, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';
import { containsByProperty, findByProperty } from '@intercom/pulse/lib/computed-properties';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: [validator('presence', true), validator('length', { max: 40 })],
  nameWords: validator('length', { message: 'Must not be more than 4 words', min: 1, max: 4 }),
});

const PROTECTED_APP_PACKAGES = ['28903'];

export default Model.extend(Validations, {
  name: attr('string'),
  iconUrl: attr('string', {
    humanisedName: 'App icon',
    renderingComponentPath: 'developer-hub/app-package/review/review-changes/app-icon-value',
  }),
  adminId: attr('number', { noDiff: true }),
  apiVersion: belongsTo('developer-hub/api-version', { noDiff: true }),
  createdAt: attr('date', { noDiff: true }),
  installUrl: attr('string', { humanisedName: 'Direct installation URL' }),
  uninstallUrl: attr('string', { humanisedName: 'Uninstallation URL' }),
  healthCheckUrl: attr('string', { humanisedName: 'Health check URL' }),
  termsOfServiceUrl: attr('string', { humanisedName: 'Terms of service URL' }),
  privacyPolicyUrl: attr('string', { humanisedName: 'Privacy policy URL' }),
  pricingModel: attr('string', {
    humanisedName: 'Pricing model',
    renderingComponentPath: 'developer-hub/app-package/review/review-changes/pricing-model',
  }),
  pricingPageUrl: attr('string', { humanisedName: 'Pricing page URL' }),
  supportEmail: attr('string', { humanisedName: 'Support email address' }),
  documentationUrl: attr('string', { humanisedName: 'Documentation URL' }),
  avatarWorkspaceIdCode: attr('string', {
    humanisedName: "Avatars' workspace",
  }),
  developerHomepageUrl: attr('string', { humanisedName: 'Website URL' }),
  description: attr('string', { humanisedName: 'Description' }),
  builtBy: attr('string', { humanisedName: 'Built by' }),
  state: attr('string', { noDiff: true }),
  ownerAppId: attr('string', { noDiff: true }),
  appStoreListing: fragment('developer-hub/app-store-listing', { noDiff: true }),
  startingGuide: fragment('developer-hub/starting-guide', { noDiff: true }),
  changeRequest: fragment('developer-hub/change-request', { noDiff: true }),
  messengerApp: fragment('developer-hub/messenger-app', { noDiff: true }),
  inboxApp: fragment('developer-hub/inbox-app', { noDiff: true }),
  oauthClient: fragment('developer-hub/oauth-client', { noDiff: true }),
  webhookDefinition: fragment('developer-hub/webhook-definition', { noDiff: true }),
  appPartner: fragment('developer-hub/app-partner', { noDiff: true }),
  isPrivateApp: equal('state', 'private_app'),
  isPublicApp: equal('state', 'public_app'),
  appService: service(),
  currentApp: readOnly('appService.app'),
  apps: readOnly('appService.app.currentAdmin.apps'),
  ownerApp: findByProperty('apps', 'id', 'ownerAppId'),
  protectedAppPackageIds: PROTECTED_APP_PACKAGES,
  isProtectedAppPackage: containsByProperty('protectedAppPackageIds', 'id'),

  nameWords: computed('name', function () {
    let name = this.name || '';
    return name.split(/\s+/);
  }),

  nameErrors: union('validations.attrs.name.errors', 'validations.attrs.nameWords.errors'),

  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },

  submitReview() {
    return this.postAndUpdate('submit_review');
  },

  cancelReview() {
    return this.postAndUpdate('cancel_review');
  },

  publishListing() {
    return this.postAndUpdate('publish_listing');
  },

  cancelListing() {
    return this.postAndUpdate('cancel_listing');
  },

  updateApiVersion() {
    return this.postAndUpdate('update_api_version');
  },

  updateWebhooks() {
    return this.postAndUpdate('update_webhook_definition');
  },

  deleteMessengerApp() {
    return this.deleteAndUpdate('delete_messenger_app');
  },

  deleteInboxApp() {
    return this.deleteAndUpdate('delete_inbox_app');
  },

  updateAppPartner() {
    return this.postAndUpdate('update_app_partner');
  },

  pingWebhookDefinition() {
    return this._post('ping');
  },

  uploadImage(image, uploadType) {
    let formData = new FormData();
    formData.append('app_id', this.get('currentApp.id'));
    formData.append('upload_type', uploadType);
    formData.append('image_upload', image);
    let url = `/ember/developer_hub/app_packages/${this.id}/upload_image`;
    return nonconcurrentAjax(this, {
      url,
      type: 'POST',
      contentType: false,
      processData: false,
      data: formData,
    });
  },

  deleteAndUpdate(path) {
    return this._delete(path).then((res) => this._updateModel(res));
  },

  _delete(path) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let url = `${adapter.buildURL(modelName, this.id)}/${path}`;
    return adapter.ajax(url, 'DELETE', { data: this.serialize() });
  },

  postAndUpdate(path) {
    return this._post(path).then((res) => this._updateModel(res));
  },

  _post(path) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let url = `${adapter.buildURL(modelName, this.id)}/${path}`;
    return adapter.ajax(url, 'POST', { data: this.serialize() });
  },

  _updateModel(response) {
    let normalizedModel = this.store.normalize(this.constructor.modelName, response);
    return this.store.push(normalizedModel);
  },
});
