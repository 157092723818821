/* RESPONSIBLE TEAM: team-workflows */
import {
  BUTTON_BOT_TYPE,
  EVERYONE_PREDICATE,
  EVERYONE_TARGET,
  AT_CONVERSATION_START,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import ButtonBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/button-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

import CustomBotConfig from '../custom-bot';

export default class ButtonCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return EVERYONE_TARGET;
  }

  get botType() {
    return BUTTON_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.buttonCustomBot;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get rolePredicateGroup() {
    return { predicates: [EVERYONE_PREDICATE] };
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.button.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.button.description';
  }

  get icon(): InterfaceIconName {
    return 'click-element';
  }

  get EditorConfigClass() {
    return ButtonBotEditorConfig;
  }

  get requiredBillingFeature() {
    return 'bot_workflows';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING];
  }

  get isTitleModifiedByWorkflowsSetup(): boolean {
    return true;
  }
}
