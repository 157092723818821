/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { TicketDescriptor, type TicketDescriptorWireFormat } from 'embercom/objects/inbox/ticket';
import { isEmpty } from '@ember/utils';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';

export interface TicketAttributeSummaryWireFormat {
  value: string | number | boolean | UploadWireFormat[];
  descriptor: TicketDescriptorWireFormat;
}

export type TicketAttributeSummaryValue =
  | string
  | number
  | boolean
  | Upload[]
  | Array<object>
  | number[]
  | undefined;

export default class TicketAttributeSummary {
  @tracked value: TicketAttributeSummaryValue;
  @tracked private originalValue: TicketAttributeSummary['value'];

  readonly descriptor: TicketDescriptor;

  constructor(value: TicketAttributeSummary['value'], descriptor: TicketDescriptor) {
    this.value = value;
    this.originalValue = value;
    this.descriptor = descriptor;
  }

  static deserialize(json: TicketAttributeSummaryWireFormat): TicketAttributeSummary {
    let descriptor = TicketDescriptor.deserialize(json.descriptor);
    if (
      typeof json.value === 'string' ||
      typeof json.value === 'number' ||
      typeof json.value === 'boolean' ||
      typeof json.value === 'undefined'
    ) {
      return new TicketAttributeSummary(json.value, descriptor);
    } else {
      return new TicketAttributeSummary(
        json.value.map((upload) => Upload.deserialize(upload)),
        descriptor,
      );
    }
  }

  get isUpdated() {
    let value = this.value;

    if (isEmpty(value) && isEmpty(this.originalValue)) {
      return false;
    }

    if (['integer', 'decimal'].includes(this.descriptor.dataType)) {
      value = Number(value);
    }

    return value !== this.originalValue;
  }

  rollback() {
    this.value = this.originalValue;
  }

  update(value: TicketAttributeSummary['value']) {
    this.value = value;
    this.originalValue = value;
  }

  normalizeValue() {
    switch (this.descriptor.dataType) {
      case 'integer':
        return this.normalizeNumber(true);
      case 'decimal':
        return this.normalizeNumber(false);
      case 'string':
        return this.normalizeString();
    }
  }

  isAttributeIntegerAndLargerThanMaxSafeInteger(): boolean {
    return (
      this.descriptor.type === 'integer' &&
      typeof this.value === 'string' &&
      !Number.isSafeInteger(parseInt(this.value, 10))
    );
  }

  get isValueValid() {
    switch (this.descriptor.dataType) {
      case 'integer':
        return !this.isAttributeIntegerAndLargerThanMaxSafeInteger();
      case 'decimal':
        return this.isEmpty || !isNaN(parseFloat(String(this.value)));
      default:
        return true;
    }
  }

  get isEmpty() {
    if (
      this.value === null ||
      this.value === undefined ||
      (Array.isArray(this.value) && this.value.length === 0)
    ) {
      return true;
    }

    switch (this.descriptor.dataType) {
      case 'integer':
      case 'decimal':
      case 'boolean':
        return this.value === '';
      case 'string':
        return (this.value as string).trim() === '';
    }

    return false;
  }

  get showErrorOnCreate(): boolean {
    return !this.isValueValid || !!(this.descriptor.requiredToCreate && this.isEmpty);
  }

  normalizeString() {
    this.value = (this.value as string).trim();
  }

  normalizeNumber(isInteger: boolean) {
    let cast = Number(this.value);
    if (this.value === '' || isNaN(cast)) {
      return;
    }

    this.value = isInteger ? Math.round(cast) : cast;
  }

  get isValueEmpty() {
    return isEmpty(this.value);
  }

  get serializedValue() {
    switch (this.descriptor.type) {
      case 'files':
        return this.serializedFilesValue();

      default:
        return this.value;
    }
  }

  serializedFilesValue() {
    if (!Array.isArray(this.value) || typeof this.value[0] !== 'object') {
      return this.value;
    }

    return (this.value as Upload[]).map((upload: Upload) => upload.id);
  }
}
