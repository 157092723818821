/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default class PeopleSyncData extends Model {
  @attr('string') sectionId;
  @attr('number') lastPeopleMatchDate;
  @fragment('integrations/salesforce/settings') settings;
  @fragmentArray('crm/attribute-mapping') attributeMappings;
  @fragmentArray('salesforce/salesforce-fields') salesforceLeadFields;
  @fragmentArray('salesforce/salesforce-fields') salesforceContactFields;
  @fragmentArray('integrations/salesforce/identity-mapping-field') identityMappingSupportedFields;
  @fragment('integrations/salesforce/last-bulk-sync-status') lastBulkSyncStatus;
}
