/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { empty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { fragment } from 'ember-data-model-fragments/attributes';

export const RETRIES = 2;
export const ERROR_COMPONENT = {
  type: 'text',
  style: 'error',
  text: 'Messenger App couldn’t be loaded',
};

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),
  content: fragment('app-framework/component-content'),
  isLive: empty('content'),
  storedData: attr(),
});
