/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* RESPONSIBLE TEAM: team-data-foundations */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import {
  ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL,
  ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME,
  ATTRIBUTE_DESCRIPTOR_TYPE_LIST,
  ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { isPresent } from '@ember/utils';
import { getEmberDataStore } from 'embercom/lib/container-lookup';

export default class ObjectsAttributeDescriptor extends Model {
  @attr('string') identifier;
  @attr('string') name;
  @attr('string') description;
  @attr('string') relatedObjectAttributeName;
  @attr('string') relatedObjectAttributeDescription;
  @attr('string') dataType;
  @attr('boolean') required;
  @attr('boolean') archived;
  @attr('date') archivedAt;
  @attr('boolean', { defaultValue: false }) standard;
  @attr('boolean', { defaultValue: true }) editable;
  @belongsTo('custom-objects/object-type', { async: false }) objectType;
  @belongsTo('custom-objects/relationship', { async: false }) relationship;
  @belongsTo('objects/reference', { async: false }) reference;
  @hasMany('conversation-attributes/list-option', { async: false }) listOptions;

  @service customObjectsService;

  async save() {
    await super.save(...arguments);
    this._clearUnsavedListOptions();
  }

  _clearUnsavedListOptions() {
    this.listOptions.filterBy('isNew').forEach((option) => this.store.unloadRecord(option));
  }

  get isStandardAttribute() {
    return this.standard;
  }

  get isCustomAttribute() {
    return !this.isStandardAttribute;
  }

  get objectTypeIdentifier() {
    return this.objectType.identifier;
  }

  get unArchivedListOptions() {
    return this.listOptions.filter((option) => !option.archived);
  }

  get predicateDataType() {
    switch (this.dataType) {
      case ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL:
        return 'float';
      case ATTRIBUTE_DESCRIPTOR_TYPE_LIST:
        return 'attribute_descriptor_list';
      case ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME:
        return 'date';
      default:
        return this.dataType;
    }
  }

  get dataTypeLabel() {
    return ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP[this.dataType];
  }

  get dataTypeIcon() {
    return ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[this.dataType];
  }

  get isRelationshipDataType() {
    return this.dataType === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP;
  }

  get isSourceRelationshipAttribute() {
    return (
      isPresent(this.relationship) &&
      this.objectTypeIdentifier === this.relationship.get('sourceObjectTypeIdentifier') &&
      this.id === this.relationship.get('sourceAttributeDescriptorId')
    );
  }

  get isDestinationRelationshipAttribute() {
    return (
      isPresent(this.relationship) &&
      this.objectTypeIdentifier === this.relationship.get('destinationObjectTypeIdentifier') &&
      this.id === this.relationship.get('destinationAttributeDescriptorId')
    );
  }

  get relatedAttribute() {
    if (this.isSourceRelationshipAttribute) {
      return this.relationship.get('destinationAttributeDescriptor');
    } else if (this.isDestinationRelationshipAttribute) {
      return this.relationship.get('sourceAttributeDescriptor');
    }
  }

  get relatedObjectTypeIdentifier() {
    if (this.isSourceRelationshipAttribute) {
      return this.relationship.get('destinationObjectTypeIdentifier');
    } else if (this.isDestinationRelationshipAttribute) {
      return this.relationship.get('sourceObjectTypeIdentifier');
    }
  }

  get referencedObjectTypeIdentifier() {
    return this.reference?.referencedObjectTypeIdentifier;
  }

  get referencedObjectType() {
    if (this.isRelationshipDataType && isPresent(this.reference)) {
      return this.customObjectsService.findObjectTypeByIdentifier(
        this.reference.referencedObjectTypeIdentifier,
      );
    }
  }

  get objectTypeName() {
    return this.objectType.name;
  }

  get cardinality() {
    let cardinality = this.relationship?.cardinality;
    if (this.isDestinationRelationshipAttribute) {
      cardinality = REVERSE_CARDINALITY_NAME_MAP[cardinality];
    }
    return cardinality;
  }

  hasRelationshipWithObjectType(objectTypeIdentifier) {
    return (
      this.isRelationshipDataType &&
      isPresent(this.relationship) &&
      (this.relationship.destinationObjectTypeIdentifier === objectTypeIdentifier ||
        this.relationship.sourceObjectTypeIdentifier === objectTypeIdentifier)
    );
  }

  hasReferenceToObjectType(objectTypeIdentifier) {
    return (
      this.isRelationshipDataType &&
      isPresent(this.reference) &&
      this.reference.referencedObjectTypeIdentifier === objectTypeIdentifier
    );
  }

  static peekOrFindById(id) {
    let store = getEmberDataStore();

    return store.hasRecordForId('objects/attribute-descriptor', id)
      ? store.peekRecord('objects/attribute-descriptor', id)
      : store.findRecord('objects/attribute-descriptor', id);
  }

  async archive() {
    this.archived = true;
    await this.save();
    await this._reloadIfRelatedToObjectAttributeDescriptor();
  }

  async unarchive() {
    this.archived = false;
    await this.save();
    await this._reloadIfRelatedToObjectAttributeDescriptor();
  }

  async _reloadIfRelatedToObjectAttributeDescriptor() {
    if (!this.isRelationshipDataType) {
      return;
    }

    let relatedAttribute = await this.relatedAttribute;
    if (
      isPresent(relatedAttribute) &&
      this.customObjectsService.customObjectTypes
        .mapBy('identifier')
        .includes(relatedAttribute.objectTypeIdentifier)
    ) {
      await relatedAttribute.reload();
    }
  }
}
