/* RESPONSIBLE TEAM: team-proactive-support */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import moment from 'moment-timezone';
import {
  CUSTOM_BOT_REQUIRED_PERMISSION,
  OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION,
} from 'embercom/lib/operator/custom-bots/constants';
import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';

export const attributes_regex = /({{.+?}})/gm;

export const states = {
  draft: 0,
  live: 1,
  paused: 2,
  scheduled: 3,
  ended: 4,
  suspended: 6,
};

export enum State {
  Draft = 0,
  Live = 1,
  Paused = 2,
  Scheduled = 3,
  Ended = 4,
  Archived = 5,
  Suspended = 6,
}

export const oneOffStates = {
  draft: 0,
  scheduled: 3,
  sent: 1,
};

export const legacyObjectTypes = {
  legacyMessagePush: 12,
  legacyMessageInApp: 13,
  legacyMessageEmail: 14,
  legacyControlGroup: 15,
};

export const objectTypes = {
  tour: 0,
  inboundTrigger: 2,
  inApp: 4,
  conversation: 5,
  conversationExcerpts: 142,
  article: 6,
  email: 8,
  messengerTrigger: 9,
  banner: 10,
  legacyMessagePush: 12,
  legacyMessageInApp: 13,
  legacyMessageEmail: 14,
  legacyControlGroup: 15,
  ruleset: 16,
  push: 18,
  answer: 24,
  sms: 30,
  controlGroup: 34,
  chat: 38,
  carousel: 42,
  post: 45,
  series: 47,
  condition: 51,
  wait: 52,
  tagger: 53,
  customBot: 54,
  inboundCustomBot: 62,
  buttonCustomBot: 63,
  splitter: 64,
  edgeSplit: 65,
  resolutionBotBehavior: 68,
  outboundWebhook: 75,
  survey: 84,
  savedReply: 90,
  tooltipGroup: 97,
  tooltip: 98,
  newsItem: 99,
  newsfeed: 103,
  triggerableCustomBot: 104,
  checklist: 109,
  whatsapp: 115,
  checklistStep: 111,
  workflowConnectorAction: 113,
  articleContent: 120,
  externalContent: 129,
  contentSnippet: 131,
  aiContentSegment: 132,
  fileSourceContent: 134,
  internalArticle: 137,
  contentLibraryFolder: 138,
  call: 140,
};

export const humanReadableObjectNamesKeys = {
  [objectTypes.tour]: 'reporting.human-readable-object-names.tour',
  [objectTypes.inApp]: 'reporting.human-readable-object-names.inApp',
  [objectTypes.article]: 'reporting.human-readable-object-names.article',
  [objectTypes.email]: 'reporting.human-readable-object-names.email',
  [objectTypes.banner]: 'reporting.human-readable-object-names.banner',
  [objectTypes.legacyMessagePush]: 'reporting.human-readable-object-names.legacyMessagePush',
  [objectTypes.legacyMessageInApp]: 'reporting.human-readable-object-names.legacyMessageInApp',
  [objectTypes.legacyMessageEmail]: 'reporting.human-readable-object-names.legacyMessageEmail',
  [objectTypes.legacyControlGroup]: 'reporting.human-readable-object-names.legacyControlGroup',
  [objectTypes.push]: 'reporting.human-readable-object-names.push',
  [objectTypes.sms]: 'reporting.human-readable-object-names.sms',
  [objectTypes.controlGroup]: 'reporting.human-readable-object-names.controlGroup',
  [objectTypes.chat]: 'reporting.human-readable-object-names.chat',
  [objectTypes.carousel]: 'reporting.human-readable-object-names.carousel',
  [objectTypes.post]: 'reporting.human-readable-object-names.post',
  [objectTypes.series]: 'reporting.human-readable-object-names.series',
  [objectTypes.condition]: 'reporting.human-readable-object-names.condition',
  [objectTypes.wait]: 'reporting.human-readable-object-names.wait',
  [objectTypes.tagger]: 'reporting.human-readable-object-names.tagger',
  [objectTypes.customBot]: 'reporting.human-readable-object-names.customBot',
  [objectTypes.inboundCustomBot]: 'reporting.human-readable-object-names.inboundCustomBot',
  [objectTypes.buttonCustomBot]: 'reporting.human-readable-object-names.buttonCustomBot',
  [objectTypes.splitter]: 'reporting.human-readable-object-names.splitter',
  [objectTypes.edgeSplit]: 'reporting.human-readable-object-names.edgeSplit',
  [objectTypes.resolutionBotBehavior]:
    'reporting.human-readable-object-names.resolutionBotBehavior',
  [objectTypes.outboundWebhook]: 'reporting.human-readable-object-names.outboundWebhook',
  [objectTypes.survey]: 'reporting.human-readable-object-names.survey',
  [objectTypes.tooltipGroup]: 'reporting.human-readable-object-names.tooltipGroup',
  [objectTypes.tooltip]: 'reporting.human-readable-object-names.tooltip',
  [objectTypes.newsItem]: 'reporting.human-readable-object-names.newsItem',
  [objectTypes.newsfeed]: 'reporting.human-readable-object-names.newsfeed',
  [objectTypes.triggerableCustomBot]: 'reporting.human-readable-object-names.triggerableCustomBot',
  [objectTypes.checklist]: 'reporting.human-readable-object-names.checklist',
  [objectTypes.whatsapp]: 'reporting.human-readable-object-names.whatsapp',
};

export const objectIcons = {
  [objectTypes.carousel]: 'carousel-filled',
  [objectTypes.chat]: 'chat-filled',
  [objectTypes.legacyMessageEmail]: 'email-filled',
  [objectTypes.legacyControlGroup]: 'activity',
  [objectTypes.legacyMessageInApp]: 'chat-filled',
  [objectTypes.legacyMessagePush]: 'push-notification-filled',
  [objectTypes.post]: 'post-filled',
  [objectTypes.push]: 'push-notification-filled',
  [objectTypes.sms]: 'sms',
  [objectTypes.tour]: 'product-tours-filled',
  [objectTypes.series]: 'series',
  [objectTypes.email]: 'email-filled',
  [objectTypes.customBot]: 'bot-filled',
  [objectTypes.inboundCustomBot]: 'bot-filled',
  [objectTypes.buttonCustomBot]: 'bot-filled',
  [objectTypes.banner]: 'banner-filled',
  [objectTypes.article]: 'article',
  [objectTypes.resolutionBotBehavior]: 'bot-filled',
  [objectTypes.survey]: 'survey-filled',
  [objectTypes.tooltipGroup]: 'tooltips-product',
  [objectTypes.newsItem]: 'news-filled',
  [objectTypes.newsfeed]: 'rss',
  [objectTypes.outboundWebhook]: 'webhook',
  [objectTypes.triggerableCustomBot]: 'bot-filled',
  [objectTypes.checklist]: 'checklist-filled',
  [objectTypes.whatsapp]: 'whatsapp',
  [objectTypes.externalContent]: 'globe',
  [objectTypes.savedReply]: 'saved-reply',
  [objectTypes.articleContent]: 'article',
  [objectTypes.contentSnippet]: 'note',
  [objectTypes.fileSourceContent]: 'attachment',
  [objectTypes.internalArticle]: 'locked',
  [objectTypes.workflowConnectorAction]: 'webhook',
  [objectTypes.answer]: 'respond',
  [objectTypes.contentLibraryFolder]: 'collection',
  [objectTypes.conversationExcerpts]: 'chat-filled',
} as const;

export const objectInterfaceIcons = {
  [objectTypes.post]: 'post-filled',
  [objectTypes.chat]: 'chat-filled',
  [objectTypes.push]: 'push-notification-filled',
  [objectTypes.email]: 'email-filled',
  [objectTypes.carousel]: 'carousel-filled',
  [objectTypes.sms]: 'push-notification-filled',
  [objectTypes.tour]: 'product-tours-filled',
  [objectTypes.series]: 'series',
  [objectTypes.customBot]: 'bot-filled',
  [objectTypes.inboundCustomBot]: 'bot-filled',
  [objectTypes.buttonCustomBot]: 'bot-filled',
  [objectTypes.resolutionBotBehavior]: 'bot-filled',
  [objectTypes.legacyMessageEmail]: 'email-filled',
  [objectTypes.banner]: 'banner-filled',
  [objectTypes.survey]: 'survey-filled',
  [objectTypes.tooltipGroup]: 'tooltips-product',
  [objectTypes.newsItem]: 'news-filled',
  [objectTypes.newsfeed]: 'rss',
  [objectTypes.triggerableCustomBot]: 'bot-filled',
  [objectTypes.checklist]: 'chat-filled', // placeholder icon
  [objectTypes.whatsapp]: 'whatsapp',
} as const;

export const objectNames = {
  [objectTypes.tour]: 'tour',
  [objectTypes.banner]: 'banner',
  [objectTypes.inApp]: 'in-app',
  [objectTypes.email]: 'email',
  [objectTypes.legacyMessagePush]: 'push',
  [objectTypes.legacyMessageInApp]: 'in-app',
  [objectTypes.legacyMessageEmail]: 'email',
  [objectTypes.legacyControlGroup]: 'control group',
  [objectTypes.push]: 'push',
  [objectTypes.sms]: 'sms',
  [objectTypes.controlGroup]: 'control_group',
  [objectTypes.chat]: 'chat',
  [objectTypes.carousel]: 'carousel',
  [objectTypes.post]: 'post',
  [objectTypes.condition]: 'rules',
  [objectTypes.series]: 'series',
  [objectTypes.wait]: 'wait',
  [objectTypes.tagger]: 'tag',
  [objectTypes.customBot]: 'custom_bot',
  [objectTypes.inboundCustomBot]: 'inbound_custom_bot',
  [objectTypes.buttonCustomBot]: 'button_custom_bot',
  [objectTypes.article]: 'article',
  [objectTypes.splitter]: 'split test',
  [objectTypes.edgeSplit]: 'edge split',
  [objectTypes.resolutionBotBehavior]: 'resolution_bot_behavior',
  [objectTypes.outboundWebhook]: 'webhook',
  [objectTypes.survey]: 'survey',
  [objectTypes.tooltipGroup]: 'tooltip_group',
  [objectTypes.tooltip]: 'tooltip',
  [objectTypes.newsItem]: 'news_item',
  [objectTypes.newsfeed]: 'newsfeed',
  [objectTypes.triggerableCustomBot]: 'triggerable_custom_bot',
  [objectTypes.checklist]: 'checklist',
  [objectTypes.checklistStep]: 'checklist_task',
  [objectTypes.whatsapp]: 'whatsapp',
  [objectTypes.externalContent]: 'external_content',
  [objectTypes.savedReply]: 'macro',
  [objectTypes.articleContent]: 'article',
  [objectTypes.contentSnippet]: 'content_snippet',
  [objectTypes.aiContentSegment]: 'ai_content_segment',
  [objectTypes.fileSourceContent]: 'file',
  [objectTypes.internalArticle]: 'internal-article',
  [objectTypes.contentLibraryFolder]: 'folder',
  [objectTypes.answer]: 'answer',
} as const;

export const objectNamesToKeys = Object.fromEntries(
  Object.entries(objectNames)
    .filter((entry) => Number(entry[0]) !== objectTypes.legacyMessageEmail)
    .map((entry) => entry.reverse()),
);

export const humanReadableObjectNames = {
  ...objectNames,
  [objectTypes.push]: 'mobile push',
  [objectTypes.controlGroup]: 'control group',
  [objectTypes.customBot]: 'workflow',
  [objectTypes.carousel]: 'mobile carousel',
  [objectTypes.inboundCustomBot]: 'workflow',
  [objectTypes.buttonCustomBot]: 'workflow',
  [objectTypes.article]: 'article',
  [objectTypes.resolutionBotBehavior]: 'Profile',
  [objectTypes.sms]: 'SMS message',
  [objectTypes.tooltipGroup]: 'tooltip group',
  [objectTypes.tooltip]: 'tooltip',
  [objectTypes.newsItem]: 'news item',
  [objectTypes.newsfeed]: 'newsfeed',
  [objectTypes.triggerableCustomBot]: 'workflow',
  [objectTypes.whatsapp]: 'WhatsApp message',
  [objectTypes.checklistStep]: 'step',
  [objectTypes.externalContent]: 'external',
  [objectTypes.contentSnippet]: 'Snippet',
  [objectTypes.aiContentSegment]: 'audience',
  [objectTypes.fileSourceContent]: 'PDF',
  [objectTypes.internalArticle]: 'Internal Article',
  [objectTypes.workflowConnectorAction]: 'Custom Actions',
  [objectTypes.contentLibraryFolder]: 'Folder',
  [objectTypes.answer]: 'Custom Answer',
};

export const statsSystemObjectNames = {
  ...objectNames,
  [objectTypes.sms]: 'sms_message',
  [objectTypes.whatsapp]: 'whatsapp_message',
};

export const audienceTypes = {
  users: 'user',
  visitors: 'visitor',
  leads: 'lead',
};

export const audienceRoles = {
  users: 'user_role',
  visitors: 'visitor_role',
  leads: 'contact_role',
};

export const audienceNames = {
  [audienceRoles.users]: 'user',
  [audienceRoles.visitors]: 'visitor',
  [audienceRoles.leads]: 'lead',
};

export enum RoleType {
  userRole = 0,
  visitorRole = 1,
  leadRole = 2,
}

// For compatibility with old code
export const roleTypes = RoleType;

export const roleNames = {
  [RoleType.userRole]: 'user',
  [RoleType.visitorRole]: 'visitor',
  [RoleType.leadRole]: 'lead',
};

export const matchBehaviors = {
  static: 0,
  single: 1,
  multi: 2,
  transient: 3,
};

export enum MatchBehavior {
  Static = 0,
  Single = 1,
  Multi = 2,
  Transient = 3,
}

export const behaviorMap = {
  ongoing: [matchBehaviors.single, matchBehaviors.multi],
  'one-off': [matchBehaviors.static],
};

export const behaviorMapDynamicFixed = {
  any: null,
  dynamic: [matchBehaviors.single, matchBehaviors.multi],
  fixed: [matchBehaviors.static],
};

export const timetableTypes = {
  insideOfficeHours: 0,
  outsideOfficeHours: 1,
  customTimes: 2,
};

export const timezoneTypes = {
  basedOnAppTimezone: 0,
  basedOnUserTimezone: 1,
};

export const scheduledStateChangeTypes = {
  activate: 0,
  deactivate: 1,
};

export const scheduledStateChangeStatuses = {
  scheduled: 0,
  complete: 1,
  cancelled: 2,
};

export const seriesNodeTypes = {
  starting: 0,
  internal: 1,
};

export const seriesEdgeTypes = {
  primary: 0,
  alternative: 1,
  split: 2,
};

export const edgeSplitTypes = {
  test: 0,
  control: 1,
};

export const seriesAnnotationTypes = {
  note: 0,
};

export const seriesExitBehaviors = {
  none: 0,
  exitRules: 1,
};

export const seriesWaitTypes = {
  relative: 0,
  absolute: 1,
};

export const nodeDelayBehaviors = {
  noDelay: 0,
  delayable: 1,
};

export const objectWithVersions = [
  objectTypes.push,
  objectTypes.chat,
  objectTypes.post,
  objectTypes.banner,
  objectTypes.email,
  objectTypes.carousel,
  objectTypes.survey,
  objectTypes.tour,
];

export const objectsWithPriority = [
  objectTypes.customBot,
  objectTypes.buttonCustomBot,
  objectTypes.inboundCustomBot,
  objectTypes.resolutionBotBehavior,
  objectTypes.newsfeed,
  objectTypes.triggerableCustomBot,
];

export const defaultDelayTimes = {
  implicit: moment.duration(30, 'days').asSeconds(),
};

export const seriesOnlyObjectTypes = [
  objectTypes.condition,
  objectTypes.wait,
  objectTypes.tagger,
  objectTypes.splitter,
  objectTypes.outboundWebhook,
];

export const objectTypesWithoutStatistics = [
  objectTypes.condition,
  objectTypes.wait,
  objectTypes.tagger,
  objectTypes.splitter,
];

export const objectWithoutAbTesting = [
  objectTypes.newsItem,
  objectTypes.buttonCustomBot,
  objectTypes.inboundCustomBot,
  objectTypes.triggerableCustomBot,
];

export const customBotObjectTypes = [
  objectTypes.customBot,
  objectTypes.inboundCustomBot,
  objectTypes.buttonCustomBot,
  objectTypes.triggerableCustomBot,
];

export const contentEditors = {
  standalone: 0,
  series: 1,
};

export const objectNotificationPreferences = {
  seen: 'seen',
  interactedWith: 'interacted_with',
  engagedWith: 'engaged_with',
};

export const objectFeatures = {
  [objectTypes.banner]: 'banner_messages',
  [objectTypes.customBot]: 'bot_workflows',
  [objectTypes.chat]: 'auto_messaging',
  [objectTypes.email]: 'email_messages',
  [objectTypes.carousel]: 'mobile_carousels',
  [objectTypes.push]: 'push_only',
  [objectTypes.post]: 'post_in-app_message',
  [objectTypes.tour]: 'product_tours',
  [objectTypes.tooltip]: 'tooltips',
  [objectTypes.splitter]: 'advanced_ab',
  [objectTypes.outboundWebhook]: 'outbound_webhooks',
  [objectTypes.survey]: 'surveys',
  [objectTypes.sms]: 'sms',
  [objectTypes.newsItem]: 'news',
  [objectTypes.checklist]: 'checklists',
  [objectTypes.whatsapp]: 'whatsapp_integration',
};

export const permissionsMap = {
  [objectTypes.customBot]: OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION,
  [objectTypes.inboundCustomBot]: CUSTOM_BOT_REQUIRED_PERMISSION,
  [objectTypes.buttonCustomBot]: CUSTOM_BOT_REQUIRED_PERMISSION,
  [objectTypes.resolutionBotBehavior]: RESOLUTION_BOT_REQUIRED_PERMISSION,
  [objectTypes.triggerableCustomBot]: CUSTOM_BOT_REQUIRED_PERMISSION,
};

export const seriesDuplicationStatuses = {
  noDuplication: -1,
  inProgress: 0,
  completed: 1,
  failed: 2,
};

export const rulesetCopyTitleSufix = ' (Copy)';

export enum matchingLocations {
  WEB_PING_BOOT = 0,
  // # MOBILE_SDK = 1 Deprecated
  MATCHING_PIPELINE = 2,
  // # PUSH = 3 Deprecated
  // # HOME_SCREEN = 4 Deprecated
  ANDROID_PING = 5,
  IOS_PING = 6,
  ANSWER_BOT = 7,
  STATIC_AUDIENCE_ENROLLMENT = 8,
  HELP_CENTER = 9,
  PUBLIC_API_CONVERSATIONS = 10,
  MOBILE_API_REQUESTS = 11,
  EVENT_TRIGGER = 12,
  TRIGGER_FROM_CODE = 13,
  WEB_MESSENGER_OPEN = 14,
  RESOLUTION_BOT_BEHAVIOR_MATCHER = 15,
  ANDROID_MESSENGER_OPEN = 16,
  IOS_MESSENGER_OPEN = 17,
  HOME_CARD = 18,
  NEW_USER_CONVERSATION = 19,
  WEB_EMBEDDED_CONTENT = 20,
  NEWS_FEED = 21,
  TRIGGER_FROM_OPERATOR = 22,
  HOME_API = 23,
  DIRECT_MATCHING = 24,
  WEB_PING_UPDATE = 25,
}

export const edgeSplitIndexName = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
} as const;

export const checkpointStatuses = {
  active: 0,
  completedAndTraversedPrimaryEdges: 1,
  expired: 2,
  invalid: 3,
  exited: 4,
  completedAndTraversedAlternativeEdges: 5,
  completedAndEjectedFromPath: 6,
  completedAndFinishedPath: 7,
  completedAndTraversedSplitEdges: 8,
  rejectedDueToCompanyPrioritization: 9,
};

export const checkpointStatusNames = {
  0: 'Active',
  1: 'Completed and took next paths',
  2: 'Expired',
  3: 'Invalid (node or user deleted)',
  4: 'Exited Series at this point',
  5: `Not matched and took the 'did not match' paths`,
  6: `Not matched and removed from path`,
  7: `Completed and finished path`,
  8: `Completed and took one of the split edges`,
  9: `Rejected due to company prioritization`,
};

export const matchingLocationTypes = {
  foreground: 0,
  background: 1,
  both: 2,
};

export const triggerEventTypes = {
  userEvent: 0,
};

export const defaultRulesetSpacingPeriod = 3600 * 24;

export const minimumEventTriggeredRulesetSpacingPeriod = 60;
export const reducedLimitMinimumEventTriggeredRulesetSpacingPeriod = 5;

export const defaultRulesetMaxMatchCount = 3;

export const recurringScheduleTypes = {
  attributeBased: 0,
  calendarBased: 1,
};

export const recurringScheduleFrequencies = {
  basedOnUserData: 0,
  daily: 1,
  weekly: 2,
  monthly: 3,
  yearly: 4,
};

export const entryBehaviors = {
  singleEntry: 0,
  multipleEntry: 1,
};

export const objectCustomReportRoute = {
  survey: 'apps.app.reports.outbound-engagement.survey-reports.report.show',
};

export const messengerWebAndMobileDependentObjects = [
  objectTypes.chat,
  objectTypes.post,
  objectTypes.survey,
];

export const messengerDependentObjects = [
  objectTypes.tour,
  objectTypes.banner,
  objectTypes.customBot,
  objectTypes.inboundCustomBot,
  objectTypes.buttonCustomBot,
  objectTypes.triggerableCustomBot,
  objectTypes.tooltipGroup,
  objectTypes.newsItem,
  objectTypes.checklist,
  ...messengerWebAndMobileDependentObjects,
];

export enum Statistic {
  RECEIPTS = 0,
  REPLIES = 1,
  OPENS = 2,
  CLICKS = 3,
  LAST_SENT = 4,
  GOALS = 5,
  FAILURES_LIST_STATS = 6,
  TOUR_STEP_FAILURES = 7,
  COMPLETIONS = 8,
  TOUR_STEP_VIEW = 9,
  REACTIONS = 10,
  DISMISSALS = 11,
  COLLECTED_EMAIL = 12,
  SERIES_COMPLETIONS = 14,
  SERIES_EXITS = 15,
  SERIES_DISENGAGEMENT = 16,
  WEBHOOK_FAILURES = 17,
  ANSWERS = 18,
  KEYWORD_REPLIES = 19,
}
