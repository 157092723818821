/* RESPONSIBLE TEAM: team-ai-agent */

import Model, { type SyncHasMany, attr, hasMany } from '@ember-data/model';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ResolutionBotBehaviorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior';
import type IntlService from 'embercom/services/intl';
import type ContentImportService from 'embercom/services/content-import-service';
import Validations from 'embercom/validations/ai-agent/profile';
import { INTERCOM_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { type AllChannels } from 'embercom/lib/operator/custom-bots/constants';
import type BotOnlyMessageLocalization from 'embercom/models/operator/bot-only-message/localization';
import type BotIntroLocalization from 'embercom/models/operator/bot-intro/localization';
import type BotIntroPart from 'embercom/models/operator/bot-intro/part';
import type PermittedTheme from './permitted-theme';

export type BotMode = 'default' | 'looping' | 'bot_only' | 'one_time' | 'disabled';
export type InactivityMode = 'routed' | 'closed';

export default class Profile extends Model.extend(Validations) {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare userInactivityModeWhenNoAnswer: InactivityMode | null;
  @attr('string') declare userInactivityModeWhenAnswer: InactivityMode | null;
  @attr('boolean') declare useAiAnswers: boolean;
  @attr('boolean') declare useCustomAnswers: boolean;
  @attr('string') declare botMode: BotMode;
  @attr('string') declare title: string;
  @attr('number') declare entityType: number;
  @attr('number') declare latestWorkflowInstanceId: number;
  @attr('boolean') declare csatEnabled: boolean;
  @attr('boolean') declare csatEnabledForHardResolution: boolean;
  @attr('boolean') declare csatEnabledForSoftResolution: boolean;
  @attr('number') declare csatBlockUpdateAfter?: number;
  @attr('number') declare endUserInactivityTimer: number;
  @attr('array') declare targetChannels: AllChannels[];
  @attr('number') declare matchPercentage?: number | null;
  @attr('boolean') declare preHandoverAnswerEnabled: boolean;
  @attr('boolean') declare themeBasedRolloutEnabled: boolean;

  @fragmentArray('operator/bot-intro/localization')
  declare botIntroLocalizations: SyncHasMany<BotIntroLocalization>;
  @fragmentArray('operator/bot-intro/part')
  declare userInactivityAutoCloseMessages: SyncHasMany<BotIntroPart>;
  @hasMany('operator/localized-generic-triage', { async: false })
  declare genericTriageLocalizations: SyncHasMany<$TSFixMe>; // app/models/operator/localized-generic-triage
  @fragmentArray('operator/bot-only-message/localization')
  declare botOnlyMessageLocalizations: BotOnlyMessageLocalization[];
  @fragmentArray('ai-agent/permitted-theme')
  declare permittedThemes: PermittedTheme[] | null;

  autoSaveDisabled = true;

  maybeResetDefaultInactivityValues() {
    if (this.botMode === 'one_time') {
      this.userInactivityModeWhenAnswer = this.userInactivityModeWhenAnswer || 'closed';
      this.userInactivityModeWhenNoAnswer = null;
    } else if (this.botMode === 'looping') {
      this.userInactivityModeWhenAnswer = this.userInactivityModeWhenAnswer || 'closed';
      this.userInactivityModeWhenNoAnswer = this.userInactivityModeWhenNoAnswer || 'routed';
    } else {
      this.userInactivityModeWhenAnswer = null;
      this.userInactivityModeWhenNoAnswer = null;
    }
  }

  get hasUnsavedChanges() {
    return Boolean(
      this.hasDirtyAttributes ||
        this.genericTriageLocalizations.any(
          (localizedContent) => localizedContent.hasUnsavedChanges,
        ),
    );
  }

  get canActivate() {
    return this.validations.isValid;
  }

  get modeEligibleForBotIntro() {
    let isLoopingOrBotOnly = this.botMode === 'looping' || this.botMode === 'bot_only';

    return isLoopingOrBotOnly && this.isTargetingIntercomChannels;
  }

  get modeEligibleForGenericTriage() {
    return this.botMode !== 'disabled';
  }

  get botIntroEnabledForAnyLocalization() {
    return this.botIntroLocalizations.any((localization) => localization.enabled === true);
  }

  get botIntroEligibleAndEnabled() {
    return this.modeEligibleForBotIntro && this.botIntroEnabledForAnyLocalization;
  }

  get enabledLocalizationsList() {
    let enabledLocalizations = this.botIntroLocalizations.filter(
      (localization) => localization.enabled === true,
    );

    return enabledLocalizations.map((localization) => localization.locale);
  }

  get disableLocalizationsValidation() {
    return !this.botIntroEligibleAndEnabled;
  }

  get disableBotOnlyMessageLocalizationsValidation() {
    return this.botMode !== 'bot_only';
  }

  get disableGenericTriageValidation() {
    return !this.modeEligibleForGenericTriage;
  }

  get isAnyCSATSettingEnabled() {
    return this.csatEnabledForHardResolution || this.csatEnabledForSoftResolution;
  }

  get isTargetingIntercomChannels() {
    return (
      !this.targetChannels || // Fallback for old RBs that don't have targetChannels (It shouldn't happen in theory)
      this.targetChannels.any((channel) => INTERCOM_CHANNELS.includes(channel as any)) // RB is targeting Intercom channels (web, ios, android)
    );
  }

  get previewConfiguration() {
    return {
      previewTitle: this.title,
      intersectionMode: 'workflow',
      analyticsEventObjectLabel: 'rb-behavior',
      composerSuggestions: null,
    };
  }

  get previewDisabledMessage() {
    if (!this.canActivate) {
      return this.intl.t('ai-agent.profiles.editor.header.preview.errors-in-behavior');
    } else if (this.hasUnsavedChanges) {
      return this.intl.t('ai-agent.profiles.editor.header.preview.please-save-behavior');
    }
    return;
  }

  get analyticsData() {
    return {
      object: 'resolution-bot-behavior',
      resolution_bot_behavior_id: this.id,
    };
  }

  get preHandoverAnswerAllowed() {
    return this.useAiAnswers && this.botMode !== 'bot_only';
  }

  editorConfig() {
    let container = getOwner(this);
    return ResolutionBotBehaviorConfigGenerator({ container });
  }

  beforeEdit() {
    this.genericTriageLocalizations.forEach((genericTriage) => genericTriage.beforeEdit());
  }

  beforeSave() {
    if (!this.appService.app.hasAnswerBot) {
      this.useCustomAnswers = false;
    }

    this.genericTriageLocalizations.forEach((genericTriage) => genericTriage.beforeSave());
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.genericTriageLocalizations.forEach((localizedContent) =>
      localizedContent.rollbackAttributes(),
    );
  }
}
