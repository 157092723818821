/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr, belongsTo } from '@ember-data/model';
import type AttributeDescriptor from './attribute-descriptor';

export default class AttributeDescriptorListOption extends Model {
  @attr('string') declare label: string;
  @attr('string') declare description: string | null;
  @attr('boolean') declare archived: boolean;

  @belongsTo('operator/visual-builder/attribute-descriptor', {
    async: false,
    inverse: 'listOptions',
  })
  declare descriptor: AttributeDescriptor;

  get trimmedLabel() {
    return this.label.trim();
  }
}
