/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { capitalize } from '@ember/string';
import { syncDirection } from 'embercom/models/crm/attribute-mapping-constants';

export default class SalesforceFields extends Fragment {
  @attr('string') label;
  @attr('string') path;
  @attr('string') type;
  @attr('boolean') updateable;

  asCrmAttributeMappingOption(
    salesforceIdentityMappingField,
    attributeMappings,
    intercomObjectType,
    salesforceObjectType,
  ) {
    let fieldType = `(${capitalize(this.type)})`;
    let tooltipText = null;
    let salesforceObjectTypes = intercomObjectType === 'contact' ? 'leads and users' : 'accounts';
    if (this.isSalesforceIdentityMappingField(salesforceIdentityMappingField)) {
      tooltipText = `This field is already used for mapping ${salesforceObjectTypes}`;
    } else if (this.isSalesforceFieldMapped(attributeMappings, salesforceObjectType)) {
      tooltipText = 'This field is already used in another attribute mapping';
    } else if (this.isSalesforceCompositeFieldType()) {
      tooltipText = 'You can’t sync a Salesforce compound field';
    } else if (!this.updateable) {
      tooltipText = 'This field cannot be updated from intercom';
    }

    let isDisabled =
      this.isSalesforceFieldMapped(attributeMappings, salesforceObjectType) ||
      this.isSalesforceIdentityMappingField(salesforceIdentityMappingField) ||
      this.isSalesforceCompositeFieldType();

    return {
      text: this.label,
      value: this.path,
      count: fieldType,
      tooltipText,
      isDisabled,
    };
  }

  isSalesforceIdentityMappingField(salesforceIdentityMappingField) {
    return ['Email', salesforceIdentityMappingField].includes(this.path);
  }

  isSalesforceCompositeFieldType() {
    return ['address', 'location'].includes(this.type);
  }

  isSalesforceFieldMapped(attributeMappings, salesforceObjectType) {
    let sourceMapping = attributeMappings.findBy('sourceAttributePath', this.path);
    let destinationMapping = attributeMappings.findBy('destinationAttributePath', this.path);
    let isLeadNameField =
      salesforceObjectType === 'Lead' && ['FirstName', 'LastName'].includes(this.path);
    let isCaseDescriptionField =
      salesforceObjectType === 'Case' && ['Description'].includes(this.path);
    let isCaseOriginField = salesforceObjectType === 'Case' && ['Origin'].includes(this.path);
    return (
      (sourceMapping && sourceMapping.direction === syncDirection.CrmtoIntercom) ||
      (destinationMapping && destinationMapping.direction === syncDirection.IntercomToCRM) ||
      isLeadNameField ||
      isCaseDescriptionField ||
      isCaseOriginField
    );
  }
}
