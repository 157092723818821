/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const defaultEventVerbsMap = {
  replied: 'replied to',
  clicked: 'clicked a link in',
  goal_hit: 'hit the goal for',
  dismissal: 'dismissed',
  completion: 'completed',
  collected_email: 'Collected an email in',
  goal_success: 'hit the goal for',
  disengaged: 'disengaged from',
};

export const eventVerbsMap = {
  chat: {
    ...defaultEventVerbsMap,
  },
  email: {
    ...defaultEventVerbsMap,
  },
  push: {
    ...defaultEventVerbsMap,
  },
  carousel: {
    ...defaultEventVerbsMap,
  },
  post: {
    ...defaultEventVerbsMap,
    reacted: 'reacted to',
  },
  tour: {
    ...defaultEventVerbsMap,
    failed: 'Encountered failure on',
  },
  custom_bot: {
    ...defaultEventVerbsMap,
  },
  banner: {
    ...defaultEventVerbsMap,
  },
  survey: {
    ...defaultEventVerbsMap,
  },
  sms: {
    ...defaultEventVerbsMap,
  },
  checklist: {
    ...defaultEventVerbsMap,
  },
};

export const stats = {
  receipt: 'receipt',
  completion: 'completion',
  goalSuccess: 'goal_success',
  open: 'open',
  click: 'click',
  reply: 'reply',
  reaction: 'reaction',
  collectedEmail: 'collected_email',
  tourStepFailure: 'tour_step_failure',
  dismissal: 'dismissal',
  emailFailure: 'email_failure',
  failure: 'failure',
  seriesCompletion: 'series_completion',
  seriesDisengagement: 'series_disengagement',
  seriesExit: 'series_exit',
  webhookFailure: 'webhook_failure',
  answer: 'answer',
  keywordReply: 'keyword_reply',
};

// CSS classes to use for text that should be in the statistic's color
export const statColors = {
  receipt: 'outbound__recipients-stat',
  completion: 'success-fill',
  goalSuccess: 'outbound__goal-stat',
  open: 'outbound__opens-stat',
  reaction: 'outbound__replies-stat',
  click: 'outbound__clicks-stat',
  reply: 'outbound__replies-stat',
  collectedEmail: 'c__collected-email-stat',
  tourStepFailure: 'text-default',
  dismissal: 'text-default',
};

// Visualization color palette names to use for trend graph lines
export const statVisualizationColors = {
  receipt: 'vis-blue-30',
  completion: 'vis-green-40',
  goalSuccess: 'vis-blue-40',
  open: 'vis-green-40',
  click: 'vis-magenta-40',
  reaction: 'vis-yellow-30',
  reply: 'vis-yellow-30',
  collected_email: 'vis-magenta-40',
  tour_step_failure: 'vis-yellow-30',
  dismissal: 'vis-yellow-30',
};

export const failureListCategories = {
  unsubscribe: 'unsubscribe',
  spamComplaint: 'spam_complaint',
  hardBounce: 'hard_bounce',
  softBounce: 'soft_bounce',
  smsFailure: 'sms_failure',
  pushFailure: 'push_failure',
  whatsappFailure: 'whatsapp_failure',
};

export const contentEvents = {
  article_viewed: {
    objectType: 'article',
    statistic: 'viewed',
  },
  chat_viewed: {
    objectType: 'chat',
    statistic: 'viewed',
  },
  chat_received: {
    objectType: 'chat',
    statistic: 'received',
  },
  chat_opened: {
    objectType: 'chat',
    statistic: 'opened',
  },
  chat_clicked: {
    objectType: 'chat',
    statistic: 'clicked',
  },
  chat_goal_hit: {
    objectType: 'chat',
    statistic: 'goal_hit',
  },
  chat_replied: {
    objectType: 'chat',
    statistic: 'replied',
  },
  chat_dismissal: {
    objectType: 'chat',
    statistic: 'dismissal',
  },
  chat_collected_email: {
    objectType: 'chat',
    statistic: 'collected_email',
  },
  post_viewed: {
    objectType: 'post',
    statistic: 'viewed',
  },
  post_received: {
    objectType: 'post',
    statistic: 'received',
  },
  post_opened: {
    objectType: 'post',
    statistic: 'opened',
  },
  post_clicked: {
    objectType: 'post',
    statistic: 'clicked',
  },
  post_goal_hit: {
    objectType: 'post',
    statistic: 'goal_hit',
  },
  post_replied: {
    objectType: 'post',
    statistic: 'replied',
  },
  post_dismissal: {
    objectType: 'post',
    statistic: 'dismissal',
  },
  post_reacted: {
    objectType: 'post',
    statistic: 'reacted',
  },
  email_received: {
    objectType: 'email',
    statistic: 'received',
  },
  email_opened: {
    objectType: 'email',
    statistic: 'opened',
  },
  email_clicked: {
    objectType: 'email',
    statistic: 'clicked',
  },
  email_goal_hit: {
    objectType: 'email',
    statistic: 'goal_hit',
  },
  email_replied: {
    objectType: 'email',
    statistic: 'replied',
  },
  email_bounced: {
    objectType: 'email',
    statistic: 'bounced',
  },
  email_unsubscribed: {
    objectType: 'email',
    statistic: 'unsubscribed',
  },
  tour_viewed: {
    objectType: 'tour',
    statistic: 'viewed',
  },
  tour_completed: {
    objectType: 'tour',
    statistic: 'completed',
  },
  tour_failed: {
    objectType: 'tour',
    statistic: 'failed',
  },
  tour_goal_hit: {
    objectType: 'tour',
    statistic: 'goal_hit',
  },
  push_received: {
    objectType: 'push',
    statistic: 'received',
  },
  push_opened: {
    objectType: 'push',
    statistic: 'opened',
  },
  push_goal_hit: {
    objectType: 'push',
    statistic: 'goal_hit',
  },
  carousel_received: {
    objectType: 'carousel',
    statistic: 'received',
  },
  carousel_completed: {
    objectType: 'carousel',
    statistic: 'completed',
  },
  carousel_dismissal: {
    objectType: 'carousel',
    statistic: 'dismissal',
  },
  carousel_goal_hit: {
    objectType: 'carousel',
    statistic: 'goal_hit',
  },
  custom_bot_received: {
    objectType: 'custom_bot',
    statistic: 'received',
  },
  custom_bot_replied: {
    objectType: 'custom_bot',
    statistic: 'replied',
  },
  custom_bot_completed: {
    objectType: 'custom_bot',
    statistic: 'completed',
  },
  custom_bot_goal_hit: {
    objectType: 'custom_bot',
    statistic: 'goal_hit',
  },
  banner_viewed: {
    objectType: 'banner',
    statistic: 'viewed',
  },
  banner_clicked: {
    objectType: 'banner',
    statistic: 'clicked',
  },
  banner_reacted: {
    objectType: 'banner',
    statistic: 'reacted',
  },
  banner_goal_hit: {
    objectType: 'banner',
    statistic: 'goal_hit',
  },
  banner_collected_email: {
    objectType: 'banner',
    statistic: 'collected_email',
  },
  series_started: {
    objectType: 'series',
    statistic: 'started',
  },
  series_completed: {
    objectType: 'series',
    statistic: 'completed',
  },
  series_disengaged: {
    objectType: 'series',
    statistic: 'disengaged',
  },
  series_exited: {
    objectType: 'series',
    statistic: 'exited',
  },
  series_goal_success: {
    objectType: 'series',
    statistic: 'goal_success',
  },
  survey_received: {
    objectType: 'survey',
    statistic: 'received',
  },
  survey_completed: {
    objectType: 'survey',
    statistic: 'completed',
  },
  survey_dismissal: {
    objectType: 'survey',
    statistic: 'dismissal',
  },
  survey_goal_hit: {
    objectType: 'survey',
    statistic: 'goal_hit',
  },
  sms_received: {
    objectType: 'sms',
    statistic: 'received',
  },
  sms_replied: {
    objectType: 'sms',
    statistic: 'replied',
  },
  sms_goal_hit: {
    objectType: 'sms',
    statistic: 'goal_hit',
  },
  checklist_received: {
    objectType: 'checklist',
    statistic: 'received',
  },
  checklist_viewed: {
    objectType: 'checklist',
    statistic: 'viewed',
  },
  checklist_completed: {
    objectType: 'checklist',
    statistic: 'completed',
  },
  checklist_goal_hit: {
    objectType: 'checklist',
    statistic: 'goal_hit',
  },
};

// Error map to show custom error messages for sms failures
export const smsErrorMessages = {
  default: 'Something went wrong - please contact support',
  unsubscribe: 'Recipient has unsubscribed from receiving messages',
  usageLimitsReached: 'SMS sending limit reached - please contact support',
  21408: "Recipient's country is unsupported",
  21611: 'Too many messages scheduled - please contact support',
  21614: 'Recipient number is not valid',
  30006: 'Unable to deliver to landline or unreachable carrier',
  30003: 'Recipients number is unreachable',
  30007: 'Message filtered by recipients carrier',
  30008: 'Unknown issue contacting this user',
  30017: 'Carrier unable to process message at this time',
  30019: 'Message content too long for carrier',
  63003: 'Recipient has been blocked from receiving messages',
};

// Error map to show custom error messages for WhatsApp failures
export const whatsappErrorMessages = {
  default: 'Something went wrong - please contact support',
  no_permissions: 'Your workspace does not have access to this WhatsApp feature',
  integration_not_found: 'The WhatsApp integration you have selected does not exist',
  empty_phone_number: 'Recipient Phone attribute is empty',
  api_error: 'An error occurred while attempting to send the WhatsApp message',
  beta_limit_reached: 'You have reached the monthly limit for sending WhatsApp messages in beta',
  trial_limit_reached:
    'You have reached the limit for sending WhatsApp messages during your trial.',
};
