/* RESPONSIBLE TEAM: team-proactive-support */

import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import toSentence from 'embercom/lib/to-sentence';
import { audienceNames } from 'embercom/models/data/matching-system/matching-constants';

export default class ContentEditorStandardAudienceEditorConfiguration extends StandardAudienceEditorConfiguration {
  container = null;
  @service contentEditorService;
  @service intl;

  contentEditorDefaults = {
    showMatchBehavior: true,
    showClientPredicates: true,
    showAudiencePreview: true,
    showEventRules: false,
    showDateEvents: false,

    useRolePredicateGroup: true,
    allowedUserRoles: ['user', 'lead', 'visitor'],
  };

  constructor(inputs) {
    super(inputs);
    this.container = inputs.container;
    this.inputs = { ...this.contentEditorDefaults, ...inputs };
    this._setDefaultPreviewConfigs();
    this.createDefaults();
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get isDisabled() {
    return false;
  }

  get _tooltipText() {
    let tooltipText = this.intl.t(
      'matching-system.audience-selector.collapsible-preview.preview-audience-tooltip.basic',
    );
    let isLiveTooltipText = this.intl.t(
      'matching-system.audience-selector.collapsible-preview.preview-audience-tooltip.is-live',
    );
    let isPausedTooltipText = this.intl.t(
      'matching-system.audience-selector.collapsible-preview.preview-audience-tooltip.is-paused',
    );
    if (this.ruleset.isDraft || this.ruleset.isScheduled) {
      return tooltipText;
    } else if (this.ruleset.isLive) {
      return `${tooltipText} ${isLiveTooltipText}`;
    } else if (this.ruleset.isPaused) {
      return `${tooltipText} ${isPausedTooltipText}`;
    }
  }

  get _humanReadableObjectName() {
    return this.contentEditorService.configRulesetLink.humanReadableObjectName;
  }

  get _audienceString() {
    let audienceRole = this._getAudienceRole();

    if (!Array.isArray(audienceRole)) {
      audienceRole = [audienceRole];
    }

    let audience = toSentence(
      audienceRole.reverse().map((role) => {
        return pluralize(audienceNames[role]);
      }),
    );
    return audience;
  }

  get _audiencePreviewText() {
    if (this.inputs.audiencePreviewText) {
      return this.inputs.audiencePreviewText;
    } else if (this.ruleset.isMemberOfSeries) {
      return this.intl.t(
        'matching-system.audience-selector.collapsible-preview.preview-audience-text.series',
      );
    } else {
      let matchBehaviorText = this.ruleset.isMatchBehaviorStatic
        ? this.intl.t(
            'matching-system.audience-selector.collapsible-preview.preview-audience-text.static-match',
          )
        : this.intl.t(
            'matching-system.audience-selector.collapsible-preview.preview-audience-text.dynamic-match',
          );
      return this.intl.t(
        'matching-system.audience-selector.collapsible-preview.preview-audience-text.basic',
        {
          object: this._humanReadableObjectName,
          audience: this._audienceString,
          matchBehaviorText,
        },
      );
    }
  }

  _getAudienceRole() {
    return this.ruleset.userRolePredicate ? this.ruleset.userRolePredicate.value : ['user_role'];
  }

  _setDefaultPreviewConfigs() {
    this.inputs = {
      ...this.inputs,
      ...{
        tooltipText: this._tooltipText,
        audiencePreviewText: this._audiencePreviewText,
        emptyStateDescription: '',
        useLegacyList: false,
      },
    };
  }
}
