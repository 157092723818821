/* RESPONSIBLE TEAM: team-actions */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class InsertableActionField extends Fragment {
  @attr('string') declare identifier: string;
  @attr('string') declare name: string;
  @attr('string') declare type: string;

  // 'list' and 'relationship' are supported by workflows but not used by insertasble action fields
  get icon() {
    switch (this.type) {
      case 'string':
        return 'recipient-data';
      case 'integer':
        return 'count';
      case 'decimal':
        return 'decimal';
      case 'boolean':
        return 'boolean';
      case 'datetime':
        return 'calendar';
      default:
        return 'insert-data';
    }
  }
}
