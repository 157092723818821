/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { get } from '../lib/ajax';
import type FeatureFlag from './feature-flag';
import AdminSummary, { type AdminSummaryWireFormat } from './inbox/admin-summary';
import TeamSummary, { type TeamSummaryWireFormat } from './inbox/team-summary';
import ConversationAttributeDescriptor, {
  type ConversationAttributeDescriptorWireFormat,
} from './inbox/conversation-attribute-descriptor';
import containerLookup from 'embercom/lib/container-lookup';
import { tracked } from '@glimmer/tracking';
import EmailSenderSettings from 'embercom/objects/inbox/email-sender-settings';
import ExternalPlan, { type ExternalPlanWireFormat } from 'embercom/objects/inbox/external-plan';
import AppSettings, { type AppSettingsWireFormat } from 'embercom/objects/inbox/app-settings';
import { trackedFunction } from 'ember-resources/util/function';
import { request } from 'embercom/lib/inbox/requests';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { type InserterId } from 'embercom/objects/inbox/types/composer';
import { use } from 'ember-resources/util/function-resource';
import { TicketType, type TicketTypeWireFormat } from 'embercom/objects/inbox/ticket';
import ENV from 'embercom/config/environment';

export type WorkspaceForList = {
  id: string;
  id_code: string;
  name: string;
  parent_app_id: string;
};

export default class Workspace {
  id: string;
  adminAssignees?: AdminSummary[];
  teamAssignees?: TeamSummary[];
  conversationAttributeDescriptors?: ConversationAttributeDescriptor[];
  emailSenderSettings?: EmailSenderSettings;
  @tracked features: Array<string> = [];
  @tracked helpCenterLocale?: string = undefined;

  @tracked isTestWorkspace?: boolean;
  @tracked isDeveloperWorkspace?: boolean;
  @tracked parentAppId?: string;
  @tracked testAppId?: string;
  @tracked customerId?: string;
  @tracked companySize?: string;
  @tracked hasIntershopAccess?: boolean;
  @tracked hasConsentedToFinIngestion?: boolean;
  @tracked consentedToFinIngestionAt?: Date;
  @tracked createdAt?: Date;
  @tracked signedUpWithSolutionId?: number;
  @tracked name?: string;
  @tracked inProductSalesExperience?: string;
  @tracked onPricing5?: boolean;
  @tracked appSettingsVersion = 0;
  @tracked hasActiveSubscription?: boolean;

  private ticketTypesPromise?: Promise<TicketType[]>;

  private appSettingsLoader = trackedFunction(this, async () => {
    this.appSettingsVersion;
    return await this.fetchAppSettings();
  });

  constructor(id: string) {
    this.id = id;
  }

  setHasConsentedToFinIngestion(value: boolean) {
    this.hasConsentedToFinIngestion = value;
  }

  get isIntercom() {
    let regionService = containerLookup('service:regionService');
    return this.id === regionService.intercomAppCode;
  }

  isFeatureEnabled(feature: string) {
    return this.features.includes(feature);
  }

  get canAccessIntershop() {
    return (
      this.hasIntershopAccess && !this.isFeatureEnabled('team-expansion-revoke-intershop-access')
    );
  }

  get canDisplayExpiredSubscriptionPage() {
    return (
      ENV.environment !== 'development' &&
      this.isFeatureEnabled('team-purchase-experience-expired-subscription-page') &&
      !this.hasActiveSubscription
    );
  }

  get canUseConditionalAttributes() {
    return (
      this.canUseConversationConditionalAttributesBeta || this.canUseTicketsConditionalAttributes
    );
  }

  get canUseConversationConditionalAttributesBeta() {
    return this.isFeatureEnabled('hsg-conversation-conditional-attributes-beta');
  }

  get canUseTicketsConditionalAttributes() {
    return this.isFeatureEnabled('hsg-tickets-inbox-conditional-attributes-beta');
  }

  get canUseOptimisticUpdates() {
    return this.isFeatureEnabled('hsg-tickets-inbox-optimistic-updates');
  }

  get hasAccessToWorkflows() {
    return this.isFeatureEnabled('workflows_core_billing_feature');
  }

  get externalLinkShieldEnabled() {
    return this.useTrustedDomainsForUrlVerification;
  }

  /**
   @deprecated fetchAdminAssignees is deprecated. We are trying to move away from
   loading all data upfront. Please use the quick search APIs to load admins, if
   possible. If not, please drop a message in #team-help-desk-experience-eng.
   */
  async fetchAdminAssignees(): Promise<AdminSummary[]> {
    if (!this.adminAssignees) {
      let response = await request(`/ember/inbox/assignees/admins?app_id=${this.id}`);
      let json = (await response.json()).admins as AdminSummaryWireFormat[];
      this.adminAssignees = json.map(AdminSummary.deserialize);
    }

    return this.adminAssignees;
  }

  /**
   @deprecated fetchTeamAssignees is deprecated. We are trying to move away from
   loading all data upfront. Please use the quick search APIs to load teams, if
   possible. If not, please drop a message in #team-help-desk-experience-eng.
   */
  async fetchTeamAssignees(): Promise<TeamSummary[]> {
    if (!this.teamAssignees) {
      let response = await request(`/ember/inbox/assignees/teams?app_id=${this.id}`);
      let json = (await response.json()).teams as TeamSummaryWireFormat[];
      this.teamAssignees = json.map(TeamSummary.deserialize);
    }

    return this.teamAssignees;
  }

  async fetchConversationAttributeDescriptors(): Promise<ConversationAttributeDescriptor[]> {
    if (!this.conversationAttributeDescriptors) {
      let response = await request(`/ember/conversation_attributes/descriptors?app_id=${this.id}`);
      let json = (await response.json()) as ConversationAttributeDescriptorWireFormat[];
      let attributes = json.map(ConversationAttributeDescriptor.deserialize);
      this.conversationAttributeDescriptors = attributes;
    }

    return this.conversationAttributeDescriptors;
  }

  async fetchFeatures() {
    let response = await get(`/ember/apps/${this.id}.json?attribute_allowlist=features`);
    this.features = response.features.map((featureModel: FeatureFlag) => featureModel.id);
  }

  async fetchRequiredAttributes() {
    let response = await get(
      `/ember/apps/${this.id}.json?attribute_allowlist=features,is_test_app,parent_app_id,test_app_id,customer_id,company_size,has_intershop_access,created_at,signed_up_with_solution_id,developer_workspace,name,has_consented_to_fin_ingestion,in_product_sales_experience,on_pricing5,has_active_subscription`,
    );
    this.features = response.features.map((featureModel: FeatureFlag) => featureModel.id);
    this.testAppId = response.test_app_id;
    this.isTestWorkspace = response.is_test_app;
    this.isDeveloperWorkspace = response.developer_workspace;
    this.parentAppId = response.parent_app_id;
    this.customerId = response.customer_id;
    this.companySize = response.company_size;
    this.hasIntershopAccess = response.has_intershop_access;
    this.hasConsentedToFinIngestion = response.has_consented_to_fin_ingestion;
    this.consentedToFinIngestionAt = new Date(response.consented_to_fin_ingestion_at);
    this.createdAt = new Date(response.created_at);
    this.signedUpWithSolutionId = response.signed_up_with_solution_id;
    this.name = response.name;
    this.inProductSalesExperience = response.in_product_sales_experience;
    this.onPricing5 = response.on_pricing5;
    this.hasActiveSubscription = response.has_active_subscription;
  }

  async fetchHelpCenterLocale() {
    if (this.appSettingsLoader.value) {
      this.helpCenterLocale = this.appSettingsLoader.value.helpCenterLocale;
    } else {
      this.helpCenterLocale = (await this.fetchAppSettings()).helpCenterLocale;
    }
  }

  async fetchEmailSenderSettings() {
    if (!this.emailSenderSettings) {
      let response = await get(`/ember/email_sender_settings?app_id=${this.id}`);
      this.emailSenderSettings = EmailSenderSettings.deserialize(response);
    }
    return this.emailSenderSettings;
  }

  get products() {
    return this.productsLoader.value ?? [];
  }

  private productsLoader = trackedFunction(this, async () => {
    let response = await get(`/ember/apps/${this.id}.json?attribute_allowlist=products`);
    return response.products ?? [];
  });

  get plans() {
    return this.plansLoader.value ?? [];
  }

  private plansLoader = trackedFunction(this, async () => {
    let json = (await get(`/ember/apps/${this.id}.json?attribute_allowlist=plans`)) as {
      plans: ExternalPlanWireFormat[];
    };

    return (json.plans ?? []).map((plan) => ExternalPlan.deserialize(plan));
  });

  get disabledInserters() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.disabledInserters;
    } else {
      return ['gifs'];
    }
  }

  get showTeammatesPresence() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.showTeammatesPresence;
    } else {
      return false;
    }
  }

  get ticketStateFiltering() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.ticketStateFiltering;
    } else {
      return false;
    }
  }

  get canFinUseConversationContent() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.finUseInboxConversationContent;
    } else {
      return false;
    }
  }

  get isAnyChannelInstalled() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.isAnyChannelInstalled;
    } else {
      return true;
    }
  }

  get hasCustomerTickets() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.hasCustomerTickets;
    } else {
      return false;
    }
  }

  get hasBackOfficeTickets() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.hasBackOfficeTickets;
    } else {
      return false;
    }
  }

  get hasTrackerTickets() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.hasTrackerTickets;
    } else {
      return false;
    }
  }

  get disabledEmailConversationHistory() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.emailRemoveConversationHistory;
    } else {
      return false;
    }
  }

  get attachUploadsInline() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.attachUploadsInline;
    } else {
      return false;
    }
  }

  get useTrustedDomainsForUrlVerification() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.useTrustedDomainsForUrlVerification;
    } else {
      return true;
    }
  }

  get trustedDomainsAndUrls() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.trustedItems;
    } else {
      return [];
    }
  }

  get trustedDomains() {
    if (this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.trustedDomains;
    } else {
      return [];
    }
  }

  get maliciousLinks() {
    if (this.maliciousLinkBlockingEnabled && this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.maliciousLinks;
    } else {
      return [];
    }
  }

  get maliciousLinkBlockingEnabled() {
    return this.isFeatureEnabled('app-settings-malicious-links');
  }

  get blockedDomainsAndUrls() {
    if (this.blockedDomainsAndUrlsEnabled && this.appSettingsLoader.value) {
      return this.appSettingsLoader.value.blockedItems;
    } else {
      return [];
    }
  }

  get blockedDomainsAndUrlsEnabled() {
    return this.isFeatureEnabled('app-settings-blocked-domains-and-urls');
  }

  async fetchAppSettings() {
    return AppSettings.deserialize(
      (await get(`/ember/inbox/app_settings.json?app_id=${this.id}`)) as AppSettingsWireFormat,
    );
  }

  @use pinnedComposerInserters = AsyncData<InserterId[]>(async () => {
    let response = await request(`/ember/admins/visible_composer_inserters.json?app_id=${this.id}`);
    let { inserters } = (await response.json()) as { inserters: InserterId[] };
    return inserters || [];
  });

  @use pinnedComposerSendCloseButton = AsyncData<boolean>(async () => {
    let response = await request(
      `/ember/admins/visible_composer_send_close.json?app_id=${this.id}`,
    );
    let { send_close_pinned } = (await response.json()) as { send_close_pinned: boolean };
    return send_close_pinned;
  });

  @use ticketTypes = AsyncData<TicketType[]>(async () => {
    return await this.fetchTicketTypes();
  });

  // Prefer the use of the ticketTypes resource, but there are lots of instances of
  // places that expect a promise & load ticket types in a task
  async fetchTicketTypes() {
    if (this.ticketTypesPromise) {
      return this.ticketTypesPromise;
    }

    return (this.ticketTypesPromise = (async () => {
      let response = await request(`/ember/inbox/ticket_types?app_id=${this.id}`);
      let json = (await response.json()) as TicketTypeWireFormat[];
      let ticketTypes = json.map((requestType) => TicketType.deserialize(requestType));
      return ticketTypes;
    })());
  }

  async reloadAppSettings() {
    // this is a hack to force the appSettingsLoader to reload
    this.appSettingsVersion++;
    return this.appSettingsLoader.value;
  }
}
