/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { tracked } from '@glimmer/tracking';
import type ResponseField from './response-field';

export default class ValueTransformation extends Fragment {
  @attr('string') declare responseValue: string;
  @attr('string') declare transformedValue: string;

  @tracked declare responseField: ResponseField;
}
