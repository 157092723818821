/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { alias, equal, not, notEmpty, or, union, readOnly } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from 'ember-cp-validations';
import { isValidEmail } from 'embercom/lib/email';
import { isValidUrlWithProtocol } from 'embercom/lib/url-validator';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import {
  getPricingModelData,
  getPricingModelValues,
} from 'embercom/lib/developer-hub/pricing-models';

const Validations = buildValidations(
  {
    name: [
      validator('presence', true),
      validator('length', { max: 40 }),
      validator('text-does-not-contain-intercom'),
    ],
    nameWords: validator('length', { message: 'Must not be more than 4 words', min: 1, max: 4 }),
    basicInfoDescription: [validator('length', { max: 70 })],

    iconUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', { presence: true, message: 'Please upload an icon' }),
        validator('length', { min: 1, message: 'Please upload an icon' }),
      ],
    },
    description: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', true),
        validator('length', { max: 70 }),
        validator('text-does-not-end-with-punctuation'),
      ],
    },
    builtBy: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [validator('presence', true), validator('length', { max: 30 })],
    },
    installUrl: {
      disabled: or(
        'model.isNotDirectInstallation',
        'model.validationsDisabled',
        'model.developerHub.isOnBasicInfo',
        'model.developerHub.isOnAppStoreListing',
      ),
      validators: [
        validator('presence', true),
        validator('format', { type: 'url' }),
        validator('https-except-in-development'),
      ],
    },
    uninstallUrl: {
      disabled: or(
        'model.validationsDisabled',
        'model.developerHub.isOnBasicInfo',
        'model.developerHub.isOnAppStoreListing',
      ),
      validators: [
        validator('format', { type: 'url', allowBlank: true }),
        validator('https-except-in-development', { allowBlank: true }),
      ],
    },
    healthCheckUrl: {
      disabled: or(
        'model.validationsDisabled',
        'model.developerHub.isOnBasicInfo',
        'model.developerHub.isOnAppStoreListing',
      ),
      validators: [
        validator('format', { type: 'url', allowBlank: true }),
        validator('https-except-in-development', { allowBlank: true }),
      ],
    },
    termsOfServiceUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', true),
        validator('format', { type: 'url' }),
        validator('format', { regex: /^https?:\/\//i, message: 'Must use HTTP or HTTPS' }),
      ],
    },
    privacyPolicyUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', true),
        validator('format', { type: 'url' }),
        validator('format', { regex: /^https?:\/\//i, message: 'Must use HTTP or HTTPS' }),
      ],
    },
    pricingModel: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', true),
        validator('inclusion', {
          in: getPricingModelValues(),
          message: 'Please select an option',
        }),
      ],
    },
    pricingPageUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('inline', {
          dependentKeys: ['model.pricingModel'],
          validate(value, options, model) {
            let pricingModel = getPricingModelData(model.pricingModel);
            if (pricingModel && !pricingModel.pricingPageRequired) {
              return true;
            } else if (
              isPresent(value) &&
              isValidUrlWithProtocol(value) &&
              value.match(/^https?:\/\//i)
            ) {
              return true;
            }

            return 'This field must be a valid HTTP or HTTPS url';
          },
        }),
      ],
    },
    supportEmail: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('inline', {
          validate(value) {
            if (isPresent(value) && isValidEmail(value)) {
              return true;
            }
            return 'Must be a valid email address';
          },
        }),
      ],
    },
    documentationUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [validator('format', { type: 'url', allowBlank: true })],
    },
    developerHomepageUrl: {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('presence', true),
        validator('format', { type: 'url' }),
        validator('format', { regex: /^https?:\/\//i, message: 'Must use HTTP or HTTPS' }),
      ],
    },
    'appStoreListing.longDescription.firstObject.text': {
      disabled: computed(
        'model.validationsDisabled',
        'model.appStoreListing.longDescription.length',
        function () {
          if (
            this.get('model.validationsDisabled') ||
            this.get('model.developerHub.isOnBasicInfo')
          ) {
            return true;
          }

          return this.get('model.appStoreListing.longDescription.length') > 1;
        },
      ),
      validators: [validator('presence', true), validator('length', { min: 1 })],
    },
    'appStoreListing.videoId': {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('video-details', {
          dependentKeys: ['model.appStoreListing.videoId', 'model.appStoreListing.videoProvider'],
        }),
      ],
    },
    'appStoreListing.imageUrls.length': {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [
        validator('number', {
          gte: 1,
          lte: 10,
          message: 'Please upload at least one image, and at most 10 images',
        }),
      ],
    },
    'appStoreListing.categories.length': {
      disabled: or('model.validationsDisabled', 'model.developerHub.isOnBasicInfo'),
      validators: [validator('number', { gt: 0, message: 'Please select at least one category' })],
    },
    'appStoreListing.learnMoreUrl': {
      disabled: or(
        'model.isDirectInstallation',
        'model.validationsDisabled',
        'model.developerHub.isOnBasicInfo',
        'model.developerHub.isOnAppStoreListing',
      ),
      validators: [
        validator('presence', true),
        validator('format', { type: 'url' }),
        validator('format', { regex: /^https:\/\//, message: 'Must use HTTPS' }),
      ],
    },
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  developerHub: service(),
  appService: service(),

  hasLearnMoreUrl: notEmpty('appStoreListing.learnMoreUrl'),
  installationMode: ternary('hasLearnMoreUrl', 'website', 'direct'),
  isDirectInstallation: equal('installationMode', 'direct'),
  isNotDirectInstallation: not('isDirectInstallation'),

  name: attr('string'),
  iconUrl: attr('string'),
  installUrl: attr('string'),
  uninstallUrl: attr('string'),
  healthCheckUrl: attr('string'),
  termsOfServiceUrl: attr('string'),
  supportEmail: attr('string'),
  documentationUrl: attr('string'),
  avatarWorkspaceIdCode: attr('string'),
  developerHomepageUrl: attr('string'),
  privacyPolicyUrl: attr('string'),
  pricingModel: attr('string'),
  pricingPageUrl: attr('string'),
  description: attr('string'),
  builtBy: attr('string'),
  state: attr('string'),
  ownerAppId: attr('string'),
  appStoreListing: fragment('developer-hub/app-store-listing'),
  startingGuide: fragment('developer-hub/starting-guide'),
  messengerApp: fragment('developer-hub/messenger-app'),
  inboxApp: fragment('developer-hub/inbox-app'),
  oauthClient: fragment('developer-hub/oauth-client'),

  basicInfoDescription: alias('description'),

  nameWords: computed('name', function () {
    let name = this.name || '';
    return name.split(/\s+/);
  }),

  validationsDisabled: true,

  nameErrors: union('validations.attrs.name.errors', 'validations.attrs.nameWords.errors'),
  basicInfoDescriptionErrors: readOnly('validations.attrs.basicInfoDescription.errors'),
  appIconErrors: readOnly('validations.attrs.iconUrl.errors'),
  descriptionErrors: readOnly('validations.attrs.description.errors'),
  builtByErrors: readOnly('validations.attrs.builtBy.errors'),
  installUrlErrors: readOnly('validations.attrs.installUrl.errors'),
  uninstallUrlErrors: readOnly('validations.attrs.uninstallUrl.errors'),
  healthCheckUrlErrors: readOnly('validations.attrs.healthCheckUrl.errors'),
  longDescriptionErrors: readOnly(
    'validations.attrs.appStoreListing.longDescription.firstObject.text.errors',
  ),
  imageUrlsErrors: readOnly('validations.attrs.appStoreListing.imageUrls.length.errors'),
  videoErrors: readOnly('validations.attrs.appStoreListing.videoId.errors'),
  categoriesErrors: readOnly('validations.attrs.appStoreListing.categories.length.errors'),
  learnMoreUrlErrors: readOnly('validations.attrs.appStoreListing.learnMoreUrl.errors'),
  termsOfServiceUrlErrors: readOnly('validations.attrs.termsOfServiceUrl.errors'),
  privacyPolicyUrlErrors: readOnly('validations.attrs.privacyPolicyUrl.errors'),
  pricingModelErrors: readOnly('validations.attrs.pricingModel.errors'),
  pricingPageUrlErrors: readOnly('validations.attrs.pricingPageUrl.errors'),
  supportEmailErrors: readOnly('validations.attrs.supportEmail.errors'),
  documentationUrlErrors: readOnly('validations.attrs.documentationUrl.errors'),
  avatarWorkspaceErrors: readOnly('validations.attrs.avatarWorkspaceIdCode.errors'),
  developerHomepageUrlErrors: readOnly('validations.attrs.developerHomepageUrl.errors'),

  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
});
