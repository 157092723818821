/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Model.extend({
  intl: service(),
  get addressSource() {
    return {
      manually_added: this.intl.t('app.models.company-email-address.manually-added'),
      forwarding_address: this.intl.t('app.models.company-email-address.forwarding'),
      automatically_added: this.intl.t('app.models.company-email-address.automatically'),
      forwarding_email_processor: this.intl.t('app.models.company-email-address.automatically'),
    };
  },
  app_id: attr(),
  email_address: attr(),
  source: attr(),
  address_source: computed('addressSource', 'source', function () {
    return this.addressSource[this.source];
  }),
});
