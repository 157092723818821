/* RESPONSIBLE TEAM: team-data-foundations */
import Model, { belongsTo, attr } from '@ember-data/model';

export default class TagStats extends Model {
  @belongsTo('tag') declare tag: any;

  @attr('number', { defaultValue: 0 }) declare userCount: number;
  @attr('number', { defaultValue: 0 }) declare companyCount: number;
  @attr('number', { defaultValue: 0 }) declare messageCount: number;
  @attr('number', { defaultValue: 0 }) declare conversationCount: number;
  @attr('number', { defaultValue: 0 }) declare articleCount: number;
  @attr('number', { defaultValue: 0 }) declare answerCount: number;
}
