/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type FinAnswerType, type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type BlockList } from '@intercom/interblocks.ts';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import { tracked } from '@glimmer/tracking';
import { Channel } from 'embercom/models/data/inbox/channels';
import {
  EmailMetadata,
  type EmailMetadataWireFormat,
} from 'embercom/objects/inbox/renderable/email-metadata';
import SendState, { type SendStateWireFormat } from 'embercom/objects/inbox/send-state';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';

export interface FinAnswerSourceWireFormat {
  entity_id: number;
  entity_type: number;
  title: string;
  url: string;
  id: number;
}
export interface FinAnswerWireFormat {
  renderable_type: RenderableType;
  blocks: BlockList;
  entity: AdminSummaryWireFormat;
  fin_answer_type?: FinAnswerType;
  issue_summary?: string;
  sources?: Array<FinAnswerSourceWireFormat>;
  private_sources?: Array<FinAnswerSourceWireFormat>;
  readonly channel?: Channel;
  send_state?: SendStateWireFormat;
  email_metadata?: EmailMetadataWireFormat;
  tags: Array<TagWireFormat>;
}

export interface FinAnswerSource {
  entityId: number;
  entityType: number;
  title: string;
  id: number;
  url?: string;
}

export default class FinAnswer implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.FinAnswer;
  readonly blocks: BlockList;
  readonly entity: AdminSummary;
  readonly finAnswerType?: FinAnswerType;
  readonly issueSummary?: string;
  readonly sources?: Array<FinAnswerSource>;
  readonly privateSources?: Array<FinAnswerSource>;
  readonly channel?: Channel;

  @tracked sendState?: SendState;
  @tracked emailMetadata?: EmailMetadata;
  @tracked tags: Array<Tag>;

  get creatingEntity() {
    return {
      type: EntityType.Bot,
      id: this.entity.id,
      object: this.entity,
    };
  }

  get styles() {
    if (this.hasError) {
      return {
        backgroundColor: 'bg-beta-container opacity-50',
        textColor: 'text-muted',
      };
    }
    if (this.channel === Channel.Email) {
      return {
        backgroundColor: 'bg-beta-container',
        textColor: 'text-default',
        backgroundColorHover: 'bg-beta-container-emphasis',
      };
    }
    return {
      backgroundColor: 'bg-beta-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-transparent',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container',
    };
  }

  get hasError() {
    return this.sendState?.sendState === 0;
  }

  updateSendState(newSendState: SendState) {
    this.sendState = newSendState;
  }

  constructor(params: {
    blocks: BlockList;
    entity: AdminSummary;
    issueSummary?: string;
    finAnswerType?: FinAnswerType;
    sources?: Array<FinAnswerSource>;
    privateSources?: Array<FinAnswerSource>;
    channel?: Channel;
    emailMetadata?: EmailMetadata;
    sendState?: SendState;
    tags?: Array<Tag>;
  }) {
    this.blocks = params.blocks;
    this.entity = params.entity;
    this.finAnswerType = params.finAnswerType;
    this.issueSummary = params.issueSummary;
    this.sources = params.sources;
    this.privateSources = params.privateSources;
    this.channel = params.channel;
    this.emailMetadata = params.emailMetadata;
    this.sendState = params.sendState;
    this.tags = params.tags || [];
  }

  static deserialize(json: FinAnswerWireFormat): FinAnswer {
    let deserializeSource = (source: FinAnswerSourceWireFormat) => {
      return {
        entityId: source.entity_id,
        entityType: source.entity_type,
        title: source.title,
        url: source.url,
        id: source.id,
      };
    };
    let emailMetadata = json.email_metadata
      ? EmailMetadata.deserialize(json.email_metadata)
      : undefined;
    return new FinAnswer({
      blocks: json.blocks,
      entity: AdminSummary.deserialize(json.entity),
      finAnswerType: json.fin_answer_type,
      issueSummary: json.issue_summary,
      sources: json.sources?.map(deserializeSource),
      privateSources: json.private_sources?.map(deserializeSource),
      channel: json.channel,
      emailMetadata,
      sendState: json.send_state && SendState.deserialize(json.send_state),
      tags: json.tags && json.tags.map(Tag.deserialize),
    });
  }
}
