/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { not } from '@ember/object/computed';

export default Fragment.extend({
  stripeAccountId: attr('string'),
  stripeAccountName: attr('string'),
  primaryAccount: attr('boolean'),
  secondaryAccount: not('primaryAccount'),
  subscriptionsEnabled: attr('boolean'),
  syncEnabled: attr('boolean'),
  stripeWriteAuthorised: attr('boolean'),
  stripeImportFailed: attr('boolean'),
  createdAt: attr('date'),
  revoked: attr('boolean'),
});
