/* RESPONSIBLE TEAM: team-workflows */
import { fragment } from 'ember-data-model-fragments/attributes';
import Step from 'embercom/models/operator/visual-builder/step';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  action: [
    validator('presence', true),
    validator('rule-action-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
});

export default class SetConversationDataAttribute extends Step.extend(Validations) {
  @fragment('rules/rule-action') declare action: any;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.setConversationDataAttribute;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'conversation_attributes';
  }

  static createNewStep(
    store: Store,
    params: { attributeIdentifier: string },
  ): SetConversationDataAttribute {
    return store.createRecord('operator/visual-builder/step/set-conversation-data-attribute', {
      type: 'operator/visual-builder/step/set-conversation-data-attribute',
      action: store.createFragment('rules/rule-action', {
        type: 'set-conversation-data-attribute',
        justAdded: true,
        actionData: {
          attribute_identifier: params.attributeIdentifier,
        },
      }),
    });
  }
}
