/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  title: attr('string'),
  url: attr('string'),
  userId: attr('string'),
  userEmail: attr('string'),
  profileType: attr('string'),
  ownerName: attr('string'),
  ownerRole: attr('string'),
  ownerEmail: attr('string'),
  objectType: attr('string'),
  status: attr('string'),
  recordCount: attr('string'),
  reasons: attr('array'),
  errors: attr('array'),
  attributes: attr(),

  // Marketo Attributes
  leadScore: attr('string'),
  leadStatus: attr('string'),

  // Pipedrive Attributes
  conversationId: attr('string'),
  dealTitle: attr('string'),
  dealStatus: attr('string'),
  dealCount: attr('string'),
  stageName: attr('string'),
});
