/* RESPONSIBLE TEAM: team-workflows */
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import StepConfig, { type StepConfigParams } from '../step';
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';

export default class FinConfiguration extends StepConfig {
  declare step: Fin;
  appService: any;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/fin',
      ...params,
    });

    this.appService = containerLookup('service:appService');
  }

  get showChannelTypeHeaders(): boolean {
    return true;
  }

  get showFinPreHandoverSection(): boolean {
    return true;
  }

  get showFinCsatSection(): boolean {
    return true;
  }

  get showFinAutoCloseSection(): boolean {
    return true;
  }
}

export class StandaloneMessagingFinStepConfig extends FinConfiguration {
  get showChannelTypeHeaders(): boolean {
    return false;
  }

  get showFinCsatSection(): boolean {
    return this.appService.app.canUseStandaloneZendeskCSAT;
  }

  get showFinAutoCloseSection(): boolean {
    return false;
  }
}

export class StandaloneTicketFinStepConfig extends FinConfiguration {
  get showChannelTypeHeaders(): boolean {
    return false;
  }

  get showFinPreHandoverSection(): boolean {
    return false;
  }

  get showFinCsatSection(): boolean {
    return false;
  }

  get showFinAutoCloseSection(): boolean {
    return false;
  }
}
