/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Import from './import';
import { computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';

const MAX_ERROR_COUNT_PER_COLUMN = 10;

export const importTypes = {
  default: 0,
  tagOnly: 1,
};

export const maxFileSizes = {
  default: 20,
  tagOnly: 45,
};

export const recordTypes = {
  users: 'users',
  leads: 'leads',
  usersAndLeads: 'users_and_leads',
};

export default Import.extend({
  filename: attr(),
  recordType: attr(),
  importType: attr('number'),
  previewColumns: attr(),
  rowCount: attr(),
  columnsExceedingMaxLength: attr(),
  hasHeader: attr(),
  columnAssignments: attr(),

  failed: equal('importStatus', 'Failed'),
  errorCounts: readOnly('importSummary.error_counts'),
  isLeads: equal('recordType', 'leads'),
  columnsWithTooManyFailingRows: computed('intl.locale', 'errorCounts', function () {
    let errorCounts = this.errorCounts;
    let columnsWithTooManyFailingRows = [];
    Object.keys(errorCounts).forEach((columnName) => {
      let columnErrors = errorCounts[columnName];
      Object.values(columnErrors).forEach((errorCount) => {
        if (errorCount > MAX_ERROR_COUNT_PER_COLUMN) {
          let notification = this.intl.t('importers.import-summary-component.more-errors-message', {
            columnName,
          });
          columnsWithTooManyFailingRows.push(notification);
        }
      });
    });
    return columnsWithTooManyFailingRows;
  }),
  remainingErrorCount: computed('errorCounts', function () {
    let errorCounts = this.errorCounts;
    let result = 0;
    Object.values(errorCounts).forEach((columnErrors) => {
      Object.values(columnErrors).forEach((errorCount) => {
        errorCount = parseInt(errorCount, 10);
        if (errorCount > MAX_ERROR_COUNT_PER_COLUMN) {
          result += errorCount - MAX_ERROR_COUNT_PER_COLUMN;
        }
      });
    });
    return result;
  }),
  isTagOnly: equal('importType', importTypes.tagOnly),
  createColumnAssignments(columnAssignments) {
    columnAssignments.app_id = this.appId;
    return ajax({
      url: `/ember/csv_imports/${this.id}/column_assignments.json`,
      method: 'POST',
      data: JSON.stringify(columnAssignments),
      contentType: 'application/json',
    });
  },
  createUserCreateJob() {
    return ajax({
      url: `/ember/csv_imports/${this.id}/user_create_job`,
      method: 'POST',
      data: JSON.stringify({ app_id: this.appId }),
      contentType: 'application/json',
    });
  },
  enqueueUserSearch() {
    return ajax({
      url: `/ember/fixed_list_messaging/csv_imports/${this.id}/enqueue_search`,
      method: 'POST',
      data: JSON.stringify({ app_id: this.appId }),
      contentType: 'application/json',
    });
  },
  updateTag() {
    return ajax({
      url: `/ember/fixed_list_messaging/csv_imports/${this.id}/update_tag`,
      method: 'PUT',
      data: JSON.stringify({ app_id: this.appId, manual_tag_id: this.manualTagId }),
      contentType: 'application/json',
    });
  },
});
