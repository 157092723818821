/* RESPONSIBLE TEAM: team-frontend-tech */
/*
 * Nolaneo – ResizeObserver is supported by Chrome, Firefox, and Safari 13.1+.
 * This represents the vast majority of our userbase so I am chosing not to add the polyfill.
 * If you have a usecase where Teammates cannot use the native browser implementation of this feature,
 * please update this modifier with the polyfill from here https://github.com/juggle/resize-observer
 */

import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { debounce } from '@ember/runloop';

export default class OnElementResize extends Modifier {
  resizeObserver;
  animationFrameId;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, () => this.removeResizeObserver());
  }

  modify(element, [handler], { debounce = false, debounceDuration }) {
    this.removeResizeObserver();

    this.addResizeObserver(element, handler, debounce, debounceDuration);
  }

  addResizeObserver(element, handler, shouldDebounce, debounceDuration = 100) {
    if (!window.ResizeObserver) {
      return console.warn(`ResizeObserver is not supported by this browser`);
    }

    this.fn = () => handler(element);

    this.resizeObserver = new ResizeObserver(() => {
      if (shouldDebounce) {
        debounce(this, this.fn, debounceDuration);
      } else {
        this.fn();
      }
    });

    // requestAnimationFrame is required here because browsers don't currently implement the
    // ResizeObserver spec correctly: https://github.com/hshoff/vx/pull/335
    // https://github.com/WICG/ResizeObserver/issues/38
    this.animationFrameId = window.requestAnimationFrame(() =>
      this.resizeObserver.observe(element),
    );

    if (shouldDebounce) {
      this.fn();
    }
  }

  removeResizeObserver() {
    if (this.resizeObserver) {
      window.cancelAnimationFrame(this.animationFrameId);
      this.resizeObserver.disconnect();
    }
  }
}
