/* RESPONSIBLE TEAM: team-standalone */
import type HandoffWithJavaScript from 'embercom/models/operator/visual-builder/step/handoff-with-javascript';
import StepConfig, { type StepConfigParams } from '../step';

export default class HandoffWithJavaScriptConfiguration extends StepConfig {
  declare step: HandoffWithJavaScript;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/handoff-with-javascript',
      ...params,
    });
  }

  get alignment() {
    return 'left' as const;
  }
}
