/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr, type SyncHasMany } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { type Block } from 'embercom/models/common/blocks/block';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

const Validations = buildValidations({
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.blank-message-error',
      messageTranslated: true,
    }),

    validator('workflow-step-blocks-audio-snippets-have-no-meaningful-content', {
      message: 'operator.workflows.visual-builder.validations.audio-files-invalid',
      messageTranslated: true,
    }),
  ],
  ttsLanguage: [validator('presence', true)],
  ttsMode: [validator('presence', true)],
});

export default class PhoneCallCsatRequest extends Step.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;
  @attr('string', { defaultValue: 'en-US' }) declare ttsLanguage: string;
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  @attr('string', { defaultValue: 'Text to Speech' }) declare ttsMode: string;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.phoneCallCsatRequest;
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): PhoneCallCsatRequest {
    return store.createRecord('operator/visual-builder/step/phone-call-csat-request', {
      type: 'operator/visual-builder/step/phone-call-csat-request',
    });
  }
}
