/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { notEmpty, or } from '@ember/object/computed';

export default Model.extend({
  tag: belongsTo('tag', { async: false }),
  admin: belongsTo('admin', { async: false }),
  tagged_at: attr(),
  workflowId: attr('number'),
  workflowName: attr('string'),
  hasAdmin: notEmpty('admin'),
  hasTaggingDate: notEmpty('tagged_at'),
  hasWorkflow: notEmpty('workflowId'),
  hasTaggingMetaData: or('hasAdmin', 'hasTaggingDate'),
});
