/* RESPONSIBLE TEAM: team-phone */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from '../renderable-data';

interface CallEventWireFormat {
  renderable_type: RenderableType;
  event_text: string;
}

export default class CallEvent implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.CallEvent;
  readonly eventText: string;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(event_text: string) {
    this.eventText = event_text;
  }

  static deserialize(json: CallEventWireFormat): CallEvent {
    return new CallEvent(json.event_text);
  }
}
