/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { filterBy, equal, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import { task } from 'ember-concurrency';
import ajax, { get } from 'embercom/lib/ajax';

let Company = Model.extend(TaggingMixin, {
  app_id: attr(),
  name: attr(),
  plan: belongsTo('external-plan'),

  remote_company_id: attr(),
  remote_created_at: attr(),
  created_at: attr(),
  updated_at: attr(),
  industry: attr(),
  last_request_at: attr(),
  monthly_spend: attr('number'),
  session_count: attr('number'),
  size: attr('number'),
  user_count: attr('number'),
  company_count: attr('number'),
  company_url: attr(),

  tags: hasMany('tag'),
  taggings: hasMany('tagging'),
  segment_ids: attr('array', { defaultValue: () => [] }),
  segments: computed('segment_ids.[]', function () {
    let store = getEmberDataStore();
    return this.segment_ids
      .map((id) => store.peekRecord('segment', id))
      .filter((segment) => segment);
  }),
  loadSegments: task(function* () {
    try {
      let response = yield get('/ember/companies/segments.json', {
        app_id: this.app_id,
        id: this.id,
      });
      this.set('segment_ids', response.segment_ids);
    } catch (e) {
      console.error(e);
      this.set('segment_ids', []);
    }
  }).drop(),
  notes: hasMany('note'),
  custom_data: attr({
    defaultValue() {
      return {};
    },
  }),
  creation_source: attr('string', { defaultValue: 'api' }),
  metadata: attr(),
  website: attr(),
  avatar_color: attr(),
  isCompany: true,
  isExpanded: false,
  displayAs: ternaryToProperty('name', 'name', 'id'),

  getValue(identifier) {
    identifier = identifier.replace(/^company\./, '');
    return this.get(identifier);
  },

  existingTags: filterBy('tags', 'isLoaded'),
  updateTaggingUrl: '/ember/companies/update_tags.json',

  avatarData: computed('avatar_color', function () {
    return {
      isTeammate: false,
      isCustomer: false,
      isCompany: true,
      color: this.avatar_color,
    };
  }),

  manuallyCreated: equal('creation_source', 'manual_edit'),
  notManuallyCreated: not('manuallyCreated'),
}).reopenClass({
  search(app, predicates, options) {
    let self = this;
    let sort_by;
    let sort_direction;

    if (this.xhr && this.xhr.readyState !== 4) {
      //we don't want concurrent requests
      this.xhr.abort();
    }

    let sortedAttributeSetting = app.get('companyAttributeSettings').findBy('isSorted', true);
    if (sortedAttributeSetting) {
      sort_by = sortedAttributeSetting.get('attribute');
      sort_direction = sortedAttributeSetting.get('sort_by');
    }

    return ajax({
      beforeSend(xhr) {
        self.xhr = xhr;
      },
      url: '/ember/companies/search.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: app.get('id'),
        predicates,
        page: options.page || 1,
        per_page: options.per_page || 30,
        sort_by,
        include_count: true,
        segment_id: options.segmentId,
        sort_direction,
      }),
    }).then(function (response) {
      let companyObjects = [];
      if (response.companies) {
        let store = getEmberDataStore();
        store.pushPayload({ companies: response.companies });

        response.companies.forEach(function (company) {
          companyObjects.pushObject(store.peekRecord('company', company.id));
        });
      }

      return {
        companies: companyObjects,
        totalCount: response.total_count || 0,
        totalPages: response.total_pages,
      };
    });
  },
});

export default Company;
