/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import Admin from 'embercom/models/admin';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { tracked } from '@glimmer/tracking';
import type ActionResponse from './action-response';
import type ApiMappableObject from './api-mappable-object';

export default class TestResult extends Fragment {
  @attr() declare testValues: any;
  @attr('number') declare adminId: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @fragment('workflow-connector/action-response') declare actionResponse: ActionResponse;
  @fragmentArray('workflow-connector/api-mappable-object')
  declare apiMappableObjects: ApiMappableObject[];

  @tracked testedAction: any;

  get admin() {
    return Admin.peekAndMaybeLoad(this.store, this.adminId?.toString());
  }
}
