/* RESPONSIBLE TEAM: team-standalone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { STANDALONE_INTERCOM_MESSENGER_CHANNEL_ONLY } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export default class HandoffWithJavaScript extends Step {
  get supportedChannels() {
    return STANDALONE_INTERCOM_MESSENGER_CHANNEL_ONLY;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): HandoffWithJavaScript {
    return store.createRecord('operator/visual-builder/step/handoff-with-javascript', {
      type: 'operator/visual-builder/step/handoff-with-javascript',
    });
  }
}
