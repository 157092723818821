/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import EmberObject, { computed } from '@ember/object';
import pluralise from 'embercom/lib/inflector';
import { readOnly } from '@ember/object/computed';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { inject as service } from '@ember/service';

export default Model.extend({
  intl: service(),

  appId: attr(),
  completedImportAt: attr(),
  createdAt: attr(),
  manualTagName: attr(),
  manualTagId: attr(),
  importedRowFailureCount: attr(),
  importSummary: attr(),
  importStatus: attr(),
  importProgress: attr(),

  translatedImportStatus: computed('intl.locale', 'importStatus', function () {
    let translatedStatus = this.importStatus;
    switch (this.importStatus) {
      case 'Complete':
        translatedStatus = this.intl.t('settings.import-table-component.complete');
        break;
      case 'In Progress':
        translatedStatus = this.intl.t('settings.import-table-component.in-progress');
        break;
      case 'Failed':
        translatedStatus = this.intl.t('settings.import-table-component.failed');
        break;
      case 'Not Started':
        translatedStatus = this.intl.t('settings.import-table-component.not-started');
        break;
    }
    return translatedStatus;
  }),

  errorReport: readOnly('importSummary.error_report'),
  pluralisedError: computed('importedRowFailureCount', function () {
    return pluralise('error', this.importedRowFailureCount);
  }),
  failedRecords: computed('intl.locale', 'errorReport', function () {
    return this.errorReport.reduce((memo, record) => {
      let failedRecord = EmberObject.create(record);
      let errorMessage = failedRecord.get('error_message');

      switch (errorMessage) {
        case 'exceeds_max_length':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.exceeds-max-length.error'),
            this.intl.t('importers.import-summary-component.exceeds-max-length.tooltip-message'),
          );
          break;
        case 'multiple_matches':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.multiple-matches.error'),
            this.intl.t('importers.import-summary-component.multiple-matches.tooltip-message'),
          );
          break;
        case 'duplicate_csv_rows_detected':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.duplicate-csv-rows-detected.error'),
            this.intl.t(
              'importers.import-summary-component.duplicate-csv-rows-detected.tooltip-message',
            ),
          );
          break;
        case 'invalid_email':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.invalid-email.error'),
            this.intl.t('importers.import-summary-component.invalid-email.tooltip-message'),
          );
          break;
        case 'missing_email':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.missing-email.error'),
            this.intl.t('importers.import-summary-component.missing-email.tooltip-message'),
          );
          break;
        case 'mismatched_data_type':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.mismatched-data-type.error'),
            this.intl.t('importers.import-summary-component.mismatched-data-type.tooltip-message'),
          );
          break;
        case 'invalid_custom_data_number':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.invalid-custom-data-number.error'),
            this.intl.t(
              'importers.import-summary-component.invalid-custom-data-number.tooltip-message',
            ),
          );
          break;
        case 'custom_attributes_limit_exceeded':
          this._setFailedRecord(
            failedRecord,
            this.intl.t(
              'importers.import-summary-component.custom-attributes-limit-exceeded.error',
            ),
            this.intl.t(
              'importers.import-summary-component.custom-attributes-limit-exceeded.tooltip-message',
            ),
          );
          break;
        case 'not_restorable':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.not-restorable.error'),
            this.intl.t('importers.import-summary-component.not-restorable.tooltip-message'),
          );
          break;
        case 'unrestorable_duplicate_user':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.unrestorable-duplicate-user.error'),
            this.intl.t(
              'importers.import-summary-component.unrestorable-duplicate-user.tooltip-message',
            ),
          );
          break;
        case 'user_not_found':
        case 'not_unique_error':
          this._setFailedRecord(
            failedRecord,
            this.intl.t('importers.import-summary-component.not-unique-error.error'),
            this.intl.t('importers.import-summary-component.not-unique-error.tooltip-message'),
          );
          break;
        default:
          if (errorMessage.toLowerCase().indexOf('phone') >= 0) {
            this._setFailedRecord(
              failedRecord,
              this.intl.t('importers.import-summary-component.invalid-phone-number'),
              errorMessage,
            );
          } else {
            this._setFailedRecord(failedRecord, errorMessage, '');
          }
      }

      memo.push(failedRecord);
      return memo;
    }, []);
  }),

  _setFailedRecord(failedRecord, displayError, tooltipError) {
    failedRecord.set('display_error_message', displayError);
    failedRecord.set('tooltip_error_message', tooltipError);
  },
  isLeads: false,
  userListUrl: computed('appId', 'manualTagId', 'isLeads', function () {
    if (!this.appId || !this.manualTagId) {
      return null;
    }
    let dummyUser = {
      isCompany: false,
      is_anonymous: this.isLeads,
      app_id: this.appId,
    };

    let predicate = {
      comparison: 'eq',
      type: 'manual_tag',
      attribute: 'manual_tag_ids',
      value: this.manualTagId.toString(),
    };
    return B64FilterParamEncoder.filterUrlFromPredicates(dummyUser, {
      predicates: [predicate],
    });
  }),
});
