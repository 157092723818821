/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export default class SendToHubspot extends Step {
  get supportedChannels() {
    return SUPPORTED_CHANNELS.sendToHubspot;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): SendToHubspot {
    return store.createRecord('operator/visual-builder/step/send-to-hubspot', {
      type: 'operator/visual-builder/step/send-to-hubspot',
    });
  }
}
