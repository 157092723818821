/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { post, get } from 'embercom/lib/ajax';

import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { ConfigurationStatus } from 'embercom/models/standalone/zendesk-configuration';
import { action } from '@ember/object';

const API_ROOT = '/ember/standalone/salesforce_configurations';

export default class SalesforceConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare instanceUrl: string;
  @attr('string') declare clientId: string;
  @attr('string') declare clientSecret: string;
  @attr('boolean') declare hasClientSecret: boolean;
  @attr('boolean') declare isSandbox: boolean;
  @attr('number') declare workflowRulesetId: number;
  @attr('number', { defaultValue: 0 }) declare contentPlaceholderCount: number;

  @attr('string')
  declare finSalesforceUserId: string | undefined;

  @attr('string')
  declare finSalesforceHandoverId: string | undefined;

  @attr('number', { defaultValue: ConfigurationStatus.Pending })
  declare status: ConfigurationStatus;

  get isPendingSetup() {
    return this.status === ConfigurationStatus.Pending;
  }

  get isConnected() {
    return !this.isPendingSetup;
  }

  get isEnabled() {
    return this.status === ConfigurationStatus.Enabled;
  }

  get readyToSetLive() {
    return this.isConnected && this.finSalesforceUserId && this.finSalesforceHandoverId;
  }

  @action async getUsageCounts(): Promise<{
    involvement_count: number;
    last_active?: string;
  }> {
    return get(`${API_ROOT}/${this.id}/usage_counts`);
  }

  async setFinUser(finSalesforceUserId: string) {
    await post(`${API_ROOT}/${this.id}/set_fin_salesforce_user`, {
      fin_salesforce_user_id: finSalesforceUserId,
    });

    this.finSalesforceUserId = finSalesforceUserId;
  }

  async setHandoverId(finSalesforceHandoverId: string) {
    await post(`${API_ROOT}/${this.id}/set_fin_salesforce_handover_id`, {
      fin_salesforce_handover_id: finSalesforceHandoverId,
    });

    this.finSalesforceHandoverId = finSalesforceHandoverId;
  }

  async activateForCases() {
    await post(`${API_ROOT}/${this.id}/activate`);
    await this.reload();
  }

  async deactivateForCases() {
    await post(`${API_ROOT}/${this.id}/deactivate`);
    await this.reload();
  }

  async disconnectApp() {
    await post(`${API_ROOT}/${this.id}/disconnect`);
    this.instanceUrl = '';
    this.clientId = '';
    this.clientSecret = '';
    this.status = ConfigurationStatus.Pending;
    this.hasClientSecret = false;
  }
}
