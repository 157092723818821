/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type TicketStateUpdatedByAdminWireFormat } from 'embercom/objects/inbox/ticket-state-summary';

export default class TicketStateUpdatedByAdmin implements RenderableData {
  renderableType: RenderableType = RenderableType.TicketStateUpdatedByAdmin;
  readonly adminSummary: AdminSummary;
  readonly ticketState: string;
  readonly adminOnly: boolean;
  readonly ticketId?: number;
  readonly ticketTypeId?: number;
  readonly isShared?: boolean;
  readonly id?: number;
  readonly ticketTitle?: string;
  readonly adminLabel?: string;
  readonly previousTicketCustomStateId?: number;
  readonly ticketCustomStateId?: number;
  readonly previousTicketState?: string;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    adminSummary: AdminSummary,
    ticketState: string,
    adminOnly = true,
    ticketId?: number,
    ticketTypeId?: number,
    isShared?: boolean,
    id?: number,
    ticketTitle?: string,
    adminLabel?: string,
    previousTicketCustomStateId?: number,
    ticketCustomStateId?: number,
    previousTicketState?: string,
  ) {
    this.adminSummary = adminSummary;
    this.ticketState = ticketState;
    this.adminOnly = adminOnly;
    this.ticketId = ticketId;
    this.ticketTypeId = ticketTypeId;
    this.isShared = isShared;
    this.id = id;
    this.ticketTitle = ticketTitle;
    this.adminLabel = adminLabel;
    this.previousTicketCustomStateId = previousTicketCustomStateId;
    this.ticketCustomStateId = ticketCustomStateId;
    this.previousTicketState = previousTicketState;
  }

  get styles() {
    return {
      backgroundColor: 'bg-beta-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  static deserialize(json: TicketStateUpdatedByAdminWireFormat): TicketStateUpdatedByAdmin {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new TicketStateUpdatedByAdmin(
      adminSummary,
      json.ticket_state,
      json?.admin_only,
      json?.ticket_id,
      json?.ticket_type_id,
      json?.is_shared,
      json?.id,
      json?.ticket_title,
      json?.admin_label,
      json?.previous_ticket_custom_state_id,
      json?.ticket_custom_state_id,
      json?.previous_ticket_state,
    );
  }
}
