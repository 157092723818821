/* RESPONSIBLE TEAM: team-data-foundations */
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';

export default function (type, rawValue) {
  if (type === 'ios_device') {
    let map = PredicateFilterMap.ios_device.data.findBy('label', rawValue);
    return map ? map.value : rawValue;
  }
  return rawValue;
}
