/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type ComposerPaneStyles, type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import {
  ChatChannel,
  ComposerPaneType,
  EmailChannel,
  ReplyChannelMap,
  SMSChannel,
  WhatsappChannel,
} from 'embercom/objects/inbox/composer-pane';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Macro from 'embercom/objects/inbox/macro';
import { type Channel } from 'embercom/models/data/inbox/channels';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type Session from 'embercom/services/session';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class ReplyPane implements ComposerPane {
  @service declare inboxState: InboxState;
  @service declare session: Session;

  readonly type: ComposerPaneType = ComposerPaneType.Reply;
  readonly replyChannel?: Channel | undefined;
  readonly hotkeyId = HotkeyID.ComposeReply;

  @tracked channel: ReplyChannel = ChatChannel;
  @tracked blocksDoc: BlocksDocument;
  @tracked macroActions: Macro['actions'] = [];

  constructor(replyChannel?: Channel) {
    this.blocksDoc = new BlocksDocument([]);
    this.replyChannel = replyChannel;
    if (this.replyChannel !== undefined) {
      this.channel = ReplyChannelMap[this.replyChannel];
    }
  }

  get styles(): ComposerPaneStyles {
    return {
      container: ' border border-neutral-border  ',
    };
  }

  get supportedChannels(): ReplyChannel[] {
    let channels = [ChatChannel, EmailChannel, WhatsappChannel];

    // only allow sms channel if the workspace has the sms add-on or is on the beta ff
    if (this.session.workspace.isFeatureEnabled('sms')) {
      channels.push(SMSChannel);
    }

    return channels;
  }

  get icon(): InterfaceIconName {
    return this.channel.icon;
  }

  get nameTranslationKey(): string {
    return this.channel.nameTranslationKey;
  }

  clear() {
    this.blocksDoc = new BlocksDocument([]);
    this.macroActions = [];
  }
}
