/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import SendState, { type SendStateWireFormat } from 'embercom/objects/inbox/send-state';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';
import type { Channel } from 'embercom/models/data/inbox/channels';
import {
  EmailMetadata,
  type EmailMetadataWireFormat,
} from 'embercom/objects/inbox/renderable/email-metadata';

interface AdminCommentWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  admin_summary: AdminSummaryWireFormat;
  tags: Array<TagWireFormat>;
  send_state?: SendStateWireFormat;
  uploads?: Array<UploadWireFormat>;
  readonly channel?: Channel;
  email_metadata?: EmailMetadataWireFormat;
}

export default class AdminComment implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.AdminComment;
  readonly blocks: Array<object>;
  readonly adminSummary: AdminSummary;
  readonly uploads?: Array<Upload>;
  readonly channel?: Channel;

  @tracked emailMetadata?: EmailMetadata;
  @tracked tags: Array<Tag>;
  @tracked sendState?: SendState;

  get teammate() {
    return this.adminSummary;
  }

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.teammate };
  }

  get styles() {
    if (this.hasError) {
      return {
        backgroundColor: 'bg-error-container opacity-50',
        textColor: 'text-muted',
      };
    } else {
      return {
        backgroundColor: 'bg-beta-container',
        textColor: 'text-default',
        backgroundColorHover: 'bg-beta-container-emphasis',
      };
    }
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-transparent',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container',
    };
  }

  get hasError() {
    return this.sendState?.sendState === 0;
  }

  updateSendState(newSendState: SendState) {
    this.sendState = newSendState;
  }

  constructor(
    blocks: Array<object>,
    adminSummary: AdminSummary,
    tags: Array<Tag>,
    sendState?: SendState,
    uploads?: Array<Upload>,
    channel?: Channel,
    emailMetadata?: EmailMetadata,
  ) {
    this.blocks = blocks;
    this.adminSummary = adminSummary;
    this.channel = channel;
    this.tags = tags || [];
    this.sendState = sendState;
    this.uploads = uploads || [];
    this.emailMetadata = emailMetadata;
  }

  static deserialize(json: AdminCommentWireFormat): AdminComment {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let emailMetadata = json.email_metadata
      ? EmailMetadata.deserialize(json.email_metadata)
      : undefined;
    return new AdminComment(
      json.blocks,
      adminSummary,
      json.tags && json.tags.map(Tag.deserialize),
      json.send_state && SendState.deserialize(json.send_state),
      json.uploads?.map(Upload.deserialize),
      json.channel,
      emailMetadata,
    );
  }
}
