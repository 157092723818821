/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import ajax from 'embercom/lib/ajax';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import moment from 'moment-timezone';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import { action } from '@ember/object';

let TaggingMixin = Mixin.create({
  updateTaggingUrl: notImplemented(),

  refreshTaggings(admin, addedTags = [], removedTags = []) {
    let taggings = this.taggings;
    taggings.removeObjects(this._taggingsForTags(removedTags));
    taggings.pushObjects(this._addedTaggings(admin, addedTags));
    return taggings;
  },

  updateTaggings(admin, addedTags = [], removedTags = [], initialTags = []) {
    let taggings = this.taggings;

    taggings.removeObjects(this._taggingsForTags(removedTags));
    taggings.pushObjects(this._addedTaggings(admin, addedTags));
    return this._saveTaggings(addedTags, removedTags, initialTags);
  },

  removeTagging: action(function (tag) {
    let addedTags = [];
    let removedTags = [tag];
    let initialTags = this.taggings.map((tagging) => tagging.tag);

    this.taggings.removeObjects(this.taggings.filter((tagging) => tagging.tag.id === tag.id));

    return this._saveTaggings(addedTags, removedTags, initialTags);
  }),

  _taggingsForTags(tags) {
    return tags.map((tag) => {
      return this.taggings.findBy('tag.id', tag.id);
    });
  },

  _addedTaggings(admin, addedTags) {
    return addedTags.map((tag) => {
      return getEmberDataStore().createRecord('tagging', {
        tag,
        admin,
        tagged_at: moment().toString(),
      });
    });
  },

  _saveTaggings(addedTags = [], removedTags = [], initialTags = []) {
    let params = {
      id: this.id,
      app_id: this.app_id,
      tag_ids: this.taggings.map((tagging) => tagging.tag.id),
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      initial_tag_ids: initialTags.map((tag) => tag.id),
    };
    return ajax({
      url: this.updateTaggingUrl,
      type: 'PUT',
      data: JSON.stringify(params),
    }).then((response) => {
      if (response.taggings) {
        let store = getEmberDataStore();
        response.taggings.forEach((taggingResponse) => {
          let existingTagging = this.taggings.findBy('tag.id', taggingResponse.tag_id.toString());
          if (existingTagging) {
            store.pushPayload({ tagging: [taggingResponse] });
            if (existingTagging.isNew) {
              let newTagging = store.peekRecord('tagging', taggingResponse.id.toString());
              let index = this.taggings.indexOf(existingTagging);
              this.taggings.replace(index, 1, [newTagging]);
              existingTagging.unloadRecord();
            }
          }
        });
      }
    });
  },
});
export default TaggingMixin;
