/* RESPONSIBLE TEAM: team-frontend-tech */

/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { tracked } from '@glimmer/tracking';
import type MutableArray from '@ember/array/mutable';
// @ts-ignore
import { cached } from 'tracked-toolbox';

import ENV from 'embercom/config/environment';
import { later } from '@ember/runloop';
import { ItemPoint } from 'embercom/components/common/tree-list/tree-item';
import { InsertionPoint } from 'embercom/components/common/tree-list/insertion-point';

export abstract class TreeParent {
  @tracked children?: MutableArray<TreeItem> = [];
  abstract isRoot: boolean;
  abstract tree: Tree;

  addChildren(children: Array<TreeItem>): void {
    if (this.children) {
      this.children.pushObjects(children);
      children.setEach('parent', this as TreeParent);
    }
  }
  addChildAtIndex(child: TreeItem, index: number): void {
    if (this.children) {
      if (index === -1) {
        this.children.pushObject(child);
      } else {
        this.children.insertAt(index, child);
      }
      child.parent = this;
    }
  }
}

export class TreeItem<T = any> extends TreeParent {
  readonly isRoot = false;

  tree: Tree;
  @tracked parent: TreeParent;
  @tracked isExpanded: boolean;
  @tracked isActive: boolean;
  canHaveChildren: boolean;
  @tracked dataObject: T;
  component: string;
  removeContentWrapperFromList?: (contentWrapperId: string) => void;

  constructor(inputs: {
    tree: Tree;
    parent: TreeParent;
    dataObject: T;
    children?: MutableArray<TreeItem>;
    isExpanded?: boolean;
    isActive?: boolean;
    canHaveChildren?: boolean;
    component: string;
    removeContentWrapperFromList?: (contentWrapperId: string) => void;
  }) {
    let {
      tree,
      parent,
      dataObject,
      children,
      isExpanded,
      isActive,
      canHaveChildren,
      component,
      removeContentWrapperFromList,
    } = inputs;
    super();
    this.tree = tree;
    this.parent = parent;
    this.children = children;
    this.isExpanded = isExpanded || false;
    this.isActive = isActive || false;
    this.canHaveChildren = canHaveChildren || false;
    this.dataObject = dataObject;
    this.component = component;
    this.removeContentWrapperFromList = removeContentWrapperFromList;
  }

  removeFromParent(): void {
    if (this.parent) {
      this.parent.children?.removeObject(this);
    }
  }

  get isBeingDragged(): boolean {
    return this.tree.draggingItem === this;
  }

  @cached
  get allChildren(): Set<TreeItem> {
    let children = new Set((this.children as Array<TreeItem>) ?? []);
    this.children?.forEach((child) => {
      let recursiveChildren = child.allChildren;
      recursiveChildren.forEach((recursiveChild) => {
        children.add(recursiveChild);
      });
    });
    return children;
  }

  get isThisOrLevelAboveDraggingItem(): boolean {
    if (this.isBeingDragged) {
      return true;
    } else if (this.depth === 0) {
      return false;
    } else {
      return (this.parent as TreeItem).isThisOrLevelAboveDraggingItem;
    }
  }

  get depth(): number {
    if (this.parent instanceof Tree) {
      return 0;
    }

    let parentItem = this.parent as TreeItem;
    return parentItem.depth + 1;
  }

  childItemForDataObject(dataObject: any): TreeItem | undefined {
    return this.children?.find((item) => item.dataObject === dataObject);
  }
}

export enum DropActionType {
  nextToItem,
  insideItem,
}

interface TreeHooksInterface {
  canDragItem?: (item: TreeItem) => boolean;
  willDropItem?: (item: TreeItem, newParent: TreeParent, dropActionType: DropActionType) => boolean;
  didDropItem?: (item: TreeItem, oldParent: TreeParent, dropActionType: DropActionType) => void;
  canDropItem?: (
    draggingItem: TreeItem,
    parent: TreeParent,
    index: number,
    dropActionType: DropActionType,
  ) => boolean;
  indexForDropInside?: (draggingItem: TreeItem, parent: TreeItem) => number;
  canSelectItem?: (item: TreeItem) => boolean;
  didSelectItem?: (item: TreeItem) => void;
}

interface TreeSettings {
  insertionPoint: {
    classes: string;
    activeClasses: string;
    highlightParent?: boolean;
    highlightClasses?: string;
    disabled?: boolean;
  };
  childList: {
    classes: string;
  };
  listItem: {
    classes: string;
    activeClasses: string;
  };
  draggableContainer: {
    classes: string;
  };
  dragProperties: {
    minDistanceInPX: number;
    onDrag?: (y: number) => void;
    shortDraggingImage?: boolean;
  };
  animationEnabled?: boolean;
}

class TreeHooks {
  externalHooks?: TreeHooksInterface;

  constructor(externalHooks?: TreeHooksInterface) {
    this.externalHooks = externalHooks;
  }

  canDragItem(item: TreeItem): boolean {
    if (this.externalHooks?.canDragItem) {
      return this.externalHooks.canDragItem(item);
    } else {
      return true;
    }
  }

  didDropItem(item: TreeItem, oldParent: TreeParent, dropActionType: DropActionType): void {
    if (this.externalHooks?.didDropItem) {
      this.externalHooks.didDropItem(item, oldParent, dropActionType);
    }
  }

  willDropItem(item: TreeItem, newParent: TreeParent, dropActionType: DropActionType): boolean {
    if (this.externalHooks?.willDropItem) {
      return this.externalHooks.willDropItem(item, newParent, dropActionType);
    }
    return true;
  }

  canDropItem(
    draggingItem: TreeItem,
    collection: TreeParent,
    index: number,
    dropActionType: DropActionType,
  ): boolean {
    if (this.externalHooks?.canDropItem) {
      return this.externalHooks.canDropItem(draggingItem, collection, index, dropActionType);
    } else {
      return true;
    }
  }

  indexForDropInside(draggingItem: TreeItem, targetItem: TreeItem): number {
    if (this.externalHooks?.indexForDropInside) {
      return this.externalHooks.indexForDropInside(draggingItem, targetItem);
    } else {
      // by default when dropping inside, insert the element at the head of the children list
      return 0;
    }
  }

  canSelectItem(item: TreeItem): boolean {
    if (this.externalHooks?.canSelectItem) {
      return this.externalHooks.canSelectItem(item);
    } else {
      return true;
    }
  }

  didSelectItem(item: TreeItem): void {
    if (this.externalHooks?.didSelectItem) {
      this.externalHooks.didSelectItem(item);
    }
  }
}

export interface ElementReference {
  element: HTMLElement;
  tree: Tree;
  parent: TreeParent;
}

interface DroppingPoint {
  parent: TreeParent;
  index: number;
  elementReference: ItemPoint | InsertionPoint;
  dropActionType: DropActionType;
}

export const DefaultTreeSettings = {
  insertionPoint: {
    classes: 'h-2 w-full rounded transition-all duration-200 ease-in-out rounded',
    activeClasses: 'bg-neutral-container-emphasis border-2 border-dotted border-neutral-border',
    highlightParent: false,
    highlightClasses: '',
    disabled: false,
  },
  childList: {
    classes: 'ml-6 border-l border-neutral-border mb-2 pl-2',
  },
  listItem: {
    classes:
      'flex flex-row items-center gap-4 rounded border-2 border-transparent mb-1 px-4 py-2 transition-colors duration-200 ease-in-out',
    activeClasses: 'bg-neutral-container-emphasis border-2 border-dotted border-neutral-border',
  },
  draggableContainer: {
    classes: 'flex flex-col gap-2',
  },
  dragProperties: {
    minDistanceInPX: 20,
  },
  animationEnabled: true,
};
export class Tree extends TreeParent {
  readonly isRoot = true;

  items: MutableArray<TreeItem>;
  hooks: TreeHooks;
  settings: TreeSettings;
  tree: Tree;

  @tracked selectedDroppingPoint?: DroppingPoint;
  @tracked draggingItem?: TreeItem;
  @tracked children: MutableArray<TreeItem> = [];
  @tracked animationDuration = 0;
  @tracked insertionPoints: MutableArray<ElementReference> = [];
  @tracked itemPoints: MutableArray<ItemPoint> = [];

  constructor(
    items: MutableArray<TreeItem> = [],
    hooks: TreeHooksInterface = {},
    settings: TreeSettings = { ...DefaultTreeSettings },
  ) {
    super();
    this.items = items;
    this.hooks = new TreeHooks(hooks);
    this.settings = settings;
    this.tree = this;
  }

  dropItemOnSelectedDroppingPoint() {
    if (
      this.selectedDroppingPoint &&
      this.draggingItem &&
      this.hooks.willDropItem(
        this.draggingItem,
        this.selectedDroppingPoint.parent,
        this.selectedDroppingPoint.dropActionType,
      )
    ) {
      this.moveDraggingItem(
        this.selectedDroppingPoint.parent,
        this.selectedDroppingPoint.index,
        this.selectedDroppingPoint.dropActionType,
      );
    }
  }

  setDroppingPoint(droppingPoint: InsertionPoint | ItemPoint) {
    let droppingElement: TreeItem | undefined = undefined;
    // to prevent dropping the dragging item inside itself
    let draggingItemDescendants = this.draggingItem?.allChildren;
    if (droppingPoint && this.draggingItem) {
      if (droppingPoint instanceof InsertionPoint && !this.tree.settings.insertionPoint.disabled) {
        let insertionPoint = droppingPoint;
        droppingElement = insertionPoint.parent as TreeItem;
        if (
          droppingElement !== this.draggingItem &&
          !draggingItemDescendants?.has(droppingElement) &&
          this.hooks.canDropItem(
            this.draggingItem,
            insertionPoint.parent,
            insertionPoint.index,
            DropActionType.nextToItem,
          )
        ) {
          this.selectedDroppingPoint = {
            parent: insertionPoint.parent,
            index: insertionPoint.index,
            elementReference: insertionPoint,
            dropActionType: DropActionType.nextToItem,
          };
        }
      } else if (droppingPoint instanceof ItemPoint) {
        droppingElement = droppingPoint.item;
        if (!droppingElement.canHaveChildren) {
          return;
        } else if (
          droppingElement !== this.draggingItem &&
          !draggingItemDescendants?.has(droppingElement)
        ) {
          let dropInsideResult = this.hooks.canDropItem(
            this.draggingItem,
            droppingElement as TreeParent,
            0,
            DropActionType.insideItem,
          );
          if (dropInsideResult) {
            this.selectedDroppingPoint = {
              parent: droppingElement as TreeParent,
              index: this.hooks.indexForDropInside(this.draggingItem, droppingElement),
              elementReference: droppingPoint,
              dropActionType: DropActionType.insideItem,
            };
          }
        }
      }
    }
  }

  moveDraggingItem(parent: TreeParent, index: number, dropActionType: DropActionType) {
    this.animationDuration = ENV.APP._200MS;
    if (this.draggingItem) {
      parent.children ??= [];
      if (this.draggingItem.parent === parent) {
        let currentIndex = parent.children.indexOf(this.draggingItem) ?? -1;
        if (currentIndex < index) {
          parent.children.removeObject(this.draggingItem);
          parent.children.insertAt(index - 1, this.draggingItem);
        } else {
          parent.children.removeObject(this.draggingItem);
          parent.children.insertAt(index, this.draggingItem);
        }
      } else {
        this.draggingItem.parent.children?.removeObject(this.draggingItem);

        parent.children.insertAt(index, this.draggingItem);
      }

      let oldParent = this.draggingItem.parent;
      this.draggingItem.parent = parent;
      this.hooks.didDropItem(this.draggingItem, oldParent, dropActionType);
      this.draggingItem = undefined;
    } else {
      console.error('moveDraggingItem called when no draggingItem is present');
    }
    later(
      this,
      () => {
        this.animationDuration = 0;
      },
      ENV.APP._200MS,
    );
  }

  childItemForDataObject(dataObject: any): TreeItem | undefined {
    return this.children.find((item) => item.dataObject === dataObject);
  }
}
