/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';
import Signal from 'embercom/models/reporting/signal';
import { inject as service } from '@ember/service';

export default class CumulativeTotalInstalls extends Model {
  @attr() signalData;

  @service appService;

  get signal() {
    let { timezone } = this.appService.app;

    return Signal.create({
      ...this.signalData,
      timezone,
    });
  }
}
