/* RESPONSIBLE TEAM: team-workflows */
import { and, not } from '@ember/object/computed';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import WorkflowViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/workflow-header-configuration';
import CustomBotPathAnalysisConfiguration from 'embercom/objects/content-editor/stats/custom-bot/path-analysis-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import CustomBotPathAnalysisTabConfiguration from 'embercom/objects/content-editor/tabs/custom-bot/path-analysis-tab-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import { CHANNEL_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';

export default function generateViewModeConfig({ container, customBot }) {
  let intl = container.lookup('service:intl');
  let contentEditorService = container.lookup('service:contentEditorService');
  let finOptInService = container.lookup('service:finOptInService');
  let finTrialService = container.lookup('service:finTrialService');

  let hasTrigger = !customBot.isNoTrigger;
  let hasTargetChannelFilterCapability = {
    component: 'content-editor/view-mode/custom-bot/triggerable-bots-stat-list',
  };

  let isCustomerFacing = contentEditorService.activeObject?.visualBuilderObject?.isCustomerFacing;

  function finOptInCheck(postAcceptCallback) {
    if (contentEditorService.activeObject?.useAiAnswers && !finOptInService.hasOptedInToFin) {
      finOptInService.postAcceptCallback = postAcceptCallback;
      finOptInService.openFinOptInModal();
    } else {
      postAcceptCallback.perform();
    }
  }

  function maybeShowTrialConfirmationModal() {
    if (contentEditorService.activeObject?.useAiAnswers) {
      finTrialService.openFinRetrialConfirmationModal();
    }
  }

  let previewConfig = !customBot.targetChannels?.includes(CHANNEL_PHONE_CALL)
    ? ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/workflows/preview-button',
        analyticEvent: {
          place: 'custom_bots',
        },
      })
    : null;

  let viewModeConfig = ViewModeConfig.create({
    headerComponentConfigs: [
      WorkflowViewModeHeaderConfiguration.create({
        container,
        preActivationCheck: finOptInCheck,
        postActivationCheck: maybeShowTrialConfirmationModal,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          duplicateModalComponent: 'custom-bots/duplicate-modal',
          hideCsvExportOption: true,
          hideTagOption: false,
          container,
        }),
        previewConfig,
        showStateChangeSummary: true,
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Workflow',
          icon: 'operator',
          view: 'content',
        }),
        ...(!contentEditorService.isOpenInFinStandalone
          ? [
              StandardStatTabConfiguration.create({
                label: intl.t('operator.custom-bot.stats.sent'),
                view: 'receipts',
                tooltip: {
                  content: intl.t('operator.custom-bot.stats.sent-tooltip'),
                },
                container,
              }),
            ]
          : []),
        ...(isCustomerFacing && !contentEditorService.isOpenInFinStandalone
          ? [
              StandardStatTabConfiguration.create({
                label: intl.t('operator.custom-bot.stats.engaged'),
                view: 'replies',
                tooltip: {
                  content: intl.t('operator.custom-bot.stats.engaged-tooltip'),
                },
                container,
              }),
            ]
          : []),
        ...(!contentEditorService.isOpenInFinStandalone
          ? [
              StandardStatTabConfiguration.create({
                label: intl.t('operator.custom-bot.stats.completed'),
                view: 'completions',
                tooltip: {
                  content: intl.t('operator.custom-bot.stats.completed-tooltip'),
                },
                container,
              }),
            ]
          : []),
        ...(hasTrigger
          ? [
              StandardStatTabConfiguration.extend({
                _isNotDraft: not('contentEditorService.ruleset.isDraft'),
                isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
              }).create({
                label: intl.t('operator.custom-bot.goal-tab.label'),
                view: 'goals',
                container,
              }),
            ]
          : []),
        CustomBotPathAnalysisTabConfiguration.create({
          label: intl.t('operator.custom-bot.path-analysis.tab-title'),
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        StandardSummaryConfiguration.create({
          component: 'content-editor/summaries/content/workflow/summary',
          title: '',
          isVisible: true,
        }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          showTargetChannelFilter: customBot.canUseOmnichannel,
          noun: intl.t('operator.custom-bot.stats.send-noun'),
          verb: intl.t('operator.custom-bot.stats.received-verb'),
          container,
          ...hasTargetChannelFilterCapability,
        }),
      ],
      replies: [
        StandardStatListConfiguration.create({
          stat: 'reply',
          hideCreatedVia: true,
          showTargetChannelFilter: customBot.canUseOmnichannel,
          noun: intl.t('operator.custom-bot.stats.engagements-noun'),
          verb: intl.t('operator.custom-bot.stats.engaged-verb'),
          container,
          ...hasTargetChannelFilterCapability,
        }),
      ],
      completions: [
        StandardStatListConfiguration.create({
          stat: 'completion',
          hideCreatedVia: true,
          showTargetChannelFilter: customBot.canUseOmnichannel,
          container,
          ...hasTargetChannelFilterCapability,
        }),
      ],
      ...(hasTrigger
        ? {
            goals: [
              StandardStatListConfiguration.create({
                filters: [
                  {
                    text: intl.t('operator.custom-bot.goal-tab.hit-after-engaging'),
                    value: stats.reply,
                  },
                  {
                    text: intl.t('operator.custom-bot.goal-tab.hit-after-completing'),
                    value: stats.completion,
                  },
                ],
                stat: 'goalSuccess',
                showTargetChannelFilter: customBot.canUseOmnichannel,
                container,
                ...hasTargetChannelFilterCapability,
              }),
            ],
          }
        : {}),
      pathAnalysis: [CustomBotPathAnalysisConfiguration.create({ container })],
    }),
    footerComponentConfigs: [],
  });
  return viewModeConfig;
}
