/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface TicketSharedWireFormat {
  renderable_type: number;
  admin_summary: AdminSummaryWireFormat;
}

export default class TicketShared implements RenderableData {
  renderableType: RenderableType = RenderableType.TicketShared;
  readonly adminSummary: AdminSummary;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(adminSummary: AdminSummary) {
    this.adminSummary = adminSummary;
  }

  get adminOnly() {
    return true;
  }

  get styles() {
    return {
      backgroundColor: 'bg-beta-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  static deserialize(json: TicketSharedWireFormat): TicketShared {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new TicketShared(adminSummary);
  }
}
