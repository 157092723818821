/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

const Validations = buildValidations({
  followUpRules: {
    validators: [
      validator('follow-up-rule-is-complete', {
        message: 'operator.workflows.visual-builder.apply-rules.rules-incomplete-error',
      }),
      validator('follow-up-rules-length', {
        message: 'operator.workflows.visual-builder.apply-rules.at-least-one-rule',
      }),
      validator('apply-rules-predicate-is-valid', {
        message: 'operator.workflows.visual-builder.apply-rules.rules-invalid-error',
      }),
    ],
  },
});

export default class ApplyRules extends Step.extend(Validations) {
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.applyRules;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): ApplyRules {
    return store.createRecord('operator/visual-builder/step/apply-rules', {
      type: 'operator/visual-builder/step/apply-rules',
      followUpRules: [],
    });
  }

  addRule() {
    let newRule = this.store.createFragment('rules/rule', { predicates: [], actions: [] });
    this.followUpRules.pushObject(newRule);
  }
}
