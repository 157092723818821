/* RESPONSIBLE TEAM: team-ai-agent */
import Model, { attr } from '@ember-data/model';
import { type State } from 'embercom/models/data/matching-system/matching-constants';
import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  prompt: [
    validator('presence', {
      presence: true,
      messageKey: 'ai-agent.guidance.validation-errors.prompt.empty',
    }),
    validator('length', { max: 2500, min: 1 }),
  ],
});

export default class Guideline extends Model.extend(Validations) {
  @attr('number') declare state: State;
  @attr('string') declare category: GuidelineCategory;
  @attr('string') declare prompt: string;

  get hasPrompt() {
    return this.prompt.trim().length > 0;
  }
}

export type GuidelineCategory = 'tone' | 'clarification' | 'routing' | 'other' | string;
