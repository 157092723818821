/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable no-restricted-imports */
import {
  NEW_SALESFORCE_CASE_TRIGGER,
  AT_CONVERSATION_START,
  TRIGGERABLE_BOT_TYPE,
  EVERYONE_PREDICATE,
  NEW_SALESFORCE_CASE_TARGET,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import NewSalesforceCaseBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/new-salesforce-case';

import CustomBotConfig from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class NewSalesforceCaseBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get rolePredicateGroup() {
    return { predicates: [EVERYONE_PREDICATE] };
  }

  get target(): BotConfigTarget {
    return NEW_SALESFORCE_CASE_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return NEW_SALESFORCE_CASE_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.new-salesforce-case.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.new-salesforce-case.description';
  }

  get icon(): InterfaceIconName {
    return 'ticket';
  }

  get EditorConfigClass() {
    return NewSalesforceCaseBotEditorConfig;
  }

  visibleToApp(app: any) {
    return app.canUseStandalone as boolean;
  }

  get selectableCustomBotType() {
    return true;
  }

  get requiredBillingFeature() {
    return 'bot_workflows';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }

  get isTitleModifiedByWorkflowsSetup(): boolean {
    return false;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION];
  }
}
