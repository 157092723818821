/* RESPONSIBLE TEAM: team-actions */
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  string: fragmentArray('crm/attribute'),
  boolean: fragmentArray('crm/attribute'),
  integer: fragmentArray('crm/attribute'),
  float: fragmentArray('crm/attribute'),
  date: fragmentArray('crm/attribute'),
});
