/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig, {
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { StandaloneMessengerConversationStartedPathConfig } from 'embercom/objects/visual-builder/configuration/path';

export default class StandaloneMessengerConversationStartedBotConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new StandaloneMessengerConversationStartedPathConfig({ path, editorState });
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
