/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { reads, readOnly } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import ArrayProxy from '@ember/array/proxy';
import { notEqualToProperty } from '@intercom/pulse/lib/computed-properties';
import ActivityFeedQuerySubscription from 'embercom/lib/users/activity-feed-query-subscription';
import ActivityFeedItem from 'embercom/models/activity-feed-item';
import { getEmberDataStore } from 'embercom/lib/container-lookup';

let ActivityFeedQuery = ArrayProxy.extend({
  page: 0,
  isLoading: true,
  user: undefined,
  subject: undefined,
  setup: on('init', function () {
    getEmberDataStore().unloadAll('activity-feed-item');
  }),
  dateCursor: reads('lastObject.created_at'),
  newPipelinePaginationCursor: readOnly('lastObject.id'),
  hasMore: notEqualToProperty('dateCursor', 'lastCreatedAt'),
  options: computed('admin_id', 'app.id', 'timelineType', 'aggregate', '[]', function () {
    let options = {
      app_id: this.get('app.id'),
      timeline_type: this.timelineType,
      followed_user_events: false,
      page_num: this.pageNum,
      page_size: this.pageSize,
      aggregate: this.aggregate,
      profileType: this.profileType,
    };
    if (this.admin_id) {
      options.admin_id = this.admin_id;
    }
    if (this.user) {
      options.intercom_user_id = this.get('user.id');
    }
    if (this.subject) {
      options.subject_id = this.get('subject.id');
    }
    if (this.length > 0) {
      options.before = this.dateCursor;
    }
    if (this.length > 0) {
      options.timestamp_event_id = this.newPipelinePaginationCursor;
    }
    return options;
  }),
  singlePage() {
    this.set('lastCreatedAt', this.dateCursor);
    return ActivityFeedItem.getFeedItems(this.options);
  },
  loadPage() {
    let self = this;
    this.set('isLoading', true);
    return this.singlePage()
      .then(function (data) {
        self.addObjects(data.items);
        self.set('totalCount', data.totalCount);
        self.set('isLoading', false);
      })
      .catch(function () {
        self.set('hasErrored', true);
      });
  },
  reload() {
    this.set('content', []);
    return this.loadPage();
  },
  nextPage() {
    this.set('pageNum', this.pageNum + 1);
    return this.loadPage();
  },
  subscribe() {
    this.unsubscribe();
    let subscription = ActivityFeedQuerySubscription.create({
      query: this,
      realTimeEventService: this.realTimeEventService,
    });
    this.set('subscription', subscription);
  },
  unsubscribe() {
    let subscription = this.subscription;
    if (subscription) {
      subscription.destroy();
    }
  },
});

export default ActivityFeedQuery;
