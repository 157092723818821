/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class LastBulkSyncStatus extends Fragment {
  @attr('string') state;
  @attr('number') finishedItemsCount;
  @attr('number') totalItemsCount;
  @attr('date') completedAt;
}
