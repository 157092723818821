/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const SAML_VERIFICATION_ERRORS = {
  saml_account_not_found: {
    message:
      "The domain in your email address isn't in your list of allowed domains. Add and verify the domain to continue.",
    showLinkToArticle: false,
  },
  invalid_saml_response: {
    message: 'Invalid SAML response',
    showLinkToArticle: true,
  },
  admin_not_found: {
    message:
      'Teammate not found. Check if the nameid set up in your identity provider is an email address or if you logged in using the right email address.',
    showLinkToArticle: true,
  },
  not_authorized: {
    message:
      "The domain in your email address isn't in your list of allowed domains. Add and verify the domain to continue",
    showLinkToArticle: true,
  },
  missing_required_attributes: {
    message:
      "The response from your identity provider didn't include firstName and lastName. Add these attributes in order to continue.",
    showLinkToArticle: true,
  },
  public_certificate_error: {
    message: 'Invalid certificate. Check if the certificate is correct and try again.',
    showLinkToArticle: true,
  },
  decryption_error: {
    message:
      "Unable to decrypt the response from your identity provider. Check if you're using AES256_CBC and the correct public certificate.",
    showLinkToArticle: true,
  },
};

const SAML_SIGN_IN_ERRORS = {
  saml_account_not_found:
    'Single sign-on has not been configured for your email address or domain yet. Try a different login method or contact your administrator.',
  invalid_saml_response:
    'Login failed because of an error from the identity provider. Contact your administrator or try again.',
  admin_create_failed:
    "We couldn't create your account for this workspace. Try again or contact support.",
  admin_not_found:
    "You don't have access to this Intercom workspace. Ask your teammates or administrator to invite you.",
  not_authorized:
    'Single sign-on has not been configured for your domain yet. Try a different login method or contact your administrator.',
  missing_required_attributes:
    'Login failed because of an error from the identity provider. Contact your administrator or try again.',
  public_certificate_error:
    "We couldn't log you in because your identity provider returned an error. Contact your administrator or try again.",
  decryption_error:
    "We couldn't log you in because your identity provider returned an error. Contact your administrator or try again.",
  session_expired: 'Your session expired, please sign in again to continue.',
};

export const getSamlVerificationErrorMessage = (errorCode, customMessage) => {
  if (errorCode && errorCode in SAML_VERIFICATION_ERRORS) {
    let { message } = SAML_VERIFICATION_ERRORS[errorCode];
    if (customMessage) {
      message = `${message}: ${customMessage}`;
    }
    return message;
  }

  return 'An unexpected error happened, please try again or contact support.';
};

export const shouldShowLinkToSamlArticle = (errorCode) => {
  if (errorCode && errorCode in SAML_VERIFICATION_ERRORS) {
    return SAML_VERIFICATION_ERRORS[errorCode].showLinkToArticle;
  }

  return false;
};

export const getSamlSignInErrorMessage = (errorCode) => {
  if (errorCode && errorCode in SAML_SIGN_IN_ERRORS) {
    let message = SAML_SIGN_IN_ERRORS[errorCode];
    return message;
  }

  return 'An unexpected error happened, please try again or contact support.';
};
