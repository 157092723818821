/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import generateUUID from 'embercom/lib/uuid-generator';

export default class WaitForCallback extends Step {
  @attr('string') declare name: string;
  @attr('string') declare externalStepId: string;
  @attr('string') declare exampleRequest: string;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.wait;
  }

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): WaitForCallback {
    return store.createRecord('operator/visual-builder/step/wait-for-callback', {
      type: 'operator/visual-builder/step/wait-for-callback',
      externalStepId: generateUUID(),
    });
  }
}
