/* RESPONSIBLE TEAM: team-data-foundations */
import { inject as service } from '@ember/service';
import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type Admin from './admin';
import type TagStats from './tag-stats';
import type IntlService from 'embercom/services/intl';

export const tagStatuses = {
  batching: 'batching',
  started: 'started',
  completed: 'completed',
};

export default class Tag extends Model {
  @service declare intl: IntlService;
  @attr() declare app_id: string;
  @attr('boolean', { defaultValue: false }) declare archived: boolean;
  @attr() declare archived_at: string;
  @belongsTo('admin', { async: false }) declare archivedBy: Admin;
  @attr() declare created_at: string;
  @belongsTo('admin', { async: false }) declare createdBy: Admin;
  @attr() declare name: string;
  @belongsTo('tag-stats') declare stats: AsyncBelongsTo<TagStats>;
  @attr() declare status: string;

  get analyticsData() {
    return {
      object: 'tag',
      tag_id: this.id,
      name: this.name,
    };
  }

  get isSegment() {
    return false;
  }

  get predicate() {
    return {
      comparison: 'in',
      value: this.id,
      attribute: 'manual_tag_ids',
      type: 'manual_tag',
      subclass: 'predicates/select',
    };
  }

  get isProcessing() {
    return [tagStatuses.batching, tagStatuses.started].includes(this.status);
  }

  get processingText() {
    return this.intl.t('tags.tag.processing-text');
  }
}
