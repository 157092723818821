/* RESPONSIBLE TEAM: team-workflows */
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import WorkflowEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/workflow-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import { CHANNEL_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';

export default function generateEditModeConfig({ container, customBot }) {
  let contentEditorService = container.lookup('service:contentEditorService');
  let finOptInService = container.lookup('service:finOptInService');
  let finTrialService = container.lookup('service:finTrialService');

  function finOptInCheck(postAcceptCallback) {
    if (contentEditorService.activeObject?.useAiAnswers && !finOptInService.hasOptedInToFin) {
      finOptInService.postAcceptCallback = postAcceptCallback;
      finOptInService.openFinOptInModal();
    } else {
      postAcceptCallback.perform();
    }
  }

  function maybeShowTrialConfirmationModal() {
    if (contentEditorService.activeObject?.useAiAnswers) {
      finTrialService.openFinRetrialConfirmationModal();
    }
  }

  let previewConfig = !customBot.targetChannels?.includes(CHANNEL_PHONE_CALL)
    ? ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/workflows/preview-button',
        analyticEvent: {
          place: 'custom_bots',
        },
      })
    : null;

  let editModeConfig = EditModeConfig.create({
    headerComponentConfigs: [
      WorkflowEditModeHeaderConfiguration.create({
        container,
        preActivationCheck: finOptInCheck,
        postActivationCheck: maybeShowTrialConfirmationModal,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          duplicateModalComponent: 'custom-bots/duplicate-modal',
          hideCsvExportOption: true,
          hideTagOption: false,
          container,
        }),
        previewConfig,
      }),
    ],
    editorPanelConfigs: [],
    footerComponentConfigs: [],
  });

  return editModeConfig;
}
