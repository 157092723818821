/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, type SyncHasMany } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';
import type MessengerLocale from '../settings/messenger-locale';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
import { dependentKeyCompat } from '@ember/object/compat';

export default class Languages extends Model {
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;

  @attr('string') declare defaultLocale: string;
  @fragmentArray('settings/messenger-locale')
  declare availableLocales: SyncHasMany<MessengerLocale>;
  @fragmentArray('settings/messenger-locale')
  declare supportedLocales: SyncHasMany<MessengerLocale>;
  @attr('string') declare conversationalIntroduction: string;
  @attr('boolean', { defaultValue: false }) declare isConversationalSettingsUpdate: boolean;

  get welcomeMessagesTooLong() {
    return this.supportedLocales.any((locale) => locale.welcomeMessageTooLong);
  }

  // @ts-expect-error this should be renamed to no longer override the built-in isValid property
  get isValid() {
    return !this.welcomeMessagesTooLong;
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  get targetUserType() {
    return this.messengerSettingsTargetUserProvider.targetUserType;
  }

  @computed('defaultLocale', 'supportedLocales.@each.{conversationalGreeting,localeId}')
  get conversationalGreeting() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.conversationalGreeting;
  }

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,greeting}')
  get defaultGreeting() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.greeting;
  }

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,mobileGreeting}')
  get defaultMobileGreeting() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.mobileGreeting;
  }

  // @ts-expect-error, helper not written in TS yet
  @userTypeComputedProperty(
    'targetUserType',
    'defaultGreeting',
    'defaultGreeting',
    // @ts-expect-error, TS tries to infer this to undefined
    'defaultMobileGreeting',
  )
  targetedGreeting?: string;

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,greetingSubtitle}')
  get defaultGreetingSubtitle() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.greetingSubtitle;
  }

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,mobileGreetingSubtitle}')
  get defaultMobileGreetingSubtitle() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.mobileGreetingSubtitle;
  }

  // @ts-expect-error, helper not written in TS yet
  @userTypeComputedProperty(
    'targetUserType',
    'defaultGreetingSubtitle',
    'defaultGreetingSubtitle',
    // @ts-expect-error, TS tries to infer this to undefined
    'defaultMobileGreetingSubtitle',
  )
  targetedGreetingSubtitle?: string;

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,userWelcomeMessage}')
  get defaultWelcomeMessage() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.userWelcomeMessage;
  }

  @computed('defaultLocale', 'supportedLocales.@each.{localeId,name}')
  get defaultLanguageName() {
    let locale = this.defaultLocale;

    return this.supportedLocales.filterBy('localeId', locale).firstObject?.name;
  }

  @computed('defaultLocale', 'supportedLocales.@each.isPermitted')
  get permittedLocales() {
    let defaultLocale = this.supportedLocales.findBy('localeId', this.defaultLocale);
    let permittedLocales = this.supportedLocales.filterBy('isPermitted', true);

    if (!defaultLocale) {
      return permittedLocales;
    }

    return permittedLocales.concat(defaultLocale);
  }

  refresh() {
    this.rollbackAttributes();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'messenger-settings/languages': Languages;
  }
}
