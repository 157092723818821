/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
} from 'embercom/models/custom-objects/constants/object-types';
import {
  ATTRIBUTE_DESCRIPTOR_TYPE_STRING,
  ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { isBlank, isPresent } from '@ember/utils';
import { getEmberDataStore } from 'embercom/lib/container-lookup';

export default class CustomObjectsObjectType extends Model {
  @attr('string') name;
  @attr('string') identifier;
  @attr('date') updatedAt;
  @attr('date') createdAt;
  @attr('boolean') archived;
  @attr('date') archivedAt;
  @attr('boolean') isSystemDefined;
  @hasMany('objects/attribute-descriptor', { async: false }) attributeDescriptors;

  @service store;
  @service customObjectsService;
  @service intl;

  get filteredAttributeDescriptors() {
    return this.attributeDescriptors.filterBy('editable');
  }

  get hasRelationshipAttributeDescriptors() {
    return isPresent(
      this.filteredAttributeDescriptors.filterBy('isRelationshipDataType').rejectBy('archived'),
    );
  }

  get attributes() {
    return this.filteredAttributeDescriptors.map((attributeDescriptor) => {
      return this._createAttribute(
        attributeDescriptor,
        `custom_objects.${this.identifier}.${attributeDescriptor.id}`,
      );
    });
  }

  get standardAttributes() {
    return this.standardAttributeDescriptors.map((attributeDescriptor) => {
      return this._createAttribute(
        attributeDescriptor,
        `custom_objects.${this.identifier}.${attributeDescriptor.name}`,
      );
    });
  }

  get attributesInclStandard() {
    return this.attributes.map((attr) => attr).concat(this.standardAttributes);
  }

  get attributeDescriptorsInclStandard() {
    return this.filteredAttributeDescriptors
      .map((attr) => attr)
      .concat(this.standardAttributeDescriptors);
  }

  get attributeGroups() {
    let attributeGroups = [];
    let customObjectTypeIdentifiers =
      this.customObjectsService.customObjectTypes.mapBy('identifier');

    this.attributeDescriptors
      .filterBy('isRelationshipDataType')
      .forEach((relationshipAttribute) => {
        if (isBlank(relationshipAttribute.relationship)) {
          return; // Exclude relationship attributes with no relationship (Ex. Reference attributes)
        }

        if (
          customObjectTypeIdentifiers.includes(relationshipAttribute.objectTypeIdentifier) &&
          customObjectTypeIdentifiers.includes(relationshipAttribute.relatedObjectTypeIdentifier)
        ) {
          return; // Exclude Custom Object to Custom Object Relationship Attributes
        }

        if (
          (relationshipAttribute.isSourceRelationshipAttribute &&
            !relationshipAttribute.relationship.oneToMany) ||
          (relationshipAttribute.isDestinationRelationshipAttribute &&
            !relationshipAttribute.relationship.manyToOne)
        ) {
          return; // Only allow Relationship Attributes where Standard Object has "has one" relationship with Custom Object
        }

        let group = {
          heading: `${relationshipAttribute.relatedAttribute.name} (${relationshipAttribute.objectTypeName})`,
          attributes: [],
        };

        let prefix = `${relationshipAttribute.relatedObjectTypeIdentifier}.related_objects.${relationshipAttribute.id}`;
        this.filteredAttributeDescriptors
          .rejectBy('isRelationshipDataType')
          .forEach((attributeDescriptor) => {
            group.attributes.pushObject(
              this._createAttribute(
                attributeDescriptor,
                `${prefix}.custom_objects.${this.identifier}.${attributeDescriptor.id}`,
              ),
            );
          });

        if (group.attributes.length > 0) {
          attributeGroups.pushObject(group);
        }
      });

    return attributeGroups;
  }

  _createAttribute(attributeDescriptor, identifier) {
    return this.store.createRecord('attribute', {
      name: attributeDescriptor.name,
      identifier,
      type: attributeDescriptor.predicateDataType,
      icon: 'transfer',
      description: attributeDescriptor.description,
      archived: false,
      templatable: true,
      templatableIdentifier: identifier,
    });
  }

  get relatedToUserObjectType() {
    return this._relatedToObjectType(USER_OBJECT_TYPE_IDENTIFIER);
  }

  get relatedToConversationType() {
    return this._relatedToObjectType(CONVERSATION_OBJECT_TYPE_IDENTIFIER);
  }

  _relatedToObjectType(objectTypeIdentifier) {
    return this.filteredAttributeDescriptors.isAny(
      'relatedObjectTypeIdentifier',
      objectTypeIdentifier,
    );
  }

  get standardAttributeDescriptors() {
    return [
      this._createStandardAttributeDescriptor(
        'external_id',
        this.intl.t(
          'settings.custom-object-data.list.attribute-descriptors.standard-attribute-external-id-description',
        ),
        ATTRIBUTE_DESCRIPTOR_TYPE_STRING,
      ),
      this._createStandardAttributeDescriptor(
        'external_created_at',
        this.intl.t(
          'settings.custom-object-data.list.attribute-descriptors.standard-attribute-external-created-at-description',
        ),
        ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME,
      ),
      this._createStandardAttributeDescriptor(
        'external_updated_at',
        this.intl.t(
          'settings.custom-object-data.list.attribute-descriptors.standard-attribute-external-updated-at-description',
        ),
        ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME,
      ),
    ];
  }

  _createStandardAttributeDescriptor(name, description, dataType) {
    return this.store.createRecord('objects/attribute-descriptor', {
      name,
      description,
      dataType,
      required: false,
      archived: false,
      editable: false,
    });
  }

  async archive() {
    this.archived = true;
    await this.save();
  }

  async unarchive() {
    this.archived = false;
    await this.save();
  }

  static peekOrFindById(id) {
    let store = getEmberDataStore();

    return store.hasRecordForId('custom-objects/object-type', id)
      ? store.peekRecord('custom-objects/object-type', id)
      : store.findRecord('custom-objects/object-type', id);
  }
}
