/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { attr } from '@ember-data/model';
import { buildValidations } from 'ember-cp-validations';
import { validator } from 'ember-cp-validations';

const Validations = buildValidations({
  handoffUrl: [
    validator('presence', {
      presence: true,
      message: 'Hand-off URL is required',
      messageTranslated: true,
    }),
    validator('format', { type: 'url' }),
  ],
});

export default class HandoffToUrl extends Step.extend(Validations) {
  @attr('string') declare handoffUrl?: string;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.handoffToUrl;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): HandoffToUrl {
    return store.createRecord('operator/visual-builder/step/handoff-to-url', {
      type: 'operator/visual-builder/step/handoff-to-url',
      justAdded: true,
    });
  }
}
