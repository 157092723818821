/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EmberObject from '@ember/object';

const USER_OBJECT_TYPE_IDENTIFIER = 'user';
const USER_OBJECT_TYPE_NAME = 'People';
const USER_OBJECT_TYPE_NAME_PERSON = 'Person';
const CONVERSATION_OBJECT_TYPE_IDENTIFIER = 'conversation';
const CONVERSATION_OBJECT_TYPE_NAME = 'Conversation';
const RESERVED_OBJECT_TYPE_IDENTIFIERS = [
  USER_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
];
const STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP = {
  [USER_OBJECT_TYPE_IDENTIFIER]: USER_OBJECT_TYPE_NAME,
  [CONVERSATION_OBJECT_TYPE_IDENTIFIER]: CONVERSATION_OBJECT_TYPE_NAME,
};

const USER_OBJECT_TYPE = EmberObject.create({
  name: USER_OBJECT_TYPE_NAME,
  identifier: USER_OBJECT_TYPE_IDENTIFIER,
  archived: false,
  attributeDescriptors: [],
});

const CONVERSATION_OBJECT_TYPE = EmberObject.create({
  name: CONVERSATION_OBJECT_TYPE_NAME,
  identifier: CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  archived: false,
  attributeDescriptors: [],
});
const STANDARD_OBJECT_TYPES = [USER_OBJECT_TYPE, CONVERSATION_OBJECT_TYPE];
const STANDARD_OBJECT_TYPE_IDENTIFIERS = STANDARD_OBJECT_TYPES.mapBy('identifier');

export {
  USER_OBJECT_TYPE_IDENTIFIER,
  USER_OBJECT_TYPE_NAME,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_NAME,
  RESERVED_OBJECT_TYPE_IDENTIFIERS,
  USER_OBJECT_TYPE,
  CONVERSATION_OBJECT_TYPE,
  STANDARD_OBJECT_TYPES,
  STANDARD_OBJECT_TYPE_IDENTIFIERS,
  STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP,
  USER_OBJECT_TYPE_NAME_PERSON,
};
