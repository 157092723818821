/* RESPONSIBLE TEAM: team-workflows */
import { fragment } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

const Validations = buildValidations({
  action: [
    validator('presence', true),
    validator('rule-action-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
});

export default class TagUser extends Step.extend(Validations) {
  @fragment('rules/rule-action') declare action: any;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.tagUser;
  }

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): TagUser {
    return store.createRecord('operator/visual-builder/step/tag-user', {
      action: store.createFragment('rules/rule-action', { type: 'tag-user', justAdded: true }),
      type: 'operator/visual-builder/step/tag-user',
    });
  }
}
