/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';

export const APP_PACKAGE_UNINSTALL_REASONS = {
  different_needs: 'different-needs',
  bad_performance: 'bad-performance',
  better_replacement: 'better-replacement',
  too_expensive: 'too-expensive',
  other: 'other',
};

export default class UninstallFeedback extends Model {
  @attr() report;
}
