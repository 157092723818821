/* RESPONSIBLE TEAM: team-data-foundations */
import Model, { attr, hasMany } from '@ember-data/model';
import SamlAccountValidations from 'embercom/validations/saml-account';

export default class SamlAccount extends Model.extend(SamlAccountValidations) {
  @attr('string') name;
  @attr('string') baseSamlUrl;
  @attr('string') idpSsoTargetUrl;
  @attr('string') idpCert;
  @attr('boolean') jitEnabled;
  @hasMany('app-sso-domain', { async: false }) appSsoDomains;
  //used for dirty tracking
  @attr({ defaultValue: (model) => model.appSsoDomains.mapBy('id') }) appSsoDomainIds;
  @attr() jitSettings;

  get isValid() {
    return this.validations.isValid;
  }
}
