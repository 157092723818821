/* RESPONSIBLE TEAM: team-data-foundations */
export default function buildCompanyPredicates(companies) {
  let predicates = companies.map((company) => {
    return {
      attribute: 'company.remote_company_id',
      type: 'string',
      comparison: 'eq',
      value: company.remote_company_id,
    };
  });
  if (predicates.length > 1) {
    predicates = [{ type: 'or', predicates }];
  }
  return predicates;
}
