/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { type SyncHasMany } from '@ember-data/model';
import { type Block } from 'embercom/models/common/blocks/block';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export const chatMessageStepBlockValidators = {
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.blank-message-error',
      messageTranslated: true,
    }),
    validator('workflow-step-blocks-messenger-apps-are-valid'),
    validator('workflow-step-blocks-messenger-cards-are-valid'),
    validator('workflow-step-blocks-audio-snippets-have-no-meaningful-content', {
      message: 'operator.workflows.visual-builder.validations.audio-files-invalid',
      messageTranslated: true,
    }),
  ],
};

const Validations = buildValidations({
  ...chatMessageStepBlockValidators,
});

export default class ChatMessage extends Step.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.chatMessage;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  get jsonBlocks() {
    return this.blocks.serialize();
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  static createNewStep(store: Store): ChatMessage {
    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    return store.createRecord('operator/visual-builder/step/chat-message', {
      blocks,
      type: 'operator/visual-builder/step/chat-message',
    });
  }
}
