/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { validator, buildValidations } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { CHANNEL_WEB } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { fragment } from 'ember-data-model-fragments/attributes';
import type AttributeCollectorSelectorParams from './attribute-collector/selector-params';
import { LOCAL_VARIABLE_IDENTIFIER_PREFIX } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

const Validations = buildValidations({
  attributeIdentifier: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  attributeLabel: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  attributeCollectionOverwritable: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  attributeCollectionDisabled: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  supportedChannels: [
    validator('workflow-step-supported-by-channel', {
      extraDependents: ['model.attributeDataType'],
    }),
  ],
});

export default class AttributeCollector extends Step.extend(Validations) {
  @attr('string') declare attributeIdentifier?: string;
  @attr('string') declare attributeLabel?: string;
  @attr('boolean') declare attributeCollectionOverwritable?: boolean;
  @attr('boolean') declare attributeCollectionDisabled?: boolean;
  @attr('string') declare attributeDataType?: string;
  @fragment('operator/visual-builder/step/attribute-collector/selector-params')
  declare attributeCollectionSelectorParams?: AttributeCollectorSelectorParams;

  get supportedChannels() {
    if (this.attributeDataType === DataType.Files) {
      return [CHANNEL_WEB] as const;
    }
    return SUPPORTED_CHANNELS.attributeCollector;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  get isCollectingLocalVariable() {
    return this.attributeIdentifier?.startsWith(LOCAL_VARIABLE_IDENTIFIER_PREFIX) ?? false;
  }

  get selectionAttributeDescriptor() {
    return this.findLocalAttributeDescriptor(
      this.attributeCollectionSelectorParams?.selectionAttributeIdentifier,
    );
  }

  get displayAttributeDescriptor() {
    return this.findLocalAttributeDescriptor(
      this.attributeCollectionSelectorParams?.displayAttributeIdentifier,
    );
  }

  get choiceAttributeDescriptor() {
    return this.findLocalAttributeDescriptor(
      this.attributeCollectionSelectorParams?.choiceAttributeIdentifier,
    );
  }

  get showNoneOfTheAboveOption() {
    return this.attributeCollectionSelectorParams?.showNoneOfTheAboveOption ?? false;
  }

  findLocalAttributeDescriptor(identifier: string | undefined) {
    return identifier ? this.editorConfig?.localAttributes.findBy('identifier', identifier) : null;
  }

  static createNewStep(store: Store): AttributeCollector {
    return store.createRecord('operator/visual-builder/step/attribute-collector', {
      type: 'operator/visual-builder/step/attribute-collector',
    });
  }
}
