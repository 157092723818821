/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, reads, or, equal, and, not, notEmpty } from '@ember/object/computed';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import PredefinedSegmentIconMap from 'embercom/models/data/predefined-segment-icon-map';
import PredicateValidator from 'embercom/models/mixins/predicate-validator';
import numberFormatter from 'embercom/lib/number-formatter';

const MASTER_SEGMENT_IDS = ['all', 'all-leads', 'all-users'];

let SegmentModel = Model.extend(PredicateValidator, {
  identifier: attr(),
  name: attr(),
  created_at: attr(),
  is_predefined: attr(),
  is_company: attr(),
  is_editable: attr(),
  app_id: attr(),
  user_count: attr('number'),
  count: attr('number'),
  predicates: attr('array', {
    defaultValue: () => [],
  }),
  search_count: -1,
  isSegment: true,
  queryPredicates: alias('predicates'),
  created_by_id: attr(),
  creator: computed('created_by_id', function () {
    // The reason why we fetch the admin from the app has to do with inconsistencies on
    // the backend controllers for Admins.
    // Details: https://github.com/intercom/intercom/issues/133233#issuecomment-470468819
    // When this issue gets solved we can just go through the Admin here.
    return this.store
      .peekRecord('app', this.app_id)
      .admins.findBy('id', String(this.created_by_id));
  }),
  analyticsData: computed(
    'id',
    'segment_id',
    'name',
    'identifier',
    'predicates.[]',
    'is_company',
    function () {
      return {
        object: 'segment',
        segment_id: this.id,
        name: this.name,
        identifier: this.identifier,
        predicates: this.predicates,
        is_company: this.is_company,
        has_nested_logical_predicates: this.nestingLevelGreaterThanOne,
      };
    },
  ),

  isPredefined: reads('is_predefined'),
  isUsersSegment: equal('identifier', 'all-users'),
  isContactsSegment: equal('identifier', 'all-leads'),
  isActiveSegment: equal('identifier', 'active'),
  isAllSegment: equal('identifier', 'all'),
  isAllUsersWithoutAcquire: and('isUserSegment', 'isAllSegment'),
  isAllUsersSegment: or('isUsersSegment', 'isAllUsersWithoutAcquire'),
  isAllCompaniesSegment: and('isAllSegment', 'isCompany'),
  isAllCompaniesSegmentWithoutChange: and('isAllCompaniesSegment', 'isNotDirty'),
  isAllUsersSegmentWithoutChange: and('isAllUsersSegment', 'isNotDirty'),
  isAllContactsSegmentWithoutChange: and('isContactsSegment', 'isNotDirty'),
  isNotDirty: not('hasDirtyAttributes'),
  isMasterSegment: computed('identifier', function () {
    return MASTER_SEGMENT_IDS.includes(this.identifier);
  }),
  isEmpty: equal('count', 0),
  hasCreator: notEmpty('created_by_id'),
  hasCreationDate: notEmpty('created_at'),
  hasCreationMetaData: or('hasCreator', 'hasCreationDate'),
  isCompany: alias('is_company'),
  isUser: not('isCompany'),
  isAnonymousSegment: reads('hasAnonymousPredicate'),
  isNotAnonymousSegment: not('isAnonymousSegment'),
  isUserSegment: reads('hasUserPredicate'),
  isNotUserSegment: not('isUserSegment'),

  personTypeName: ternary('isAnonymousSegment', 'lead', 'user'),
  defaultPredicateLength: ternary('is_company', 0, 1),

  predefinedIcon: computed('isCompany', 'name', function () {
    let iconMap = this.isCompany
      ? SegmentModel.predefinedCompanyIconMap
      : SegmentModel.predefinedUserIconMap;
    return iconMap[this.name] || 'segment';
  }),

  defaultIcon: 'segment',
  icon: ternaryToProperty('isPredefined', 'predefinedIcon', 'defaultIcon'),

  formattedCount: computed('count', 'search_count', function () {
    let displayedCount = this.search_count < 0 ? this.count : this.search_count;
    return numberFormatter(displayedCount);
  }),

  toJSON() {
    this.set('predicates', this.activePredicates);
    return this;
  },
}).reopenClass(PredefinedSegmentIconMap);

export default SegmentModel;
