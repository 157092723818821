/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { gt, lt, not, filterBy, readOnly, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { findByProperty, rejectByProperty } from '@intercom/pulse/lib/computed-properties';
import parseUrl from 'embercom/lib/url-parser';
import { fragmentArray, fragment } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { scheduleOnce } from '@ember/runloop';
import { EntityType } from 'embercom/models/data/entity-types';
import { isValidUrlWithProtocol } from 'embercom/lib/url-validator';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const maxFooterLinkCount = 8;
const maxHeaderLinkCount = 3;
const ticketsPortalPathName = 'tickets-portal';
const placeholderCircleUploadImage = assetUrl(
  '/@intercom/embercom-prosemirror-composer/placeholder_circle_upload.svg',
);
const footerContentPlaceholderJsonBlocks = (intl) => [
  {
    type: 'paragraph',
    class: 'no-margin',
    text: intl.t('articles.settings.stylings.footer.create-custom-design'),
    align: 'center',
  },
  {
    type: 'paragraph',
    class: 'no-margin',
    text: ' ',
  },
  {
    type: 'paragraph',
    class: 'no-margin',
    text: `<img class="entity_mention inline-image" src="${placeholderCircleUploadImage}" width="70">    <img class="entity_mention inline-image" src="${placeholderCircleUploadImage}" width="70">    <img class="entity_mention inline-image" src="${placeholderCircleUploadImage}" width="70">    <img class="entity_mention inline-image" src="${placeholderCircleUploadImage}" width="70">`,
    align: 'center',
  },
];

// should equal VALID_IDENTIFIER from app/services/article_service/commands/site/update.rb
const validIdentifierRegex = /^[a-zA-Z0-9_-]+$/;

// should equal VALID_CUSTOM_DOMAIN_AND_PATH_PREFIX from app/services/article_service/commands/site/update.rb
const validCustomDomainRegex =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])(\/\S*)?$/;

const Validations = buildValidations({
  externalLoginName: validator('presence', {
    presence: true,
    messageKey: 'articles.settings.redesign.validations.privacy-title-missing',
    disabled: not('model.segmentedContentTurnedOn'),
  }),
  externalLoginUrl: [
    validator('inline', {
      disabled: not('model.segmentedContentTurnedOn'),
      validate(_value, _options, model) {
        let haveLabelButNoLink = model.externalLoginName && !model.externalLoginUrl;
        if (haveLabelButNoLink) {
          return model.intl.t('articles.settings.redesign.validations.privacy-url-missing');
        }
        return true;
      },
    }),
    validator('length', {
      disabled: not('model.segmentedContentTurnedOn'),
      max: 191,
      messageKey: 'articles.settings.redesign.validations.url-too-long',
    }),
    validator('inline', {
      disabled: not('model.segmentedContentTurnedOn'),
      dependentKeys: ['model.externalLoginName'],
      validate(value, _options, model) {
        // dont check for format when user hasn't typed anything
        if (!model.externalLoginUrl) {
          return true;
        }
        if (isValidUrlWithProtocol(value)) {
          return true;
        }
        return model.intl.t('articles.settings.redesign.validations.privacy-url-format');
      },
    }),
  ],
  identifier: [
    validator('presence', {
      presence: true,
      messageKey: 'articles.settings.redesign.validations.url-empty',
    }),
    validator('format', {
      regex: validIdentifierRegex,
      messageKey: 'articles.settings.redesign.validations.url-special-char',
    }),
    validator('length', {
      max: 72,
      messageKey: 'articles.settings.redesign.validations.url-too-long',
    }),
  ],
  googleAnalyticsTrackingId: validator('format', {
    disabled: not('model.googleAnalyticsTrackingId'),
    regex: /^(UA|YT|MO)-\d+-\d+$|^G-[0-9A-Z]{10}$|^$/i,
    messageKey: 'articles.settings.redesign.validations.google-analytics',
  }),
  customDomainAndPathPrefix: [
    validator('custom-domain-with-path-prefix', {
      disabled: empty('model.customDomainAndPathPrefix'),
    }),
    validator('format', {
      disabled: empty('model.customDomainAndPathPrefix'),
      regex: validCustomDomainRegex,
      messageKey: 'articles.settings.redesign.validations.custom-domain-special-char',
    }),
    validator('length', {
      max: 191,
      messageKey: 'articles.settings.redesign.validations.custom-domain-too-long',
    }),
  ],
  footerLinks: validator('has-many'),
  headerLinks: validator('has-many'),
  socialLinks: validator('has-many'),
  supportedLocales: validator('has-many'),
  'selectedLocalesWithHeadlineTooLong.length': validator('number', {
    message: 'Make sure that the headline is under 160 characters for every language.',
    lte: 0,
  }),
});

export default Model.extend(Validations, {
  intl: service(),
  appService: service(),
  app: readOnly('appService.app'),
  appId: readOnly('app.id'),

  name: attr('string'),
  headline: readOnly('defaultLocale.headline'),
  customDomain: attr('string'),
  customDomainAndPathPrefix: attr('string'),
  customDomainUsesSsl: attr('boolean'),
  seoIndexingEnabled: attr('boolean'),
  themeColor: attr('string'),
  headerFontColor: computed('customizationOptions.header.fontColor', function () {
    return this.customizationOptions?.header?.fontColor?.slice(1);
  }),
  meetsQuickTlsCriteria: attr('boolean'),
  customizationOptions: fragment('customization-options/help-center-site'),
  identifier: attr('string'),
  headerId: attr('number'),
  headerUrl: attr('string'),
  faviconUrl: attr('string'),
  logoId: attr('number'),
  logoUrl: attr('string'),
  footerLogoUrl: attr('string'),
  socialUrl: attr('string'),
  url: attr('string'),
  defaultDomain: attr('string'),
  turnedOn: attr('boolean'),
  ticketsPortalTurnedOn: attr('boolean', { defaultValue: false }),
  ticketsPortalDependenciesMet: attr('boolean', { defaultValue: false }),
  websiteTurnedOn: attr('boolean'),
  supportedLocales: fragmentArray('articles/site/supported-locale'),
  locale: attr('string'),
  homeUrl: attr('string'),
  googleAnalyticsTrackingId: attr('string'),
  externalLoginUrl: attr('string'),
  externalLoginName: attr('string'),
  segmentedContentTurnedOn: attr('boolean'),
  zendeskImporterClientId: attr('string'),
  zendeskImporterRedirectUri: attr('string'),
  customOriginServer: attr('string'),
  disableBranding: attr('boolean'),
  showAuthors: attr('boolean', { defaultValue: true }),
  hideAuthors: not('showAuthors'),
  showTableOfContents: attr('boolean', { defaultValue: false }),
  isDefault: attr('boolean'),
  contentSegmentIds: attr('array', { defaultValue: () => [] }),
  entityType: computed(function () {
    return EntityType.HelpCenterSite;
  }),

  showRelatedArticles: attr('boolean', { defaultValue: true }),

  footerContactDetails: attr('string'),
  customizedFooterTextContents: fragmentArray('help-center-footer-text-content'),

  homeCollectionCols: attr('number'),
  visibleCollections: attr('array', { defaultValue: () => [] }),

  canDelete: attr('boolean'),

  isHelpCenterSectionOpen: attr('boolean', { defaultValue: false }),
  creatingAdminId: attr('string'),

  footerLinks: fragmentArray('help-center-site-link'),
  headerLinks: fragmentArray('help-center-site-link'),
  footerLinkGroups: fragmentArray('help-center-site-link-group'),
  headerLinkGroups: fragmentArray('help-center-site-link-group'),
  socialLinks: fragmentArray('help-center-social-link'),
  canAddFooterLinks: lt('footerLinks.length', maxFooterLinkCount),
  canAddHeaderLinks: lt('headerLinks.length', maxHeaderLinkCount),

  selectedLocales: filterBy('supportedLocales', 'isSelected', true),
  selectedLocalesWithoutDefault: rejectByProperty('selectedLocales', 'localeId', 'locale'),
  defaultLocale: findByProperty('selectedLocales', 'localeId', 'locale'),
  selectedLocalesOrdered: computed('selectedLocales.[]', 'locale', function () {
    let withoutDefault = this.selectedLocalesWithoutDefault;
    return this.defaultLocale ? [this.defaultLocale].concat(withoutDefault) : withoutDefault;
  }),
  selectedLocalesWithoutTitle: filterBy('selectedLocales', 'invalidTitle', true),
  selectedLocalesWithHeadlineTooLong: filterBy('selectedLocales', 'invalidHeadline', true),

  userFriendlyUrl: computed('url', function () {
    let { hostname, pathname, search } = parseUrl(this.url);
    if (pathname === '/') {
      pathname = '';
    }
    return hostname + pathname + search;
  }),

  ticketsPortalUrl: computed('url', 'locale', function () {
    let { hostname, pathname } = parseUrl(this.url);

    if (pathname === '/') {
      pathname = '';
    }

    let maybeSlash = pathname.endsWith('/') ? '' : '/';

    return `${hostname}${pathname}${maybeSlash}${this.locale}/${ticketsPortalPathName}`;
  }),

  turnedOff: not('turnedOn'),

  hasMultipleLocales: gt('selectedLocales.length', 1),

  originalWebsiteTurnedOn: computed('websiteTurnedOn', function () {
    let changed = this.changedAttributes();
    if (!changed) {
      return this.websiteTurnedOn;
    }
    let changedWebsiteTurnedOn = changed['websiteTurnedOn'];
    if (!changedWebsiteTurnedOn) {
      return this.websiteTurnedOn;
    }
    return changedWebsiteTurnedOn[0];
  }),

  originalTicketsPortalTurnedOn: computed('ticketsPortalTurnedOn', function () {
    let changed = this.changedAttributes();
    if (!changed) {
      return this.ticketsPortalTurnedOn;
    }

    let changedTicketsPortalTurnedOn = changed['ticketsPortalTurnedOn'];
    if (!changedTicketsPortalTurnedOn) {
      return this.ticketsPortalTurnedOn;
    }

    return changedTicketsPortalTurnedOn[0];
  }),

  homeUrlForEditing: computed('homeUrl', {
    get() {
      return this.homeUrl;
    },
    set(_, value) {
      //fill the prefix https:// with there is no schema
      //check value.length > 6 is a workaround for users who are typing "http://"
      if (value && value.length > 6 && !value.trim().startsWith('http')) {
        value = `https://${value.trim()}`;
      }
      this.set('homeUrl', value);
      return value;
    },
  }),

  ticketsPortalRuleset: belongsTo('matching-system/ruleset', {
    async: false,
  }),

  dirtyAttributes: computed('externalLoginName', 'externalLoginUrl', function () {
    return Object.keys(this.changedAttributes());
  }),

  hasUnsavedChanges: computed('hasDirtyAttributes', function () {
    let changedAttributesKeys = Object.keys(this.changedAttributes());

    // These attributes simply hold UI state, and are not data attributes
    // as such, if these are the only changes site isn't actually dirty
    if (
      this.hasDirtyAttributes &&
      changedAttributesKeys.length === 2 &&
      changedAttributesKeys.includes('isHelpCenterSectionOpen') &&
      changedAttributesKeys.includes('visibleCollections')
    ) {
      return false;
    } else if (
      this.hasDirtyAttributes &&
      changedAttributesKeys.length === 1 &&
      changedAttributesKeys.includes('headerLinkGroups')
    ) {
      return false;
    } else if (
      this.hasDirtyAttributes &&
      changedAttributesKeys.length === 1 &&
      changedAttributesKeys.includes('visibleCollections')
    ) {
      return false;
    } else {
      return this.hasDirtyAttributes;
    }
  }),

  addCustomizedFooterTextContent(locale) {
    let footerTextContent = this.store.createFragment('help-center-footer-text-content', {
      locale,
      jsonBlocks:
        this.defaultLocale.localeId === locale ? footerContentPlaceholderJsonBlocks(this.intl) : [],
    });
    this.customizedFooterTextContents.pushObject(footerTextContent);
    return footerTextContent;
  },

  addLink(location = 'footer') {
    let emptyLink = this.store.createFragment('help-center-site-link');
    let links = this[`${location}Links`];
    links.pushObject(emptyLink);
    this._reOrderLinks(location);
    return emptyLink;
  },

  addFooterLinkGroup(locale, sortOrder) {
    this._addLinkGroup(this.footerLinkGroups, locale, sortOrder);
  },

  addHeaderLinkGroup(locale, sortOrder) {
    this._addLinkGroup(this.headerLinkGroups, locale, sortOrder);
  },

  _addLinkGroup(linkGroupArray, locale, sortOrder) {
    let emptyLinkGroup = this.store.createFragment('help-center-site-link-group');
    emptyLinkGroup.locale = locale;
    emptyLinkGroup.sortOrder = sortOrder;
    linkGroupArray.pushObject(emptyLinkGroup);
    return emptyLinkGroup;
  },

  removeLink(link, location = 'footer') {
    let links = this[`${location}Links`];
    links.removeObject(link);
    this._reOrderLinks(location);
  },

  _reOrderLinks(location = 'footer') {
    let links = this[`${location}Links`];
    links.forEach((link, index) => {
      link.set('sortOrder', index + 1);
    });
  },

  removeSocialLink(socialLink) {
    this.socialLinks.removeObject(socialLink);
  },

  addSocialLink(socialNetwork) {
    this.socialLinks.pushObject(
      this.store.createFragment('help-center-social-link', { socialNetwork }),
    );
  },

  canAddLinks(location = 'footer') {
    if (location === 'header') {
      return this.canAddHeaderLinks;
    } else {
      return this.canAddFooterLinks;
    }
  },

  hasMissingImages() {
    let collectionCard = this.customizationOptions?.collectionCard;
    if (!collectionCard) {
      return false;
    }

    if (collectionCard.global.imageType !== 'images') {
      return false;
    }

    if (collectionCard.global.backgroundImageUrl) {
      return false;
    }

    let collectionMap = collectionCard.collections.reduce((map, col) => {
      map[`${col.collectionId}`] = col;
      return map;
    }, {});
    return this.visibleCollections.some(
      (col) => !collectionMap[col.id]?.outboundContent.backgroundImageUrl,
    );
  },

  supportsLocale(locale) {
    return this.selectedLocales.any((supportedLocale) => supportedLocale.localeId === locale);
  },

  ready() {
    scheduleOnce('afterRender', this, this.setupDefaultPredicateGroup);
  },

  setupDefaultPredicateGroup() {
    if (this.ticketsPortalRuleset) {
      return;
    }

    let ruleset = this.store.createRecord('matching-system/ruleset');
    ruleset.set('predicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('clientPredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('rolePredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('defaultPredicateGroup', this.store.createFragment('predicates/predicate-group'));

    this.ticketsPortalRuleset = ruleset;
  },

  initializeLocalisedContent(localeId) {
    let selectedLocale = this.selectedLocales.find(
      (selectedLocale) => selectedLocale.localeId === localeId,
    );
    if (!selectedLocale?.contentBlock) {
      selectedLocale?.initializeContentBlock();
    }
  },
});
