/* RESPONSIBLE TEAM: team-ai-agent */
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { attr, type SyncHasMany } from '@ember-data/model';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { buildValidations, validator } from 'ember-cp-validations';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';

const Validations: Validations<$TSFixMe> = buildValidations({
  ticketTypeId: [
    validator('presence', {
      presence: true,
      disabled: computed('model.endingType', function (this: { model: AnswerTerminal }) {
        return this.model.endingType !== 'send_ticket';
      }),
      messageKey: 'operator.workflows.visual-builder.validations.ticket-type-missing',
    }),
  ],
  endingType: [
    validator('visual-builder/send-ticket-channel-is-valid', {
      disabled: computed('model.endingType', function (this: { model: AnswerTerminal }) {
        return this.model.endingType !== 'send_ticket';
      }),
    }),
  ],
});

type EndingType =
  | 'show_standard_replies'
  | 'pass_to_team'
  | 'resolve'
  | 'finish_answer'
  | 'send_ticket';

export default class AnswerTerminal extends Step.extend(Validations) {
  @attr('string') declare endingType: EndingType;
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;
  @fragment('rules/rule') declare simpleActionRule?: any;
  @attr('string') declare ticketTypeId?: string;
  @attr('boolean') declare disableComposer?: boolean;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.answerTerminal;
  }

  static get requiredBillingFeature() {
    let app = getApp();

    if (app.canUseAiAgentWorkflows) {
      return 'ai_answers_billing_feature';
    }

    return 'answer_workflows';
  }

  get analyticsData() {
    return {
      ...super.analyticsData,
      ending_type: this.endingType,
      ticket_type_id: this.ticketTypeId,
    };
  }

  get hasSimpleActionRule() {
    return !!this.simpleActionRule;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): AnswerTerminal {
    return store.createRecord('operator/visual-builder/step/answer-terminal', {
      type: 'operator/visual-builder/step/answer-terminal',
      endingType: 'show_standard_replies',
    });
  }
}
