/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import Model, { attr, hasMany } from '@ember-data/model';
import { A } from '@ember/array';
import $ from 'jquery';
import { isPresent } from '@ember/utils';
import EmberObject, { computed, set } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  alias,
  reads,
  notEmpty,
  not,
  equal,
  and,
  filterBy,
  or,
  readOnly,
  gt,
} from '@ember/object/computed';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import UserPresence from 'embercom/models/types/user-presence-model';
import ajax, { get } from 'embercom/lib/ajax';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import DataUpdateContext from 'embercom/models/data/data-update-context';

export default Model.extend(UserPresence, TaggingMixin, UserAvatarData, {
  appService: service(),
  intl: service(),
  user_id: attr(),
  app_id: attr(),
  name: attr(),
  is_anonymous: attr(),
  role: attr(),
  display_as: attr(),
  email: attr(),
  email_domain: attr(),
  phone: attr(),
  whatsapp_number: attr(),
  whatsapp_number_source: attr(),
  avatar: attr(),
  geoip_data: attr(),
  has_device_token: attr(),
  mobile_unidentified: attr(),
  language_override: attr(),
  last_heard_from_at: attr(),
  last_contacted_at: attr(),
  last_outbound_content_received_at_data: attr(),
  last_conversation_rating_given: attr(),
  last_request_at: attr(),
  last_visited_url: attr(),
  recent_page_views: attr('array', { defaultValue: () => [] }),
  recentPageViews: alias('recent_page_views'),
  last_request_at_ios: attr(),
  last_request_at_android: attr(),
  last_session_start: attr(),
  last_email_opened_at: attr(),
  last_email_clicked_at: attr(),
  remote_created_at: attr(),
  created_at: attr(),
  updated_at: attr(),
  session_count: attr(),
  session_count_ios: attr(),
  session_count_android: attr(),
  social_accounts: attr(),
  ios_app_name: attr(),
  ios_app_version: attr(),
  ios_device: attr(),
  ios_os_version: attr(),
  android_app_name: attr(),
  pseudonym: attr(),
  android_app_version: attr(),
  android_device: attr(),
  android_os_version: attr(),

  companies: hasMany('company'),

  unsubscribed_from_emails: attr(),
  marked_email_as_spam: attr(),
  has_hard_bounced: attr(),
  opted_in_subscription_type_ids: attr(),
  opted_out_subscription_type_ids: attr(),

  sms_consent: attr(),
  unsubscribed_from_sms: attr(),
  hard_unsubscribed_from_sms: attr(),
  phone_country: attr(),

  // canNotBeEmailed does not include unsubscribed_from_emails as we allow 1:1 emails to be sent.
  canNotBeEmailed: or('hasNoEmail', 'marked_email_as_spam', 'has_hard_bounced'),

  user_agent_data: attr(),
  custom_data: attr(),
  metadata: attr(),
  qualification_company_id: attr(),
  isAdmin: false,

  tags: hasMany('tag'),

  taggings: hasMany('tagging'),
  referrer: attr(),
  owner_id: attr(),
  ownerId: alias('owner_id'),
  account_name: attr(),
  lead_category: attr(),
  utm_campaign: attr(),
  utm_content: attr(),
  utm_medium: attr(),
  utm_source: attr(),
  utm_term: attr(),

  unloadRecord() {
    this.taggings && this.taggings.compact().forEach((t) => t.unloadRecord);
    this._super(...arguments);
  },

  user_event_summaries: fragmentArray('event-summary'),

  displayAsDidUpdate: false,
  consents_to_impersonation: attr(),
  browser_locale: attr(),

  locale: computed('browser_locale', function () {
    return getEmberDataStore()
      .peekRecord('app', this.app_id)
      .get('browser_locales')
      .findBy('locale_id', this.browser_locale);
  }),

  country: alias('geoip_data.country_code2'),
  timezone: reads('geoip_data.timezone'),
  hasTimezone: notEmpty('timezone'),
  socialAccountsUserNamePosition: { facebook: 3, twitter: 3, linkedin: 4 },
  isNotAnonymous: not('is_anonymous'),
  isUserRole: equal('role', 'user_role'),
  isLeadRole: equal('role', 'contact_role'),
  isLeadWithNoEmail: and('isLeadRole', 'hasNoEmail'),

  localTime: computed('hasTimezone', 'timezone', function () {
    if (this.hasTimezone) {
      return this.intl.formatTime(new Date(), {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: this.timezone,
      });
    }
  }),

  first_name: computed('displayAs', function () {
    return this.displayAs.split(' ')[0];
  }),
  computedUrl: computed('app_id', 'id', function () {
    return `apps/${this.app_id}/users/${this.id}`;
  }),

  notes: hasMany('note'),

  event_summaries: alias('user_event_summaries'),
  nameOrEmail: ternaryToProperty('hasEmailOnly', 'email', 'name'),
  typeLabel: computed('intl.locale', 'is_anonymous', function () {
    return this.is_anonymous
      ? this.intl.t('users.profile.lead')
      : this.intl.t('users.profile.user');
  }),

  displayAs: computed('name', 'email', 'display_as', 'pseudonym', function () {
    return this.name || this.email || this.pseudonym || this.display_as || 'unknown';
  }),

  searchableProperties: computed('display_as', 'email', function () {
    return [this.display_as, this.email];
  }),

  hasNameAndEmail: and('name', 'email'),

  hasEmailOnly: computed('name', 'email', function () {
    return !this.name && this.email;
  }),

  existingTags: filterBy('tags', 'isLoaded'),
  existingSegments: filterBy('segments', 'isLoaded'),

  shouldDisplayEmailRollover: computed('display_as', 'email', 'hasEmailPresent', function () {
    return this.display_as !== this.email && this.hasEmailPresent;
  }),

  shouldDisplayPageActivity: notEmpty('last_visited_url'),
  hasEmailPresent: notEmpty('email'),
  hasNoEmail: not('hasEmailPresent'),
  mostRecentCompany: alias('companies.firstObject'),
  hasDisplayableCompany: or('mostRecentCompany.notManuallyCreated', 'mostRecentCompany.name'),
  qualificationCompanyId: readOnly('qualificationCompany.id'),

  qualificationCompany: computed('companies.length', 'qualification_company_id', function () {
    let qualificationCompany = this.companies.findBy('id', String(this.qualification_company_id));

    if (qualificationCompany) {
      return qualificationCompany;
    } else if (isPresent(this.companies)) {
      return this.mostRecentCompany;
    } else {
      return EmberObject.create({ user_count: 0 });
    }
  }),

  hasIosAttributes: gt('session_count_ios', 0),
  hasAndroidAttributes: gt('session_count_android', 0),
  hasMobileAttributes: or('hasIosAttributes', 'hasAndroidAttributes'),
  hasMobileOrSocialAttributes: or('hasMobileAttributes', 'hasSocialAccounts'),
  hasNotes: notEmpty('notes'),

  getValue(identifier) {
    let value;
    try {
      value = this.get(identifier);
    } catch (e) {
      if (!identifier.startsWith('custom_data.')) {
        return;
      }
      let subIdentifiers = identifier.split('.');
      value = this._data[subIdentifiers[0]];
      for (let i = 1; i < subIdentifiers.length; i++) {
        value = value[subIdentifiers[i]];
      }
    }
    return value;
  },

  segment_ids: attr('array', { defaultValue: () => [] }),
  segments: computed('segment_ids.[]', function () {
    let store = getEmberDataStore();
    // Filter out null segments https://github.com/intercom/intercom/issues/141011
    return this.segment_ids
      .map((id) => store.peekRecord('segment', id))
      .filter((segment) => segment);
  }),
  segmentsError: false,
  loadSegments: task(function* () {
    this.set('segmentsError', false);
    try {
      let response = yield get('/ember/users/segments.json', {
        app_id: this.app_id || this.app.id,
        id: this.id,
      });
      this.set('segment_ids', response.segment_ids);
    } catch (e) {
      console.error(e);
      this.set('segment_ids', []);
      this.set('segmentsError', true);
    }
  }).drop(),

  loadSeries: task(function* () {
    let response = yield get('/ember/users/series.json', {
      app_id: this.app_id || this.app.id,
      id: this.id,
    });
    this.set('series', response);
  }).drop(),

  displayableSocialAccounts: computed('social_accounts', function () {
    let accounts = {};
    let unfilteredAccounts = this.social_accounts || {};
    let accountsUserNamePosition = this.socialAccountsUserNamePosition;
    let accountNames = Object.keys(accountsUserNamePosition);

    accountNames.forEach(function (accountName) {
      if (!(accountName in unfilteredAccounts)) {
        return;
      }

      accounts[accountName] = unfilteredAccounts[accountName];
      accounts[accountName].accounts.forEach(function (el) {
        if (el.username) {
          set(el, 'usernameLink', el.username);
        } else if (el.url) {
          let usernamePosition = accountsUserNamePosition[accountName];
          let urlSplitOnSlash = el.url.split('/');
          set(el, 'usernameLink', urlSplitOnSlash[usernamePosition]);
        } else {
          set(el, 'usernameLink', 'See profile');
        }
      });
    });

    return accounts;
  }),

  hasSocialAccounts: computed('social_accounts', function () {
    return !$.isEmptyObject(this.displayableSocialAccounts);
  }),

  otherCompanies: computed('companies', function () {
    let mostRecentCompany = this.mostRecentCompany;
    return this.companies.filter(function (company) {
      return company && company !== mostRecentCompany;
    });
  }),

  otherCompanyNames: computed('otherCompanies', function () {
    return this.otherCompanies
      .map(function (company) {
        return company.get('name');
      })
      .join(', ');
  }),

  otherCompaniesCount: alias('otherCompanies.length'),
  updateTaggingUrl: '/ember/users/update_tags.json',

  app: readOnly('appService.app'),
  companyEmailAddresses: readOnly('app.companyEmailAddresses'),
  emailIsAppCompanyAddress: computed('companyEmailAddresses.[]', function () {
    return !!this.companyEmailAddresses.findBy('email_address', this.email);
  }),

  hasMailingListSource: equal(
    'metadata.data_sources.email.source',
    DataUpdateContext.INBOUND_MAILING_LIST_SOURCE,
  ),

  hasWhatsappNumber: notEmpty('whatsapp_number'),
  isWhatsappLead: and('hasWhatsappNumber', 'isLeadRole'),

  updateLeadEmail(email) {
    let params = {
      app_id: this.app_id,
      email,
      lead_id: this.id,
    };

    return ajax({
      url: '/ember/lead_email.json',
      type: 'POST',
      data: JSON.stringify(params),
    });
  },

  updateLeadName(name) {
    let params = {
      app_id: this.app_id,
      name,
      lead_id: this.id,
    };

    return ajax({
      url: '/ember/user_attributes/update_name.json',
      type: 'POST',
      data: JSON.stringify(params),
    });
  },

  saveUnsubscribedFromEmails() {
    let params = {
      id: this.id,
      app_id: this.app_id,
      unsubscribed_from_emails: this.unsubscribed_from_emails,
    };
    return ajax({
      url: '/ember/users/update_unsubscribed_from_emails.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  saveSmsConsent() {
    let params = {
      id: this.id,
      app_id: this.app_id,
      sms_consent: this.sms_consent,
    };
    return ajax({
      url: '/ember/users/update_sms_consent.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  saveUnsubscribedFromSms() {
    let params = {
      id: this.id,
      app_id: this.app_id,
      unsubscribed_from_sms: this.unsubscribed_from_sms,
    };
    return ajax({
      url: '/ember/users/update_unsubscribed_from_sms.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  updateSubscriptionTypeIds() {
    let params = {
      id: this.id,
      app_id: this.app_id,
      opted_in_subscription_type_ids: this.opted_in_subscription_type_ids || [],
      opted_out_subscription_type_ids: this.opted_out_subscription_type_ids || [],
    };
    return ajax({
      url: '/ember/users/update_subscription_type_ids.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  blockUser(conversation) {
    let options = {
      id: this.id,
      app_id: this.app_id,
    };
    if (conversation) {
      options.conversation_id = conversation.get('id');
    }
    return ajax({
      url: '/ember/users/block',
      type: 'POST',
      data: JSON.stringify(options),
    });
  },

  addToCompany(remoteCompanyId, companyName) {
    let params = {
      id: this.id,
      app_id: this.app_id,
      remote_company_id: remoteCompanyId,
      company_name: companyName,
    };
    return ajax({
      url: '/ember/users/add_to_company.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  removeFromCompany(qualificationCompanyId) {
    let params = {
      id: this.id,
      app_id: this.app_id,
      qualification_company_id: qualificationCompanyId,
    };
    return ajax({
      url: '/ember/users/remove_from_company.json',
      type: 'PUT',
      data: JSON.stringify(params),
    });
  },

  analyticsData: computed('id', 'role', function () {
    return {
      object: 'user',
      user_id: this.id,
      user_role: this.role,
    };
  }),
  _setAndNotifyChange(keyName, value) {
    this.store.pushPayload({ user: { id: this.id, [keyName]: value } });
  },
}).reopenClass({
  search(app, predicates, options) {
    let self = this;
    let sort_by = options.sort_by;
    let sort_direction = options.sort_direction;

    if (this.xhr && this.xhr.readyState !== 4) {
      //we don't want concurrent requests
      this.xhr.abort();
    }

    let sortedAttributeSetting = app.get('userAttributeSettings').findBy('isSorted', true);
    if (sortedAttributeSetting) {
      sort_by = sort_by || sortedAttributeSetting.get('attribute');
      sort_direction = sort_direction || sortedAttributeSetting.get('sort_by');
    }

    return ajax({
      beforeSend(xhr) {
        self.xhr = xhr;
      },
      url: '/ember/users/search.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: app.get('id'),
        predicates,
        segment_id: options.segmentId,
        page: options.page || 1,
        per_page: options.per_page || 25,
        sort_by,
        include_count: false,
        sort_direction,
      }),
    }).then((response) => {
      let array = A();

      if (response.users) {
        let store = getEmberDataStore();
        store.pushPayload({ users: response.users });

        response.users.forEach(function (user) {
          array.pushObject(store.peekRecord('user', user.id));
        });
      }

      return {
        users: array,
        totalCount: response.total_count,
        totalPages: response.total_pages,
        limited: response.limited,
      };
    });
  },

  getMaybeMergedUser(userId) {
    let store = getEmberDataStore();

    return store.findRecord('user', userId).catch((response) => {
      if (response.jqXHR && response.jqXHR.status === 302) {
        let id = response.jqXHR.responseJSON.merged_into_user_id;
        if (id !== null && id !== undefined) {
          return store.findRecord('user', id);
        }
      }
    });
  },
});
