/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';

export type LinkedEntityAction =
  | 'started_linked_conversation'
  | 'linked_existing_ticket'
  | 'linked_ticket_to_conversation'
  | 'linked_customer_ticket_to_tracker';

interface EntityLinkedWireFormat {
  admin_summary: AdminSummaryWireFormat;
  linked_conversation_id?: number; // legacy property
  linked_conversation_user_summary?: UserSummaryWireFormat;
  entity_id: number;
  linked_entity_action?: LinkedEntityAction;
  ticket_type_id?: number;
  user_facing_ticket_id?: number;
  ticket_title?: string;
}

export default class EntityLinked implements RenderableData {
  renderableType: RenderableType = RenderableType.EntityLinked;
  readonly adminSummary: AdminSummary;
  readonly entityId: number;
  readonly linkedEntityAction: LinkedEntityAction;
  readonly ticketTypeId: number | undefined;
  readonly linkedConversationUserSummary: UserSummary | undefined;
  readonly userFacingTicketId: number | undefined;
  readonly ticketTitle: string | undefined;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    adminSummary: AdminSummary,
    entityId: number,
    linkedEntityAction: LinkedEntityAction,
    ticketTypeId?: number,
    linkedConversationUserSummary?: UserSummary,
    userFacingTicketId?: number,
    ticketTitle?: string,
  ) {
    this.entityId = entityId;
    this.adminSummary = adminSummary;
    this.ticketTypeId = ticketTypeId;
    this.linkedEntityAction = linkedEntityAction;
    this.linkedConversationUserSummary = linkedConversationUserSummary;
    this.userFacingTicketId = userFacingTicketId;
    this.ticketTitle = ticketTitle;
  }

  get adminOnly() {
    return true;
  }

  get styles() {
    return {
      backgroundColor: 'bg-beta-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  static deserialize(json: EntityLinkedWireFormat): EntityLinked {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let entityId = json.linked_conversation_id || json.entity_id;
    let linkedEntityAction = json.linked_entity_action || 'started_linked_conversation';
    let linkedConversationUserSummary = json.linked_conversation_user_summary
      ? UserSummary.deserialize(json.linked_conversation_user_summary)
      : undefined;

    return new EntityLinked(
      adminSummary,
      entityId,
      linkedEntityAction,
      json.ticket_type_id,
      linkedConversationUserSummary,
      json.user_facing_ticket_id,
      json.ticket_title,
    );
  }
}
