/* RESPONSIBLE TEAM: team-phone */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface Call {
  id: string;
  type: string;
  direction: string;
  state: string;
  external_id: string | null;
  admin: AdminSummaryWireFormat;
  initiated_at: Date;
  answered_at: Date | null;
  ended_at: Date | null;
  duration: number | null;
  recording_enabled: boolean;
  recording_url: string | null;
  transcript_url: string | null;
  ended_reason: string | null;
  deflection_type: string | null;
  deflection_trigger: string | null;
  recording_name: string | null;
  forwarded: boolean;
  externally_transferred_to: string | null;
  detailed_state: string | null;
  is_recorded: boolean;
  csat_rating: string | null;
  call_listening_enabled: boolean;
}

interface CallSummaryWireFormat {
  renderable_type: RenderableType;
  call: Call;
}

export default class CallSummary implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.CallSummary;
  readonly id: string;
  readonly type: string;
  readonly direction: string;
  readonly state: string;
  readonly externalId: string | null;
  readonly admin: AdminSummaryWireFormat;
  readonly initiatedAt: Date;
  readonly answeredAt: Date | null;
  readonly endedAt: Date | null;
  readonly duration: number | null;
  readonly recordingEnabled: boolean;
  readonly recordingUrl: string | null;
  readonly transcriptUrl: string | null;
  readonly endedReason: string | null;
  readonly deflectionType: string | null;
  readonly deflectionTrigger: string | null;
  readonly recordingName: string | null;
  readonly forwarded: boolean;
  readonly externallyTransferredTo: string | null;
  readonly detailedState: string | null;
  readonly isRecorded: boolean;
  readonly csatRating: string | null;
  readonly callListeningEnabled: boolean;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  get styles() {
    return {
      backgroundColor: 'bg-notice-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-notice-container-emphasis',
    };
  }

  constructor(
    id: string,
    type: string,
    direction: string,
    state: string,
    external_id: string | null,
    admin: AdminSummaryWireFormat,
    initiated_at: Date,
    answered_at: Date | null,
    ended_at: Date | null,
    duration: number | null,
    recording_enabled: boolean,
    recording_url: string | null,
    transcript_url: string | null,
    ended_reason: string | null,
    deflection_type: string | null,
    deflection_trigger: string | null,
    recording_name: string | null,
    forwarded: boolean,
    externally_transferred_to: string | null,
    detailed_state: string | null,
    is_recorded: boolean,
    csat_rating: string | null,
    call_listening_enabled: boolean,
  ) {
    this.id = id;
    this.type = type;
    this.direction = direction;
    this.state = state;
    this.externalId = external_id;
    this.admin = admin;
    this.initiatedAt = initiated_at;
    this.answeredAt = answered_at;
    this.endedAt = ended_at;
    this.duration = duration;
    this.recordingEnabled = recording_enabled;
    this.recordingUrl = recording_url;
    this.transcriptUrl = transcript_url;
    this.endedReason = ended_reason;
    this.deflectionType = deflection_type;
    this.deflectionTrigger = deflection_trigger;
    this.recordingName = recording_name;
    this.forwarded = forwarded;
    this.externallyTransferredTo = externally_transferred_to;
    this.detailedState = detailed_state;
    this.isRecorded = is_recorded;
    this.csatRating = csat_rating;
    this.callListeningEnabled = call_listening_enabled;
  }

  static deserialize(json: CallSummaryWireFormat): CallSummary {
    return new CallSummary(
      json.call.id,
      json.call.type,
      json.call.direction,
      json.call.state,
      json.call.external_id,
      json.call.admin,
      json.call.initiated_at,
      json.call.answered_at,
      json.call.ended_at,
      json.call.duration,
      json.call.recording_enabled,
      json.call.recording_url,
      json.call.transcript_url,
      json.call.ended_reason,
      json.call.deflection_type,
      json.call.deflection_trigger,
      json.call.recording_name,
      json.call.forwarded,
      json.call.externally_transferred_to,
      json.call.detailed_state,
      json.call.is_recorded,
      json.call.csat_rating,
      json.call.call_listening_enabled,
    );
  }
}
