/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type DeserializableRenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from './renderable-types';
import UserComment from 'embercom/objects/inbox/renderable/user-comment';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import AdminNote from 'embercom/objects/inbox/renderable/admin-note';
import BotNote from 'embercom/objects/inbox/renderable/bot-note';
import BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import StateChange from 'embercom/objects/inbox/renderable/state-change';
import UnknownPart from 'embercom/objects/inbox/renderable/unknown-part';
import Assignment from 'embercom/objects/inbox/renderable/assignment';
import TitleChanged from 'embercom/objects/inbox/renderable/title-changed';
import QuickReplyOptions from 'embercom/objects/inbox/renderable/quick-reply-options';
import QuickReplyResponse from 'embercom/objects/inbox/renderable/quick-reply-response';
import AttributeCollector from 'embercom/objects/inbox/renderable/attribute-collector';
import UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';
import AttributeCollected from 'embercom/objects/inbox/renderable/attribute-collected';
import ConversationAttributeUpdated from 'embercom/objects/inbox/renderable/conversation-attribute-updated';
import ConversationSlaAppliedByWorkflow from 'embercom/objects/inbox/renderable/conversation-sla-applied-by-workflow';
import ConversationSlaRemoved from 'embercom/objects/inbox/renderable/conversation-sla-removed';
import ConversationSlaPaused from 'embercom/objects/inbox/renderable/conversation-sla-paused';
import ConversationSlaUnpaused from 'embercom/objects/inbox/renderable/conversation-sla-unpaused';
import Chat from 'embercom/objects/inbox/renderable/chat';
import PriorityChanged from 'embercom/objects/inbox/renderable/priority-changed';
import CustomBot from 'embercom/objects/inbox/renderable/custom-bot';
import PriorityChangedByWorkflow from 'embercom/objects/inbox/renderable/priority-changed-by-workflow';
import Email from 'embercom/objects/inbox/renderable/email';
import ConversationSlaTargetMissed from 'embercom/objects/inbox/renderable/conversation-sla-target-missed';
import ChannelAndReplyTimeExpectation from 'embercom/objects/inbox/renderable/channel-and-reply-time-expectation';
import DuplicateConversationNote from 'embercom/objects/inbox/renderable/duplicate-conversation-note';
import ParticipantAdded from 'embercom/objects/inbox/renderable/participant-added';
import ParticipantRemoved from 'embercom/objects/inbox/renderable/participant-removed';
import ParticipantBlocked from 'embercom/objects/inbox/renderable/participant-blocked';
import UnengagedInboundBotIntroduction from 'embercom/objects/inbox/renderable/unengaged-inbound-bot-introduction';
import ConversationRatingChanged from 'embercom/objects/inbox/renderable/conversation-rating-changed';
import ConversationRatingRemarkAdded from 'embercom/objects/inbox/renderable/conversation-rating-remark-added';
import PriorityChangedByRule from 'embercom/objects/inbox/renderable/priority-changed-by-rule';
import UnknownParticipantForEmailConversationNote from 'embercom/objects/inbox/renderable/unknown-participant-for-email-conversation-note';
import Article from 'embercom/objects/inbox/renderable/article';
import AttachmentsDropped from 'embercom/objects/inbox/renderable/attachments-dropped';
import ConversationSlaAppliedByRule from 'embercom/objects/inbox/renderable/conversation-sla-applied-by-rule';
import Post from 'embercom/objects/inbox/renderable/post';
import UserBecameIdle from 'embercom/objects/inbox/renderable/user-became-idle';
import AiAgentFoundNoAnswer from 'embercom/objects/inbox/renderable/ai-agent-found-no-answer';
import ChannelSwitched from 'embercom/objects/inbox/renderable/channel-switched';
import TicketStateUpdatedByAdmin from 'embercom/objects/inbox/renderable/ticket-state-updated-by-admin';
import TicketStateUpdated from 'embercom/objects/inbox/renderable/ticket-state-updated';
import AvailableFinActions from 'embercom/objects/inbox/renderable/available-fin-actions';
import CustomActionStarted from 'embercom/objects/inbox/renderable/custom-action-started';
import CustomActionFinished from 'embercom/objects/inbox/renderable/custom-action-finished';
import Deleted from 'embercom/objects/inbox/renderable/deleted';
import Sms from 'embercom/objects/inbox/renderable/sms';
import PhoneCall from 'embercom/objects/inbox/renderable/phone-call';
import Whatsapp from 'embercom/objects/inbox/renderable/whatsapp';
import ConversationSummary from 'embercom/objects/inbox/renderable/conversation-summary';
import RatingRequestNotSent from 'embercom/objects/inbox/renderable/rating-request-not-sent';
import TicketShared from 'embercom/objects/inbox/renderable/ticket-shared';
import LinkedTicketShared from 'embercom/objects/inbox/renderable/linked-ticket-shared';
import OperatorWorkflowEvent from 'embercom/objects/inbox/renderable/operator-workflow-event';
import CallSummary from 'embercom/objects/inbox/renderable/call-summary';
import EntityLinked from 'embercom/objects/inbox/renderable/entity-linked';
import CreatedByForwarding from 'embercom/objects/inbox/renderable/created-by-forwarding';
import Forwarded from 'embercom/objects/inbox/renderable/forwarded';
import FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';
import EntitiesUnLinked from 'embercom/objects/inbox/renderable/entities-unlinked';
import SideConversationStarted from 'embercom/objects/inbox/renderable/side-conversation-started';
import AutoSummarizeLimitReached from 'embercom/objects/inbox/renderable/auto-summarize-limit-reached';
import SideConversationReply from 'embercom/objects/inbox/renderable/side-conversation-reply';
import TransferCall from 'embercom/objects/inbox/renderable/transfer-call';
import MergedConversationReply from 'embercom/objects/inbox/renderable/merged-conversation-reply';
import MergedUserComment from 'embercom/objects/inbox/renderable/merged-user-comment';
import MergedSecondaryConversation from 'embercom/objects/inbox/renderable/merged-secondary-conversation';
import MergedPrimaryConversation from 'embercom/objects/inbox/renderable/merged-primary-conversation';
import LanguageDetectionDetails from 'embercom/objects/inbox/renderable/language-detection-details';
import FinGuidanceApplied from 'embercom/objects/inbox/renderable/fin-guidance-applied';
import CallEvent from 'embercom/objects/inbox/renderable/call-event';
import ConversationTagsUpdated from 'embercom/objects/inbox/renderable/conversation-tags-updated';

export const RenderableTypeClassMapping: Record<RenderableType, DeserializableRenderableData> = {
  [RenderableType.Unknown]: UnknownPart,
  [RenderableType.UserComment]: UserComment,
  [RenderableType.AdminComment]: AdminComment,
  [RenderableType.AdminNote]: AdminNote,
  [RenderableType.BotNote]: BotNote,
  [RenderableType.BotComment]: BotComment,
  [RenderableType.StateChange]: StateChange,
  [RenderableType.Assignment]: Assignment,
  [RenderableType.QuickReplyOptions]: QuickReplyOptions,
  [RenderableType.QuickReplyResponse]: QuickReplyResponse,
  [RenderableType.AttributeCollector]: AttributeCollector,
  [RenderableType.UserEmailComment]: UserEmailComment,
  [RenderableType.AttributeCollected]: AttributeCollected,
  [RenderableType.ConversationAttributeUpdated]: ConversationAttributeUpdated,
  [RenderableType.TitleChanged]: TitleChanged,
  [RenderableType.ConversationSlaAppliedByWorkflow]: ConversationSlaAppliedByWorkflow,
  [RenderableType.ConversationSlaRemoved]: ConversationSlaRemoved,
  [RenderableType.ConversationSlaPaused]: ConversationSlaPaused,
  [RenderableType.ConversationSlaUnpaused]: ConversationSlaUnpaused,
  [RenderableType.Chat]: Chat,
  [RenderableType.PriorityChangedByWorkflow]: PriorityChangedByWorkflow,
  [RenderableType.PriorityChanged]: PriorityChanged,
  [RenderableType.CustomBot]: CustomBot,
  [RenderableType.Email]: Email,
  [RenderableType.ConversationSlaTargetMissed]: ConversationSlaTargetMissed,
  [RenderableType.ChannelAndReplyTimeExpectation]: ChannelAndReplyTimeExpectation,
  [RenderableType.DuplicateConversationNote]: DuplicateConversationNote,
  [RenderableType.ParticipantAdded]: ParticipantAdded,
  [RenderableType.ParticipantRemoved]: ParticipantRemoved,
  [RenderableType.ParticipantBlocked]: ParticipantBlocked,
  [RenderableType.UnengagedInboundBotIntroduction]: UnengagedInboundBotIntroduction,
  [RenderableType.ConversationRatingChanged]: ConversationRatingChanged,
  [RenderableType.ConversationRatingRemarkAdded]: ConversationRatingRemarkAdded,
  [RenderableType.PriorityChangedByRule]: PriorityChangedByRule,
  [RenderableType.Article]: Article,
  [RenderableType.AttachmentsDropped]: AttachmentsDropped,
  [RenderableType.UnknownParticipantForEmailConversationNote]:
    UnknownParticipantForEmailConversationNote,
  [RenderableType.ConversationSlaAppliedByRule]: ConversationSlaAppliedByRule,
  [RenderableType.Post]: Post,
  [RenderableType.UserBecameIdle]: UserBecameIdle,
  [RenderableType.AiAgentFoundNoAnswer]: AiAgentFoundNoAnswer,
  [RenderableType.ChannelSwitched]: ChannelSwitched,
  [RenderableType.TicketStateUpdatedByAdmin]: TicketStateUpdatedByAdmin,
  [RenderableType.AvailableFinActions]: AvailableFinActions,
  [RenderableType.CustomActionStarted]: CustomActionStarted,
  [RenderableType.CustomActionFinished]: CustomActionFinished,
  [RenderableType.TicketStateUpdated]: TicketStateUpdated,
  [RenderableType.Deleted]: Deleted,
  [RenderableType.Sms]: Sms,
  [RenderableType.PhoneCall]: PhoneCall,
  [RenderableType.Whatsapp]: Whatsapp,
  [RenderableType.ConversationSummary]: ConversationSummary,
  [RenderableType.RatingRequestNotSent]: RatingRequestNotSent,
  [RenderableType.TicketShared]: TicketShared,
  [RenderableType.LinkedTicketShared]: LinkedTicketShared,
  [RenderableType.OperatorWorkflowEvent]: OperatorWorkflowEvent,
  [RenderableType.CallSummary]: CallSummary,
  [RenderableType.EntityLinked]: EntityLinked,
  [RenderableType.CreatedByForwarding]: CreatedByForwarding,
  [RenderableType.Forwarded]: Forwarded,
  [RenderableType.FinAnswer]: FinAnswer,
  [RenderableType.EntitiesUnlinked]: EntitiesUnLinked,
  [RenderableType.SideConversationStarted]: SideConversationStarted,
  [RenderableType.AutoSummarizeLimitReached]: AutoSummarizeLimitReached,
  [RenderableType.SideConversationReply]: SideConversationReply,
  [RenderableType.TransferCall]: TransferCall,
  [RenderableType.MergedConversationReply]: MergedConversationReply,
  [RenderableType.MergedUserComment]: MergedUserComment,
  [RenderableType.MergedSecondaryConversation]: MergedSecondaryConversation,
  [RenderableType.MergedPrimaryConversation]: MergedPrimaryConversation,
  [RenderableType.LanguageDetectionDetails]: LanguageDetectionDetails,
  [RenderableType.FinGuidanceApplied]: FinGuidanceApplied,
  [RenderableType.CallEvent]: CallEvent,
  [RenderableType.ConversationTagsUpdated]: ConversationTagsUpdated,
};

export const HumanCommentTypes = new Set([
  RenderableType.UserComment,
  RenderableType.AdminComment,
  RenderableType.AdminNote,
  RenderableType.UserEmailComment,
  RenderableType.Chat,
  RenderableType.Email,
  RenderableType.Post,
  RenderableType.TicketStateUpdatedByAdmin,
  RenderableType.Sms,
  RenderableType.Whatsapp,
  RenderableType.MergedConversationReply,
  RenderableType.MergedUserComment,
]);
