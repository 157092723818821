/* RESPONSIBLE TEAM: team-data-foundations */
import Model, { attr } from '@ember-data/model';

export default class DataExportJobRun extends Model {
  @attr('string') job_type;
  @attr('string') status;
  @attr('string') start_time;
  @attr('string') end_time;
  @attr() export_job_definition;
}
