/* RESPONSIBLE TEAM: team-actions */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type SetupActionField from './setup-action-field';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isPresent } from '@ember/utils';
import type SuggestedQuestion from 'embercom/models/workflow-connector/suggested-question';
import { BaseAction } from 'embercom/models/workflow-connector/base-action';
import Admin from 'embercom/models/admin';

export default class SetupAction extends BaseAction {
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare usage: string;
  @attr('string') declare request_attribute_identifiers: string;
  @attr('string') declare response_attribute_identifiers: string;
  @attr('string') declare created_by: string;
  @attr('string') declare appPackageCode: string;
  @attr('boolean') declare isTemplate: boolean;
  @attr('string') declare url: string;
  @fragmentArray('workflow-connector/setup-action-field')
  declare requestParameters: Array<SetupActionField>;
  @fragmentArray('workflow-connector/setup-action-field')
  declare responseFields: Array<SetupActionField>;

  @attr('string') declare httpMethod: string;
  @attr('boolean') declare finEnabled: boolean;
  @attr('string') declare state: string;
  @attr('string') declare iconUrl: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare createdByAdminId: number;
  @attr('number') declare updatedByAdminId: number;
  @attr('boolean', { defaultValue: false }) declare isRecommendedAction: boolean;
  @attr('string') declare tokenId: string | null;
  @attr('number') declare conversationUsage: number;
  @fragmentArray('workflow-connector/suggested-question')
  declare suggestedQuestions: SuggestedQuestion[];

  @hasMany('workflow-connector/setup-action', { async: false })
  declare children: SetupAction[];
  @belongsTo('workflow-connector/setup-action', { async: false, inverse: 'children' })
  declare parent: SetupAction;

  // Custom object inputs come with this format: https://github.com/intercom/intercom/blob/055b02f2c89cbb4a1fa81d2ec720924e794264d7/app/lib/custom_objects/commands/related_objects_context.rb#L55
  // For example: conversation.related_objects.<relationship id>.custom_objects.<custom object name>.<id>
  // All other attributes return their normal identifiers
  get requestAttributeIdentifiersArray() {
    return this.request_attribute_identifiers.split(',');
  }
  // Custom object outputs come with this format: custom_object_attribute.<id>
  // All other attributes return their normal identifiers
  get responseAttributeIdentifiersArray() {
    return this.response_attribute_identifiers.split(',');
  }

  get hasInputs() {
    let hasInput =
      isPresent(this.request_attribute_identifiers) &&
      this.request_attribute_identifiers.length > 0;

    if (this.usage === 'fin') {
      hasInput =
        hasInput || (isPresent(this.requestParameters) && this.requestParameters.length > 0);
    }

    return hasInput;
  }

  get hasOutputs() {
    if (this.usage === 'fin') {
      return isPresent(this.responseFields) && this.responseFields.length > 0;
    } else {
      return (
        isPresent(this.response_attribute_identifiers) &&
        this.response_attribute_identifiers.length > 0
      );
    }
  }

  get createdByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.createdByAdminId?.toString());
  }

  get updatedByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.updatedByAdminId?.toString());
  }
}
