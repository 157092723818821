/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */
import {
  TRIGGERABLE_BOT_TYPE,
  ADMIN_NOTE_CREATED_TARGET,
  ADMIN_NOTE_CREATED_TRIGGER,
  DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import AdminNoteCreatedEditorConfig from 'embercom/objects/visual-builder/configuration/editor/admin-note-created';
import type { BotConfigTarget } from '../configuration';

import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import type { TargetContext } from '../custom-bot';
import {
  computeSelectedTargetContexts,
  ADMIN_ADDS_NOTE_PREDICATES,
} from '../target-context-config';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class AdminNoteCreatedCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get target(): BotConfigTarget {
    return ADMIN_NOTE_CREATED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return ADMIN_NOTE_CREATED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.admin-note-created.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.admin-note-created.description';
  }

  get icon(): InterfaceIconName {
    return 'note';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
      targetContexts: this.selectedTargetContexts,
    });
  }

  get EditorConfigClass() {
    return AdminNoteCreatedEditorConfig;
  }

  get triggerPredicates() {
    return ADMIN_ADDS_NOTE_PREDICATES;
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  get requiredBillingFeature() {
    return 'inbox_rules';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }

  get selectedTargetContexts(): TargetContext[] | null {
    if (this.customBot?.triggerPredicateGroup) {
      return computeSelectedTargetContexts(this.customBot.triggerPredicateGroup);
    } else {
      return null;
    }
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS];
  }

  get isTitleModifiedByWorkflowsSetup(): boolean {
    return true;
  }
}
