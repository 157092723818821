/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { SimpleAction } from 'embercom/objects/inbox/command-k/action';
import type ThemeService from 'embercom/services/theme-service';

export default class ToggleDarkModeAction extends SimpleAction {
  @service declare themeService: ThemeService;

  id = 'toggle-dark-mode';
  icon = 'theme' as const;

  get label() {
    return this.themeService.darkModeEnabled
      ? this.intl.t('inbox.switch-light-mode')
      : this.intl.t('inbox.switch-dark-mode');
  }
}
