/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import {
  TIME_GROUP_PROPERTY_IDENTIFIER,
  getDefaultSegmentByPropertyBySource,
} from 'embercom/lib/reporting/custom/object-catalog';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import Range from 'embercom/models/reporting/range';
import { closestSafeIntervalFor } from 'embercom/lib/reporting/custom/safe-limits';
import ajax from 'embercom/lib/ajax';
import { isDateCDA } from 'embercom/lib/reporting/custom/filter-helpers';
import {
  VISUALIZATION_TYPES,
  visualizationSupportsFeature,
  STACKED,
  SHOW_DATA_LABELS,
  MULTI_METRICS,
  TARGET,
  TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
  MULTI_UNITS,
} from 'embercom/models/reporting/custom/visualization-options';
import { VISUALIZATION_TYPE_GRID_SIZES } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';
import { TIME, HOUR_OF_DAY, DAY_OF_WEEK } from 'embercom/lib/reporting/flexible/constants';
import generateUUID from 'embercom/lib/uuid-generator';
import { copy } from 'ember-copy';
import { METRIC_UNIT_MAP } from 'embercom/objects/reporting/unified/metrics/types';

// width and height types go hand in hand with enum described here
// https://github.com/intercom/intercom/blob/d3878db79f0b6283d60cb7ce28f71bb7998742fa/app/services/reporting_service/models/custom_chart.rb#L22-L23

export const WIDTH_TYPES = {
  HALF: 'half',
  FULL: 'full',
  ONE_THIRD: 'one-third',
  ONE_QUARTER: 'one-quarter',
  TWO_COLUMN: '2-column',
  THREE_COLUMN: '3-column',
  FOUR_COLUMN: '4-column',
  ONE_SIXTH: '6-column',
};

export const HEIGHT_TYPES = {
  SMALL: 'small',
  TALL: 'tall',
  X_TALL: 'x-tall',
  X_SMALL: 'x-small',
  FULL: 'full',
  TINY: 'tiny',
};

const SECONDS_IN = {
  minute: 60,
  hour: 3600,
  day: 3600 * 24,
};

export default class Chart extends Model {
  @service appService;
  @service reportingMetrics;
  @service store;

  @attr('string') title;
  @attr('string') description;
  @attr('string', { defaultValue: VISUALIZATION_TYPES.BAR }) visualizationType;
  @attr('string', { defaultValue: TIME_GROUP_PROPERTY_IDENTIFIER }) viewBy;
  @attr('string', { defaultValue: 'desc', shouldSerialize: false }) viewBySortDirection; // 'asc' | 'desc';
  @attr('string', { defaultValue: null }) viewByTimeInterval;
  @attr('number', { defaultValue: 10 }) viewByDisplayLimit;
  @attr('string') segmentBy;
  @attr('string', { defaultValue: null }) segmentByTimeInterval;
  @attr('number', { defaultValue: 7 }) segmentByDisplayLimit;
  @attr('boolean', { defaultValue: false }) segmentByDisplayOther;
  @attr('boolean', { defaultValue: false }) viewByDisplayOther;
  @attr createdById;
  @attr updatedById;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @fragment('reporting/custom/date-range', {
    defaultValue: {
      selection: 'past_4_weeks',
    },
  })
  dateRange;
  @attr('boolean', { defaultValue: false }) stacked;
  @attr('string') componentName;
  @attr('string', { defaultValue: WIDTH_TYPES.HALF }) widthType;
  @attr('string', { defaultValue: HEIGHT_TYPES.TALL }) heightType;
  @attr({ defaultValue: () => [], shouldSerialize: false }) ignoredFilters;
  @attr({ defaultValue: null, shouldSerialize: false }) tooltip;
  @fragmentArray('reporting/custom/chart-series') chartSeries;
  @attr('string', { shouldSerialize: false }) footerText;
  @attr('string', { shouldSerialize: false }) footerCtaComponent;
  @attr('boolean', { defaultValue: true, shouldSerialize: false }) showDefaultChartTitle;
  @attr('number', { shouldSerialize: false }) numberRowsVisible;
  @attr('boolean', { defaultValue: false, shouldSerialize: false }) shouldRenderChrome;
  @fragmentArray('reporting/custom/chart-series', { shouldSerialize: false }) chartSeriesSnapshot;
  @attr('string') templateId;
  @fragment('reporting/custom/visualization-options', {
    defaultValue: () => ({}),
  })
  visualizationOptions;
  @attr('number') gridPositionX;
  @attr('number') gridPositionY;
  @attr('number') gridWidth;
  @attr('number') gridHeight;

  @attr('boolean', { defaultValue: false, shouldSerialize: false }) disableDrillInPopover;
  @attr('string', { defaultValue: 'attribute-titles', shouldSerialize: false }) drillInIcon;
  @attr('string', { shouldSerialize: false }) drillInLabel;
  @attr('array', { defaultValue: null, shouldSerialize: false }) defaultDrillInAttributes;

  get app() {
    return this.appService.app;
  }

  validateAndSave() {
    this.cleanupConfig(this);
    this.deletePercentileValueForNonPercentileAggregation();
    if (this.app.canSeeR2Beta) {
      this.id = this.id || generateUUID();
      return;
    } else {
      return this.save();
    }
  }

  deepCopyChartAttributes() {
    let normalizedPayload = this.store.normalize(
      'reporting/custom/chart',
      copy(this.serialize(), true),
    );
    return normalizedPayload.data.attributes;
  }

  get isMultimetric() {
    return this.chartSeries?.length > 1;
  }

  cleanupConfig(chart) {
    if (chart.visualizationType === VISUALIZATION_TYPES.COUNTER) {
      let cleanableFields = [
        'segmentBy',
        'segmentByDisplayLimit',
        'segmentByDisplayOther',
        'viewBy',
        'viewByDisplayLimit',
        'viewByDisplayOther',
        'viewByTimeInterval',
      ];
      cleanableFields.forEach((field) => (chart[field] = undefined));
    } else if (chart.visualizationType === VISUALIZATION_TYPES.DONUT) {
      let cleanableFields = ['segmentBy', 'segmentByDisplayLimit', 'segmentByDisplayOther'];
      cleanableFields.forEach((field) => (chart[field] = undefined));
    }
    chart.visualizationOptions?.ensureOptionsAreCompatible(chart.visualizationType);
    return chart;
  }

  deletePercentileValueForNonPercentileAggregation() {
    this.chartSeries.forEach((series) => series.cleanupPercentile());
  }

  get range() {
    return Range.createFromModel(this.dateRange, this.app.timezone);
  }

  get filters() {
    throw new Error('filters should not be accessed directly, use chartSeries instead');
  }

  set filters(_val) {
    throw new Error('do not set chart.filters, use chartSeries instead');
  }

  getRangeWithTimeZone(timezone) {
    if (isPresent(timezone)) {
      return Range.createFromModel(this.dateRange, timezone);
    }
    return this.range;
  }

  applyDateRange(dateRange) {
    this.dateRange.selection = dateRange.selectedRange;

    if (dateRange.selectedRange === 'custom') {
      this.dateRange.start = dateRange.startMoment.toDate();
      this.dateRange.end = dateRange.endMoment.toDate();
    }

    this.updateSafeIntervalLimit();
  }

  updateMetric(metricId, chartSeries, clearSnapshot = true) {
    chartSeries.updateMetric(metricId);

    this.ensureBreakdownsAreCompatibleWithCurrentMetricAndVisualization(metricId);
    this.ensureRelativeValuesIsCompatibleWithCurrentConfiguration();
    this.resetTargetValuesToOriginal();

    if (clearSnapshot) {
      // if the user updates a metric we'll restart the snapshots, we assume the user is starting a new setup
      this.deleteChartSeriesSnapshot();
    }
  }

  get hasTicketMetrics() {
    return this.chartSeries.any((chartSeries) => chartSeries.metric.isTicketMetric);
  }

  removeChartSeries(chartSeries, clearSnapshot = true) {
    this.chartSeries.removeObject(chartSeries);
    if (clearSnapshot) {
      // if the user removes a metric we'll restart the snapshots, we assume the user is starting a new setup
      this.deleteChartSeriesSnapshot();
    }
    this.ensureViewByPropertyIsCompatibleWhenSwitchingSingleMetric();
  }

  addChartSeries(clearSnapshot = true) {
    if (!clearSnapshot) {
      this.takeChartSeriesSnapshot();
    }
    let metricId = this.chartSeries.firstObject.metricId;
    let newChartSeries = this.store.createFragment('reporting/custom/chart-series', {
      metricId,
    });

    // set the current state for the dataLabel option, if there is any with this option enabled we set it to true
    newChartSeries.showDataLabels = this.chartSeries.any((series) => series.showDataLabels);
    this.chartSeries.pushObject(newChartSeries);
    this.updateMetric(metricId, newChartSeries, clearSnapshot);
    if (clearSnapshot) {
      // if the user removes a metric we'll restart the snapshots, we assume the user is starting a new setup
      this.deleteChartSeriesSnapshot();
    }
    this.ensureSetupIsCompatibleWithMultimetrics();
  }

  ensureSetupIsCompatibleWithMultimetrics() {
    this.stacked = false; // stacked is not supported by any visualization with multimetrics
    this.resetTargetValuesToOriginal();
  }

  ensureViewByPropertyIsCompatibleWhenSwitchingSingleMetric() {
    if (!this.isMultimetric) {
      let metric = this.chartSeries.firstObject.metric;
      if (!this.isSupportedBreakdown(this.viewBy, metric)) {
        this.viewBy = TIME_GROUP_PROPERTY_IDENTIFIER;
      }
    }
  }

  toggleShowDataLabels() {
    if (this.isMultimetric) {
      this.chartSeries.forEach((series) => (series.showDataLabels = !series.showDataLabels));
    } else {
      this.chartSeries.firstObject.showDataLabels = !this.chartSeries.firstObject.showDataLabels;
    }
  }

  updateViewBy(newViewBy) {
    this.viewBy = newViewBy;
    if (this.visualizationType === VISUALIZATION_TYPES.COUNTER) {
      this.visualizationType = VISUALIZATION_TYPES.BAR;
    }
    if (this.viewBy === null || this.viewBy === TIME) {
      this.viewByDisplayOther = false;
    }
    this.ensureTimeComparisonIsCompatibleWithCurrentBreakdowns();
  }

  updateSegmentBy(newSegmentBy) {
    this.segmentBy = newSegmentBy;
    if (this.segmentBy === null && !this.supportsStackingWithoutSegmentBy) {
      this.stacked = false;
    }
    if (this.segmentBy === null || this.segmentBy === TIME) {
      this.segmentByDisplayOther = false;
    }
    this.ensureVisualizatonOptionsAreCompatibleWithCurrentConfiguration();
  }

  updateMetricVisualization(newVisualization) {
    this.restoreChartSeriesSnapshot();
    this.visualizationType = newVisualization;
    this.ensureBreakdownsAreCompatibleWithMultiMetricAndVisualization();
    this.ensureVisualizatonOptionsAreCompatibleWithCurrentConfiguration();
    this.ensureChartSeriesAreSetupForVisualization();
    this.ensureMetricsAreInSameUnit();
    if (newVisualization === VISUALIZATION_TYPES.TABLE) {
      this.updateSafeIntervalLimit();
    }
  }

  ensureMetricsAreInSameUnit() {
    let firstSeries = this.chartSeries.firstObject;
    this.removeMetricsWithDifferentUnits(firstSeries.metric.unit);
  }

  removeMetricsWithDifferentUnits(unit) {
    if (!this.isMultimetric || (this.isMultimetric && this.supportsFeature(MULTI_UNITS))) {
      return;
    }
    //We'll probably show a confirmation modal before removing incompatible metrics
    let filteredSeries = this.chartSeries.filter(
      (chartSeries) => METRIC_UNIT_MAP[chartSeries.metric.unit] !== METRIC_UNIT_MAP[unit],
    );
    this.takeChartSeriesSnapshot();
    filteredSeries.forEach((chartSeries) => this.removeChartSeries(chartSeries, false));
  }

  toggleStacked() {
    this.stacked = !this.stacked;
    if (this.stacked && isEmpty(this.segmentBy) && !this.supportsStackingWithoutSegmentBy) {
      this.segmentBy = this.defaultSegmentByProperty;
      this.segmentByDisplayLimit = 7;
      this.segmentByDisplayOther = true;
    }
    this.ensureVisualizatonOptionsAreCompatibleWithCurrentConfiguration();
  }

  get defaultSegmentByProperty() {
    return getDefaultSegmentByPropertyBySource(this.source);
  }

  get source() {
    return this.chartSeries.firstObject.metric.source;
  }

  ensureTimeComparisonIsCompatibleWithCurrentBreakdowns() {
    if (this.visualizationOptions && (this.segmentBy || this.viewBy !== TIME)) {
      this.visualizationOptions.showTimeComparison = false;
    }
  }

  resetTargetValuesToOriginal() {
    if (
      !this.supportsFeature(TARGET) &&
      this.visualizationOptions &&
      this.visualizationOptions.target
    ) {
      this.visualizationOptions.target = null;
    } else {
      let val = this.visualizationOptions?.target?.value;
      let unit = this.visualizationOptions?.target?.timeUnit;
      if (unit > 1 && this.chartSeries.firstObject.metric.unit !== 'seconds') {
        if (unit === SECONDS_IN.day) {
          this.visualizationOptions.target.value = val / SECONDS_IN.day;
        } else if (unit === SECONDS_IN.hour) {
          this.visualizationOptions.target.value = val / SECONDS_IN.hour;
        } else if (unit === SECONDS_IN.minute) {
          this.visualizationOptions.target.value = val / SECONDS_IN.minute;
        }
        this.visualizationOptions.target.timeUnit = 1;
      }
    }
  }

  get canEnableRelativeValues() {
    return (
      !this.isMultimetric &&
      this.isStacked &&
      this.metricSupportsRelativeValues &&
      (isPresent(this.segmentBy) || this.supportsStackingWithoutSegmentBy)
    );
  }

  get metricSupportsRelativeValues() {
    if (this.isMultimetric) {
      return false;
    }
    return this.chartSeries.firstObject.metric.supportsRelativeValues;
  }

  ensureRelativeValuesIsCompatibleWithCurrentConfiguration() {
    if (this.visualizationOptions && !this.canEnableRelativeValues) {
      this.visualizationOptions.showRelativeValues = false;
    }
  }

  ensureStackedIsCompatibleWithCurrentVisualization() {
    if (!this.segmentBy && !this.supportsStackingWithoutSegmentBy) {
      this.stacked = false;
    }
  }

  ensureVisualizatonOptionsAreCompatibleWithCurrentConfiguration() {
    this.ensureTimeComparisonIsCompatibleWithCurrentBreakdowns();
    this.ensureHeatmapBreakdownsAreReset();
    this.ensureStackedIsCompatibleWithCurrentVisualization();
    this.ensureRelativeValuesIsCompatibleWithCurrentConfiguration();
  }

  ensureBreakdownsAreCompatibleWithMultiMetricAndVisualization() {
    this.chartSeries.forEach((chartSeries) =>
      this.ensureBreakdownsAreCompatibleWithCurrentMetricAndVisualization(chartSeries.metricId),
    );
  }

  ensureBreakdownsAreCompatibleWithCurrentMetricAndVisualization(metricId) {
    let metric = this.reportingMetrics.getMetricById(metricId);
    if (!this.isSupportedBreakdown(this.viewBy, metric)) {
      this.viewBy = TIME_GROUP_PROPERTY_IDENTIFIER;
    }

    if (this.visualizationType === VISUALIZATION_TYPES.HEATMAP) {
      this.segmentBy = TIME;
      this.viewBy = TIME;
      this.segmentByDisplayLimit = 25;
      this.viewByTimeInterval = DAY_OF_WEEK;
      this.segmentByTimeInterval = HOUR_OF_DAY;
      this.chartSeries.firstObject.showDataLabels = true; // this is fine because heatmaps does not support multimetrics
    } else if (isPresent(this.segmentBy) && !this.isSupportedBreakdown(this.segmentBy, metric)) {
      this.segmentBy = this.isStacked ? this.defaultSegmentByProperty : null;
      this.segmentByDisplayLimit = 7;
      this.segmentByDisplayOther = this.isStacked ? true : this.segmentByDisplayOther;
    }
  }

  ensureHeatmapBreakdownsAreReset() {
    if (
      this.visualizationType !== VISUALIZATION_TYPES.HEATMAP &&
      this.viewByTimeInterval === DAY_OF_WEEK &&
      this.segmentByTimeInterval === HOUR_OF_DAY
    ) {
      this.segmentBy = null;
      this.segmentByDisplayLimit = 7;
      this.viewByTimeInterval = null;
      this.segmentByTimeInterval = null;
    }
  }

  ensureChartSeriesAreSetupForVisualization() {
    let firstMetric = copy(this.chartSeries.firstObject);
    if (this.isMultimetric) {
      if (this.isDualChart && this.chartSeries.length > 2) {
        this.takeChartSeriesSnapshot();
        let secondMetric = copy(this.chartSeries.objectAt(1));
        this.chartSeries = [firstMetric, secondMetric];
      } else if (!this.supportsFeature(MULTI_METRICS, false)) {
        this.takeChartSeriesSnapshot();
        this.chartSeries = [firstMetric];
      }
    } else if (this.isDualChart) {
      this.segmentBy = null;
      this.addChartSeries(false);
    }
  }

  takeChartSeriesSnapshot() {
    this.chartSeriesSnapshot = copy(this.chartSeries, true);
  }

  restoreChartSeriesSnapshot() {
    if (isPresent(this.chartSeriesSnapshot)) {
      this.chartSeries = copy(this.chartSeriesSnapshot, true);
      this.deleteChartSeriesSnapshot();
    }
  }

  deleteChartSeriesSnapshot() {
    this.chartSeriesSnapshot = [];
  }

  isSupportedBreakdown(property, metric) {
    let supportedBreakdownIds = metric.supportedBreakdowns.map((b) => b.field);
    let isSupported = supportedBreakdownIds.includes(property);
    if (this.isMultimetric && this.supportsFeature(MULTI_METRICS)) {
      return isSupported || this.isMultimetricSupportedBreakdown(property);
    }
    return isSupported;
  }

  isMultimetricSupportedBreakdown(property) {
    if (
      property === TIME_GROUP_PROPERTY_IDENTIFIER ||
      (property === 'team' && this.supportsTeamAttribute) ||
      (property === 'teammate' && this.supportsTeammateAttribute)
    ) {
      return true;
    }
    return false;
  }

  updateSafeIntervalLimit() {
    this.viewByTimeInterval = closestSafeIntervalFor(
      this.viewByTimeInterval,
      this.range.inDays,
      this.visualizationType,
    );
    this.segmentByTimeInterval = closestSafeIntervalFor(
      this.segmentByTimeInterval,
      this.range.inDays,
      this.visualizationType,
    );
  }

  get isCounter() {
    return this.visualizationType === VISUALIZATION_TYPES.COUNTER;
  }

  get isTable() {
    return this.visualizationType === VISUALIZATION_TYPES.TABLE;
  }

  get isDonut() {
    return this.visualizationType === VISUALIZATION_TYPES.DONUT;
  }

  get isArea() {
    return this.visualizationType === VISUALIZATION_TYPES.AREA;
  }

  get isHeatmap() {
    return this.visualizationType === VISUALIZATION_TYPES.HEATMAP;
  }

  get isDualChart() {
    return this.visualizationType === VISUALIZATION_TYPES.COLUMN_LINE;
  }

  get isBanner() {
    return this.visualizationType === VISUALIZATION_TYPES.BANNER;
  }

  get isDataTable() {
    return this.visualizationType === VISUALIZATION_TYPES.DATA_TABLE;
  }

  get isStackable() {
    return this.supportsFeature(STACKED);
  }

  get canShowDataLabels() {
    return this.supportsFeature(SHOW_DATA_LABELS);
  }

  get isStacked() {
    return this.stacked && this.isStackable;
  }

  async duplicateChart(requestBody) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let baseUrl = adapter.buildURL(modelName);
    let url = `${baseUrl}/duplicate`;
    return await ajax({ url, type: 'POST', data: JSON.stringify(requestBody) });
  }

  get isSegmentedByTime() {
    return this.isTimePropertyBreakdown(this.segmentBy);
  }

  get isBrokenDownByTime() {
    return this.isTimePropertyBreakdown(this.viewBy);
  }

  isTimePropertyBreakdown(property) {
    return property === 'time' || isDateCDA(property);
  }

  updateChartPosition(gridPositionX, gridPositionY) {
    this.gridPositionX = gridPositionX;
    this.gridPositionY = gridPositionY;
  }

  get minSizeForVisType() {
    return {
      minW: VISUALIZATION_TYPE_GRID_SIZES[this.visualizationType].minWidth,
      minH: VISUALIZATION_TYPE_GRID_SIZES[this.visualizationType].minHeight,
    };
  }

  get isFullWidth() {
    return this.isTable || this.isHeatmap || this.widthType === WIDTH_TYPES.FULL;
  }

  get isXTall() {
    return this.isTable || this.isHeatmap || this.heightType === HEIGHT_TYPES.X_TALL;
  }

  get isFullHeight() {
    return this.heightType === HEIGHT_TYPES.FULL;
  }

  get supportsStackingWithoutSegmentBy() {
    return this.visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR;
  }

  get isSmall() {
    return (
      this.isCounter ||
      (this.visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR &&
        !this.segmentBy &&
        this.isStacked) ||
      this.heightType === HEIGHT_TYPES.SMALL
    );
  }

  get isXSmall() {
    return this.heightType === HEIGHT_TYPES.X_SMALL;
  }

  get isBespoke() {
    return this.visualizationType === VISUALIZATION_TYPES.BESPOKE;
  }

  get isInvisible() {
    return this.visualizationType === VISUALIZATION_TYPES.INVISIBLE;
  }

  get isComparisonCounter() {
    return this.visualizationOptions?.showTimeComparison && this.isCounter;
  }

  get usesOfficeHoursVariantMetric() {
    let areTeamOOVariantsPresent = this.chartSeries.any(
      (chartSeries) => chartSeries.metric.metricVariantType === 'team_office_hours',
    );

    return areTeamOOVariantsPresent && this.range.startMoment.isBefore('2023-07-05T00:00:00Z');
  }

  supportsFeature(featureName, checkMultimetricSupport = true) {
    // there are some cases where we want to skip multimetric validation i.e disabling segmentBy breakdown
    let multimetric = checkMultimetricSupport ? this.isMultimetric : false;
    return visualizationSupportsFeature(this.visualizationType, featureName, multimetric);
  }

  get supportsTeamAttribute() {
    let supportsDynamicTeamAttr = this.chartSeries
      .toArray()
      .every((series) => series.metric.allFieldMetrics.every((metric) => metric.teamProperty));
    return this.supportsFeature(TEAM_TEAMMATE_GROUPING_MULTIMETRIC) && supportsDynamicTeamAttr;
  }

  get supportsTeammateAttribute() {
    let supportsDynamicTeammateAttr = this.chartSeries
      .toArray()
      .every((series) => series.metric.allFieldMetrics.every((metric) => metric.teammateProperty));
    return this.supportsFeature(TEAM_TEAMMATE_GROUPING_MULTIMETRIC) && supportsDynamicTeammateAttr;
  }
}
