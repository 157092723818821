/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import CompanySummary, {
  type CompanySummaryWireFormat,
} from 'embercom/objects/inbox/company-summary';
import GeoipData, { type GeoipDataWireFormat } from './geoip-data';
import { AVATAR_PALETTE } from '@intercom/pulse/lib/palette';

export const AVATAR_COLORS = Object.values(AVATAR_PALETTE);

import md5 from 'blueimp-md5';

// Picks a colour based on a string (email / ID etc)
//
// Uses the same approach as the server [1] by taking a hash of the string
// and converting that to an integer which modulos agains the list of
// colours in order to generate the same 'random' colour for the same string.
//
// Unlike admin IDs user IDs aren't guaranteed to be numbers, so we can't
// simply modulo the user ID like we do for AdminSummary#color
//
// [1] - https://github.com/intercom/intercom/blob/master/app/services/user_service/models/avatar_color.rb#L56
function stringToAvatarColor(
  string: string | undefined,
  colors: Array<(typeof AVATAR_COLORS)[number]>,
) {
  if (!string) {
    return colors[0];
  }

  let chars = md5(string).slice(-8);
  let num = parseInt(chars, 16) || 0;
  let index = num % colors.length;
  let color = colors[index];

  return color;
}

// Actual role values from back end
export enum Role {
  User = 'user_role',
  Lead = 'lead_role',
  Contact = 'contact_role',
}

// Role to be displayed in front-end
export enum RoleLabel {
  User = 'User',
  Lead = 'Lead',
  Contact = 'Contact',
}

export interface UserSummaryWireFormat {
  id: string;
  email?: string;
  name?: string;
  pseudonym?: string;
  company_ids?: Array<string>;
  first_company?: CompanySummaryWireFormat;
  companies?: Array<CompanySummaryWireFormat>;
  geoip_data?: GeoipDataWireFormat;
  merged_into_id?: string;
  role?: Role;
  image_url?: string;
  user_id?: string;
  phone?: string;
  phone_country?: string;
  sdk_supports_ticket?: boolean;
}

export default class UserSummary {
  readonly id: string;
  readonly email?: string;
  readonly name?: string;
  readonly pseudonym?: string;
  readonly companyIds: Array<string>;
  readonly company?: CompanySummary;
  readonly companies?: Array<CompanySummary>;
  readonly sortedCompanies?: Array<CompanySummary>;
  readonly geoipData?: GeoipData;
  readonly mergedIntoId?: string;
  readonly role?: Role;
  readonly imageURL?: string;
  readonly userId?: string;
  readonly phone?: string;
  readonly phoneCountry?: string;
  readonly sdkSupportsTicket?: boolean;

  constructor(
    id: string,
    email?: string,
    name?: string,
    companyIds?: Array<string>,
    company?: CompanySummary,
    companies?: Array<CompanySummary>,
    geoipData?: GeoipData,
    mergedIntoId?: string,
    role?: Role,
    imageURL?: string,
    userId?: string,
    pseudonym?: string,
    phone?: string,
    phoneCountry?: string,
    sdkSupportsTicket?: boolean,
  ) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.companyIds = companyIds ?? [];
    this.company = company;
    this.companies = companies;
    this.sortedCompanies = this.sortCompanies;
    this.geoipData = geoipData;
    this.mergedIntoId = mergedIntoId;
    this.role = role;
    this.imageURL = imageURL;
    this.userId = userId;
    this.pseudonym = pseudonym;
    this.phone = phone;
    this.phoneCountry = phoneCountry;
    this.sdkSupportsTicket = sdkSupportsTicket;
  }

  static deserialize(json: UserSummaryWireFormat): UserSummary {
    let company = undefined;
    if (json.first_company) {
      company = CompanySummary.deserialize(json.first_company);
    }

    let companies = undefined;
    if (json.companies) {
      companies = json.companies.map((company) => CompanySummary.deserialize(company));
    }

    let geoipData = undefined;
    if (json.geoip_data) {
      geoipData = GeoipData.deserialize(json.geoip_data);
    }

    return new UserSummary(
      json.id,
      json.email,
      json.name,
      json.company_ids,
      company,
      companies,
      geoipData,
      json.merged_into_id,
      json.role,
      json.image_url,
      json.user_id,
      json.pseudonym,
      json.phone,
      json.phone_country,
      json.sdk_supports_ticket,
    );
  }

  get displayAs(): string {
    return this.name || this.email || this.pseudonym || this.userId || this.id;
  }

  get firstName(): string | undefined {
    return this.name?.split(' ')[0];
  }

  get emailUsername(): string | undefined {
    return this.email?.split('@')[0];
  }

  private get avatarKey() {
    return this.email || this.pseudonym || this.id;
  }

  get color() {
    return stringToAvatarColor(this.avatarKey, AVATAR_COLORS);
  }

  get initials(): string {
    if (this.role === Role.User) {
      return this.name ? this.name[0] : '?';
    } else {
      return this.email ? this.email[0] : '?';
    }
  }

  get typeLabel(): RoleLabel {
    return this.role === Role.User ? RoleLabel.User : RoleLabel.Lead;
  }

  get isNewUser(): boolean {
    return this.id === '';
  }

  get mostRecentCompany() {
    return this.sortedCompanies?.firstObject || this.company;
  }

  get otherCompanies() {
    if (this.sortedCompanies) {
      let mostRecentCompany = this.mostRecentCompany;

      return this.sortedCompanies.filter(function (company) {
        return company && company !== mostRecentCompany;
      });
    }

    return [];
  }

  private get sortCompanies() {
    let companies = this.companies;
    let companyIds = this.companyIds;

    if (companies && companyIds) {
      let companiesById = new Map<string, CompanySummary>();

      companies.forEach((company: CompanySummary) => {
        companiesById.set(company.id, company);
      });

      return companyIds.map((id) => companiesById.get(id)) as Array<CompanySummary>;
    }

    return companies;
  }
}

export function latestUpdatedRecipientsListStorageKey(
  workspaceId: string,
  conversationId?: number,
) {
  let conversation = conversationId || 'new';
  return `latest-updated-recipients-list-${workspaceId}-${conversation}`;
}

export function getDraftRecipients(
  storage: { get: (arg0: string) => any },
  workspaceId: string,
  conversationId?: number,
) {
  let latestUpdatedRecipientsList = storage.get(
    latestUpdatedRecipientsListStorageKey(workspaceId, conversationId),
  );

  if (!latestUpdatedRecipientsList) {
    return;
  }

  return {
    all: latestUpdatedRecipientsList?.all.map((recipient: UserSummary) =>
      participantObjectToUserSummary(recipient),
    ) as UserSummary[],
    to: latestUpdatedRecipientsList?.to,
    cc: latestUpdatedRecipientsList?.cc,
  };
}

export function latestUpdatedParticipantsStorageKey(workspaceId: string, conversationId?: number) {
  let conversation = conversationId || 'new';
  return `latest-updated-participants-${workspaceId}-${conversation}`;
}

export function getLatestUpdatedParticipants(
  storage: { get: (arg0: string) => any },
  workspaceId: string,
  conversationId?: number,
) {
  let latestUpdatedParticipants = storage.get(
    latestUpdatedParticipantsStorageKey(workspaceId, conversationId),
  );

  return latestUpdatedParticipants?.map((participant: UserSummary) =>
    participantObjectToUserSummary(participant),
  );
}

export function getParticipantChanges(
  existingParticipantsList: UserSummary[] | undefined,
  newParticipantsList: UserSummary[] | undefined,
) {
  let existingParticipantListIds =
    existingParticipantsList?.map((participant) => participant.id) || [];
  let newParticipantsListIds = newParticipantsList?.map((participant) => participant.id) || [];

  let addedParticipants =
    newParticipantsList?.filter(
      // new participants that aren't on the existing list
      (participant) => !existingParticipantListIds.includes(participant.id),
    ) || [];
  let removedParticipants =
    existingParticipantsList?.filter(
      // participants that are existing participants but not in the new list
      (participant) => !newParticipantsListIds.includes(participant.id),
    ) || [];

  return { addedParticipants, removedParticipants };
}

export function participantObjectToUserSummary(participant: UserSummary): UserSummary {
  return new UserSummary(
    participant.id,
    participant.email,
    participant.name,
    participant.companyIds,
    participant.company,
    participant.companies,
    participant.geoipData,
    participant.mergedIntoId,
    participant.role,
    participant.imageURL,
    participant.userId,
    participant.pseudonym,
    participant.phone,
    participant.phoneCountry,
  );
}
