/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

export default class CloseConversation extends Step {
  @service declare appService: any;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.closeConversation;
  }

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): CloseConversation {
    return store.createRecord('operator/visual-builder/step/close-conversation', {
      type: 'operator/visual-builder/step/close-conversation',
    });
  }
}
