/* RESPONSIBLE TEAM: team-ml */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from '../renderable-data';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
/* eslint-disable @intercom/intercom/no-bare-strings */

interface ConversationSummaryWireFormat {
  renderable_type: RenderableType;
  readonly blocks: Array<object>;
  readonly entity: AdminSummaryWireFormat;
}

export default class ConversationSummary implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.ConversationSummary;
  readonly blocks?: Array<object>;
  readonly adminSummary: AdminSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.adminSummary.id, object: this.adminSummary };
  }

  get styles() {
    return {
      backgroundColor: 'bg-notice-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-notice-container-emphasis',
    };
  }

  get isLoading() {
    return this.blocks === undefined;
  }

  constructor(adminSummary: AdminSummary, blocks?: Array<object>) {
    this.blocks = blocks;
    this.adminSummary = adminSummary;
  }

  static deserialize(json: ConversationSummaryWireFormat): ConversationSummary {
    return new ConversationSummary(AdminSummary.deserialize(json.entity), json.blocks);
  }

  static loading(adminSummary: AdminSummary) {
    return new ConversationSummary(adminSummary);
  }
}
