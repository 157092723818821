/* RESPONSIBLE TEAM: team-frontend-tech */

import Modifier from 'ember-modifier';
import type ApplicationInstance from '@ember/application/instance';
import { registerDestructor } from '@ember/destroyable';
import split from 'split.js';
import storage from 'embercom/vendor/intercom/storage';

interface Args {
  Positional: [];
  Named: {
    //Read and store the layout dimensions in storage, the value is an array of numbers
    saveToStorage?: boolean;
    //The key to use when saving the layout dimensions in storage
    storageKey?: string;
    //reset the layout to the initial sizes when the window is resized
    resetOnWindowResize?: boolean;
    //Callback function that is called when the split instance is initialized
    onInit?: (splitInstance: SplitInstance) => void;
    //Callback function that is called when the modifier is destroyed
    onCleanup?: () => void;
  };
}

export interface ResizableSignature {
  Element: HTMLElement;
  Args: Args;
}

export interface SplitInstance extends split.Instance {
  //reset the layout to the initial sizes read from the data attributes
  resetToInitialLayout: () => void;
  //reset the layout to the stored dimensions
  resetToStoredLayout: () => void;
}

interface ResizableState {
  minWidths: number[];
  initialWidths: number[];
}

function cleanup(instance: Resizable) {
  instance.namedArgs?.onCleanup?.();
  instance.splitInstance?.destroy();
  instance.splitInstance = undefined;
  instance.resizableState = undefined;
  instance.namedArgs = undefined;
  window.removeEventListener('resize', instance.windowResizeHandler);
}

/**
 * A modifier that makes the children of the element horizontally resizable using the split.js library.
 * The children that are resizable should have the data attribute `data-resize-target` set.
 * The children can have the following data attributes:
 * - `data-resize-min-width`: The minimum width of the resizable element in pixels.
 * - `data-resize-initial-width-percentage`: The initial width of the resizable element in percentage.
 *
 * Note:
 * - The modifier could be configured to support vertical resizing.
 */
export default class Resizable extends Modifier<ResizableSignature> {
  //The data attribute that is used to select the elements that should be resizable (placed on the children of the element with the modifier)
  RESIZE_TARGET_SELECTOR = 'data-resize-target';
  //The data attribute that is used to set the minimum width of the resizable element in pixels. (e.g. data-resize-min-width="200")
  MIN_WIDTH_ATTRIBUTE = 'data-resize-min-width';
  //The data attribute that is used to set the initial width of the resizable element in percentage. (e.g. data-resize-initial-width-percentage="50")
  INITIAL_WIDTH_ATTRIBUTE = 'data-resize-initial-width-percentage';
  //The data attribute placed on the handle elements (the handle is created by the split.js library when the modifier is initialized)
  HANDLE_DATA_ATTRIBUTE = 'data-resize-handle';

  splitInstance?: SplitInstance;
  resizableState?: ResizableState;
  namedArgs?: Args['Named'];

  constructor(owner: ApplicationInstance, args: never) {
    super(owner, args);
    this.init = this.init.bind(this);
    this.resetToInitialLayout = this.resetToInitialLayout.bind(this);
    this.resetToStoredLayout = this.resetToStoredLayout.bind(this);
    this.storeLayout = this.storeLayout.bind(this);
    this.getStoredLayout = this.getStoredLayout.bind(this);
    this.windowResizeHandler = this.windowResizeHandler.bind(this);

    registerDestructor(this, cleanup);
  }

  modify(element: HTMLElement, _postional: Args['Positional'], named: Args['Named']) {
    this.namedArgs = named;
    this.init(element);
    if (this.namedArgs.resetOnWindowResize) {
      window.addEventListener('resize', this.windowResizeHandler);
    }
  }

  resetToStoredLayout() {
    if (!this.splitInstance || !this.resizableState) {
      return;
    }

    let dimensions = this.getStoredLayout() || this.resizableState.initialWidths;
    this.splitInstance.setSizes(dimensions);
  }

  resetToInitialLayout() {
    if (!this.splitInstance || !this.resizableState) {
      return;
    }
    let dimensions = this.resizableState.initialWidths;
    this.splitInstance.setSizes(dimensions);
    this.storeLayout(dimensions);
  }

  init(element: HTMLElement) {
    let childNodes = this.getChildResizeTargetElements(element);
    this.resizableState = this.getResizeOptionsFromNodes(childNodes);

    this.splitInstance = split(childNodes, {
      minSize: this.resizableState.minWidths,
      sizes: this.getStoredLayout() || this.resizableState.initialWidths,
      gutterSize: 2,
      expandToMin: true,
      gutter: () => {
        let gutter = document.createElement('div');
        gutter.addEventListener('dblclick', this.resetToInitialLayout);
        gutter.className =
          'h-full hover:bg-accent-fill active:bg-accent-fill cursor-col-resize relative z-50';
        //the details panel is set to z-index 2500, so we need to set the gutter to a higher z-index
        gutter.style.zIndex = '2505';
        gutter.setAttribute(this.HANDLE_DATA_ATTRIBUTE, '');

        //increase the draggable area of the gutter, with an invisible child element
        let gutterChild = document.createElement('div');
        gutterChild.className =
          'absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2 w-2 h-full';

        gutter.appendChild(gutterChild);
        return gutter;
      },
      onDragEnd: (sizes: number[]) => {
        this.storeLayout(sizes);
      },
      elementStyle: (_dimension, size, gutterSize, index) => {
        let minWidth = this.resizableState?.minWidths?.[index] || 0;

        return {
          'flex-basis': `calc(${size}% - ${gutterSize}px)`,
          'min-width': `${minWidth}px`,
        };
      },
    }) as SplitInstance;

    this.splitInstance.resetToInitialLayout = this.resetToInitialLayout;
    this.splitInstance.resetToStoredLayout = this.resetToStoredLayout;

    this.namedArgs?.onInit?.(this.splitInstance);
  }

  windowResizeHandler() {
    this.resetToInitialLayout();
  }

  private isUninitializedInitialSize(size: number) {
    return isNaN(size) || size < 0 || size > 100;
  }

  private getChildResizeTargetElements(element: HTMLElement) {
    let childElements = [...element.children] as HTMLElement[];
    return childElements.filter((child) => child.hasAttribute(this.RESIZE_TARGET_SELECTOR));
  }

  private getResizeOptionsFromNodes(childNodes: HTMLElement[]) {
    let uninitializedInitialSizeCount = 0;
    let initialSizeSum = 0;

    let resizableState: ResizableState = { minWidths: [], initialWidths: [] };

    childNodes.forEach((node) => {
      let minWidth = parseInt(node.getAttribute(this.MIN_WIDTH_ATTRIBUTE) || '', 10);
      let initialWidth = parseInt(node.getAttribute(this.INITIAL_WIDTH_ATTRIBUTE) || '', 10);
      resizableState.minWidths.push(minWidth);
      resizableState.initialWidths.push(initialWidth);

      if (this.isUninitializedInitialSize(initialWidth)) {
        uninitializedInitialSizeCount++;
      } else {
        initialSizeSum += initialWidth;
      }
    });

    let equalSplit = (100 - initialSizeSum) / uninitializedInitialSizeCount;

    if (equalSplit < 0 || equalSplit > 100) {
      console.error('Invalid initial width values');
      return resizableState;
    }

    resizableState.initialWidths.forEach((initialWidth, index) => {
      if (this.isUninitializedInitialSize(initialWidth)) {
        resizableState.initialWidths[index] = equalSplit;
      }
    });

    return resizableState;
  }

  private storeLayout(sizes: number[]) {
    if (!this.namedArgs?.saveToStorage || !this.namedArgs?.storageKey) {
      return;
    }
    storage.set(this.namedArgs.storageKey, JSON.stringify(sizes));
  }

  private getStoredLayout(): number[] | void {
    if (!this.namedArgs?.saveToStorage || !this.namedArgs?.storageKey) {
      return;
    }
    let storedLayout = storage.get(this.namedArgs.storageKey);

    try {
      let parsedLayout = JSON.parse(storedLayout);
      if (
        Array.isArray(parsedLayout) &&
        parsedLayout.length === this.resizableState?.initialWidths.length
      ) {
        return parsedLayout;
      }
    } catch (e) {
      return;
    }
  }
}
