/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';

const Validations = buildValidations({
  teamId: [validator('presence', true)],
  hasOutboundNumbers: [validator('has-outbound-phone-number')],
});

export default class Callback extends Step.extend(Validations) {
  @attr('string') declare teamId: string;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.callback;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): Callback {
    return store.createRecord('operator/visual-builder/step/callback', {
      type: 'operator/visual-builder/step/callback',
    });
  }
}
