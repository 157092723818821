/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class AttributeCollectorSelectorParams extends Fragment {
  @attr('string') declare selectionAttributeIdentifier: string;
  @attr('string') declare displayAttributeIdentifier: string;
  @attr('string') declare choiceAttributeIdentifier: string;
  @attr('boolean', { defaultValue: true }) declare showNoneOfTheAboveOption: boolean;
}
