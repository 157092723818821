/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { equal } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';

const ENDPOINT_URL = '/ember/mailchimp_lists';

let MailchimpList = EmberObject.extend({
  id: null,
  stats: null,
  name: null,
  date_created: null,
  empty: equal('stats.member_count', 0),
  isSelected: false,
}).reopenClass({
  createFromPayload(listPayload) {
    return this.create({
      id: listPayload.id,
      stats: listPayload.stats,
      name: listPayload.name,
      date_created: listPayload.date_created,
    });
  },
  async list(appId) {
    let response = await ajax({
      url: ENDPOINT_URL,
      type: 'POST',
      data: JSON.stringify({
        app_id: appId,
      }),
    });
    let listObjects = response.mailchimp_lists.map((list) => this.createFromPayload(list));
    return A(listObjects);
  },
  async singleList(appId, listID) {
    try {
      let response = await ajax({
        url: `${ENDPOINT_URL}/single_list`,
        type: 'POST',
        data: JSON.stringify({
          app_id: appId,
          list_id: listID,
        }),
      });
      return this.createFromPayload(response.mailchimp_list);
    } catch (error) {
      return error;
    }
  },
});

export default MailchimpList;
