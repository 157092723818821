/* RESPONSIBLE TEAM: team-tickets-1 */
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface TagSummary {
  id: number;
  name: string;
}

interface ConversationTagsUpdatedWireFormat {
  renderable_type: RenderableType;
  tagging_entity: AdminSummaryWireFormat;
  tags_added: TagSummary[];
  tags_removed: TagSummary[];
}

export default class ConversationTagsUpdated {
  readonly renderableType = RenderableType.ConversationTagsUpdated;
  readonly tagsAdded: TagSummary[] = [];
  readonly tagsRemoved: TagSummary[] = [];
  readonly taggingEntity: AdminSummary;

  constructor(taggingEntity: AdminSummary, tagsAdded: TagSummary[], tagsRemoved: TagSummary[]) {
    this.tagsAdded = tagsAdded;
    this.tagsRemoved = tagsRemoved;
    this.taggingEntity = taggingEntity;
  }

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.taggingEntity.id,
      object: this.taggingEntity,
    };
  }

  get adminOnly() {
    return true;
  }

  get styles() {
    return {
      backgroundColor: 'bg-beta-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-beta-container-emphasis',
    };
  }

  static deserialize(json: ConversationTagsUpdatedWireFormat): ConversationTagsUpdated {
    let taggingEntity = AdminSummary.deserialize(json.tagging_entity);
    return new ConversationTagsUpdated(taggingEntity, json.tags_added, json.tags_removed);
  }
}
