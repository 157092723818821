/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';

export const APP_PACKAGE_INVITE_FORM_STATES = {
  collapsed: 'COLLAPSED',
  expanded: 'EXPANDED',
};

export default Model.extend({
  appId: attr('string'),
  appPackage: belongsTo('appstore/app-package'),
  state: attr('string'),
});
