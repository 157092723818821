/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import CustomBotSummary, {
  type CustomBotSummaryWireFormat,
} from 'embercom/objects/inbox/custom-bot-summary';

interface BotNoteWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  entity: AdminSummaryWireFormat;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class BotNote implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.BotNote;
  readonly blocks: Array<object>;
  readonly adminSummary: AdminSummary;
  readonly customBotSummary?: CustomBotSummary;

  get entity() {
    return this.adminSummary;
  }

  get creatingEntity() {
    return {
      type: EntityType.Bot,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  get styles() {
    return {
      backgroundColor: 'bg-notice-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-notice-container-emphasis',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-transparent',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container',
    };
  }

  constructor(blocks: Array<object>, entity: AdminSummary, customBotSummary?: CustomBotSummary) {
    this.blocks = blocks;
    this.adminSummary = entity;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: BotNoteWireFormat): BotNote {
    return new BotNote(
      json.blocks,
      AdminSummary.deserialize(json.entity),
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
