/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import DuplicateConversationSummary, {
  type DuplicateConversationSummaryWireFormat,
} from '../duplicate-conversation-summary';

interface UnknownParticipantForEmailConversationNoteWireFormat {
  renderable_type: RenderableType;
  conversation_summary: DuplicateConversationSummaryWireFormat;
}

export default class UnknownParticipantForEmailConversationNote implements RenderableData {
  readonly renderableType: RenderableType =
    RenderableType.UnknownParticipantForEmailConversationNote;
  readonly conversationSummary: DuplicateConversationSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  get styles() {
    return {
      backgroundColor: 'bg-notice-container ',
      textColor: 'text-default  ',
    };
  }

  constructor(conversationSummary: DuplicateConversationSummary) {
    this.conversationSummary = conversationSummary;
  }

  static deserialize(
    json: UnknownParticipantForEmailConversationNoteWireFormat,
  ): UnknownParticipantForEmailConversationNote {
    let conversationSummary = DuplicateConversationSummary.deserialize(json.conversation_summary);
    return new UnknownParticipantForEmailConversationNote(conversationSummary);
  }
}
