/* RESPONSIBLE TEAM: team-data-foundations */
export default {
  string: 'settings.data-format-map.string',
  role: 'settings.data-format-map.role',
  last_conversation_rating_given: 'settings.data-format-map.last-conversation-rating-given',
  date: 'settings.data-format-map.date',
  integer: 'settings.data-format-map.integer',
  fixnum: 'settings.data-format-map.fixnum',
  browser_locale: 'settings.data-format-map.browser-locale',
  downcased_string: 'settings.data-format-map.downcased-string',
  tag: 'settings.data-format-map.tag',
  manual_tag: 'settings.data-format-map.manual-tag',
  boolean: 'settings.data-format-map.boolean',
  float: 'settings.data-format-map.float',
  ios_device: 'settings.data-format-map.ios-device',
  ios_version: 'settings.data-format-map.ios-version',
  android_app_version: 'settings.data-format-map.android-app-version',
  android_device: 'settings.data-format-map.android-device',
  android_version: 'settings.data-format-map.android-version',
  plan: 'settings.data-format-map.plan',
  company_tag: 'settings.data-format-map.company-tag',
  owner_id: 'settings.data-format-map.owner-id',
  account_id: 'settings.data-format-map.account-id',
  relationship: 'settings.data-format-map.relationship',
};
