/* RESPONSIBLE TEAM: team-data-foundations */
import { attr } from '@ember-data/model';
import Import from './import';
import ajax from 'embercom/lib/ajax';

export default Import.extend({
  appIdCode: attr(),
  adminId: attr(),
  apiKey: attr(),
  apiSecret: attr(),
  userCount: attr(),
  manualTagName: attr(),
  manualTagId: attr(),

  completeFor(app_id) {
    return ajax({
      url: '/ember/mixpanel_imports/complete',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id,
        id: window.mixpanel_import_id,
      }),
    });
  },
});
